import { MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';
import { Unit } from './RowText';

const RowSelectComponent = (props) => {
    const { name, initValue, blockNumber, listValue, symbol, paddingLeftDes } = props;
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const dispatch = useDispatch();

    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);

    const blockData = sectionData?.data?.version110?.[blockNumber];

    const [valueSelectBox, setValueSelectBox] = useState(initValue || listValue[0].value);

    useEffect(() => {
        setValueSelectBox(initValue);
    }, [initValue]);

    // console.log(initValue);

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const handleChange = (event) => {
        let newValue = event.target.value;
        setValueSelectBox(newValue);

        if (newValue !== initValue) {
            let dataChanged = {
                ...blockData,
                [name]: { value: newValue.toString() },
            };
            // let sectionDataChanged = {
            //     ...sectionData.data,
            //     [blockNumber]: dataChanged,
            // };

            let sectionDataChanged = {
                ...sectionData.data,
                version110: { ...sectionData.data?.version110, [blockNumber]: dataChanged },
            };

            // console.log(sectionDataChanged);

            if (!_.isEqual(blockData, dataChanged)) {
                // console.log(blockData, dataChanged);
                updateData(sectionDataChanged);
            }
        }
    };
    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '33%', alignItems: 'center', marginLeft: 10, paddingLeft: paddingLeftDes ? paddingLeftDes : 0 }}>
                <Typography>{props.description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '7%' }}>
                <Typography sx={{ width: symbol?.mathSymbol ? '70%' : '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>

                {symbol?.mathSymbol && <Typography sx={{ width: '30%' }}>{` ${symbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '15%' }}>
                <Select
                    defaultValue={initValue || listValue[0].value}
                    disabled={canEdited}
                    value={valueSelectBox ?? listValue[0].value}
                    onChange={handleChange}
                    sx={{
                        width: '100%',
                        textAlign: 'right',
                        height: '32px',
                        padding: 0,
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                    }}
                >
                    {listValue.map((item) => {
                        return (
                            <MenuItem key={item.id} value={item.value}>
                                {item.value}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', paddingLeft: 3 }}>
                <Unit unit={props?.unit} />
            </Box>
        </Box>
    );
};
export default RowSelectComponent;
