import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import SectionTitle from '../share-components/Tilte';
import image from './3_1.png';

const Block0301 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block003`];
    const cacheData = cacheStore((state) => state.cacheData);
    // Dùng để lưu cache, nó là 1 bộ nhớ đệm, loại bỏ trường hợp khi nhập dữ liệu quá nhanh, dữ liệu sẽ bị mất
    const setCacheData = cacheStore((state) => state.setCacheData);

    useEffect(() => {
        setCacheData(blockData);
    }, [blockData]);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="Block0301" height="650px" />
                    </article>
                    <Typography className="absolute w-full flex top-[2.25rem] left-[1.5rem] text-sm text-center justify-center">面方向</Typography>

                    <Typography className="absolute w-full flex top-[13.75rem] left-[-15.5rem] text-sm text-center justify-center">{`${blockData?.f118?.value} 段目`}</Typography>
                    <Typography className="absolute w-full flex top-[22.5rem] left-[-15.5rem] text-sm text-center justify-center">2 段目</Typography>
                    <Typography className="absolute w-full flex top-[24.75rem] left-[-15.5rem] text-sm text-center justify-center">1 段目</Typography>

                    <Typography className="absolute w-full flex top-[3.75rem] left-[-3rem] text-sm text-center justify-center">
                        <div>
                            <span>X</span>
                            <span dangerouslySetInnerHTML={{ __html: `<sub>L</sub>` }} />
                            <span> = </span>
                            {!isNaN(parseFloat(blockData?.f104?.value)) ? blockData?.f104?.value : 0}
                        </div>
                    </Typography>

                    <Typography className="absolute w-full flex top-[3.75rem] left-[6rem] text-sm text-center justify-center">
                        <div>
                            <span>X</span>
                            <span dangerouslySetInnerHTML={{ __html: `<sub>R</sub>` }} />
                            <span> = </span>
                            {!isNaN(parseFloat(blockData?.sheet4Data?.aK12?.value)) ? blockData?.sheet4Data?.aK12?.value : 0}
                        </div>
                    </Typography>

                    <Typography className="absolute w-full flex top-[7rem] left-[-6rem] text-sm text-center justify-center">H2</Typography>

                    <Typography className="absolute w-full flex top-[12.5rem] left-[-6rem] text-sm text-center justify-center">(xmL;yn)</Typography>
                    <Typography className="absolute w-full flex top-[12.5rem] left-[-0.5rem] text-sm text-center justify-center">(x1L;yn)</Typography>
                    <Typography className="absolute w-full flex top-[12.5rem] left-[3.5rem] text-sm text-center justify-center">(x1R;yn)</Typography>
                    <Typography className="absolute w-full flex top-[12.5rem] left-[9rem] text-sm text-center justify-center">(xmR;yn)</Typography>

                    <Typography className="absolute w-full flex top-[24rem] left-[-6rem] text-sm text-center justify-center">(xmL;y1)</Typography>

                    <Typography className="absolute w-full flex top-[24rem] left-[-0.5rem] text-sm text-center justify-center">(x1L;y1)</Typography>
                    <Typography className="absolute w-full flex top-[24rem] left-[3.5rem] text-sm text-center justify-center">(x1R;y1)</Typography>

                    <Typography className="absolute w-full flex top-[24rem] left-[9.5rem] text-sm text-center justify-center">(xmR;y1)</Typography>

                    <Typography className="absolute w-full flex top-[18rem] left-[-18rem] text-sm text-center justify-center -rotate-90">y方向（縦方向）</Typography>
                    <Typography className="absolute w-full flex top-[23.5rem] left-[-9.35rem] text-sm text-center justify-center">Y</Typography>

                    <Typography className="absolute w-full flex top-[16.75rem] left-[-4.5rem] text-sm text-center justify-center">ボルト群の中心</Typography>
                    <Typography className="absolute w-full flex top-[28.75rem] left-[1.5rem] text-sm text-center justify-center">原点 (x0,y0)</Typography>
                    <Typography className="absolute w-full flex top-[28.75rem] left-[-4.25rem] text-sm text-center justify-center">X</Typography>
                    <Typography className="absolute w-full flex top-[30.25rem] left-[1.5rem] text-sm text-center justify-center">x方向（面方向）</Typography>

                    <Typography className="absolute w-full flex top-[37.5rem] left-[-6.5rem] text-sm text-center justify-center -rotate-90">{`${Math.floor(
                        blockData?.f119?.value / 2,
                    )} 左列目`}</Typography>
                    <Typography className="absolute w-full flex top-[37.5rem] left-[-2.0rem] text-sm text-center justify-center -rotate-90">2 左列目</Typography>
                    <Typography className="absolute w-full flex top-[37.5rem] left-[0rem] text-sm text-center justify-center -rotate-90">1 左列目</Typography>
                    <Typography className="absolute w-full flex top-[36.75rem] left-[1.25rem] text-sm text-center justify-center -rotate-90">中心</Typography>
                    <Typography className="absolute w-full flex top-[37.5rem] left-[2.5rem] text-sm text-center justify-center -rotate-90">1 右列目</Typography>
                    <Typography className="absolute w-full flex top-[37.5rem] left-[4.5rem] text-sm text-center justify-center -rotate-90">2 右列目</Typography>
                    <Typography className="absolute w-full flex top-[37.5rem] left-[8.75rem] text-sm text-center justify-center -rotate-90">{`${Math.floor(
                        blockData?.f119?.value / 2,
                    )} 左列目`}</Typography>

                    <Typography className="absolute w-full flex top-[19rem] left-[-14rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK20?.value) ? '0' : `${blockData?.sheet4Data?.aK20?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[19rem] left-[-12rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK18?.value) ? '0' : `${blockData?.sheet4Data?.aK18?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[26.5rem] left-[-12rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK19?.value) ? '0' : `${blockData?.sheet4Data?.aK19?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[11rem] left-[-12rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK17?.value) ? '0' : `${blockData?.sheet4Data?.aK17?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[24rem] left-[15rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK22?.value) ? '0' : `${blockData?.sheet4Data?.aK22?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[13rem] left-[15rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK21?.value) ? '0' : `${blockData?.sheet4Data?.aK21?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[13.25rem] left-[13rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aL37?.value) ? '0' : `${blockData?.sheet4Data?.aL37?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[7.25rem] left-[13rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(blockData?.sheet4Data?.aK10?.value) ? '0' : `h3 = ${blockData?.sheet4Data?.aK10?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[31.75rem] left-[1rem] text-sm text-center justify-center">
                        {isNaN(blockData?.sheet4Data?.aK13?.value) ? '0' : `${blockData?.sheet4Data?.aK13?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[31.75rem] left-[10rem] text-sm text-center justify-center">
                        {isNaN(blockData?.sheet4Data?.aK15?.value) ? '0' : `${blockData?.sheet4Data?.aK15?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[31.75rem] left-[-7.5rem] text-sm text-center justify-center">
                        {isNaN(blockData?.sheet4Data?.aK14?.value) ? '0' : `${blockData?.sheet4Data?.aK14?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex top-[33.75rem] left-[1rem] text-sm text-center justify-center">
                        {isNaN(blockData?.sheet4Data?.aK16?.value) ? '0' : `${blockData?.sheet4Data?.aK16?.value}`}
                    </Typography>
                </div>

                <SectionTitle title="ストッパー位置" />

                <RowTextInputComponent
                    blockNumber="block003"
                    name="f104"
                    description="ブラケット左端～ストッパー中心"
                    initValue={blockData?.f104?.value}
                    symbol={{ normalText: 'X', subText: 'L', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block003"
                    name="f106"
                    description="ベース～ストッパー中心"
                    initValue={blockData?.f106?.value}
                    symbol={{ normalText: 'L1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0301;
