//data Fake

const listToolsData = [
    {
        toolId: 1,
        avaSrc: '',
        toolName: 'Bridge collapse prevention 01',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 1,
                avaSrc: '',
                fileName: 'Project_01',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 2,
                avaSrc: '',
                fileName: 'Project_02',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 3,
                avaSrc: '',
                fileName: 'Project_03',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },
    {
        toolId: 2,
        avaSrc: '',
        toolName: 'Tools 02',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 4,
                avaSrc: '',
                fileName: 'Project_04',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 5,
                avaSrc: '',
                fileName: 'Project_05',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 6,
                avaSrc: '',
                fileName: 'Project_06',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },
    {
        toolId: 3,
        avaSrc: '',
        toolName: 'Add-in 03',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 7,
                avaSrc: '',
                fileName: 'Project_07',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 8,
                avaSrc: '',
                fileName: 'Project_08',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 9,
                avaSrc: '',
                fileName: 'Project_09',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },
    {
        toolId: 4,
        avaSrc: '',
        toolName: 'Bridge 04',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 10,
                avaSrc: '',
                fileName: 'Project_10',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 11,
                avaSrc: '',
                fileName: 'Project_11',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 12,
                avaSrc: '',
                fileName: 'Project_12',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },

    {
        toolId: 5,
        avaSrc: '',
        toolName: 'Bridge collapse prevention 05',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 13,
                avaSrc: '',
                fileName: 'Project_13',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 14,
                avaSrc: '',
                fileName: 'Project_14',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 15,
                avaSrc: '',
                fileName: 'Project_15',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },

    {
        toolId: 6,
        avaSrc: '',
        toolName: 'POSIDEN 06',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 88,
                avaSrc: '',
                fileName: 'Project_88',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 99,
                avaSrc: '',
                fileName: 'Project_99',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 77,
                avaSrc: '',
                fileName: 'Project_77',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 50,
                avaSrc: '',
                fileName: 'Project_50',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 51,
                avaSrc: '',
                fileName: 'Project_51',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 52,
                avaSrc: '',
                fileName: 'Project_52',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 53,
                avaSrc: '',
                fileName: 'Project_53',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 54,
                avaSrc: '',
                fileName: 'Project_54',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 55,
                avaSrc: '',
                fileName: 'Project_55',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 56,
                avaSrc: '',
                fileName: 'Project_56',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 57,
                avaSrc: '',
                fileName: 'Project_57',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 58,
                avaSrc: '',
                fileName: 'Project_58',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },

    {
        toolId: 7,
        avaSrc: '',
        toolName: 'Bridge collapse prevention 07',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
    },

    {
        toolId: 8,
        avaSrc: '',
        toolName: 'Bridge collapse prevention 08',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
    },

    {
        toolId: 9,
        avaSrc: '',
        toolName: 'Bridge collapse prevention 06',
        descripton:
            'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Doloremque laudantium provident dignissimos molestiae? Incidunt molestias pariatur sequi? Assumenda aperiam, voluptatibus, molestiae laborum consectetur atque corrupti, magnam expedita adipisci facere animi.',
        toolLink: '',
        sheets: [
            {
                sheetId: 19,
                avaSrc: '',
                fileName: 'Project_16',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 20,
                avaSrc: '',
                fileName: 'Project_14',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
            {
                sheetId: 21,
                avaSrc: '',
                fileName: 'Project_17',
                modifiedDate: '04/02/2022',
                fileSize: '120KB',
            },
        ],
    },
];

export default listToolsData;
