import Block0601 from './Block0601';

const Section06 = (props) => {
    return (
        <>
            <Block0601 />
        </>
    );
};

export default Section06;
