import axiosClient from './configs/axiosClient';

const CommentAPI = {
    async getComments() {
        const url = `/sheetcomments`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getCommentBySheetId(sheetId) {
        const url = `/sheetcomments/sheet/${sheetId}`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async postComment(params) {
        const url = `/sheetcomments`;
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: params,
        });
    },

    async updateComment(commentId, commentContent) {
        const url = `/sheetcomments/${commentId}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
            data: commentContent,
        });
    },
};

export default CommentAPI;
