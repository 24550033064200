import React from 'react';
import { Grid, Stack, Typography, Box, Button } from '@mui/material';

SignUpSuccess.propTypes = {};

function SignUpSuccess(props) {
    return (
        <Box>
            <Grid
                item
                spacing={3}
                sx={{
                    position: 'absolute',
                    height: 500,
                    top: '50%',
                    left: '50%',
                    width: '600px',
                    marginTop: '-90px',
                    marginLeft: '-300px',
                }}
            >
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
                        <Typography variant="h3" align="center">
                            Hozen System
                        </Typography>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        height: 350,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyItems: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography marginTop={2} color="primary" variant="body1" sx={{ marginBottom: '20px', fontSize: '18px', marginTop: '40px' }}>
                        Sign up successfully!
                    </Typography>
                    <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="primary" sx={{ width: 200 }}>
                        Continue
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SignUpSuccess;
