import { useCallback, useEffect } from 'react';
// material-ui
import { Box } from '@mui/system';
// import SheetAPI from 'A_API/SheetAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import 'simplebar-react/dist/simplebar.min.css';
import { changeIsLoading } from 'store/reducers/calculatationSlice';
import { getCommentsBySheetId } from 'store/reducers/commentsSlice';
import { getListSectionsOfSheet, getSheetById } from 'store/reducers/sheetSlice';
import RightPanel from './right-panel/RightPanel';
import Tool001V100 from './tool001/version_1.0.0/left-panel/Tool001V110';
import Tool001V110 from './tool001/version_1.1.0/left-panel/Tool001V110';
import Tool002V100 from './tool002/version_1.0.0/left-panel/Tool002V100';
// import LeftPanel from './components/left-panel/LeftPanel';

// ==============================|| TOOL CALCULATE||============================== //

const ToolCalculate = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const fileInfoInit = location?.state?.fileInfoInit;
    //fetch data
    const sheetCurrent = useSelector((state) => state.sheet.sheetActive);

    const currentId = fileInfoInit?.id ?? sheetCurrent.id;
    //fetch data
    const initFetch = useCallback(() => {
        dispatch(changeIsLoading(true));
        dispatch(getSheetById(currentId));
        dispatch(getListSectionsOfSheet(currentId));
        dispatch(getCommentsBySheetId(currentId));
        dispatch(changeIsLoading(false));
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const RenderToolView = ({ tool, versionId }) => {
        const listTools = {
            Tool001: {
                '1.0.0': <Tool001V100 />,
                '1.1.0': <Tool001V110 />,
            },
            Tool002: {
                '1.0.0': <Tool002V100 />,
            },
        };

        const toolView = listTools[tool]?.[versionId] || <Tool001V100 />;

        // console.log(tool, versionId, toolView);

        return toolView;
    };

    // const leftPanel = RenderToolView(<RenderToolView tool={sheetCurrent?.tool.toolCode} versionId={sheetCurrent?.tool.versionId} />);

    // Call renderToolView to get the toolView
    const leftPanel = RenderToolView({
        tool: sheetCurrent?.tool?.toolCode,
        versionId: sheetCurrent?.tool?.versionId,
    });

    return (
        <>
            <div className="xl:w-[71%] 2xl:w-[75%] w-full sticky top-0 left-0 bg-zinc-50 z-50">
                {/* <SectionSelection /> */}
                {leftPanel}
            </div>

            {/* <div className="pt-3 pl-3 pr-3 w-full xl:w-9/12 overflow-hidden min-w-[9/12]">
               
            </div> */}
            <div className="2xl:w-[20%] xl:w-[23%] fixed right-5 top-11 bg-zinc-50 h-full hidden xl:block">
                <Box
                    component="div"
                    sx={{
                        borderLeft: '1px solid #d1d5db',
                        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                        borderRadius: '5px',
                        backgroundColor: 'white',
                    }}
                >
                    <RightPanel />
                </Box>
            </div>
        </>
    );
};

export default ToolCalculate;
