import axios from 'axios';
import axiosClient from './configs/axiosClient';

const MenuAPI = {
    async getListFilesFake() {
        const url = `https://mocki.io/v1/29ab7a95-ec0f-487f-ae98-a0ce78114d3d`;
        return axios.get(url);
    },

    async GetListTools() {
        const url = `tools`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getListSheets() {
        const url = `/sheets/byusercompany`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getListSheetsDeleted() {
        const url = `/sheets/isactived/false`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },
};

export default MenuAPI;
