import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FieldStatus } from 'utils/StringDefinition';
import ResultInRow from './ResultInRow';
import { CompletedIcon, ErrorIcon, NAIcon } from './StatusIcon';

const RowTextComponent = (props) => {
    const {
        description,
        isEqualTo,
        descriptionWidth,
        physicSymbolWidth,
        resultInRowWidth,
        status,
        physicSymbolLeft,
        physicSymbolRight,
        valueLeft,
        mathSymbol,
        valueRight,
        valueStandardSymbol,
        unit,
        tooltipLeft,
        paddingLeftFit,
        tooltipRight,
    } = props;
    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: descriptionWidth ? descriptionWidth : '40%', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 10 : 10 }}>
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: resultInRowWidth ? resultInRowWidth : '50%' }}>
                <ResultInRow
                    isEqualTo={isEqualTo}
                    paintColor={paintColor}
                    physicSymbolWidth={physicSymbolWidth}
                    physicSymbolLeft={physicSymbolLeft}
                    valueLeft={valueLeft}
                    tooltipLeft={tooltipLeft}
                    mathSymbol={mathSymbol}
                    physicSymbolRight={physicSymbolRight}
                    valueStandardSymbol={valueStandardSymbol}
                    valueRight={valueRight}
                    tooltipRight={tooltipRight}
                    unit={unit}
                />
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 4.5 }}>
                    {/* {console.log(status)} */}
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};
export default RowTextComponent;
