import { SearchOutlined } from '@ant-design/icons';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select from '@mui/material/Select/Select';
import { useTranslation } from 'react-i18next';
import { SheetStatus } from 'utils/StringDefinition';

const ListFilesFunction = (props) => {
    const { t } = useTranslation();
    const { searchKeywords, handleSearchInput, searchStatus, setSearchStatus } = props;

    return (
        <>
            {/* SearchBox */}
            <Box
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'start',
                    pl: 2,
                    pr: 2,
                }}
            >
                <FormControl
                    sx={{
                        width: '10%',
                    }}
                >
                    <Select
                        value={searchStatus}
                        onChange={(e) => {
                            setSearchStatus(e.target.value);
                            // console.log(e.target.value);
                            handleSearchInput(searchKeywords, e.target.value);
                        }}
                    >
                        <MenuItem value={SheetStatus.ALL}>{t(`calculationTable.${SheetStatus.ALL}`)}</MenuItem>
                        <MenuItem value={SheetStatus.EDITING}>{t(`calculationTable.${SheetStatus.EDITING}`)}</MenuItem>
                        <MenuItem value={SheetStatus.REVIEWING}>{t(`calculationTable.${SheetStatus.REVIEWING}`)}</MenuItem>
                        <MenuItem value={SheetStatus.COMPLETED}>{t(`calculationTable.${SheetStatus.COMPLETED}`)}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl
                    sx={{
                        width: '30%',
                        pl: 2,
                    }}
                >
                    <OutlinedInput
                        size="large"
                        id="margin-normal"
                        startAdornment={
                            <InputAdornment
                                position="start"
                                sx={{
                                    mr: -0.5,
                                }}
                            >
                                <SearchOutlined />
                            </InputAdornment>
                        }
                        value={searchKeywords}
                        onChange={(e) => {
                            handleSearchInput(e.target.value, searchStatus);
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        sx={{
                            '& legend': {
                                display: 'none',
                            },
                            '& fieldset': {
                                top: 0,
                            },
                        }}
                        placeholder={t('calculationTable.searchBoxPalaceHolder')}
                    />
                </FormControl>
                {/* Add Button */}
                {/* <Button variant="contained" sx={{ textTransform: 'capitalize', bgcolor: 'primary.main', width: '125px' }} onClick={onNewFileClick}>
                    <PlusOutlined />
               
                </Button> */}
            </Box>
        </>
    );
};

export default ListFilesFunction;
