import Block0501 from './Block0501';
import Block0502 from './Block0502';

const Section05 = (props) => {
    return (
        <>
            <Block0501 />
            <Block0502 />
        </>
    );
};

export default Section05;
