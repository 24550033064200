import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import RowSelectComponent from '../share-components/RowSelect';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';

const Block04 = (props) => {
    const { blockData } = props;

    const listUpperFitting = [
        { id: 1, value: '塗装' },
        { id: 2, value: '溶融亜鉛メッキ' },
        { id: 3, value: '耐候性' },
    ];

    return (
        <>
            <Card
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 16px 0px 16px',
                }}
            >
                <Stack sx={{ width: '100%' }}>
                    <SectionTitle title="防錆仕様" />
                    <RowSelectComponent blockNumber="block004" name="aY4" description="上部工金具" initValue={blockData?.aY4} listValue={listUpperFitting} />

                    <RowSelectComponent blockNumber="block004" name="aY5" description="下部工金具" initValue={blockData?.aY5} listValue={listUpperFitting} />

                    <RowSelectComponent
                        blockNumber="block004"
                        name="aY6"
                        description="主桁補強材"
                        initValue={blockData?.aY6}
                        listValue={[
                            { id: 1, value: '塗装' },
                            { id: 2, value: '溶融亜鉛メッキ' },
                            { id: 3, value: '耐候性' },
                        ]}
                    />
                </Stack>
            </Card>
            <Card
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 16px 16px 16px',
                }}
            >
                <Stack sx={{ width: '100%' }}>
                    <SectionTitle title="アンカー定着部の注入材質" />

                    <RowSelectComponent
                        blockNumber="block004"
                        name="aY10"
                        description="下部工ブラケット"
                        initValue={blockData?.aY10}
                        listValue={[
                            { id: 1, value: '5' },
                            { id: 2, value: '10' },
                        ]}
                    />
                    <RowTextResult description="※有機系「10」、無機系「5」" />
                </Stack>
            </Card>
        </>
    );
};

export default Block04;
