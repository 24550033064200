import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowF118F119 from '../share-components/RowF118F119';
import RowTextInputComponent from '../share-components/RowInput';
import RowSelectComponent from '../share-components/RowSelect';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';
import Tablec125P133 from './TableC125P133';
import TableC135Q142 from './TableC135Q142';

const Block0302 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block003`];

    const checkLe = parseFloat(blockData?._G112?.value) * 15 < parseFloat(blockData?.f116?.value) ? false : true || false;

    const listF112 = [
        { id: 1, value: 18, textValue: 'M18' },
        { id: 2, value: 20, textValue: 'M20' },
        { id: 3, value: 22, textValue: 'M22' },
        { id: 4, value: 24, textValue: 'M24' },
        { id: 5, value: 27, textValue: 'M27' },
        { id: 6, value: 30, textValue: 'M30' },
        { id: 7, value: 33, textValue: 'M33' },
        { id: 8, value: 36, textValue: 'M36' },
        { id: 9, value: 39, textValue: 'M39' },
        { id: 10, value: 42, textValue: 'M42' },
        { id: 11, value: 45, textValue: 'M45' },
        { id: 12, value: 48, textValue: 'M48' },
        { id: 13, value: 52, textValue: 'M52' },
        { id: 14, value: 56, textValue: 'M56' },
        { id: 15, value: 60, textValue: 'M60' },
        { id: 16, value: 64, textValue: 'M64' },
        { id: 17, value: 68, textValue: 'M68' },
        { id: 18, value: 72, textValue: 'M72' },
        { id: 19, value: 76, textValue: 'M76' },
        { id: 20, value: 80, textValue: 'M80' },
    ];

    const listF114 = [
        { id: 1, value: 1, textValue: '異形棒鋼' },
        { id: 2, value: 2, textValue: '丸鋼' },
    ];

    const listF118 = [
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 },
    ];
    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="アンカーボルトの計算" />

                {/* <RowSelectAndInput
                    description="アンカーボルトサイズ(ネジ径)"
                    blockNumber="block003"
                    name="f112"
                    valueLeft={blockData?.f112?.value}
                    tooltipLeft={blockData?.f112?.toolTip}
                    valueRight={blockData?._G112?.value}
                    tooltipRight={blockData?._G112?.toolTip}
                /> */}

                <RowSelectComponent blockNumber="block003" name="f112" description="アンカーボルトサイズ (ネジ径)" initValue={blockData?.f112?.value} listValue={listF112} />

                <RowTextResult
                    defaultDisable={true}
                    paddingLeftDes={3}
                    blockNumber="block003"
                    name="_G112"
                    symbol={{ mathSymbol: '=' }}
                    description="呼び径"
                    initValue={`D${blockData?._G112?.value !== undefined ? blockData?._G112?.value : ''}`}
                />

                <RowSelectComponent blockNumber="block003" name="f114" description="アンカーボルト分類" unit="※異形棒鋼：1、丸鋼：2" initValue={blockData?.f114?.value} listValue={listF114} />

                <RowTextInputComponent
                    blockNumber="block003"
                    name="f116"
                    description="アンカーボルト定着長"
                    errorBgColor={checkLe}
                    initValue={blockData?.f116?.value}
                    symbol={{ normalText: 'L', subText: 'e', mathSymbol: '=' }}
                    unit={`${UNIT.mm}   ※Le＝15D以上`}
                />

                {/* <RowTextInputComponent
                    blockNumber="block003"
                    name="f118"
                    description="アンカーボルト段数 (縦方向)"
                    initValue={blockData?.f118?.value}
                    symbol={{ normalText: 'y', mathSymbol: '=' }}
                    unit={UNIT.step}
                /> */}

                <RowF118F119
                    blockNumber="block003"
                    name="f118"
                    description="アンカーボルト段数 (縦方向)"
                    initValue={blockData?.f118?.value}
                    symbol={{ normalText: 'y', mathSymbol: '=' }}
                    unit={UNIT.step}
                    listValue={listF118}
                />
                {/* 
                <RowTextInputComponent
                    blockNumber="block003"
                    name="f119"
                    description="アンカーボルト列数 (横方向)"
                    initValue={blockData?.f119?.value}
                    symbol={{ normalText: 'x', mathSymbol: '=' }}
                    unit={UNIT.column}
                /> */}

                <RowF118F119
                    blockNumber="block003"
                    name="f119"
                    description="アンカーボルト列数 (横方向)"
                    initValue={blockData?.f119?.value}
                    symbol={{ normalText: 'x', mathSymbol: '=' }}
                    unit={UNIT.column}
                    listValue={listF118}
                />

                <RowTextResult
                    blockNumber="block003"
                    name="f120"
                    defaultDisable={true}
                    description="アンカーボルト本数"
                    initValue={blockData?._F120?.value}
                    symbol={{ normalText: 'n2', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <Tablec125P133 />
                <TableC135Q142 />
            </Stack>
        </Card>
    );
};

export default Block0302;
