import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const SelectCompany = ({ valueInit, name, options, editUserSetting, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();

    const handleChange = (event) => {
        let companySelected = options.find((op) => op.id === event.target.value);
        setFieldValue(name, companySelected);
    };

    return (
        <Select onChange={handleChange} defaultValue={valueInit?.id} disabled={editUserSetting} sx={{ width: '100%' }}>
            {Object.keys(options).map((item, index) => (
                <MenuItem key={options[index]?.id} value={options[index]?.id}>
                    {options[index]?.companyName}
                </MenuItem>
            ))}
        </Select>
    );
};

export default SelectCompany;

export const SelectPermission = ({ valueInit, name, options, editUserSetting, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation();

    const handleChange = (event) => {
        let permission = event.target.value;
        // console.log('per', permission);
        setFieldValue(name, permission);
    };

    return (
        <Select
            onChange={handleChange}
            defaultValue={valueInit}
            disabled={editUserSetting}
            sx={{ width: '100%' }}
            renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {/* <Chip
                        key={selected?.id}
                        sx={{
                            borderRadius: 1,
                            backgroundColor: selected === PermissionsDefinition.ADMIN ? '#2e7d32' : selected === PermissionsDefinition.REVIEWER ? '#ed6c02' : '#1976d2',
                            color: 'white',
                            '& > .MuiChip-deleteIcon': {
                                color: 'white',
                                '&:hover': {
                                    color: '#e1e1e1',
                                },
                            },
                            width: '150px',
                        }}
                        label={t(`permissions.${selected}`)}
                    /> */}
                    {t(`permissions.${selected}`)}
                </Box>
            )}
        >
            {Object.keys(options).map((item, index) => (
                <MenuItem key={options[index]?.id} value={options[index]?.permissionName}>
                    {t(`permissions.${options[index]?.permissionName}`)}
                </MenuItem>
            ))}
        </Select>
    );
};
