import { Typography } from '@mui/material';

const Table0403 = ({ arrayValue1, arrayValue2 }) => {
    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-center flex-col">
            <div className="w-9/12 pb-1">
                <Typography style={{ color: '#02457a' }}>
                    <strong>ボルト間隔</strong>
                </Typography>
            </div>
            <table className="w-9/12 text-right pb-3">
                <thead className="text-right text-sm dark:bg-gray-700">
                    <tr className="h-[32px]">
                        {arrayValue1 &&
                            arrayValue1.map((value, index) => (
                                <td scope="col" className="border border-solid border-gray-500 px-1 py-1 w-[46px]" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value}</Typography>
                                </td>
                            ))}
                    </tr>
                </thead>
                <tbody className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 text-sm">
                    <tr className="h-[32px]">
                        {arrayValue2 &&
                            arrayValue2.map((value, index) => (
                                <td className="bg-[#ccffcc] border border-solid border-gray-500 px-1 py-1 w-[46px]" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value}</Typography>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table0403;
