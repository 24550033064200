import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useEffect } from 'react';
import UrlConstant from 'utils/url/UrlConstant';
import Iconify from './Iconify';

import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: 144,
    height: 144,
    margin: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
    error: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    helperText: PropTypes.node,
    sx: PropTypes.object,
};

export default function UploadAvatar({ userInfo, error, setFiles, files, helperText, sx, ...other }) {
    const { t } = useTranslation();
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: {
            'image/*': [],
        },
        onDrop: (acceptedFiles) => {
            setFiles(
                acceptedFiles.map((file) => {
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    });
                }),
            );
        },
    });

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    }, []);

    let avatarPath = `${UrlConstant.HOZEN_HOST_URL}${userInfo?.avatar ?? '/database/images/avatars/default/avatar.jpg'}?${new Date().getTime()}`;

    return (
        <>
            <RootStyle {...getRootProps({ className: 'dropzone' })}>
                <DropZoneStyle>
                    <input {...getInputProps()} />

                    <img alt="avatar" src={files[0] ? files[0].preview : avatarPath} />

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(files && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.900',
                                '&:hover': { opacity: 0.72 },
                            }),
                        }}
                    >
                        <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
                        <Typography variant="caption">{files ? t('signup.uploadPhoto') : t('signup.updatePhoto')}</Typography>

                        <Typography
                            variant="caption"
                            sx={{
                                mt: 2,
                                mx: 'auto',
                                display: 'block',
                                textAlign: 'center',
                                color: 'text.secondary',
                            }}
                        >
                            Allowed *.jpeg, *.jpg, *.png
                        </Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>
        </>
    );
}
