import StorageKeys from 'utils/constants/StorageKeys';

class TokenService {
    addToken(data) {
        localStorage.setItem(StorageKeys.access, data.accessToken);
        localStorage.setItem(StorageKeys.refresh, data.refreshToken);
        localStorage.setItem(StorageKeys.expiration_access, data.accessExpiration);
        localStorage.setItem(StorageKeys.expiration_refresh, data.refreshExpiration);
    }

    removeToken() {
        localStorage.removeItem(StorageKeys.access);
        localStorage.removeItem(StorageKeys.refresh);
        localStorage.removeItem(StorageKeys.expiration_access);
        localStorage.removeItem(StorageKeys.expiration_refresh);
    }

    getLocalRefreshToken() {
        return localStorage.getItem(StorageKeys.refresh);
    }
    getLocalAccessToken() {
        return localStorage.getItem(StorageKeys.access);
    }
    updateLocalAccessToken(token) {
        localStorage.setItem(StorageKeys.access, token);
    }
    updateLocalRefreshToken(token) {
        localStorage.setItem(StorageKeys.refresh, token);
    }

    getUser() {
        return JSON.parse(localStorage.getItem(StorageKeys.user));
    }
    setUser(user) {
        localStorage.setItem(StorageKeys.user, JSON.stringify(user));
    }

    removeUser() {
        localStorage.removeItem(StorageKeys.user);
    }

    getLocalStorageRemember() {
        return localStorage.getItem('RememberAccHozen');
    }
    setLocalStorageRemember(state) {
        return localStorage.setItem('RememberAccHozen', state);
    }
}
export default new TokenService();
