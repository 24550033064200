import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import NotificationAPI from 'api/NotificationAPI';

export const getNotificationsByUserId = createAsyncThunk('notifications/getNotificationsByUserId', async (payload) => {
    try {
        const res = await NotificationAPI.GetNotificationsByUserId(payload);
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getNotificationsByUserId!!!');
        return error.message;
    }
});

const NotificationSlice = createSlice({
    name: 'notifications',
    initialState: {
        listNotifications: [],
        status: 'idle',
        error: null,
    },
    reducers: [],
    extraReducers: {
        [getNotificationsByUserId.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getNotificationsByUserId.fulfilled]: (state, action) => {
            state.listNotifications = action.payload.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            state.status = 'succeeded';
        },
        [getNotificationsByUserId.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { actions, reducer } = NotificationSlice;
export default reducer;
