import { Grid, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import AuthWrapper from './AuthWrapper';
import FirebaseRegister from './auth_forms/AuthRegister';
SignUp.propTypes = {};

function SignUp(props) {
    const { changeMode } = props;

    return (
        <AuthWrapper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="baseline"
                        sx={{
                            mb: { xs: -0.5, sm: 0.5 },
                        }}
                    >
                        <Typography variant="h3">
                            <Trans i18nKey="signup.createAccount" />
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <FirebaseRegister changeMode={changeMode} />
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}

export default SignUp;
