import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import InputComment from './InputComment';

const CommentContent = (props) => {
    const { comment, onUpdateComment } = props;
    const user = useSelector((state) => state.user.current);
    const author = comment?.createdBy?.id;
    const isAuthor = user.id === author;

    const [onEdit, setOnEdit] = useState(false);

    const handleUpdateComment = (value) => {
        if (onUpdateComment) {
            onUpdateComment(comment.id, value);
        }
        setOnEdit(false);
    };

    return (
        <>
            {isAuthor && onEdit ? (
                <InputComment onSaveComment={(value) => handleUpdateComment(value)} commentContent={comment?.commentBody} />
            ) : (
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', paddingTop: 1 }}>
                    <Typography style={{ wordBreak: 'break-word', width: '85%' }} color="error" onDoubleClick={() => setOnEdit(!onEdit)}>
                        {props?.comment?.commentBody}
                    </Typography>
                    {isAuthor && (
                        <IconButton onClick={() => setOnEdit(!onEdit)} sx={{ padding: 0 }}>
                            <EditIcon />
                        </IconButton>
                    )}
                </Box>
            )}
        </>
    );
};

export default CommentContent;
