import { Box, Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

const Block0302 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block007`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="上部工ブラケット断面照査" />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1. トッププレートの断面照査" />
                </Box>
                <RowTextComponent
                    description="1)	曲げ応力度"
                    physicSymbol="σt"
                    valueCalculate={blockData?._AL97?.value}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._AR97?.value}
                    valueStandard={blockData?._AS97?.value}
                    resultTooltip={blockData?._AL97?.toolTip}
                    standardTooltip={blockData?._AS97?.toolTip}
                    status={blockData?._BC97?.value}
                ></RowTextComponent>
                <RowTextComponent
                    description=""
                    physicSymbol="σc"
                    valueCalculate={blockData?._AL98?.value}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._AR98?.value}
                    valueStandard={blockData?._AS98?.value}
                    resultTooltip={blockData?._AL98?.toolTip}
                    standardTooltip={blockData?._AS98?.toolTip}
                    status={blockData?._BC98?.value}
                ></RowTextComponent>
                <RowTextComponent
                    description="2)	せん断応力度"
                    physicSymbol="τs"
                    valueCalculate={blockData?._AL99?.value}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._AR99?.value}
                    valueStandard={blockData?._AS99?.value}
                    resultTooltip={blockData?._AL99?.toolTip}
                    standardTooltip={blockData?._AS99?.toolTip}
                    status={blockData?._BC99?.value}
                ></RowTextComponent>
                <RowTextComponent
                    description="3)	合成応力度"
                    unit={UNIT.none}
                    valueCalculate={blockData?._AL100?.value}
                    mathSymbol={blockData?._AR100?.value}
                    valueStandard={blockData?._AS100?.value}
                    resultTooltip={blockData?._AL100?.toolTip}
                    standardTooltip={blockData?._AL100?.toolTip}
                    status={blockData?._BC100?.value}
                ></RowTextComponent>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2. ボルトの作用力" />
                </Box>
                <RowTextComponent description="1)	ボルトの作用力照査"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・引張力"
                    physicSymbol="PV"
                    unit={UNIT.N}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL104?.value}
                    mathSymbol={blockData?._AR104?.value}
                    valueStandard={blockData?._AS104?.value}
                    resultTooltip={blockData?._AL104?.toolTip}
                    standardTooltip={blockData?._AS104?.toolTip}
                    status={blockData?._BC104?.value}
                ></RowTextComponent>
                <RowTextComponent
                    description="2)	てこ反力を考慮したボルトの照査"
                    physicSymbol="ρt"
                    unit={UNIT.kN}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL108?.value}
                    mathSymbol={blockData?._AR108?.value}
                    valueStandard={blockData?._AS108?.value}
                    resultTooltip={blockData?._AL108?.toolTip}
                    standardTooltip={blockData?._AS108?.toolTip}
                    status={blockData?._BC108?.value}
                ></RowTextComponent>
                <RowTextComponent
                    description="3)	引張力とせん断力が同時に作用するﾎﾞﾙﾄの照査"
                    physicSymbol="PH"
                    unit={UNIT.kN}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL110?.value}
                    mathSymbol={blockData?._AR110?.value}
                    valueStandard={blockData?._AS110?.value}
                    resultTooltip={blockData?._AL110?.toolTip}
                    standardTooltip={blockData?._AS110?.toolTip}
                    status={blockData?._BC110?.value}
                ></RowTextComponent>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="3. ベースプレート断面照査" />
                </Box>
                <RowTextComponent description="1)	リブ－トッププレート断面検討"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL115?.value}
                    mathSymbol={blockData?._AR115?.value}
                    valueStandard={blockData?._AS115?.value}
                    resultTooltip={blockData?._AL115?.toolTip}
                    standardTooltip={blockData?._AS115?.toolTip}
                    status={blockData?._BC115?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL117?.value}
                    mathSymbol={blockData?._AR117?.value}
                    valueStandard={blockData?._AS117?.value}
                    resultTooltip={blockData?._AL117?.toolTip}
                    standardTooltip={blockData?._AS117?.toolTip}
                    status={blockData?._BC117?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL119?.value}
                    mathSymbol={blockData?._AR119?.value}
                    valueStandard={blockData?._AS119?.value}
                    resultTooltip={blockData?._AL119?.toolTip}
                    standardTooltip={blockData?._AS119?.toolTip}
                    status={blockData?._BC119?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・曲げせん断応力度"
                    physicSymbol="τb"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL120?.value}
                    mathSymbol={blockData?._AR120?.value}
                    valueStandard={blockData?._AS120?.value}
                    resultTooltip={blockData?._AL120?.toolTip}
                    standardTooltip={blockData?._AS120?.toolTip}
                    status={blockData?._BC120?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・せん断応力度"
                    physicSymbol="τs"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL121?.value}
                    mathSymbol={blockData?._AR121?.value}
                    valueStandard={blockData?._AS121?.value}
                    resultTooltip={blockData?._AL121?.toolTip}
                    standardTooltip={blockData?._AS121?.toolTip}
                    status={blockData?._BC121?.value}
                ></RowTextComponent>
                <RowTextComponent description="2)	リブ－ベースプレート断面検討"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL125?.value}
                    mathSymbol={blockData?._AR125?.value}
                    valueStandard={blockData?._AS125?.value}
                    resultTooltip={blockData?._AL125?.toolTip}
                    standardTooltip={blockData?._AS125?.toolTip}
                    status={blockData?._BC125?.value}
                ></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL127?.value}
                    mathSymbol={blockData?._AR127?.value}
                    valueStandard={blockData?._AS127?.value}
                    resultTooltip={blockData?._AL127?.toolTip}
                    standardTooltip={blockData?._AS127?.toolTip}
                    status={blockData?._BC127?.value}
                ></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL129?.value}
                    mathSymbol={blockData?._AR129?.value}
                    valueStandard={blockData?._AS129?.value}
                    resultTooltip={blockData?._AL129?.toolTip}
                    standardTooltip={blockData?._AS129?.toolTip}
                    status={blockData?._BC129?.value}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0302;
