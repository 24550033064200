import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const Table0102 = () => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block001`];
    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-start flex-col pl-[17%]">
            <table className="text-sm text-right pb-3">
                <thead className="bg-white border-b dark:bg-gray-900 text-black text-center uppecase">
                    <tr className="h-[32px] w-[500px]">
                        {['', 'L', 'CL', 'θ(°)	'].map((header) => (
                            <td key={header} className="border border-solid border-gray-500 px-1 py-0.5">
                                <Typography sx={{ justifyContent: 'center' }}>{header}</Typography>
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody className="border-b dark:bg-gray-900 dark:border-gray-700">
                    <tr className="h-[32px] w-[500px] hover:bg-sky-100">
                        <td className="border text-center border-solid border-gray-500 px-1 py-0.5 w-[200px]">
                            <Typography sx={{ justifyContent: 'center' }}>取付時</Typography>
                        </td>
                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5 w-[100px]">
                            <Typography sx={{ justifyContent: 'flex-end' }}>{blockData?.sheet2Data?.j122?.value}</Typography>
                        </td>
                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5 w-[100px]">
                            <Typography sx={{ justifyContent: 'flex-end' }}>{blockData?.sheet2Data?.m122?.value}</Typography>
                        </td>
                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5 w-[100px]">
                            <Typography sx={{ justifyContent: 'flex-end' }}>{blockData?.sheet2Data?.p122?.value}</Typography>
                        </td>
                        <td className="text-right px-1 py-0.5">
                            <Typography sx={{ justifyContent: 'flex-end', paddingLeft: 2 }}>※θ1&nbsp;＝&nbsp;TAN^-1(H1 / Lmax)</Typography>
                        </td>
                    </tr>

                    <tr className="h-[32px]  hover:bg-sky-100">
                        <td className="border text-center border-solid border-gray-500 px-1 py-0.5 w-[200px]">
                            <Typography sx={{ justifyContent: 'center' }}>地震時最大</Typography>
                        </td>
                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5 w-[100px]">
                            <Typography sx={{ justifyContent: 'flex-end' }}>{blockData?.sheet2Data?.j123?.value}</Typography>
                        </td>
                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5 w-[100px]">
                            <Typography sx={{ justifyContent: 'flex-end' }}>{blockData?.sheet2Data?.m123?.value}</Typography>
                        </td>
                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5 w-[100px]">
                            <Typography sx={{ justifyContent: 'flex-end' }}></Typography>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table0102;
