import { Divider, Drawer, List } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';

import { RouterConstants } from 'utils/constants/RouterConstants';

export default function DrawerBar({ changeRouterUser }) {
    const [isUserSetting, setIsUserSetting] = React.useState(true);

    const drawerWidth = 260;
    const handleChangeProfile = () => {
        setIsUserSetting(true);
        const profileRouter = `/${RouterConstants.USER}/${RouterConstants.PROFILE}`;
        if (changeRouterUser) changeRouterUser(profileRouter);
    };
    const handleChangeAccount = () => {
        setIsUserSetting(false);
        const accountRouter = `/${RouterConstants.USER}/${RouterConstants.ACCOUNT}`;
        if (changeRouterUser) changeRouterUser(accountRouter);
    };
    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, marginTop: '60px' },
                }}
                open
            >
                <h2 style={{ textAlign: 'center', marginTop: '40px', marginBottom: '40px' }}>MY SETTINGS</h2>
                <Divider />
                <List>
                    {/* <ListItem disablePadding className={isUserSetting ? classes.selectedBtn : ''}>
                        <ListItemButton onClick={handleChangeProfile}>
                            <ListItemText primaryTypographyProps={{ style: itemTextStyle }} primary="PROFILE" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding className={!isUserSetting ? classes.selectedBtn : ''}>
                        <ListItemButton onClick={handleChangeAccount}>
                            <ListItemText primaryTypographyProps={{ style: itemTextStyle }} primary="ACCOUNT" />
                        </ListItemButton>
                    </ListItem> */}
                </List>
            </Drawer>
        </Box>
    );
}
