import { createSlice } from '@reduxjs/toolkit';

const calculatationSlice = createSlice({
    name: 'calculatationSection',
    initialState: {
        index: 1,
        screenHeight: 1080,
        isLoading: false,
        status: 'idle',
        error: null,
    },
    reducers: {
        changeSection(state, action) {
            state.index = action.payload;
        },
        changeScreenHeight(state, action) {
            state.screenHeight = action.payload;
        },
        changeIsLoading(state, action) {
            state.isLoading = action.payload;
        },
    },
});

const { actions, reducer } = calculatationSlice;
export const { changeSection, changeIsLoading, changeScreenHeight } = actions;
export default reducer;
