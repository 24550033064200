// @mui
import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PageNotFoundIllustration from './illustration_404';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
    return (
        <>
            <title> ページが利用できません </title>

            <Container>
                <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                        ページが利用できません!
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>このページにアクセスする許可がありません.</Typography>

                    <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />

                    <Button href="/#/menu" size="large" variant="contained">
                        メニューに戻る
                    </Button>
                </StyledContent>
            </Container>
        </>
    );
}
