import Block0501 from './Block0501';

const Section05 = (props) => {
    return (
        <>
            {/* {sectionContent.map((x) => (
                <Block01 key={x.id} content={x} />
            ))} */}
            <Block0501 />
        </>
    );
};

export default Section05;
