import Block0301 from './Block0301';
import Block0302 from './Block0302';

const Section03 = (props) => {
    return (
        <>
            <Block0301 />
            <Block0302 />
        </>
    );
};

export default Section03;
