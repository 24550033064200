import { useSelector } from 'react-redux';
import Block01 from './Block01';
import Block02 from './Block02';
import Block03 from './Block03';
import Block04 from './Block04';
import Block05 from './Block05';

const Section01 = (props) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData001 = sheetData?.data?.version100?.[`block001`];
    const blockData002 = sheetData?.data?.version100?.[`block002`];
    const blockData003 = sheetData?.data?.version100?.[`block003`];
    const blockData004 = sheetData?.data?.version100?.[`block004`];
    const blockData005 = sheetData?.data?.version100?.[`block005`];

    return (
        <>
            <Block05 blockData={blockData005} />
            <Block01 blockData={blockData001} />
            <Block02 blockData={blockData002} />
            <Block03 blockData={blockData003} />
            <Block04 blockData={blockData004} />
        </>
    );
};

export default Section01;
