import axiosClient from './configs/axiosClient';
import axiosClientDownloadFile from './configs/axiosClientDownLoadFile';

const SheetAPI = {
    async getListSheets() {
        const url = `/sheets/byusercompany`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getSheetById(sheetId) {
        const url = `/sheets/${sheetId}`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getListSectionsOfSheet(sheetId) {
        const url = `/sheets/sections/${sheetId}`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async ToggleSheet(sheetId) {
        const url = `/sheets/toggleactive/${sheetId}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
        });
    },

    async changeSheetStatus(sheetId, status) {
        const url = `/sheets/${sheetId}/status/${status}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
        });
    },

    async updateSheet(params) {
        const url = `/sheets/${params.id}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
            data: params,
        });
    },

    async deleteSheet(sheetId) {
        const url = `/sheets/${sheetId}`;
        return axiosClient.request({
            method: 'DELETE',
            url: url,
        });
    },

    async createSheet(params) {
        const url = `/sheets`;
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: params,
        });
    },

    async copySheet(params) {
        const url = `/sheets/copysheet`;
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: params,
        });
    },

    async getDataSection(params) {
        const url = `/sheets/section/${params?.sheetId}/${params?.section}`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async putDataSection(data) {
        const url = `/sheets/section`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
            data: data,
        });
    },

    async putOneCell(data) {
        const url = `/sheets/section/short`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
            data: data,
        });
    },

    async downloadSheet(sheetId) {
        const url = `/sheets/export/${sheetId}`;

        return axiosClientDownloadFile.request({
            method: 'GET',
            url: url,
            responseType: 'blob',
        });
    },
};

export default SheetAPI;
