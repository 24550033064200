import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import RowTextInputComponent from '../share-components/RowInput';
import image1 from './4-1-1.png';
import Table0401 from './table/Table01';
import Table0402 from './table/Table02';
import Table0403 from './table/Table03';

const Block0401 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version100?.[`block008`];

    const boltPositionManualValueArr = blockData?.g160AE160;

    const boltPositionTypicalArr = blockData?._G157AE157;

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <article className="h-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image1} alt="background" height="500" />

                    {/* <Typography className="absolute w-full flex justify-center w-full flex justify-center top-[20rem] left-[-4rem] text-sm  text-center"></Typography> */}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[-12rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[0]) ? '' : `${boltPositionManualValueArr[0]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[-8.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[1]) ? '' : `${boltPositionManualValueArr[1]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[-6.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[2]) ? '' : `${boltPositionManualValueArr[2]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[-4.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[3]) ? '' : `${boltPositionManualValueArr[3]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[-2.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[4]) ? '' : `${boltPositionManualValueArr[4]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[-0.7rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[5]) ? '' : `${boltPositionManualValueArr[5]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[0.2rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[6]) ? '' : `${boltPositionManualValueArr[7]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[1.8rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[7]) ? '' : `${boltPositionManualValueArr[8]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[3.8rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[8]) ? '' : `${boltPositionManualValueArr[9]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[6rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[9]) ? '' : `${boltPositionManualValueArr[10]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[8rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[10]) ? '' : `${boltPositionManualValueArr[11]}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[26.5rem] left-[11rem] text-sm  text-center`}>
                            {isNaN(boltPositionManualValueArr[11]) ? '' : `${boltPositionManualValueArr[12]}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[1.5rem] left-[-7.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[0]) ? 'Y6' : `Y6=${boltPositionTypicalArr[0]}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[1.5rem] left-[7.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[12]) ? 'Y6' : `Y6'=${boltPositionTypicalArr[12]}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[3.9rem] left-[-5.8rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[1]) ? 'Y5' : `Y5=${boltPositionTypicalArr[1]}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[3.9rem] left-[5.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[11]) ? 'Y5' : `Y5'=${boltPositionTypicalArr[11]}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[5.7rem] left-[-4.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[2]) ? 'Y4' : `Y4=${boltPositionTypicalArr[2]}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[5.7rem] left-[4.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[10]) ? 'Y4' : `Y4'=${boltPositionTypicalArr[10]}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[7.2rem] left-[-3.9rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[3]) ? 'Y3' : `Y3=${boltPositionTypicalArr[3]}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[7.2rem] left-[3.2rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[9]) ? 'Y3' : `Y3'=${boltPositionTypicalArr[9]}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[8.8rem] left-[-2.5rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[4]) ? 'Y2' : `Y2=${boltPositionTypicalArr[4]}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[8.8rem] left-[2rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[8]) ? 'Y2' : `Y2'=${boltPositionTypicalArr[8]}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[10.7rem] left-[-1.7rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[5]) ? 'Y1' : `Y1=${boltPositionTypicalArr[5]}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[10.7rem] left-[1.4rem] text-sm  text-center`}>
                            {isNaN(boltPositionTypicalArr[5]) ? 'Y1' : `Y1'=${boltPositionTypicalArr[5]}`}
                        </Typography>
                    )}
                </article>

                <Table0401 arrayValue={blockData?._G157AE157} />

                <Table0402 arrayValue1={blockData?.g160AE160} arrayValue2={blockData?._G162AE162} />

                <div className="pl-[3.75rem]">
                    <Typography color="error" sx={{ paddingLeft: '7%', marginBottom: '4px !important', marginTop: '4px !important' }}>
                        <strong>＊手入力時、センタにボルトを入れる場合は１を入力する</strong>
                    </Typography>
                    {/* <RowTextInputComponent blockNumber="block008" name="numberOfBoltsPerRow" initValue={blockData?.numberOfBoltsPerRow} description="1列のボルト本数" /> */}
                    <RowTextInputComponent
                        blockNumber="block008"
                        name="_AY162"
                        initValue={blockData?._AY162}
                        backgroundColor="#d0fecd"
                        defaultDisable={true}
                        description="手入力ボルト間隔の場合のトッププレート長"
                    />
                    <RowTextInputComponent
                        blockNumber="block008"
                        name="_AY163"
                        initValue={blockData?._AY163}
                        backgroundColor="#d0fecd"
                        defaultDisable={true}
                        description="手入力ボルト間隔の場合のベースプレート長"
                    />{' '}
                </div>

                <Table0403 arrayValue1={blockData?._G164AE164} arrayValue2={blockData?._G165AE165} />
            </Stack>
        </Card>
    );
};

export default Block0401;
