import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FieldStatus, UNIT } from 'utils/StringDefinition';
import { CompletedIcon, ErrorIcon, NAIcon } from './StatusIcon';

const RowTextComponent = (props) => {
    const { description, isEqualTo, status, physicSymbol, valueCalculate, mathSymbol, valueStandard, valueStandardSymbol, unit, paddingLeftFit } = props;
    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '40%', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 10 : 10 }}>
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                <ResultInRow
                    physicSymbol={physicSymbol}
                    isEqualTo={isEqualTo}
                    paintColor={paintColor}
                    unit={unit}
                    valueResult={valueCalculate}
                    mathSymbol={mathSymbol}
                    valueStandardSymbol={valueStandardSymbol}
                    valueStandard={valueStandard}
                />
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 4.5 }}>
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};
export default RowTextComponent;

export const ResultInRow = (props) => {
    // console.log(props);
    return (
        <>
            <Typography sx={{ width: '10%' }}>{props?.physicSymbol}</Typography>
            <Typography sx={{ width: '5%' }}>{props?.isEqualTo ? '=' : null}</Typography>
            <Typography sx={{ width: '20%', textAlign: 'right', color: props?.valueResult === '#N/A' ? 'red' : '' }}>{props?.valueResult && formatNumber(props?.valueResult)}</Typography>
            {props?.isEqualTo ? <Unit unit={props?.unit} /> : <Typography sx={{ width: '20%' }}></Typography>}
            <Typography sx={{ width: '10%', display: 'flex', justifyContent: 'center', color: props?.paintColor ? 'red' : '' }}>{props?.mathSymbol}</Typography>
            <Typography sx={{ width: '20%', textAlign: 'right', color: props?.valueStandard === '#N/A' ? 'red' : '' }}>
                {props?.valueStandardSymbol && props?.valueStandardSymbol}
                {props?.valueStandard && formatNumber(props?.valueStandard)}
            </Typography>
            {props?.isEqualTo ? <Unit unit={props?.unit} /> : <Typography sx={{ width: '20%' }}></Typography>}
        </>
    );
};

export const RowTextSpecial = (props) => {
    const { description, isEqualTo, status, valueCalculate, mathSymbol, valueStandard } = props;
    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'flex', width: '44%', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 2 }}>
                {description && (
                    <Box component="div" sx={{ display: 'flex', width: '22%' }}>
                        <Typography>{description?.description1}</Typography>
                        <sup>2</sup>
                        <Typography>{description?.description2}</Typography>
                        <sup>2</sup>
                    </Box>
                )}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '37%' }}>
                <Box component="div" sx={{ display: 'flex', width: '100%' }}>
                    <Typography sx={{ width: '0%' }}></Typography>
                    <Typography sx={{ width: '7%', paddingLeft: '0.5%' }}>{isEqualTo ? '=' : null}</Typography>
                    <Typography sx={{ width: '25%', textAlign: 'right', color: valueCalculate === '#N/A' ? 'red' : '' }}>
                        {/* {valueCalculate} */}
                        {valueCalculate && formatNumber(valueCalculate)}
                    </Typography>
                    <Typography sx={{ width: '20%' }}></Typography>
                    <Typography sx={{ width: '15%', display: 'flex', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>
                    <Typography sx={{ width: '25%', textAlign: 'right', color: valueStandard === '#N/A' ? 'red' : '' }}>{valueStandard && formatNumber(valueStandard)}</Typography>
                    <Typography sx={{ width: '20%' }}></Typography>
                </Box>
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: '34px' }}>
                    {/* {console.log(status)} */}
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};

export const Unit = ({ unit }) => {
    function RenderUnitText(unit) {
        switch (unit) {
            case UNIT.mm:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>{UNIT.mm}</Typography>
                    </Box>
                );

            case UNIT.Nmm2:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>N/mm</Typography>
                        <sup>2</sup>
                    </Box>
                );
            case UNIT.kN:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>kN</Typography>
                    </Box>
                );
            case UNIT.N:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>N</Typography>
                    </Box>
                );
            case 'none':
                return <Box component="div" sx={{ display: 'flex', width: '20%' }}></Box>;
            case UNIT.kNmm2:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>KN/mm</Typography>
                        <sup>2</sup>
                    </Box>
                );

            case UNIT.kata:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.kata}</Typography>
                    </Box>
                );

            case UNIT.description1:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '70%' }}>
                        <Typography>{UNIT.description1}</Typography>
                    </Box>
                );

            default:
                return <Box component="div" sx={{ display: 'flex', width: '20%' }}></Box>;
        }
    }

    const renderView = RenderUnitText(unit);

    return <>{renderView}</>;
};

function formatNumber(numStr) {
    const num = parseFloat(numStr);
    if (num >= 1000000) {
        return num.toExponential(5);
    } else {
        return num.toLocaleString().replace(/,/g, '');
    }
}
