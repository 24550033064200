import { Stack } from '@mui/system';
import CommentAPI from 'api/CommentAPI';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { getCommentsBySheetId } from 'store/reducers/commentsSlice';
import CommentInfo from './CommentInformation';
import CommentNav from './CommentNav';
import CommentTimeline from './CommentTimeline';
import InputComment from './InputComment';

const RightPanel = (props) => {
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const listComments = useSelector((state) => state.comments.comments);
    let listCommentsSorted = [...listComments].sort(function (a, b) {
        return moment(b.createdDate).unix() - moment(a.createdDate).unix();
    });

    // const user = useSelector((state) => state.user.current);

    const dispatch = useDispatch();

    const handleSaveComment = async (valueSubmit) => {
        const response = await CommentAPI.postComment(valueSubmit);
        if (response.status === 200) {
            dispatch(getCommentsBySheetId(sheetInfo.id));
        }
    };

    const handleUpdateComment = async (commentId, commentContent) => {
        const response = await CommentAPI.updateComment(commentId, commentContent);
        if (response.status === 200) {
            dispatch(getCommentsBySheetId(sheetInfo.id));
        }
    };

    return (
        <>
            <SimpleBar style={{ maxHeight: '100vh', minHeight: '100vh' }}>
                <Stack
                    sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'start',
                        p: 2,
                        pl: 2.5,
                        height: '100%',
                    }}
                >
                    {/* {viewFuntion && <CommentNav />} */}
                    <CommentNav />
                    <CommentInfo />
                    <InputComment onSaveComment={handleSaveComment} />
                    <CommentTimeline listComments={listCommentsSorted} onUpdateComment={handleUpdateComment} />
                </Stack>

                {/* <ShowTestTool002 /> */}
            </SimpleBar>
        </>
    );
};

export default RightPanel;
