import PropTypes from 'prop-types';
// @mui
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CompanyDefinition } from 'utils/StringDefinition';

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

TableHeadCustom.propTypes = {
    onSort: PropTypes.func,
    orderBy: PropTypes.string,
    headLabel: PropTypes.array,
    rowCount: PropTypes.number,
    numSelected: PropTypes.number,
    onSelectAllRows: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']),
    sx: PropTypes.object,
};

export default function TableHeadCustom({ order, orderBy, rowCount = 0, headLabel, numSelected = 0, onSort, sx }) {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.current);
    const companyName = user?.company?.companyName;

    const tableHeadByUser = companyName === CompanyDefinition.NKE ? headLabel : headLabel.filter((header) => header.id !== 'action');

    return (
        <TableHead sx={sx}>
            <TableRow>
                {tableHeadByUser.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align || 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ width: headCell.width, minWidth: headCell.minWidth, paddingLeft: headCell.paddingLeft, backgroundColor: '#c7d7ea' }}
                    >
                        {onSort ? (
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => onSort(headCell.id)}
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {/* <Trans i18nKey={`calculationTable.${headCell.id}`} /> */}
                                {t(`calculationTable.${headCell.id}`)}
                                {orderBy === headCell.id ? <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box> : null}
                            </TableSortLabel>
                        ) : (
                            <Trans i18nKey={`calculationTable.${headCell.id}`} />
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
