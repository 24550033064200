import TokenServices from 'api/token/TokenServices';
import axios from 'axios';
import queryString from 'query-string';
import store from 'store';
import { refreshToken } from 'store/reducers/userSlice';
import UrlConstant from 'utils/url/UrlConstant';

const axiosClientDownloadFile = axios.create({
    baseURL: UrlConstant.HOZEN_API_URL,
    headers: {
        'Content-Type': 'application/octet-stream',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin,DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
    },
    paramsSerializer: (params) => queryString.stringify(params),
});

axiosClientDownloadFile.interceptors.request.use(async (config) => {
    const accessToken = TokenServices.getLocalAccessToken();
    // const token = await TokenServices.getLocalAccessToken();

    try {
        if (!!accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }
    } catch (error) {
        alert('Error In Axios config');
    }
    return config;
});

axiosClientDownloadFile.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (originalConfig.url !== 'login' && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const actions = refreshToken({
                        token: TokenServices.getLocalRefreshToken(),
                        userEmail: TokenServices.getUser()?.email,
                    });
                    const result = await store.dispatch(actions);
                    return axiosClientDownloadFile(originalConfig);
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    },
);

export default axiosClientDownloadFile;
