import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import SectionTitle from '../share-components/Tilte';
import image from './2_1.png';

const Block0201 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block002`];

    const cacheData = cacheStore((state) => state.cacheData);
    // Dùng để lưu cache, nó là 1 bộ nhớ đệm, loại bỏ trường hợp khi nhập dữ liệu quá nhanh, dữ liệu sẽ bị mất
    const setCacheData = cacheStore((state) => state.setCacheData);

    const mergedObject = { ...blockData };

    for (const key in cacheData) {
        if (cacheData.hasOwnProperty(key)) {
            mergedObject[key] = cacheData[key];
        }
    }

    // console.log('🐳 ~ mergedObject:', mergedObject);

    useEffect(() => {
        setCacheData(mergedObject);
    }, [blockData]);

    const numberOfBolts = Math.floor(blockData?.f66?.value / 2) || 'n';

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="Block0201_1" />
                        {/* image1*/}

                        <Typography className="absolute w-full flex top-[1.5rem] left-[-9.0rem] text-sm text-center justify-center">面平行方向</Typography>
                        <Typography className="absolute w-full flex top-[5.25rem] left-[-3.5rem] text-sm text-center justify-center">S2</Typography>

                        <Typography className="absolute w-full flex justify-center top-[10rem] left-[-19rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.f53?.value)) ? 't1 = 0' : `t1 = ${blockData?.f53?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[6.25rem] left-[-21.5rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK27?.value)) ? 'h2 = 0' : `h2 = ${blockData?.sheet3Data?.aK27?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[-15.25rem] text-sm text-center">c2</Typography>
                        <Typography className="absolute w-full flex justify-center top-[12rem] left-[-15.25rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f60?.value)) ? '0' : `${blockData?.f60?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[-3rem] text-sm text-center">c2</Typography>
                        <Typography className="absolute w-full flex justify-center top-[12rem] left-[-3rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f60?.value)) ? '0' : `${blockData?.f60?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[9rem] left-[-9rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK13?.value)) ? 'bw1 = 0' : `bw1 = ${blockData?.sheet3Data?.aK13?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[-9rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f57?.value)) ? 'bw2 = 0' : `bw2 = ${blockData?.f57?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[12.5rem] left-[-9rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK11?.value)) ? 'bw = 0' : `bw = ${blockData?.sheet3Data?.aK11?.value}`}
                        </Typography>

                        {/* image2*/}

                        <Typography className="absolute w-full flex top-[1.5rem] left-[13.75rem] text-sm text-center justify-center">面直角方向</Typography>
                        <Typography className="absolute w-full flex top-[5.25rem] left-[19.5rem] text-sm text-center justify-center">S1</Typography>

                        <Typography className="absolute w-full flex justify-center top-[6.25rem] left-[2.75rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK27?.value)) ? 'h2 = 0' : `h2 = ${blockData?.sheet3Data?.aK27?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[6.25rem] left-[4.75rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK26?.value)) ? 'h1 = 0' : `h1 = ${blockData?.sheet3Data?.aK26?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10rem] left-[4.75rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.f53?.value)) ? 't1 = 0' : `t1 = ${blockData?.f53?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[9rem] left-[14rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK12?.value)) ? 'Bw1 = 0' : `Bw1 = ${blockData?.sheet3Data?.aK12?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10.75rem] left-[14rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f55?.value)) ? 'Bw2 = 0' : `Bw2 = ${blockData?.f55?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[9.25rem] text-sm text-center">c1</Typography>
                        <Typography className="absolute w-full flex justify-center top-[12rem] left-[9.25rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f59?.value)) ? '0' : `${blockData?.f59?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[18.5rem] text-sm text-center">c1</Typography>
                        <Typography className="absolute w-full flex justify-center top-[12rem] left-[18.5rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f59?.value)) ? '0' : `${blockData?.f59?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[12.5rem] left-[14rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK10?.value)) ? 'Bw = 0' : `Bw = ${blockData?.sheet3Data?.aK10?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[16.25rem] left-[-9rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK11?.value)) ? 'bw = 0' : `bw = ${blockData?.sheet3Data?.aK11?.value}`}
                        </Typography>

                        {/* image3 */}
                        <Typography className="absolute w-full flex justify-center top-[22.5rem] left-[-19rem] text-sm text-center -rotate-90">c1</Typography>
                        <Typography className="absolute w-full flex justify-center top-[22.5rem] left-[-17.75rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.f59?.value)) ? '0' : `${blockData?.f59?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[32rem] left-[-19rem] text-sm text-center -rotate-90">c1</Typography>
                        <Typography className="absolute w-full flex justify-center top-[32rem] left-[-17.75rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.f59?.value)) ? '0' : `${blockData?.f59?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[27rem] left-[-19rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.f55?.value)) ? 'Bw2 = 0' : `Bw2 = ${blockData?.f55?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[27rem] left-[-21.25rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK10?.value)) ? 'Bw = 0' : `Bw = ${blockData?.sheet3Data?.aK10?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[22.75rem] left-[-9rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK13?.value)) ? 'bw1 = 0' : `bw1 = ${blockData?.sheet3Data?.aK13?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[17.75rem] left-[-9rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f57?.value)) ? 'bw2 = 0' : `bw2 = ${blockData?.f57?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[27rem] left-[-13.25rem] text-sm text-center -rotate-90">
                            {isNaN(parseFloat(blockData?.sheet3Data?.aK12?.value)) ? 'Bw1 = 0' : `Bw1 = ${blockData?.sheet3Data?.aK12?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[17.75rem] left-[-15.25rem] text-sm text-center">c2</Typography>
                        <Typography className="absolute w-full flex justify-center top-[19.25rem] left-[-15.25rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f60?.value)) ? '0' : `${blockData?.f60?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[17.75rem] left-[-3rem] text-sm text-center">c2</Typography>
                        <Typography className="absolute w-full flex justify-center top-[19.25rem] left-[-3rem] text-sm text-center">
                            {isNaN(parseFloat(blockData?.f60?.value)) ? '0' : `${blockData?.f60?.value}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[27.25rem] left-[1rem] text-sm text-center -rotate-90">面直角方向</Typography>
                        <Typography className="absolute w-full flex justify-center top-[23.5rem] left-[4.5rem] text-sm text-center">1段目　</Typography>
                        <Typography className="absolute w-full flex justify-center top-[25.25rem] left-[4.5rem] text-sm text-center">2段目　</Typography>
                        <Typography className="absolute w-full flex justify-center top-[31.25rem] left-[4.5rem] text-sm text-center">{`${numberOfBolts}段目　`}</Typography>
                        <Typography className="absolute w-full flex justify-center top-[37rem] left-[-4rem] text-sm text-center rotate-90">2列目　</Typography>
                        <Typography className="absolute w-full flex justify-center top-[37rem] left-[-14.25rem] text-sm text-center rotate-90">1列目　</Typography>

                        <Typography className="absolute w-full flex top-[35rem] left-[-9.0rem] text-sm text-center justify-center">面方向</Typography>
                    </article>
                </div>

                <SectionTitle title="下部工下調整プレートに関する寸法" />
                <RowTextInputComponent
                    blockNumber="block002"
                    name="f53"
                    description="下調整プレート厚さ"
                    initValue={blockData?.f53?.value}
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block002"
                    name="f55"
                    description="連結ボルト間隔 (橋軸方向)"
                    initValue={blockData?.f55?.value}
                    symbol={{ normalText: 'Bw2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block002"
                    name="f57"
                    description="連結ボルト間隔 (直角方向)"
                    initValue={blockData?.f57?.value}
                    symbol={{ normalText: 'bw2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block002"
                    name="f59"
                    description="ボルトの縁端距離 (橋軸方向)"
                    initValue={blockData?.f59?.value}
                    symbol={{ normalText: 'c1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block002"
                    name="f60"
                    description="ボルトの縁端距離 (直角方向)"
                    initValue={blockData?.f60?.value}
                    symbol={{ normalText: 'c2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0201;
