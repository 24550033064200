import { Typography } from '@mui/material';

const Table0401 = ({ arrayValue }) => {
    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-center flex-col">
            <div className="w-10/12 pb-1 flex pl-10">
                <Typography style={{ color: '#02457a' }}>
                    <strong>標準値</strong>
                </Typography>
            </div>

            <table className="text-sm text-right pb-3">
                <thead className="bg-white border-b dark:bg-gray-900 text-black text-right uppecase">
                    <tr className="h-[32px]">
                        {['y６', 'y５', 'y４', 'y３', 'y２', 'y1', 'y0', 'y１’', 'y２’', 'y３’', 'y４’', 'y５’', 'y６’'].map((header) => (
                            <td key={header} scope="col" className="w-[65px]  border border-solid border-gray-500 px-1 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>{header}</Typography>
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody className="bg-[#fdff9d] border-b dark:bg-gray-900 dark:border-gray-700">
                    <tr className="h-[32px]">
                        {arrayValue &&
                            arrayValue.map((value, index) => (
                                <td className="w-[65px] border text-right border-solid border-gray-500 px-1 py-1" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value?.value}</Typography>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table0401;
