import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translationEN from './en-languages.json';
import translationJP from './jp-languages.json';
import translationVN from './vn-languages.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    jp: {
        translation: translationJP,
    },
    vn: {
        translation: translationVN,
    },
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'jp',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
