import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Card, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { SectionNameTool001 } from 'utils/StringDefinition';

export default function RequestReviewPopup(props) {
    const { openPopup, setOpenPopup, handleRequest } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleClose = () => {
        setOpenPopup(false);
    };

    const location = useLocation();
    const fileInfoInit = location?.state?.fileInfoInit;
    //fetch data
    const sheetCurrent = useSelector((state) => state.sheet.sheetActive);

    //fetch data
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const initFetch = React.useCallback(() => {
        dispatch(getListSectionsOfSheet(currentId));
    }, [dispatch]);

    React.useEffect(() => {
        initFetch();
    }, [initFetch]);

    const currentId = fileInfoInit?.id ?? sheetCurrent.id;

    const sheetSectionsData = useSelector((state) => state.sheet.listSections);

    const listSection = sheetSectionsData?.data;

    const isTrueAll = listSection?.every((section) => section.status === true);

    const listSectionView =
        Array.isArray(listSection) &&
        listSection.map((item) => {
            return (
                <Card elevation={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1.25, p: 1 }} key={item.sectionCode} style={{ cursor: 'pointer', height: '10%' }}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography variant="h5">
                                {(() => {
                                    let sectionName;
                                    switch (item.sectionCode) {
                                        case 'Section001':
                                            sectionName = SectionNameTool001.Section001;
                                            break;
                                        case 'Section002':
                                            sectionName = SectionNameTool001.Section002;
                                            break;
                                        case 'Section003':
                                            sectionName = SectionNameTool001.Section003;
                                            break;
                                        case 'Section004':
                                            sectionName = SectionNameTool001.Section004;
                                            break;
                                        case 'Section005':
                                            sectionName = SectionNameTool001.Section005;
                                            break;
                                        case 'Section006':
                                            sectionName = SectionNameTool001.Section006;
                                            break;
                                        default:
                                            sectionName = SectionNameTool001.Section001;
                                            break;
                                    }
                                    return sectionName;
                                })()}
                            </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {item.status ? <CheckCircleIcon style={{ fontSize: '22px', color: '#55a630' }} /> : <WarningIcon style={{ fontSize: '22px', color: '#ff9f1c' }} />}
                        </Grid>
                    </Grid>
                </Card>
            );
        });

    return (
        <>
            <Dialog open={openPopup} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle align="center">{t('requestReviewDialog.title')}</DialogTitle>
                <DialogContent sx={{ marginTop: 3 }}>
                    <DialogContentText id="alert-dialog-description"></DialogContentText>

                    {listSectionView}
                    {!isTrueAll && <Typography sx={{ paddingTop: 2, color: 'red' }}>{t('requestReviewDialog.errorSubmit')}</Typography>}
                </DialogContent>
                <DialogActions style={{ padding: '12px 24px 12px 24px', backgroundColor: '#f2f2f4' }}>
                    <Button variant="outlined" onClick={handleClose}>
                        <Trans i18nKey="sheetDialog.btnCancel" />
                    </Button>
                    <Button autoFocus onClick={handleRequest} variant="contained" disabled={!isTrueAll}>
                        {t('requestReviewDialog.btnRequest')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
