import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import RowSelectsubstructure from '../share-components/RowSelectsubstructure';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';

const Block042 = (props) => {
    const { blockData } = props;

    return (
        <>
            <Card
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 16px 16px 16px',
                }}
            >
                <Stack sx={{ width: '100%' }} spacing={0.25}>
                    <SectionTitle title="アンカー定着部の注入材質" />

                    <RowSelectsubstructure
                        bracket
                        blockNumber="block004"
                        name="aY10"
                        description="下部工ブラケット"
                        initValue={blockData?.aY10?.value}
                        listValue={[
                            { id: 1, value: '5', textValue: '無機系 （モルタル）' },
                            { id: 2, value: '10', textValue: '有機系（樹脂)' },
                        ]}
                    />
                    <RowTextResult description="※有機系（樹脂)「10」、無機系 （モルタル）「5」" />
                </Stack>
            </Card>
        </>
    );
};

export default Block042;
