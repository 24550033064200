import { TextField, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const RowTextThreeInput = (props) => {
    const { name, description, initValue, symbol, unit, allowNegativeNumber, tooltipText, blockNumber, hidden } = props;
    const sectionData = useSelector((state) => state.sheet.dataSection);
    var index = useSelector((state) => state.calculatationSection.index);
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const cacheData = cacheStore((state) => state.cacheData);
    const setCacheData = cacheStore((state) => state.setCacheData);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const sheetId = sectionData?.data?.sheetId;
    const blockData = sectionData?.data?.version110?.[blockNumber];

    const [num, setNum] = React.useState(initValue?.input ?? 0);
    const dispatch = useDispatch();

    // console.log('initValue?.input ', initValue?.input);

    const handleChange = (e) => {
        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = e.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            setNum(newValue);
        }
    };

    // This effect will be called every time initValue changes.
    useEffect(() => {
        setNum(initValue?.input);
    }, [initValue?.input]);

    const handleBlur = (e) => {
        let newValue = e.target.value;

        if (newValue === '.' || newValue === '-.' || newValue === '.-' || newValue === '-') {
            setNum('');
        } else {
            let dataChanged = {
                ...cacheData,
                [name]: {
                    value: newValue === '' ? '' : parseFloat(newValue).toString(),
                },
            };
            setCacheData(dataChanged);
            // let sectionDataChanged = {
            //     ...sectionData.data,
            //     [blockNumber]: dataChanged,
            // };

            let sectionDataChanged = {
                ...sectionData.data,
                version110: { ...sectionData.data?.version110, [blockNumber]: dataChanged },
            };

            if (!_.isEqual(blockData, dataChanged)) {
                // console.log(dataChanged);
                updateData(sectionDataChanged);
            }
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '35%', alignItems: 'center', marginLeft: 10 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '5%' }}>
                <Typography sx={{ width: '50%' }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>
                <Typography sx={{ width: '50%' }}>{symbol?.mathSymbol && ` ${symbol?.mathSymbol}`}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>
                {tooltipText ? (
                    <Tooltip title={tooltipText} placement="top-end">
                        <TextField
                            value={initValue?.looking ?? ''}
                            disabled={true}
                            onChange={handleChange}
                            // placeholder="標準値"
                            inputProps={{
                                style: { textAlign: 'right', cursor: 'not-allowed' },
                            }}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#272343',
                                },
                                '& .MuiInputBase-root': {
                                    backgroundColor: '#fdff9d',
                                    height: '32px',
                                    padding: 0,
                                },
                                width: '30%',
                            }}
                        ></TextField>
                    </Tooltip>
                ) : (
                    <TextField
                        value={initValue?.looking ?? ''}
                        disabled={true}
                        onChange={handleChange}
                        // placeholder="標準値"
                        inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed' } }}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#272343',
                            },
                            '& .MuiInputBase-root': {
                                backgroundColor: hidden === 'looking' ? '#b6b6b6' : '#fdff9d',
                                height: '32px',
                                padding: 0,
                                cursor: hidden === 'looking' ? 'not-allowed' : '',
                            },

                            width: '30%',
                        }}
                    ></TextField>
                )}

                <TextField
                    // defaultValue={initValue?.input}
                    value={num !== 0 ? num : ''}
                    disabled={canEdited || hidden === 'input'}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={hidden === 'input' ? '' : '入力'}
                    inputProps={{
                        style: {
                            textAlign: 'right',
                            cursor: hidden === 'input' ? 'not-allowed' : '',
                        },
                    }}
                    sx={{
                        width: '30%',
                        '& .MuiInputBase-root': {
                            backgroundColor: hidden === 'input' ? '#b6b6b6' : '#d2feff',
                            height: '32px',
                            padding: 0,
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: 'red',
                            cursor: 'not-allowed',
                        },
                    }}
                ></TextField>

                <TextField
                    value={initValue?.calculate ?? ''}
                    disabled={true}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed', color: 'white' } }}
                    sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: '#d0fecd',
                        },
                        '& .MuiInputBase-input': {
                            WebkitTextFillColor: 'black',
                            height: '32px',
                            padding: 0,
                            paddingRight: '10px',
                        },
                        width: '30%',
                    }}
                    // placeholder="採用値"
                ></TextField>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 3 }}>
                <Typography>{unit}</Typography>
            </Box>
        </Box>
    );
};
export default RowTextThreeInput;
