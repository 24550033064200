import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { changeSection } from 'store/reducers/calculatationSlice';
import { getDataSection, getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { SectionNameTool002 } from 'utils/StringDefinition';

const SectionSelectionTool002 = (props) => {
    var index = useSelector((state) => state.calculatationSection.index);
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const updateDataSection = useCallback(
        async (blockIndex) => {
            const params = {
                sheetId: sheetInfo?.id,
                section: `Section00${blockIndex}`,
            };

            await dispatch(getListSectionsOfSheet(sheetInfo?.id));
        },
        [sheetInfo, dispatch, getDataSection],
    );

    const handleChangeSection = useCallback(
        async (event) => {
            const j = event.target.value;
            const action = changeSection(j);
            await dispatch(action);
            await updateDataSection(j);
        },
        [dispatch, updateDataSection],
    );

    const handlePreClick = useCallback(async () => {
        if (index > 1) {
            const action = changeSection(index - 1);
            await dispatch(action);

            await updateDataSection(index - 1);
        }
    }, [index, dispatch, updateDataSection]);

    const handleNextClick = useCallback(async () => {
        if (index < 5) {
            await dispatch(changeSection(index + 1));

            await updateDataSection(index + 1);
        }
    }, [index, dispatch, updateDataSection]);

    return (
        <>
            <Box
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 0.5,
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {index === 1 ? (
                        <Box sx={{ mr: '20px', display: 'flex', alignItems: 'center', cursor: 'not-allowed', color: 'gray' }} cursor="pointer" onClick={handlePreClick}>
                            <SkipPreviousIcon />
                            <Typography>{t('selectionSection.previous')}</Typography>
                        </Box>
                    ) : (
                        <Box sx={{ mr: '20px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} cursor="pointer" onClick={handlePreClick}>
                            <SkipPreviousIcon />
                            <Typography>{t('selectionSection.previous')}</Typography>
                        </Box>
                    )}

                    <Box>
                        <FormControl sx={{ minWidth: 400, width: 400 }} size="small">
                            <Select labelId="demo-select-small" id="demo-select-small" value={index} onChange={handleChangeSection}>
                                <MenuItem value={1}>{SectionNameTool002.Section001}</MenuItem>
                                <MenuItem value={2}>{SectionNameTool002.Section002}</MenuItem>
                                <MenuItem value={3}>{SectionNameTool002.Section003}</MenuItem>
                                <MenuItem value={4}>{SectionNameTool002.Section004}</MenuItem>
                                <MenuItem value={5}>{SectionNameTool002.Section005}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    {index === 5 ? (
                        <Box sx={{ ml: '20px', display: 'flex', alignItems: 'center', cursor: 'not-allowed', color: 'gray' }} onClick={handleNextClick}>
                            <Typography>{t('selectionSection.next')}</Typography>
                            <SkipNextIcon />
                        </Box>
                    ) : (
                        <Box sx={{ ml: '20px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleNextClick}>
                            <Typography>{t('selectionSection.next')}</Typography>
                            <SkipNextIcon />
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default SectionSelectionTool002;
