import { Card, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import RowTextInputComponent from '../share-components/RowInput';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';
import image from './1_1.png';

const Block01 = () => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block001`];

    // Dùng để lưu cache, nó là 1 bộ nhớ đệm, loại bỏ trường hợp khi nhập dữ liệu quá nhanh, dữ liệu sẽ bị mất
    const setCacheData = cacheStore((state) => state.setCacheData);

    useEffect(() => {
        setCacheData(blockData);
    }, [blockData]);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="Block01" />
                    </article>

                    <Typography className="absolute w-full flex justify-center top-[5rem] left-[-6rem] text-sm text-center">主桁補強材</Typography>
                    <Typography className="absolute w-full flex justify-center top-[9.7rem] left-[-7rem] text-sm text-center">せん断ストッパー</Typography>
                    <Typography className="absolute w-full flex justify-center top-[11.25rem] left-[-7rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f24?.value)) ? '0' : `${blockData?.f24?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[26.5rem] left-[-7rem] text-sm text-center">下部工ブラケット</Typography>

                    <Typography className="absolute w-full flex justify-center top-[33.25rem] left-[-6.5rem] text-sm text-center">アンカーボルト</Typography>
                    <Typography className="absolute w-full flex justify-center top-[40rem] left-[-7rem] text-sm text-center">下部工ブラケット</Typography>
                    <Typography className="absolute w-full flex justify-center top-[45.75rem] left-[-7rem] text-sm text-center">せん断ストッパー</Typography>
                    <Typography className="absolute w-full flex justify-center top-[47.25rem] left-[-7rem] text-sm text-center">
                        {isNaN(parseFloat(blockData?.f24?.value)) ? '0' : `${blockData?.f24?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[24.5rem] left-[5.5rem] text-sm text-center">アンカーボルト</Typography>

                    <Typography className="absolute w-full flex justify-center top-[6.25rem] left-[6rem] text-sm text-center">せん断ストッパー</Typography>
                    <Typography className="absolute w-full flex justify-center top-[2.5rem] left-[17rem] text-sm text-center">主桁補強材</Typography>
                    <Typography className="absolute w-full flex justify-center top-[7.75rem] left-[6.5rem]  text-sm text-center">
                        {isNaN(parseFloat(blockData?.f24?.value)) ? '0' : `${blockData?.f24?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[21.5rem] left-[25.25rem] text-sm text-center">下部工ブラケット</Typography>
                </div>
                <SectionTitle title="反力・移動量・などの入力" />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="f8"
                    description="死荷重反力"
                    initValue={blockData?.f8?.value}
                    symbol={{ normalText: 'R', subText: 'd', mathSymbol: '=' }}
                    unit={UNIT.kNdivBearingLine}
                />

                <RowTextInputComponent blockNumber="block001" name="f10" description="設置基数" initValue={blockData?.f10?.value} symbol={{ normalText: 'n', mathSymbol: '=' }} unit={UNIT.basis} />
                <RowTextInputComponent
                    blockNumber="block001"
                    name="f12"
                    description="設計水平力"
                    initValue={blockData?.f12?.value}
                    symbol={{ normalText: 'H', subText: 'f', mathSymbol: '=' }}
                    unit={`${UNIT.kNdivBearingLine}`}
                />

                <RowTextResult
                    blockNumber="block001"
                    name="_K12"
                    description="橋軸方向"
                    paddingLeftDes={3}
                    initValue={blockData?._K12?.value}
                    defaultDisable={true}
                    symbol={{ normalText: 'H', subText: 'fe', mathSymbol: '=' }}
                    unit={UNIT.kNdivBasis}
                />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="f13"
                    initValue={blockData?.f13?.value}
                    symbol={{ normalText: 'H', subText: "f'", mathSymbol: '=' }}
                    unit={`${UNIT.kNdivBearingLine}	`}
                />
                <RowTextResult
                    blockNumber="block001"
                    name="_K13"
                    paddingLeftDes={3}
                    description="橋軸直角方向"
                    initValue={blockData?._K13?.value}
                    defaultDisable={true}
                    symbol={{ normalText: 'H', subText: "fe'", mathSymbol: '=' }}
                    unit={UNIT.kNdivBasis}
                />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="f15"
                    description="設計上場力"
                    initValue={blockData?.f15?.value}
                    symbol={{ normalText: 'R', subText: 'v', mathSymbol: '=' }}
                    unit={UNIT.kNdivBearingLine}
                />

                <RowTextResult blockNumber="block001" name="_K15" defaultDisable={true} initValue={blockData?._K15?.value} symbol={{ normalText: 'V', mathSymbol: '=' }} unit={UNIT.kNdivBasis} />

                <RowTextInputComponent blockNumber="block001" name="f17" description="支承移動量" initValue={blockData?.f17?.value} unit={UNIT.mm} />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="f19"
                    description="主桁セット方向"
                    initValue={blockData?.f19?.value}
                    symbol={{ normalText: 'θ1', mathSymbol: '=' }}
                    unit={UNIT.celsius}
                />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="f20"
                    description="設計上場力"
                    initValue={blockData?.f20?.value}
                    symbol={{ normalText: 'θ2', mathSymbol: '=' }}
                    unit={UNIT.celsius}
                />

                <RowTextResult
                    blockNumber="block001"
                    name="_K19"
                    defaultDisable={true}
                    description="斜角"
                    initValue={blockData?._K19?.value}
                    symbol={{ normalText: 'θ', mathSymbol: '=' }}
                    unit={UNIT.celsius}
                />
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 10 }}>
                    <Typography>※WEB方向にセットするとは限らず、温度伸縮方向に取り付けることがある (曲線橋、折れ桁)、 セット方向が桁方向と異なる場合があります。</Typography>
                </Box>
            </Stack>
        </Card>
    );
};

export default Block01;
