import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';

const Table0701 = ({ arrayValue }) => {
    return (
        <div className="w-full overflow-x-auto flex justify-start items-center">
            <div className="w-[100%] pb-3 ml-[7%]">
                <div className="pb-1">
                    <Typography sx={{ color: '#02457a' }}>
                        <strong>標準値</strong>
                    </Typography>
                </div>

                <table className="w-full text-sm text-right pb-3">
                    <thead className="text-xs text-white text-left bg-sky-600 dark:bg-gray-700">
                        <tr className="h-[32px]">
                            <th scope="col" className="px-2 py-1"></th>
                            <th scope="col" colSpan={2} className="px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>L側アンカーボルト</Typography>
                            </th>

                            <th scope="col" colSpan={2} className="px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>R側アンカーボルト</Typography>
                            </th>
                        </tr>

                        <tr className="h-[32px]">
                            <th scope="col" className="px-2 py-1"></th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>X軸</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>Y軸</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>X軸</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>Y軸</Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrayValue &&
                            arrayValue.map((value, index) => (
                                <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100" key={index}>
                                    <td scope="row" className="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        <Typography>{index + 1}</Typography>
                                    </td>
                                    <td>
                                        <TextField
                                            value={value[0] ?? ''}
                                            disabled
                                            inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed' } }}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#fdff9d',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                            }}
                                        ></TextField>
                                    </td>
                                    <td>
                                        <TextField
                                            value={value[1] ?? ''}
                                            disabled
                                            inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed' } }}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#fdff9d',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                            }}
                                        ></TextField>
                                    </td>
                                    <td>
                                        <TextField
                                            value={value[2] ?? ''}
                                            disabled
                                            inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed' } }}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#fdff9d',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                            }}
                                        ></TextField>
                                    </td>
                                    <td>
                                        <TextField
                                            value={value[3] ?? ''}
                                            disabled
                                            inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed' } }}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#fdff9d',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                            }}
                                        ></TextField>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table0701;
