import { TextField } from '@mui/material';
import React from 'react';

const CellOfTable = React.memo((props) => {
    const { initValue, rowIndex, index, handleChange, handleBlur, canEdited } = props;

    return (
        <TextField
            value={initValue ?? ''}
            onChange={(e) => handleChange(rowIndex, index, e)}
            onBlur={(e) => handleBlur(rowIndex, index, e)}
            onPaste={(e) => handleBlur(rowIndex, index, e)}
            placeholder="入力"
            disabled={canEdited}
            sx={{
                '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#272343',
                    cursor: 'not-allowed',
                },
                '& .MuiInputBase-root': {
                    height: '32px',
                },
            }}
            inputProps={{ style: { textAlign: 'center' } }}
        ></TextField>
    );
});

export default CellOfTable;
