import { Flip, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastShowSuccess = (message) =>
    toast.success(message, {
        position: 'bottom-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        transition: Flip,
        theme: 'colored',
    });

export const ToastShowError = (message, time) =>
    toast.error(message, {
        position: 'bottom-center',
        autoClose: time || 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        transition: Flip,
        theme: 'colored',
    });

export const ToastShowWarning = (message, time) =>
    toast.warn(message, {
        position: 'bottom-center',
        autoClose: time || 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        transition: Flip,
        theme: 'colored',
    });
