import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import image from './1-1.png';
import Table0101 from './Table01';
const Block05 = (props) => {
    const { blockData } = props;

    return (
        <>
            <Card
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 16px 0px 16px',
                }}
            >
                <div className="h-full w-full relative overflow-hidden flex items-center justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-center justify-center flex-col">
                        <img src={image} alt="Block05" height="375" />
                        <Box component="div" sx={{ display: 'inline-block', alignItems: 'center', paddingLeft: 1, marginTop: 1.5, marginBottom: 1.5 }}>
                            <Typography>取付位置</Typography>
                        </Box>
                    </article>
                    <Table0101 arrayValue={blockData?._AI35BC46} />
                </div>
            </Card>
        </>
    );
};

export default Block05;
