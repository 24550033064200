import { useCallback, useEffect, useState } from 'react';

// material-ui
import { Table, TableBody } from '@mui/material';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// component
import useTable, { getComparator } from 'custom_hook/useTable';

import { Box, Stack } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import { CustomToastComponent } from 'components/popup_components/CustomToastComponent';
import { ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import { t } from 'i18next';
import ListFilesFunction from 'pages/menu/components/ListFilesFunction/ListFilesFunction';
import TableHeadCustom from 'pages/menu/components/ListFilesTableHeader/TableHeadCustom';
import { applySortFilter, searchFilesFnc } from 'pages/menu/ultils/FileUltis';
import Page404 from 'pages/not-found/NotFound';
import { getListSheetsDeleted } from 'store/reducers/menuSlice';
import { PermissionsDefinition } from 'utils/StringDefinition';
import SheetDeletedRow from './components/SheetDeletedRow/SheetDeletedRow';

// ==============================|| Menu List Files ||============================== //

const RecycleBin = () => {
    const user = useSelector((state) => state.user.current);
    const isAdmin = user?.permission === PermissionsDefinition.ADMIN ? true : false;

    const currentHeight = useSelector((state) => state.calculatationSection.screenHeight);
    // console.log(currentHeight);

    let MAX_FILE_QUANTITY;

    switch (true) {
        case currentHeight <= 768:
            MAX_FILE_QUANTITY = 5;
            break;
        case currentHeight < 969:
            MAX_FILE_QUANTITY = 6;
            break;
        case currentHeight >= 969:
            MAX_FILE_QUANTITY = 9;
            break;
        default:
            MAX_FILE_QUANTITY = 9;
            break;
    }

    const { dense, order, orderBy, selected, onSort } = useTable();

    const TABLE_SHEET_DELETED_HEAD = [
        { id: 'sheetName', label: 'sheetName', align: 'center', width: '50px', minWidth: '50px' },
        { id: 'toolName', label: 'Tool', align: 'center', width: 2.5 },
        { id: 'editorName', label: 'Editor', align: 'center', width: 1.5 },
        { id: 'checkerName', label: 'Checker', align: 'center', width: 1.5 },
        { id: 'createdDate', label: 'Created Date', align: 'center', width: 1.5 },
        { id: 'deletedDate', label: 'Deleted Date', align: 'center', width: 1.5 },
        { id: 'status', label: 'Status', align: 'center', width: 1.5 },
        { id: 'action', label: 'action', align: 'center', width: 1.5 },
    ];
    const dispatch = useDispatch();

    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getListSheetsDeleted());
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const listFilesInit = useSelector((state) => state.menu.listSheetsDeleted);

    const [listFiles, setListFiles] = useState(listFilesInit || []);

    const [searchKeywords, setSearchKeywords] = useState('');
    const [searchStatus, setSearchStatus] = useState('All');

    const [pageOfFiles, setPageOfFiles] = useState({
        currentPage: 1,
        totalPages: Math.ceil(listFiles?.length / MAX_FILE_QUANTITY) * 10,
        minIndex: 0,
        maxIndex: MAX_FILE_QUANTITY,
    });

    useEffect(() => {
        setListFiles(listFilesInit);
        setPageOfFiles({ ...pageOfFiles, totalPages: Math.ceil(listFilesInit.length / MAX_FILE_QUANTITY) * 10 });
    }, [listFilesInit]);

    //When search box typing
    const handleSearchInput = (searchValue, searchStatus) => {
        // var searchValue = e.target.value;

        setSearchKeywords(searchValue);
        let listFilesResult = searchFilesFnc(listFilesInit, searchValue, searchStatus);
        // console.log(listFilesResult, searchValue, searchStatus);

        setListFiles(listFilesResult);
        setPageOfFiles({
            ...pageOfFiles,
            currentPage: 1,
            totalPages: Math.ceil(listFilesResult?.length / MAX_FILE_QUANTITY) * 10 || 10,
            minIndex: 0,
            maxIndex: MAX_FILE_QUANTITY,
        });
        // if (searchValue !== '') {
        //     setListFiles(listFilesResult);
        //     setPageOfFiles({
        //         ...pageOfFiles,
        //         currentPage: 1,
        //         totalPages: Math.ceil(listFilesResult?.length / MAX_FILE_QUANTITY) * 10 || 10,
        //         minIndex: 0,
        //         maxIndex: MAX_FILE_QUANTITY,
        //     });
        // } else if (searchValue === '') {
        //     setListFiles(listFilesResult);
        //     setPageOfFiles({
        //         currentPage: 1,
        //         totalPages: Math.ceil(listFilesInit?.length / MAX_FILE_QUANTITY) * 10,
        //         minIndex: 0,
        //         maxIndex: MAX_FILE_QUANTITY,
        //     });
        // }
    };

    const handleChangePageFiles = (current) => {
        setPageOfFiles({
            ...pageOfFiles,
            currentPage: current,
            minIndex: (current - 1) * MAX_FILE_QUANTITY,
            maxIndex: current * MAX_FILE_QUANTITY,
        });
    };

    const dataFiltered = applySortFilter({
        listFiles,
        comparator: getComparator(order, orderBy),
    });

    const handleRestoreSheet = async (values) => {
        const sheetId = values.id;

        //Restore sheet
        const response = await SheetAPI.ToggleSheet(sheetId);
        if (response.status === 200) {
            dispatch(getListSheetsDeleted());
            ToastShowSuccess(t('toastNoti.restore'));
        } else {
        }
    };

    const handleDeleteSheet = async (values) => {
        const sheetId = values.id;

        //deleteSheet
        const response = await SheetAPI.deleteSheet(sheetId);
        if (response.status === 200) {
            dispatch(getListSheetsDeleted());
            ToastShowSuccess(t('toastNoti.delete'));
        }
    };

    //Export list deleted file
    const listFilesView = dataFiltered?.map((file, index) => {
        if (index >= pageOfFiles?.minIndex && index < pageOfFiles?.maxIndex) {
            return <SheetDeletedRow key={file.id} currentUser={user} fileInfo={file} onRestoreSheet={() => handleRestoreSheet(file)} onDeleteSheet={() => handleDeleteSheet(file)} />;
        }
    });

    return (
        <>
            {isAdmin ? (
                <>
                    <Stack>
                        <Box
                            xs={12}
                            sx={{
                                pt: 2,
                                pb: 2,

                                width: '100wh',
                            }}
                        >
                            {/* SearchBox */}

                            <ListFilesFunction searchKeywords={searchKeywords} searchStatus={searchStatus} setSearchStatus={setSearchStatus} handleSearchInput={handleSearchInput} />
                        </Box>

                        {/* Main View Recycle Bin */}

                        <Stack
                            sx={{
                                m: 2,
                                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                                borderRadius: '5px',
                                width: '100wh',
                                height: '82vh',
                            }}
                        >
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Table size={dense ? 'small' : 'medium'}>
                                    <TableHeadCustom
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_SHEET_DELETED_HEAD}
                                        rowCount={listFilesInit.length}
                                        numSelected={selected.length}
                                        onSort={onSort}
                                    />

                                    <TableBody height="60vh">{listFilesView}</TableBody>
                                </Table>
                            </Box>

                            <Box component="div" sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                                {listFiles?.length > 0 ? (
                                    <Pagination defaultCurrent={1} current={pageOfFiles?.currentPage} showSizeChanger={false} total={pageOfFiles?.totalPages} onChange={handleChangePageFiles} />
                                ) : null}
                            </Box>
                        </Stack>

                        <CustomToastComponent />
                    </Stack>

                    <CustomToastComponent />
                </>
            ) : (
                <Page404 />
            )}
        </>
    );
};

export default RecycleBin;
