import Block0401 from './Block0401';
import Block0402 from './Block0402';
import Block0403 from './Block0403';

const Section04 = (props) => {
    return (
        <>
            <Block0401 />
            <Block0402 />
            <Block0403 />
        </>
    );
};

export default Section04;
