import { Card, Typography } from '@mui/material';
import { Box } from '@mui/system';
import image from './1-1.png';
import Table0101 from './Table01';
import { useSelector } from 'react-redux';
const Block05 = (props) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const { blockData } = props;
    const block001Sheet2Data = sheetData?.data?.version110?.[`block001`]?.sheet2Data;
    const block003Data = sheetData?.data?.version110?.[`block003`];
    const block003Sheet2Data = sheetData?.data?.version110?.[`block003`]?.sheet2Data;

    return (
        <>
            <Card
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0px 16px 0px 16px',
                }}
            >
                <div className="h-full w-full relative overflow-hidden items-center justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-center justify-center flex-col">
                        <img src={image} alt="block05" height="650" />

                        <Typography className="absolute w-full flex top-[9.75rem] left-[-21.5rem] text-sm text-center justify-center -rotate-90">C</Typography>
                        <Typography className="absolute w-full flex top-[9.75rem] left-[-20rem] text-sm text-center justify-center -rotate-90">
                            {isNaN(parseFloat(block003Data?.t256?.value)) ? '' : `${parseFloat(block003Data?.t256?.value)}`}
                        </Typography>
                        <Typography className="absolute w-full flex top-[0.75rem] left-[4rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r106?.value)) ? 'L1' : `L1 = ${parseFloat(block003Sheet2Data?.r106?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[12.25rem] left-[4.25rem] text-sm text-center justify-center -rotate-90">θ</Typography>

                        <Typography className="absolute w-full flex top-[12.25rem] left-[5.5rem] text-sm text-center justify-center -rotate-90">
                            {isNaN(parseFloat(block003Data?._AK25?.value)) ? '' : `${parseFloat(block003Data?._AK25?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[14rem] left-[2rem] text-sm text-center justify-center -rotate-90">θ1</Typography>

                        <Typography className="absolute w-full flex top-[14.25rem] left-[3.25rem] text-sm text-center justify-center -rotate-90">
                            {isNaN(parseFloat(block003Data?.aK27?.value)) ? '' : `${parseFloat(block003Data?.aK27?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[23rem] left-[3.5rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block001Sheet2Data?.p118?.value)) ? 'Lmax' : `Lmax = ${parseFloat(block001Sheet2Data?.p118?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[20.75rem] left-[-1rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r109?.value)) ? 'L2' : `L2 = ${parseFloat(block003Sheet2Data?.r109?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[20.75rem] left-[11.5rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r112?.value)) ? 'L3' : `L3 = ${parseFloat(block003Sheet2Data?.r112?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[34.75rem] left-[-5.25rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r100?.value)) ? 'AL' : `AL = ${parseFloat(block003Sheet2Data?.r100?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[32.5rem] left-[4.25rem] text-sm text-center justify-center">DL</Typography>
                        <Typography className="absolute w-full flex top-[34rem] left-[4.25rem] text-.5sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r102?.value)) ? '' : `${parseFloat(block003Sheet2Data?.r102?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[34.75rem] left-[13rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r101?.value)) ? 'BL' : `BL = ${parseFloat(block003Sheet2Data?.r101?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[37.25rem] left-[1.75rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r99?.value)) ? 'CL' : `CL = ${parseFloat(block003Sheet2Data?.r99?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[19.75rem] left-[16.5rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block003Sheet2Data?.r113?.value)) ? '' : `${parseFloat(block003Sheet2Data?.r113?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[18.25rem] left-[16.5rem] text-sm text-center justify-center">L4</Typography>

                        <Typography className="absolute w-full flex top-[18.25rem] left-[18.5rem] text-sm text-center justify-center">L5</Typography>

                        <Typography className="absolute w-full flex top-[19.75rem] left-[18.5rem] text-sm text-center justify-center">
                            {isNaN(parseFloat(block001Sheet2Data?.p119?.value)) ? '' : `${parseFloat(block001Sheet2Data?.p119?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[11rem] left-[24.75rem] text-sm text-center justify-center -rotate-90">
                            {isNaN(parseFloat(block003Sheet2Data?.r105?.value)) ? 'H1' : `H1 = ${parseFloat(block003Sheet2Data?.r105?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[15.5rem] left-[24rem] text-sm text-center justify-center -rotate-90">
                            {isNaN(parseFloat(block003Sheet2Data?.r110?.value)) ? 'H2' : `H2= ${parseFloat(block003Sheet2Data?.r110?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[11rem] left-[22.25rem] text-sm text-center justify-center -rotate-90">
                            {isNaN(parseFloat(block003Sheet2Data?.r111?.value)) ? 'H3' : `H3 = ${parseFloat(block003Sheet2Data?.r111?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[3.5rem] left-[5rem] text-sm text-center justify-center ">主桁下フランジ</Typography>
                        <Typography className="absolute w-full flex top-[10rem] left-[3rem] text-sm text-center justify-center -rotate-[10deg]">地震時作用時</Typography>
                        <Typography className="absolute w-full flex top-[0.75rem] left-[23rem] text-sm text-center justify-center ">補強材</Typography>
                        <Typography className="absolute w-full flex top-[8rem] left-[-15rem] text-sm text-center justify-center">橋座面</Typography>
                        <Typography className="absolute w-full flex top-[18.25rem] left-[-21rem] text-sm text-center justify-center ">アンカーボルト</Typography>
                        <Typography className="absolute w-full flex top-[19.75rem] left-[-21rem] text-sm text-center justify-center">エポキシ樹脂注入</Typography>
                        <Typography className="absolute w-full flex top-[23rem] left-[16rem] text-sm text-center justify-center">(移動可能量)</Typography>

                        <Box component="div" sx={{ display: 'inline-block', alignItems: 'center', paddingLeft: 1, marginTop: 1.5, marginBottom: 1.5 }}>
                            <Typography>
                                <b>取付寸法</b>
                            </Typography>
                        </Box>
                    </article>
                    <Table0101 arrayValue={blockData?._AI35BC46} />
                </div>
            </Card>
        </>
    );
};

export default Block05;
