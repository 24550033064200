import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MenuAPI from 'api/MenuAPI';

export const getListSheets = createAsyncThunk('menu/getListSheets', async () => {
    try {
        const res = await MenuAPI.getListSheets();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListSheets!!!');
        return error.message;
    }
});

export const getListSheetsDeleted = createAsyncThunk('menu/getListSheetsDeleted', async () => {
    try {
        const res = await MenuAPI.getListSheetsDeleted();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListSheetsDeleted!!!');
        return error.message;
    }
});

export const getListTools = createAsyncThunk('tools/getListTools', async () => {
    try {
        const res = await MenuAPI.GetListTools();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListTools!!!');
        return error.message;
    }
});

const menuSlice = createSlice({
    name: 'menu',
    initialState: {
        listSheets: [],
        listSheetsDeleted: [],
        listTools: [],
        status: 'idle',
        error: null,
    },
    reducers: [],
    extraReducers: {
        [getListSheets.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListSheets.fulfilled]: (state, action) => {
            state.listSheets = action.payload.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            state.status = 'succeeded';
        },
        [getListSheets.rejected]: (state, action) => {
            state.status = action.error.message;
        },

        [getListSheetsDeleted.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListSheetsDeleted.fulfilled]: (state, action) => {
            state.listSheetsDeleted = action.payload.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            state.status = 'succeeded';
        },
        [getListSheetsDeleted.rejected]: (state, action) => {
            state.status = action.error.message;
        },
        [getListTools.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListTools.fulfilled]: (state, action) => {
            state.listTools = action.payload?.data;
            state.status = 'succeeded';
        },
        [getListTools.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { actions, reducer } = menuSlice;

export default reducer;
