import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import Table0701 from './Table0701';
import Table0702 from './Table0702';
import Table0703 from './Table0703';

import RowTextThreeInput from '../share-components/RowThreeInput';
import SectionTitle, { InstructRow, SectionTitleSub } from '../share-components/Tilte';

import image611 from './6-1-1.png';
import image612 from './6-1-2.png';
import image613 from './6-1-3.png';
import image614 from './6-1-4.png';
import image615 from './6-1-5.png';

import RowTextThreeSelect from '../share-components/RowThreeSelect';

const Block0601 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block010`];

    const tableData = sheetData?.data?.version110?.[`block011`];

    let value = 1;
    let leftAL236 = 'left-[3rem]';
    let leftAL235 = 'left-[3.5rem]';

    const al235Value = blockData?._AL235?.value;
    const al236AbsValue = blockData?._AL236ABS?.value;

    if (al235Value !== undefined && al236AbsValue !== undefined) {
        if (al235Value < 0 && al236AbsValue < 0) {
            value = 2;
            leftAL236 = 'left-[4rem]';
            leftAL235 = 'left-[2.5rem]';
        } else if (al235Value < 0 && al236AbsValue > 0) {
            value = 3;
            leftAL236 = 'left-[4.5rem]';
            leftAL235 = 'left-[-0.5rem]';
        } else if (al235Value > 0 && al236AbsValue < 0) {
            value = 4;
            leftAL236 = 'left-[0.5rem]';
            leftAL235 = 'left-[4.5rem]';
        } else if (al235Value === al236AbsValue) {
            value = 5;
            leftAL236 = 'left-[2rem]';
            leftAL235 = 'left-[2rem]';
        }
    }

    const getImageSource = (image) => {
        switch (image) {
            case 1:
                return image611;
            case 2:
                return image612;
            case 3:
                return image613;
            case 4:
                return image614;
            case 5:
                return image615;
            default:
                return image611;
        }
    };

    const imageSource = getImageSource(value);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                {/* <SectionTitle title="下部工ブラケット" />
                <Box component="div" sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, paddingLeft: 2, paddingBottom: 2 }}>
                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">下部工ブラケット設置形式は、縦型、横型、回転型の中から選択する。</Typography>
                    </Box>
                    <RowInputSubConstructionType blockNumber="block001" name="subConstructionType" initValue={blockData?._K201} />

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・斜角６０度程度までは、縦型を選択する。</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・建築限界に支障がある場合は、横型を選択する。</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・※斜角のある橋では斜角なしの場合と下記照査が異なる為、本計算書は斜角が無い場合にのみ使用すること。</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 3 }}>
                        <Typography variant="h5">「・アンカーボルトのせん断力の照査・補強リブの形状に合わせた断面照査・溶接設計」</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・斜角が６０度以下で横型を採用できない場合は回転型を採用する</Typography>
                    </Box>
                </Box> */}

                <article className="h-full w-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={imageSource} alt="background" height="375" />

                    <Typography className="absolute w-full flex justify-center top-[11rem] left-[-27.75rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T232?.value) ? 'L1' : `L1 = ${blockData?._T232?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[11rem] left-[-25.75rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T241?.value) ? 'L4' : `L4 = ${blockData?._T241?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[12.75rem] left-[-23.75rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T253?.value) ? 'e' : `e = ${blockData?._T253?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[11rem] left-[-8.75rem] text-sm -rotate-90">
                        {isNaN(blockData?._T232?.value) ? 'L1' : `L1 = ${blockData?._T232?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[-22.5rem] text-sm text-center">
                        {isNaN(blockData?._T235?.value) ? 't2' : `t2 = ${blockData?._T235?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[18.5rem] left-[-18.25rem] text-sm text-center">
                        {isNaN(blockData?._T234?.value) ? 'h1' : `h1 = ${blockData?._T234?.value}`}
                    </Typography>
                    <Typography className={`absolute w-full flex justify-center top-[5rem] ${leftAL236} text-sm text-center`}>
                        {isNaN(blockData?._AL236ABS?.value) ? 'σc' : `σc = ${blockData?._AL236ABS?.value}`}
                    </Typography>

                    <Typography className={`absolute w-full flex justify-center top-[17.75rem] ${leftAL235} text-sm text-center`}>
                        {isNaN(blockData?._AL235?.value) ? 'σt' : `σt = ${blockData?._AL235?.value}`}
                    </Typography>
                    {/* <Typography className={`absolute w-full flex justify-center top-[18.5rem] left-[${padding?.leftAL235}] text-sm text-center`}>
                        {isNaN(blockData?._AL235?.value) ? 'σt' : `σt = ${blockData?._AL235?.value}`}
                    </Typography> */}
                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[-7.75rem] text-sm text-center">
                        {isNaN(blockData?._T233?.value) ? 't1' : `t1 = ${blockData?._T233?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[41.5rem] left-[2.5rem] text-sm -rotate-90">
                        {isNaN(blockData?._T232?.value) ? 'L2' : `L2 = ${blockData?._T232?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[53rem] left-[7rem] text-sm text-center">
                        {isNaN(blockData?._T245?.value) ? 'h2' : `h2 = ${blockData?._T245?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[0.75rem] left-[13rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[0][0]?.value) || parseFloat(tableData?._AO277AX280[0][0]?.value) === 0 ? 'X1L = None' : `X1L = ${tableData?._AO277AX280[0][0]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[0.75rem] left-[25rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[0][2]?.value) || parseFloat(tableData?._AO277AX280[0][2]?.value) === 0 ? 'X1R = None' : `X1R = ${tableData?._AO277AX280[0][2]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[11rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[1][0]?.value) || parseFloat(tableData?._AO277AX280[1][0]?.value) === 0 ? 'X2L = None' : `X2L = ${tableData?._AO277AX280[1][0]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[27.5rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[1][2]?.value) || parseFloat(tableData?._AO277AX280[1][2]?.value) === 0 ? 'X2R = None' : `X2R = ${tableData?._AO277AX280[1][2]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[20rem] left-[11rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[2][0]?.value) || parseFloat(tableData?._AO277AX280[2][0]?.value) === 0 ? 'X3L = None' : `X3L = ${tableData?._AO277AX280[2][0]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[20rem] left-[27rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[2][2]?.value) || parseFloat(tableData?._AO277AX280[2][2]?.value) === 0 ? 'X3R = None' : `X3R = ${tableData?._AO277AX280[2][2]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[22rem] left-[13rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[3][0]?.value) || parseFloat(tableData?._AO277AX280[3][0]?.value) === 0 ? 'X4L = None' : `X4L = ${tableData?._AO277AX280[3][0]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[22rem] left-[25rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[3][3]?.value) || parseFloat(tableData?._AO277AX280[3][3]?.value) === 0 ? 'X4R = None' : `X4R = ${tableData?._AO277AX280[3][3]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15.5rem] left-[11.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[3][1]?.value) || parseFloat(tableData?._AO277AX280[3][1]?.value) === 0 ? 'Y4L = None' : `Y4L = ${tableData?._AO277AX280[3][1]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[8.25rem] left-[11.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[0][1]?.value) || parseFloat(tableData?._AO277AX280[0][1]?.value) === 0 ? 'Y1L = None' : `Y1L = ${tableData?._AO277AX280[0][1]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[9.5rem] left-[13.75rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[1][1]?.value) || parseFloat(tableData?._AO277AX280[1][1]?.value) === 0 ? 'Y2L = None' : `Y2L = ${tableData?._AO277AX280[1][1]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[14rem] left-[13.75rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[2][1]?.value) || parseFloat(tableData?._AO277AX280[2][1]?.value) === 0 ? 'Y3L = None' : `Y3L = ${tableData?._AO277AX280[2][1]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15rem] left-[26.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[3][3]?.value) || parseFloat(tableData?._AO277AX280[3][3]?.value) === 0 ? 'Y4R = None' : `Y4R = ${tableData?._AO277AX280[3][3]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[8.5rem] left-[26.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[0][3]?.value) || parseFloat(tableData?._AO277AX280[0][3]?.value) === 0 ? 'Y1R = None' : `Y1R = ${tableData?._AO277AX280[0][3]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[14rem] left-[24rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[2][3]?.value) || parseFloat(tableData?._AO277AX280[2][3]?.value) === 0 ? 'Y3R = None' : `Y3R = ${tableData?._AO277AX280[2][3]?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[9.5rem] left-[24rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[1][3]?.value) || parseFloat(tableData?._AO277AX280[1][3]?.value) === 0 ? 'Y2R = None' : `Y2R = ${tableData?._AO277AX280[1][3]?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[-18.5rem] text-sm text-center -rotate-90">F.P</Typography>

                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[-14rem] text-sm text-center">ベースプレート</Typography>

                    <Typography className="absolute w-full flex justify-center top-[5rem] left-[-13rem] text-sm text-center">補強リブ</Typography>

                    <Typography className="absolute w-full flex justify-center top-[16.25rem] left-[-14rem] text-sm text-center">トッププレート</Typography>
                </article>

                {/* <article className="h-full w-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image61} alt="background" height="650" width="100%" />

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[-33.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T230) ? 'L1' : `L1 = ${blockData?._T230}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[-30.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T239) ? 'L4' : `L4 = ${blockData?._T239}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[14.3rem] left-[-28.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T51) ? 'e' : `e = ${blockData?._T251}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[-7rem] text-sm -rotate-90">{isNaN(blockData?._T230) ? 'L1' : `L1 = ${blockData?._T230}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[4.5rem] left-[-26rem] text-sm text-center">
                        {isNaN(blockData?._T233) ? 't2' : `t2 = ${blockData?._T233}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[24rem] left-[-22rem] text-sm text-center">{isNaN(blockData?._T232) ? 'h1' : `h1 = ${blockData?._T232}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[6rem] left-[1.5rem] text-sm text-center">
                        {isNaN(blockData?._AL234) ? 'σc' : `σc = ${blockData?._AL234}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[20rem] left-[2.5rem] text-sm text-center">
                        {isNaN(blockData?._AL233) ? 'σt' : `σt = ${blockData?._AL233}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[5rem] left-[-10.5rem] text-sm text-center">
                        {isNaN(blockData?._T231) ? 't1' : `t1 = ${blockData?._T231}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[41.5rem] left-[2.5rem] text-sm -rotate-90">
                        {isNaN(blockData?._T230) ? 'L2' : `L2 = ${blockData?._T230}`}
                    </Typography>

                   <Typography className="absolute w-full flex justify-center top-[5rem] left-[25rem] text-sm text-center">{isNaN(blockData?._T233) ? 't2' : `t2 = ${blockData?._T233}`}</Typography> 

                    <Typography className="absolute w-full flex justify-center top-[53rem] left-[7rem] text-sm text-center">{isNaN(blockData?._T243) ? 'h2' : `h2 = ${blockData?._T243}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[9rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[1][0]) ? 'X2L' : `X2L = ${tableData?._AO277AX280[1][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[16rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[0][0]) ? 'X1L' : `X1L = ${tableData?._AO277AX280[0][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[27rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[0][2]) ? 'X1R' : `X1R = ${tableData?._AO277AX280[0][2]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[32rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[1][2]) ? 'X2R' : `X2R = ${tableData?._AO277AX280[1][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[21rem] left-[9rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[2][0]) ? 'X3L' : `X3L = ${tableData?._AO277AX280[2][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[24.5rem] left-[15rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[3][0]) ? 'X4L' : `X4L = ${tableData?._AO277AX280[3][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[24.5rem] left-[27rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[3][3]) ? 'X4R' : `X4R = ${tableData?._AO277AX280[3][3]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[21rem] left-[33rem] text-sm text-center">
                        {isNaN(tableData?._AO277AX280[2][2]) ? 'X3R' : `X3R = ${tableData?._AO277AX280[2][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15.5rem] left-[12rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[3][1]) ? 'Y4L' : `Y4L = ${tableData?._AO277AX280[3][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[9.5rem] left-[12rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[0][1]) ? 'Y1L' : `Y1L = ${tableData?._AO277AX280[0][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[15rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[1][1]) ? 'Y2L' : `Y2L = ${tableData?._AO277AX280[1][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[16rem] left-[15rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[2][1]) ? 'Y3L' : `Y3L = ${tableData?._AO277AX280[2][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15.5rem] left-[29rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[3][3]) ? 'Y4R' : `Y4R = ${tableData?._AO277AX280[3][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[29rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[0][3]) ? 'Y1R' : `Y1R = ${tableData?._AO277AX280[0][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[16rem] left-[26.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[2][3]) ? 'Y3R' : `Y3R = ${tableData?._AO277AX280[2][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[26.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO277AX280[1][3]) ? 'Y2R' : `Y2R = ${tableData?._AO277AX280[1][3]}`}
                    </Typography>
                </article> */}

                <InstructRow />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p232"
                    description="トッププレート長さ"
                    initValue={{ looking: blockData?._L232?.value, input: blockData?.p232?.value, calculate: blockData?._T232?.value }}
                    symbol={{ normalText: 'L1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p233"
                    description="トッププレート厚"
                    initValue={{ looking: blockData?._L233?.value, input: blockData?.p233?.value, calculate: blockData?._T233?.value }}
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p234"
                    description="荷重の作用高さ"
                    initValue={{ looking: blockData?._L234?.value, input: blockData?.p234?.value, calculate: blockData?._T234?.value }}
                    symbol={{ normalText: 'h1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p235"
                    description="ベースプレート厚"
                    initValue={{ looking: blockData?._L235?.value, input: blockData?.p235?.value, calculate: blockData?._T235?.value }}
                    symbol={{ normalText: 't2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p236"
                    description="ベースプレート長さ"
                    initValue={{ looking: blockData?._L236?.value, input: blockData?.p236?.value, calculate: blockData?._T236?.value }}
                    symbol={{ normalText: 'L2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p237"
                    description="補強リブ厚"
                    initValue={{ looking: blockData?._L237?.value, input: blockData?.p237?.value, calculate: blockData?._T237?.value }}
                    symbol={{ normalText: 't3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p238"
                    description="補強リブ高さ"
                    initValue={{ looking: blockData?._L238?.value, input: blockData?.p238?.value, calculate: blockData?._T238?.value }}
                    symbol={{ normalText: 'h3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p239"
                    description="補強リブ幅"
                    initValue={{ looking: blockData?._L239?.value, input: blockData?.p239?.value, calculate: blockData?._T239?.value }}
                    symbol={{ normalText: 'w', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p240"
                    description="スカーラップ"
                    initValue={{ looking: blockData?._L240?.value, input: blockData?.p240?.value, calculate: blockData?._T240?.value }}
                    symbol={{ normalText: 'R', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p241"
                    description="リブ間距離"
                    initValue={{ looking: blockData?._L241?.value, input: blockData?.p241?.value, calculate: blockData?._T241?.value }}
                    symbol={{ normalText: 'L4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Typography sx={{ paddingLeft: '59%' }}>※リブ高さ０の場合は、採用値に手入力</Typography>

                <SectionTitleSub title="アンカーボルトの計算" paddingLeftFit={5} />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p245"
                    description="荷重の作用高さ"
                    initValue={{ looking: blockData?._L245?.value, input: blockData?.p245?.value, calculate: blockData?._T245?.value }}
                    symbol={{ normalText: 'h2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p246"
                    description="ボルト中心間隔"
                    initValue={{ looking: blockData?._L246?.value, input: blockData?.p246?.value, calculate: blockData?._T246?.value }}
                    symbol={{ normalText: 'b1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p247"
                    description="ボルト本数"
                    initValue={{ looking: blockData?._L247?.value, input: blockData?.p247?.value, calculate: blockData?._T247?.value }}
                    symbol={{ normalText: 'n', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p248"
                    description="1列のボルト本数"
                    initValue={{ looking: blockData?._L248?.value, input: blockData?.p248?.value, calculate: blockData?._T248?.value }}
                    symbol={{ normalText: 'n1', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />

                <RowTextThreeSelect
                    blockNumber="block010"
                    name="p250"
                    description="アンカーボルト径"
                    initValue={{ looking: blockData?._L250?.value, input: blockData?.p250?.value, calculate: blockData?._T250?.value }}
                    symbol={{ normalText: 'Ｄ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p251"
                    description="アンカーボルトネジ径"
                    initValue={{ looking: blockData?._L251?.value, input: blockData?.p251?.value, calculate: blockData?._T251?.value }}
                    symbol={{ normalText: 'Ｍ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p253"
                    description="偏心量"
                    initValue={{ looking: blockData?._L253?.value, input: blockData?.p253?.value, calculate: blockData?._T253?.value }}
                    symbol={{ normalText: 'e', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p254"
                    description="ボルトの縁端距離 (縦)"
                    initValue={{ looking: blockData?._L254?.value, input: blockData?.p254?.value, calculate: blockData?._T254?.value }}
                    symbol={{ normalText: 'a1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p255"
                    description="ボルトの縁端距離 (横)"
                    initValue={{ looking: blockData?._L255?.value, input: blockData?.p255?.value, calculate: blockData?._T255?.value }}
                    symbol={{ normalText: 'a2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p256"
                    description="へりあき寸法"
                    initValue={{ looking: blockData?._L256?.value, input: blockData?.p256?.value, calculate: blockData?._T256?.value }}
                    symbol={{ normalText: 'c', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 1 }}>
                    <SectionTitle title="ボルト間隔 (ブラケット中心からのアンカー位置)" />
                </Box>
                <div className="grid grid-cols-2 w-[90%]">
                    <Table0701 arrayValue={tableData?._M277V280} />

                    <Table0702 arrayValue={tableData?.aA277AJ280} />
                    <div className="col-span-2">
                        <Table0703 arrayValue={tableData?._AO277AX280} />
                    </div>
                </div>
            </Stack>
        </Card>
    );
};

export default Block0601;
