import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';

import RowTextInputComponent from '../share-components/RowInput';
import RowSelectComponent from '../share-components/RowSelect';
import RowTextResult from '../share-components/RowTextResult';
import RowTextResultPA from '../share-components/RowTextResultPA';
import SectionTitle from '../share-components/Tilte';
import Table0102 from './Table0102';

const bufferChainTypeList = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 8 },
    { id: 8, value: 9 },
    { id: 9, value: 10 },
];

const Block01 = (props) => {
    const { blockData } = props;

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="反力・移動量" />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="o4"
                    description="① １支承線あたりの死荷重反力"
                    initValue={blockData?.o4?.value}
                    numDigitsAfterDot={1}
                    symbol={{ normalText: 'R', subText: 'd', mathSymbol: '=' }}
                    unit={UNIT.kN}
                />
                <RowTextInputComponent blockNumber="block001" name="o6" initValue={blockData?.o6.value} description="② 緩衝チエーン取付箇所数" allowNegativeNumber={false} unit={UNIT.hon} />
                <RowTextResult name="_O8" initValue={blockData?._O8.value} description="③ １箇所あたりの反力" unit={UNIT.kN} />

                <RowTextResult
                    name="_O10"
                    paddingLeftDes={3}
                    initValue={blockData?._O10.value}
                    description="1）作用力"
                    descriptionWidth="15%"
                    formulaWidth="25%"
                    mathSymbolWidth="8%"
                    symbol={{ normalText: 'HF = 1.5 × R', subText: 'd', mathSymbol: '=' }}
                    unit={`${UNIT.kN}	/	${blockData?._V10.value ?? ''} ${UNIT.basis}`}
                />

                <RowTextResult
                    name="_O11"
                    initValue={blockData?._O11?.value}
                    description=""
                    symbol={{ normalText: 'HR ＝ HF / 緩衝チエーン取付箇所数', mathSymbol: '=' }}
                    unit={`${UNIT.kN}	/	${blockData?._V11?.value} ${UNIT.basis}`}
                    descriptionWidth="5%"
                    formulaWidth="35%"
                    mathSymbolWidth="6%"
                />
                <RowTextInputComponent paddingLeftDes={3} blockNumber="block001" name="o27" initValue={blockData?.o27.value} description="2）下部工水平耐力 PLG" unit={UNIT.kN} />

                <RowSelectComponent
                    blockNumber="block001"
                    name="o13"
                    paddingLeftDes={3}
                    description="緩衝チェーンタイプ"
                    initValue={blockData?.o13.value}
                    listValue={bufferChainTypeList}
                    unit={UNIT.kata}
                />
                <RowTextResultPA
                    description="チェーンの許容荷重の照査"
                    paddingLeftDes={3}
                    valuePA={blockData?._K14?.value}
                    mathSymbol={blockData?._N14?.value}
                    valueHR={blockData?._P14?.value}
                    status={blockData?._V14?.value}
                    descriptionWidth="24%"
                    formulaWidth="12%"
                    initValueWidth="19%"
                    unit={UNIT.kN}
                />

                <RowTextResult
                    blockNumber="block001"
                    name="_O16"
                    initValue={blockData?._O16.value}
                    description="④ 必要桁かかり長"
                    descriptionWidth="15%"
                    formulaWidth="25%"
                    mathSymbolWidth="8%"
                    symbol={{ normalText: ' (0.005 × 支間長＋0.7) × 1000', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent blockNumber="block001" name="o18" initValue={blockData?.o18.value} description="⑤ 支承移動量" unit={UNIT.mm} />

                <RowTextResult name="_O20" initValue={blockData?._O20.value} description="⑥ 移動可能量" unit={UNIT.mm} />
                <RowTextResult paddingLeftDes={3} description="緩衝チェーンの伸量" initValue={blockData?.sheet2Data?.p116?.value} symbol={{ normalText: '⊿a', mathSymbol: '=' }} unit={UNIT.mm} />
                <RowTextResult paddingLeftDes={3} description="Clmax	＝	CL ＋ ⊿a" initValue={blockData?.sheet2Data?.p117?.value} symbol={{ mathSymbol: '=' }} unit={UNIT.mm} />
                <RowTextResult paddingLeftDes={3} description="Lmax  = √( CLmax² － H1²)" initValue={blockData?.sheet2Data?.p118?.value} symbol={{ mathSymbol: '=' }} unit={UNIT.mm} />
                <RowTextResult paddingLeftDes={3} description="L5	＝	 Lmax － L1	" initValue={blockData?.sheet2Data?.p119?.value} symbol={{ mathSymbol: '=' }} unit={UNIT.mm} />
                <RowTextResult paddingLeftDes={3} description="移動可能量  = ⊿L = L4 ＋ L5 " initValue={blockData?.sheet2Data?.p120?.value} symbol={{ mathSymbol: '=' }} unit={UNIT.mm} />
                <Table0102 arrayValue={blockData?._AI35BC46} />

                <RowTextResult paddingLeftFit={14} description="緩衝チェーンの伸量⊿a <  ⊿L < SE × 0.75" />

                <RowTextInputComponent blockNumber="block001" name="o23" initValue={blockData?.o23.value} description="⑦ 斜角" unit={UNIT.do} />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="o25"
                    initValue={`${blockData?.o25.value !== '' ? parseFloat(blockData?.o25.value).toFixed(3) : ''}`}
                    numDigitsAfterDot={3}
                    description="⑧ 支間長"
                    unit={UNIT.m}
                />
            </Stack>
        </Card>
    );
};

export default Block01;
