const StorageKeys = {
    user: 'user',
    access: 'access_token',
    refresh: 'refresh_token',
    expiration_access: 'expiration_access',
    expiration_refresh: 'expiration_refresh',
    persist_value: 'persist:root',
};

export default StorageKeys;
