import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const RowTextInputComponent = React.memo((props) => {
    const dispatch = useDispatch();

    const { name, description, initValue, symbol, unit, allowNegativeNumber, defaultDisable, blockNumber, widthTextField, numDigitsAfterDot, paddingLeftDes, errorBgColor } = props;
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);

    const sectionData = useSelector((state) => state.sheet.dataSection);
    var index = useSelector((state) => state.calculatationSection.index);
    const cacheData = cacheStore((state) => state.cacheData);
    const setCacheData = cacheStore((state) => state.setCacheData);

    const [num, setNum] = useState(initValue);
    const isEditor = user?.id === sheetInfo?.editor?.id;
    const canEdited = defaultDisable ? defaultDisable : !(sheetInfo && [SheetStatus.EDITING, SheetStatus.CHECKED].includes(sheetInfo.status) && isEditor);
    const sheetId = sectionData?.data?.sheetId;
    const blockData = sectionData?.data?.tool002version100?.[blockNumber];

    // This effect will be called every time initValue changes.
    useEffect(() => {
        if (initValue !== num) {
            setNum(initValue);
        }
    }, [initValue]);

    const handleChange = (e) => {
        // For positive numbers
        const regex = numDigitsAfterDot ? new RegExp(`^[0-9]*\\.?[0-9]{0,${numDigitsAfterDot}}$`) : /^[0-9]*\.?[0-9]*$/;

        // For negative numbers
        const regexNegativeNumber = numDigitsAfterDot ? new RegExp(`^-?[0-9]*\\.?[0-9]{0,${numDigitsAfterDot}}$`) : /^-?[0-9]*\.?[0-9]*$/;

        let newValue = e.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            setNum(newValue);
        }
    };

    function getTypeByName(name) {
        // Check if the name exists in the JSON object
        if (blockData.hasOwnProperty(name)) {
            // Extract the "type" based on the given "name"
            return blockData[name].type;
        } else {
            // Return an error message or handle the case when the name is not found
            return 'Name not found in the JSON object';
        }
    }

    const handleBlur = (e) => {
        let newValue = e.target.value;

        if (newValue === '.' || newValue === '-.' || newValue === '.-' || newValue === '-') {
            setNum('');
        } else {
            let cacheDataChanged = {
                ...cacheData,
                [name]: {
                    value: newValue === '' ? '' : parseFloat(newValue).toString(),
                    type: getTypeByName(name),
                },
            };

            setCacheData(cacheDataChanged);

            // console.log('cacheData', cacheData);

            let sectionDataChanged = {
                ...sectionData.data,
                tool002version100: { ...sectionData.data?.tool002version100, [blockNumber]: cacheDataChanged },
            };

            if (!_.isEqual(blockData, cacheDataChanged)) {
                // console.log(blockData, dataChanged);
                updateData(sectionDataChanged);
            }
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '33%', alignItems: 'center', marginLeft: 10, paddingLeft: paddingLeftDes ? paddingLeftDes : 0 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '7%' }}>
                <Typography sx={{ width: symbol?.mathSymbol ? '70%' : '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>

                {symbol?.mathSymbol && <Typography sx={{ width: '30%' }}>{` ${symbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: widthTextField || '15%' }}>
                <TextField
                    disabled={canEdited}
                    value={num ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPaste={handleBlur}
                    placeholder={defaultDisable ? '' : '入力'}
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                        '& .MuiInputBase-root': {
                            // backgroundColor: defaultDisable ? '#FFF2CC' : errorBgColor ? 'red' : '',
                            border: `${errorBgColor ? '1px solid #dc2626' : ''}`,
                            backgroundColor: defaultDisable ? '#FFF2CC' : errorBgColor ? '#f4e6e6' : '',
                            height: '32px',
                            padding: 0,
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: `${errorBgColor ? '#dc2626' : ''}`,
                        },
                        '&.Mui-focused': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `${errorBgColor ? '1px solid #dc2626' : ''}`,
                            },
                        },
                    }}
                    inputProps={{ style: { textAlign: 'right' } }}
                ></TextField>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', paddingLeft: 3 }}>
                <Typography>{unit}</Typography>
            </Box>
        </Box>
    );
});

export default RowTextInputComponent;
