import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
// @mui
import { Avatar, Badge, Box, Divider, List, ListItemAvatar, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';

// components
import NotificationAPI from 'api/NotificationAPI';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { getNotificationsByUserId } from 'store/reducers/NotificationSlice';
import { changeSection } from 'store/reducers/calculatationSlice';
import { getCommentsBySheetId } from 'store/reducers/commentsSlice';
import { getListSectionsOfSheet, getSheetById } from 'store/reducers/sheetSlice';
import { RouterConstants } from 'utils/constants/RouterConstants';
import UrlConstant from 'utils/url/UrlConstant';
import MenuPopover from '../LanguagePopover/MenuPopover';
import Iconify from '../MainLayout/HZMainSideBar/Navigation/Iconify';
import IconButtonAnimate from './IconButtonAnimate';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
    const [open, setOpen] = useState(null);
    const listNotifications = useSelector((state) => state.notifications.listNotifications);

    const listNotificationsSorted = listNotifications?.length > 50 ? [...listNotifications]?.slice(0, 50) : [...listNotifications];

    const totalUnRead = [...listNotifications]?.filter((item) => item.isRead === false).length;

    // console.log('listNotificationsSorted ', listNotificationsSorted);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const dispatch = useDispatch();

    const handleMarkAllAsRead = async () => {
        const response = await NotificationAPI.markAllAsRead();
        if (response.status === 200) {
            dispatch(getNotificationsByUserId());
        }
    };

    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getNotificationsByUserId());
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    // const currentSheet = useSelector((state) => state.menu.currentSheet);

    // const dispatch = useDispatch();

    // useEffect(() => {
    //     const connection = new HubConnectionBuilder().withUrl(`${UrlConstant.HOZEN_HOST_URL}/NotificationHub`).withAutomaticReconnect().configureLogging(LogLevel.Information).build();

    //     connection.start().then(function () {
    //         connection.on(`SheetStatusChanged`, () => {
    //             // Hàm này sẽ lắng nghe sự kiện SheetStatusChanged, nếu có tín hiệu báo từ Hub về thì bắn 1 dispatch lên Redux để fetch lại dữ liệu
    //             // const action = getNotificationsByUserId(user?.id);
    //             // dispatch(action);
    //             if (currentSheet.id) {
    //                 const action2 = getCommentsBySheetId(currentSheet.id);
    //                 dispatch(action2);
    //             }
    //         });

    //         connection
    //             .invoke('getConnectionId')
    //             .then(function (id) {
    //                 // console.log('connectionid:', id);
    //             })
    //             .catch(function (err) {
    //                 return console.log('ERROR in NotificationHub getConnectionId:', err.toString());
    //             });

    //         connection.invoke('LoginSession', user?.id).catch(function (err) {
    //             return console.log('ERROR in NotificationHub LoginSession:', err.toString());
    //         });

    //         connection.on(`JoinRoom`, (message) => {
    //             console.log(`JoinRoom: `, message);
    //         });

    //         // connection.on(`Login`, (message) => {
    //         //     console.log(`Login: `, message);
    //         // });
    //     });

    //     // connection
    //     //     .start()
    //     //     .then(() => connection.invoke('LoginSession', userIdString))
    //     //     .then(() => setConnection(connection))
    //     //     .catch(function (err) {
    //     //         return console.log('ERROR in NotificationHub  LoginSession:', err.toString());
    //     //     });

    //     return () => {
    //         if (connection) connection.stop();
    //     };
    // }, []);
    return (
        <>
            <IconButtonAnimate color={open ? 'primary' : 'default'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
                <Badge badgeContent={totalUnRead} color="error">
                    <Iconify icon="eva:bell-fill" width={20} height={20} />
                </Badge>
            </IconButtonAnimate>

            <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose} sx={{ width: 360, p: 0, mt: 0.25, ml: 0.5, height: '90%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1">お知らせ</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            未読のメッセージは {totalUnRead} 件です
                        </Typography>
                    </Box>

                    {totalUnRead > 0 && (
                        <Tooltip title="すべて既読にする">
                            <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                                <Iconify icon="eva:done-all-fill" width={20} height={20} />
                            </IconButtonAnimate>
                        </Tooltip>
                    )}
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <SimpleBar style={{ height: '90%' }}>
                    <List disablePadding>
                        {listNotificationsSorted?.length > 0 &&
                            listNotificationsSorted?.map((notification) => <NotificationItem key={notification.id} notification={notification} setOpen={setOpen} />)}
                    </List>
                </SimpleBar>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Box sx={{ p: 1 }}>
                    {/* <Button fullWidth disableRipple>
                        すべて表示
                    </Button> */}
                </Box>
            </MenuPopover>
        </>
    );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
    notification: PropTypes.shape({
        createdAt: PropTypes.instanceOf(Date),
        id: PropTypes.string,
        isUnRead: PropTypes.bool,
        title: PropTypes.string,
        description: PropTypes.string,
        type: PropTypes.string,
        avatar: PropTypes.any,
    }),
};

function NotificationItem({ notification, setOpen }) {
    let avatarPath = `${UrlConstant.HOZEN_HOST_URL}${notification?.avatarPath}`;

    const dispatch = useDispatch();
    let navigate = useNavigate();
    const handleOpenSheet = async () => {
        await NotificationAPI.markRead(notification?.id);
        const sheetId = notification?.sheet?.id;
        // console.log(sheetId);
        dispatch(getSheetById(sheetId));
        dispatch(getListSectionsOfSheet(sheetId));
        dispatch(getCommentsBySheetId(sheetId));
        dispatch(getNotificationsByUserId());
        dispatch(changeSection(1));
        navigate(`/${RouterConstants.SHEET}`);
        setOpen(false);
    };
    return (
        <ListItemButton
            sx={{
                py: 1.5,
                px: 2.5,
                mt: '1px',
                ...(!notification.isRead && {
                    bgcolor: 'action.selected',
                }),
            }}
            onClick={() => handleOpenSheet(notification)}
        >
            <ListItemAvatar>
                <Avatar sx={{ bgcolor: 'background.neutral' }} src={avatarPath}></Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box>
                        <Typography>{notification?.notificationHeader}</Typography>
                        <Typography>{notification?.notificationBody}</Typography>
                    </Box>
                }
                secondary={
                    <Typography
                        variant="caption"
                        sx={{
                            mt: 0.5,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
                        {moment(notification?.createdDate).format('YYYY-MM-DD HH:mm:ss')}
                    </Typography>
                }
            />
        </ListItemButton>
    );
}
