import { useState } from 'react';

// material-ui
import { Autocomplete, Button, Chip, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';

// project import
import AnimateButton from 'components/main_components/components/@extended/AnimateButton';

// Component
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import UserAPI from 'api/UserAPI';
import SelectCompany from 'pages/user-setting/components/SelectComponent';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouterConstants } from 'utils/constants/RouterConstants';
import { PermissionsDefinition } from 'utils/StringDefinition';

const AuthRegister = (props) => {
    const { changeMode } = props;
    const { t } = useTranslation();
    const handleSignUpSuccess = () => {
        if (changeMode) changeMode(RouterConstants.SINGUPSUCCESS);
    };

    const listPermissions = useSelector((state) => state.usersManagement.listPermissions);

    const listCompanies = useSelector((state) => state.usersManagement.listCompanies);

    const [showPassword, setShowPassword] = useState(false);
    const [showComfirmPassword, setShowComfirmPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmitForm = async (values) => {
        const valueSubmit = {
            ...values,
            companyId: listCompanies[values.companyIndex].companyId,
            birthday: new Date(),
        };

        const response = await UserAPI.CreateNewUser(valueSubmit);
        // try {
        //     setStatus({ success: false });
        //     setSubmitting(false);
        //     handleSignUpSuccess();
        // } catch (err) {
        //     console.error(err);
        //     setStatus({ success: false });
        //     setErrors({ submit: err.message });
        //     setSubmitting(false);
        // }
    };

    const handleClickShowComfirmPassword = () => {
        setShowComfirmPassword(!showComfirmPassword);
    };

    const handleMouseEvents = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Formik
                initialValues={{
                    firstname: '',
                    lastname: '',
                    email: '',
                    companyIndex: 0,
                    phoneNumber: '',
                    password: '',
                    permissions: [listPermissions[0]],
                    confirmPassword: '',
                    submit: null,
                }}
                validationSchema={Yup.object().shape({
                    firstname: Yup.string()
                        .max(255)
                        .required(<Trans i18nKey="signup.firstNameRequired" />),
                    lastname: Yup.string()
                        .max(255)
                        .required(<Trans i18nKey="signup.lastNameRequired" />),
                    email: Yup.string()
                        .email('Must be a valid email')
                        .max(255)
                        .required(<Trans i18nKey="signup.emailRequired" />),
                    password: Yup.string()
                        .max(255)
                        .required(<Trans i18nKey="signup.passwordRequired" />),
                    confirmPassword: Yup.string()
                        .oneOf([Yup.ref('password'), null], 'Your password and comfirmation password must match')
                        .required(<Trans i18nKey="signup.confirmPasswordRequired" />),
                    permissions: Yup.array().required().min(1, 'Permissions must have at least 1 items'),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    handleSubmitForm(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="firstname-signup">
                                        <Trans i18nKey="signup.firstName" />
                                    </InputLabel>
                                    <OutlinedInput
                                        id="firstname-login"
                                        type="firstname"
                                        value={values.firstname}
                                        name="firstname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t('signup.firstName')}
                                        fullWidth
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        error={Boolean(touched.firstname && errors.firstname)}
                                    />
                                    {touched.firstname && errors.firstname && (
                                        <FormHelperText error id="helper-text-firstname-signup">
                                            {errors.firstname}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="lastname-signup">
                                        <Trans i18nKey="signup.lastName" />
                                    </InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        fullWidth
                                        error={Boolean(touched.lastname && errors.lastname)}
                                        id="lastname-signup"
                                        type="lastname"
                                        value={values.lastname}
                                        name="lastname"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t('signup.lastName')}
                                    />
                                    {touched.lastname && errors.lastname && (
                                        <FormHelperText error id="helper-text-lastname-signup">
                                            {errors.lastname}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-signup">
                                        <Trans i18nKey="signup.emailAdress" />
                                    </InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        fullWidth
                                        error={Boolean(touched.email && errors.email)}
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder={t('signup.emailAdress')}
                                        inputProps={{ autoComplete: 'new-email' }}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="helper-text-email-signup">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-signup">
                                        <Trans i18nKey="signup.password" />
                                    </InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        fullWidth
                                        error={Boolean(touched.password && errors.password)}
                                        id="password-signup"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        autoComplete="on"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseEvents}
                                                    onMouseUp={handleMouseEvents}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="******"
                                        inputProps={{ autoComplete: 'new-password' }}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="helper-text-password-signup">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="confirmPassword-signup">
                                        <Trans i18nKey="signup.confirmPassword" />
                                    </InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        fullWidth
                                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                        id="confirmPassword-signup"
                                        type={showComfirmPassword ? 'text' : 'password'}
                                        value={values.confirmPassword}
                                        name="confirmPassword"
                                        onBlur={handleBlur}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        autoComplete="on"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowComfirmPassword}
                                                    onMouseDown={handleMouseEvents}
                                                    onMouseUp={handleMouseEvents}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showComfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="******"
                                    />
                                    {touched.confirmPassword && errors.confirmPassword && (
                                        <FormHelperText error id="helper-text-comfirmpassword-signup">
                                            {errors.confirmPassword}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            {/* Permissions */}

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="job-signup">
                                        <Trans i18nKey="signup.role" />
                                    </InputLabel>

                                    <Autocomplete
                                        multiple
                                        options={listPermissions}
                                        getOptionLabel={(option) => option.permissionName}
                                        defaultValue={[listPermissions[0]]}
                                        filterSelectedOptions
                                        onChange={(event, newValue) => {
                                            setFieldValue('permissions', newValue);
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        sx={{
                                                            borderRadius: 1,
                                                            backgroundColor:
                                                                option.permissionName === PermissionsDefinition.ADMIN
                                                                    ? '#2e7d32'
                                                                    : option.permissionName === PermissionsDefinition.REVIEWER
                                                                    ? '#ed6c02'
                                                                    : '#1976d2',
                                                            color: 'white',
                                                            '& > .MuiChip-deleteIcon': {
                                                                color: 'white',
                                                                '&:hover': {
                                                                    color: '#e1e1e1',
                                                                },
                                                            },
                                                        }}
                                                        variant="outlined"
                                                        label={option ? option.permissionName : ''}
                                                        {...getTagProps({ index })}
                                                    />
                                                );
                                            })
                                        }
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                    {touched.permissions && errors.permissions && <FormHelperText error>{errors.permissions}</FormHelperText>}
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="company-signup">
                                        <Trans i18nKey="signup.company" />
                                    </InputLabel>

                                    <SelectCompany
                                        name="companyIndex"
                                        listCompanies={listCompanies}
                                        options={listCompanies.map((company) => company.companyName).sort()}
                                        placeholder="Job Title"
                                    ></SelectCompany>
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="phoneNumber">
                                        <Trans i18nKey="signup.phoneNumber" />
                                    </InputLabel>
                                    <OutlinedInput
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        fullWidth
                                        error={Boolean(touched.company && errors.company)}
                                        id="phoneNumber"
                                        value={values.phoneNumber}
                                        name="phoneNumber"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder=""
                                    />
                                </Stack>
                            </Grid>

                            <Grid item xs={12}>
                                <AnimateButton>
                                    <Button disableElevation disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained" color="primary">
                                        <Trans i18nKey="signup.btnCreate" />
                                    </Button>
                                </AnimateButton>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthRegister;
