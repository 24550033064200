/* global Autodesk, THREE */

import axios from 'axios';

var viewer;

function launchViewer(div, urn, accessToken) {
    // console.log(urn, accessToken);
    try {
        var options = {
            env: 'AutodeskProduction',
            api: 'derivativeV2', //region
            accessToken: accessToken,
            language: 'ja',
        };

        Autodesk.Viewing.Initializer(options, function () {
            var htmlDiv = document.getElementById(div);
            viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
            var startedCode = viewer.start();
            if (startedCode > 0) {
                console.error('Failed to create a Viewer: WebGL not supported.');
                return;
            }

            console.log('Initialization complete, loading a model next...');
        });

        var documentId = urn;
        Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);

        viewer?.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, onGeometryLoaded);

        function onDocumentLoadSuccess(viewerDocument) {
            var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            const viewables = viewerDocument.getRoot().search(
                {
                    type: 'geometry',
                    role: '3d',
                },
                true,
            );
            viewer.loadDocumentNode(viewerDocument, viewables[0] ?? defaultModel);
            // console.log(viewer);

            // const viewables = viewerDocument.getRoot().search({ type: 'geometry', role: '3d' });

            // var defaultModel;
            // if (viewables.length != 0) {
            //     defaultModel = viewables[0];
            // } else {
            //     defaultModel = viewerDocument.getRoot().getDefaultGeometry();
            // }

            // load viewable vào view.
            viewer.loadDocumentNode(viewerDocument, viewables[0] ?? defaultModel, {
                keepCurrentModels: true,
                applyScaling: 'mm',
            });

            viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
                viewer.fitToView();
            });
            window.addEventListener('resize', function (event) {
                viewer.resize();
            });

            viewer.loadExtension('Autodesk.DocumentBrowser');
        }

        function onDocumentLoadFailure() {
            console.error('Failed fetching Forge manifest');
        }
    } catch (err) {
        console.error('Failed fetching Forge token', err);
    }
}

function onGeometryLoaded(event) {
    var viewer = event.target;

    viewer.removeEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, onGeometryLoaded);
    viewer.fitToView();
    viewer.loadExtension('Autodesk.DocumentBrowser');
}

export function getSelection(DoorFinish) {
    const dbId = viewer.getSelection()[0];

    if (viewer.getSelectionCount() !== 1) {
        alert('Select Door Element');
    } else if (viewer.getSelectionCount() > 0) {
        viewer.model.getProperties(dbId, (item) => {
            var data = {
                _id: `${item.externalId}`,
                DoorFinish: DoorFinish,
            };

            axios
                .patch('*your backend url/api/doors/update', data)
                .then((response) => {
                    alert('DoorFinish Value Updated');
                    window.location.reload();
                })
                .catch((err) => {
                    console.log(err);
                });
        });
    }
}

export default launchViewer;
