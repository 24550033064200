import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
// ==============================|| File Row ||============================== //

const ToolRow = (props) => {
    const { toolName, description, versionId, createdDate, modifiedDate } = props.fileInfo;

    return (
        <TableRow hover>
            <TableCell align="left" width="30%">
                {toolName}
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                {description}
            </TableCell>

            <TableCell align="center" width="10%">
                {versionId}
            </TableCell>

            <TableCell align="center" width="10%">
                {moment(createdDate).format('YYYY-MM-DD')}
            </TableCell>

            <TableCell align="center" width="10%">
                {moment(modifiedDate).format('YYYY-MM-DD')}
            </TableCell>
        </TableRow>
    );
};

export default ToolRow;
