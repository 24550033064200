import { VpnKey } from '@mui/icons-material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PersonIcon from '@mui/icons-material/Person';
import RestoreIcon from '@mui/icons-material/Restore';
import { IconButton, TableCell, TableRow, Tooltip } from '@mui/material';
import LabelCustom from 'pages/menu/components/ListFilesTableRow/LabelCustom';
import { Trans, useTranslation } from 'react-i18next';

// ==============================|| UserRow ||============================== //

const UserRow = (props) => {
    let { firstName, lastName, email, phoneNumber, jobTitle, company, permission, action, onChangeUserStatus, userStatus } = props.userInfo;

    const { t } = useTranslation();

    return (
        <TableRow hover>
            <TableCell align="left" width="10%">
                {firstName}
            </TableCell>

            <TableCell align="left" width="10%">
                {lastName}
            </TableCell>

            <TableCell align="left" width="15%">
                {email}
            </TableCell>

            <TableCell align="center" width="10%">
                {phoneNumber}
            </TableCell>

            {/* <TableCell align="left" width="10%">
                {jobTitle}
            </TableCell> */}

            <TableCell align="center" width="10%">
                {company?.companyName}
            </TableCell>

            <TableCell align="center" width="10%">
                {t(`permissions.${permission}`)}
            </TableCell>

            <TableCell align="center" width="8%">
                <LabelCustom color={userStatus.statusName === 'Active' ? 'success' : 'warning'} sx={{ textTransform: 'capitalize', width: { sm: '90%', md: '100%', lg: '100%', xl: '70%' } }}>
                    {t(`userTable.${userStatus.statusName}`)}
                </LabelCustom>
            </TableCell>

            <TableCell align="center" width="8%">
                <IconButton disabled={userStatus.statusName === 'Inactive' ? true : false} onClick={() => props.onShowUser(props.userInfo)}>
                    <Tooltip title={<Trans i18nKey="userTable.editUser" />} placement="bottom">
                        <PersonIcon style={{ marginRight: '10%', cursor: 'pointer' }} />
                    </Tooltip>
                </IconButton>

                <IconButton disabled={userStatus.statusName === 'Inactive' ? true : false} onClick={() => props.onOpenChangePassword(props.userInfo)}>
                    <Tooltip title={<Trans i18nKey="userTable.changePassword" />} placement="bottom">
                        <VpnKey style={{ marginRight: '10%', cursor: 'pointer' }} />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => props.onChangeUserStatus(props.userInfo)}>
                    {userStatus.statusName === 'Active' ? (
                        <Tooltip title={<Trans i18nKey="userTable.deactiveUser" />} placement="bottom">
                            <ClearOutlinedIcon style={{ marginRight: '10%', cursor: 'pointer' }} />
                        </Tooltip>
                    ) : (
                        <Tooltip title={<Trans i18nKey="userTable.activeUser" />} placement="bottom">
                            <RestoreIcon style={{ marginRight: '10%', cursor: 'pointer' }} />
                        </Tooltip>
                    )}
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default UserRow;
