import SheetForgeAPI from 'api/SheetForgeAPI';
import { useEffect, useState } from 'react';
import launchViewer from './ViewerFunctions';

function ViewerForge({ sheetId }) {
    const viewerDiv = 'viewerDiv';
    // let urnLink = 'dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6aG96ZW4tZGVtby5jZjY3YTJjNi05M2JlLTQ0NjEtYTY1Mi05MTg4OGIxNWFmM2EvcmVzdWx0LmR3Zw';
    // // const documentId = ;
    // const accessToken =
    //     'eyJhbGciOiJSUzI1NiIsImtpZCI6IjY0RE9XMnJoOE9tbjNpdk1NU0xlNGQ2VHEwUSIsInBpLmF0bSI6Ijd6M2gifQ.eyJzY29wZSI6WyJ2aWV3YWJsZXM6cmVhZCJdLCJjbGllbnRfaWQiOiJLTWR2Q21maHNxeDZDYjVTcnh4blhyNFNhUEFBZE5zZyIsImF1ZCI6Imh0dHBzOi8vYXV0b2Rlc2suY29tL2F1ZC9hand0ZXhwNjAiLCJqdGkiOiJOZm9NaDRzYzI2SWJhRzhBS1JaSWl0RU9ucVJ2dkpXeThSTXJMZ0tGN0ZIVFJMejdCbG1WZ0M2R1dpQkZ2aFE5IiwiZXhwIjoxNjk2OTk2ODYxfQ.WQBaQR05eoTtjy0qvExQ-9hG9wI5PHJ9jPSrseGeNqyU2vKuYD2piBfWWHJIzQWFISkGVo7pqobBNs-hw__dIFXG_7N8pu-v1lSa41aRbKyfdRMQfw4YfwkMnAPHmHg5-8eKWGb71qFUVY95P-SsLlBNcXdHjS6_gYBoZiT4NbC7vU70VtPefOAXfNlFS2CRmcGOLbOHe5sORrzQS5mfe0SqMw9FhTdbICZJkbSfDL4y811GubNRlYa37ClZ6Zhib-qRcLAuMzrpYon_l8UTEwfPruke0e04xwwFEZIF_yNeAIlzb97FCkSeUSby8LJsSN6SpkUCio3w1uWi52M5Eg';

    // const TIME_REFRESH = 55;
    // const sheetId = '64f6f0251d361e710fbd8ae5';

    // const [forgeAccessToken, setForgeAccessToken] = useState(null);
    const [forgeInfo, setForgeInfo] = useState({
        forgeAccessToken: null,
        documentId: '',
    });

    const getURNModel = async () => {
        try {
            const response = await SheetForgeAPI.getSheetForgeById(sheetId);
            console.log('🐳 ~ response:', response?.data);

            if (response.status === 200) {
                setForgeInfo({
                    forgeAccessToken: response.data?.token,
                    documentId: `urn:${response.data?.urn}`,
                });
            }

            // setForgeInfo({
            //     forgeAccessToken: accessToken,
            //     documentId: `urn:${urnLink}`,
            // });

            // const response2 = await ForgeAPI.downloadModel3D(sheetId);
            // const outputFilename = `Test.rvt`;

            // fileDownload(response2.data, outputFilename);
            // const response3 = await ForgeAPI.saveFile(response2.data);

            // console.log(response2.data);
        } catch (error) {
            console.error('getURNModel: ', error);
        }
    };

    useEffect(() => {
        getURNModel();
        launchViewer(viewerDiv, forgeInfo?.documentId, forgeInfo?.forgeAccessToken);

        // call getForgeAccessToken every 59 minutes
        // const intervalId = setInterval(() => {
        //     getForgeAccessToken();
        // }, TIME_REFRESH * 60 * 1000);

        // return () => {
        //     clearInterval(intervalId);
        // };
    }, [forgeInfo?.documentId, forgeInfo?.forgeAccessToken]);

    return <div className="relative flex flex-col w-full h-full overflow-auto" id={viewerDiv} />;
}

export default ViewerForge;
