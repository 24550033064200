import { EyeInvisibleOutlined, EyeOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, Link, OutlinedInput, Stack, Typography } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import TokenServices from 'api/token/TokenServices';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeScreenHeight } from 'store/reducers/calculatationSlice';
import { login } from 'store/reducers/userSlice';
import { RouterConstants } from 'utils/constants/RouterConstants';
import * as Yup from 'yup';

// ============================|| LOGIN ||============================ //

const AuthLogin = (props) => {
    const { changeMode } = props;
    const [rememberMeChecked, setRememberMehecked] = React.useState(true);
    const [isFailed, setIsFailed] = React.useState(false);
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseEvents = (event) => {
        event.preventDefault();
    };

    const handleChangeForgot = () => {
        if (changeMode) changeMode(RouterConstants.RESET);
    };

    const [screenSize, setScreenSize] = React.useState({ width: 0, height: 0 });

    React.useEffect(() => {
        function handleResize() {
            setScreenSize({ width: window.screen.width, height: window.screen.height });
            const action = changeScreenHeight(window.screen.height);
            dispatch(action);
        }

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Get initial screen size
        handleResize();

        // Remove event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Formik
                initialValues={{
                    email: '',
                    password: '',
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email('有効なメールアドレスを入力してください。').max(255).required('必須'),
                    password: Yup.string().max(255).required('必須'),
                })}
                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                        var userInfo = {
                            userName: values?.email.toLowerCase(),
                            password: values?.password,
                        };

                        const actions = await login(userInfo);
                        const resultAction = await dispatch(actions);
                        const userUnwrap = unwrapResult(resultAction);
                        if (userUnwrap?.id) {
                            // setStatus({ success: true });
                            // setSubmitting(false);

                            changeMode(RouterConstants.MENU);
                            TokenServices.setLocalStorageRemember(rememberMeChecked);
                        } else {
                            setIsFailed(true);
                            // setStatus({ success: false });
                            // setSubmitting(false);
                        }
                    } catch (err) {
                        console.error('Error when login: ', err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                    }
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login" sx={{ color: 'black' }}>
                                        メールアドレス
                                    </InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="メールアドレス"
                                        fullWidth
                                        error={touched.email && !!errors.email}
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <MailOutlined />
                                            </InputAdornment>
                                        }
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="password-login" sx={{ color: 'black' }}>
                                        パスワード
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        error={touched.password && !!errors.password}
                                        id="-password-login"
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <LockOutlined />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseEvents}
                                                    onMouseUp={handleMouseEvents}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        placeholder="パスワード"
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error id="standard-weight-helper-text-password-login">
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                    {/* <PasswordField></PasswordField> */}
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sx={{ mt: -1 }}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={rememberMeChecked} onChange={(event) => setRememberMehecked(event.target.checked)} name="checked" color="primary" size="small" />}
                                        label={<Typography variant="h6">ログイン情報を保持する</Typography>}
                                    />
                                </Stack>
                            </Grid>

                            {isFailed && (
                                <Grid item xs={12} alignItems="left" justifyContent="left">
                                    <Stack direction="row" justifyContent="left" alignItems="left" sx={{ mb: { xs: -0.1, sm: 0.1 } }}>
                                        <Typography variant="body1" sx={{ textDecoration: 'none' }} color="red" align="left">
                                            メールまたはパスワードが正しくありません。
                                        </Typography>
                                    </Stack>
                                </Grid>
                            )}

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Button disableElevation disabled={isSubmitting} size="large" type="submit" variant="contained" sx={{ backgroundColor: '##02457a', width: '65%' }}>
                                    ログイン
                                </Button>
                            </Grid>

                            <Grid item xs={12} alignItems="center" justifyContent="center">
                                <Stack direction="row" justifyContent="center" alignItems="left" sx={{ mb: { xs: -0.1, sm: 0.1 } }}>
                                    <Link variant="h6" onClick={handleChangeForgot} color="text.primary" sx={{ cursor: 'pointer' }}>
                                        パスワードをお忘れの方
                                    </Link>
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthLogin;
