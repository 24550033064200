import ClearIcon from '@mui/icons-material/Clear';
import RestoreIcon from '@mui/icons-material/Restore';
import { IconButton, TableCell, TableRow } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { SheetStatus } from 'utils/StringDefinition';
import LabelCustom from './LabelCustom';
// ==============================|| File Row ||============================== //

const SheetDeletedRow = (props) => {
    const { id, sheetName, tool, editor, createdDate, reviewer, avaSrc, modifiedDate, fileSize, status } = props.fileInfo;
    const { t } = useTranslation();
    return (
        <TableRow hover>
            <TableCell align="left" width="20%">
                {sheetName}
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }} width="18%">
                {`${tool?.toolName} ${tool.versionId}`}
            </TableCell>

            <TableCell align="center" width="15%">
                {`${editor?.firstName} ${editor?.lastName}`}
            </TableCell>

            <TableCell align="center" width="15%">
                {`${reviewer?.firstName} ${reviewer?.lastName}`}
            </TableCell>

            <TableCell align="center" width="8%">
                {moment(createdDate).format('YYYY-MM-DD')}
            </TableCell>

            <TableCell align="center" width="8%">
                {moment(modifiedDate).format('YYYY-MM-DD')}
            </TableCell>

            <TableCell align="center" width="8%">
                <LabelCustom
                    color={
                        (status === SheetStatus.COMPLETED && 'success') ||
                        (status === SheetStatus.EDITING && 'warning') ||
                        (status === SheetStatus.CHECKED && 'warning') ||
                        (status === SheetStatus.REVIEWING && 'primary') ||
                        (status === null && 'primary')
                    }
                    sx={{ textTransform: 'capitalize', width: '100%' }}
                >
                    {t(`calculationTable.${status}`)}
                </LabelCustom>
            </TableCell>

            <TableCell align="center" width="12%">
                <IconButton onClick={() => props.onRestoreSheet(props.fileInfo)}>
                    <Tooltip title={<Trans i18nKey="calculationTable.restore" />} placement="bottom">
                        <RestoreIcon style={{ marginRight: '10%', cursor: 'pointer' }} />
                    </Tooltip>
                </IconButton>

                <IconButton onClick={() => props.onDeleteSheet(props.fileInfo)}>
                    <Tooltip title={<Trans i18nKey="calculationTable.delete" />} placement="bottom">
                        <ClearIcon style={{ marginRight: '10%', cursor: 'pointer', color: 'red' }} />
                    </Tooltip>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default SheetDeletedRow;
