import { useCallback, useContext, useEffect, useState } from 'react';

// material-ui
import { Table, TableBody } from '@mui/material';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// component
import useTable, { getComparator } from 'custom_hook/useTable';
import { MainController } from 'pages/MainServices';

import { Box, Stack } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import { CustomToastComponent } from 'components/popup_components/CustomToastComponent';
import { ToastShowError, ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { getNotificationsByUserId } from 'store/reducers/NotificationSlice';
import { changeSection } from 'store/reducers/calculatationSlice';
import { getListSheets, getListTools } from 'store/reducers/menuSlice';
import { getSheetById } from 'store/reducers/sheetSlice';
import { getCompany, getEditors, getReviewers } from 'store/reducers/userManagementSlice';
import { RouterConstants } from 'utils/constants/RouterConstants';
import ListFilesFunction from './components/ListFilesFunction/ListFilesFunction';
import TableHeadCustom from './components/ListFilesTableHeader/TableHeadCustom';
import SheetRow from './components/ListFilesTableRow/SheetRow';
import { applySortFilter, searchFilesFnc } from './ultils/FileUltis';

// ==============================|| Menu List Files ||============================== //

const View3D = () => {
    const { openDialogNewSheet, setOpenDialogNewSheet, setSelectedRow, sheetMode, setSheetMode, setMainMode } = useContext(MainController);
    const user = useSelector((state) => state.user.current);
    let navigate = useNavigate();
    const { t } = useTranslation();

    const currentHeight = useSelector((state) => state.calculatationSection.screenHeight);
    // console.log(currentHeight);

    let MAX_FILE_QUANTITY;

    switch (true) {
        case currentHeight <= 768:
            MAX_FILE_QUANTITY = 5;
            break;
        case currentHeight < 969:
            MAX_FILE_QUANTITY = 6;
            break;
        case currentHeight >= 969:
            MAX_FILE_QUANTITY = 9;
            break;
        default:
            MAX_FILE_QUANTITY = 9;
            break;
    }

    const { dense, order, orderBy, selected, onSort } = useTable();

    const TABLE_HEAD = [
        { id: 'sheetName', label: 'sheetName', align: 'center', width: '50px', minWidth: '50px' },
        { id: 'toolName', label: 'Tool', align: 'center', width: 2.5 },
        { id: 'editorName', label: 'Editor', align: 'center', width: 1.5 },
        { id: 'checkerName', label: 'Checker', align: 'center', width: 1.5 },
        { id: 'createdDate', label: 'Created Date', align: 'center', width: 1.5 },
        { id: 'status', label: 'Status', align: 'center', width: 1.5 },
        { id: 'action', label: 'action', align: 'center', width: 1.5, paddingLeft: '20px' },
        { id: 'navigate', label: 'navigate', align: 'center', width: '90px', minWidth: '90px', paddingLeft: '20px' },
    ];
    const dispatch = useDispatch();

    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getListSheets());
        dispatch(getReviewers());
        dispatch(getEditors());
        dispatch(getListTools());
        dispatch(getCompany());
        dispatch(getNotificationsByUserId());
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const listFilesInit = useSelector((state) => state.menu.listSheets);

    const [listFiles, setListFiles] = useState(listFilesInit || []);

    const [searchKeywords, setSearchKeywords] = useState('');
    const [searchStatus, setSearchStatus] = useState('All');

    const [pageOfFiles, setPageOfFiles] = useState({
        currentPage: 1,
        totalPages: Math.ceil(listFiles?.length / MAX_FILE_QUANTITY) * 10,
        minIndex: 0,
        maxIndex: MAX_FILE_QUANTITY,
    });
    // const [selectedRow, setSelectedRow] = useState({});

    useEffect(() => {
        setListFiles(listFilesInit);
        setPageOfFiles({ ...pageOfFiles, totalPages: Math.ceil(listFilesInit.length / MAX_FILE_QUANTITY) * 10 });
    }, [listFilesInit]);

    //When search box typing
    const handleSearchInput = (searchValue, searchStatus) => {
        // var searchValue = e.target.value;

        setSearchKeywords(searchValue);
        let listFilesResult = searchFilesFnc(listFilesInit, searchValue, searchStatus);
        // console.log(listFilesResult, searchValue, searchStatus);

        setListFiles(listFilesResult);
        setPageOfFiles({
            ...pageOfFiles,
            currentPage: 1,
            totalPages: Math.ceil(listFilesResult?.length / MAX_FILE_QUANTITY) * 10 || 10,
            minIndex: 0,
            maxIndex: MAX_FILE_QUANTITY,
        });
        // if (searchValue !== '') {
        //     setListFiles(listFilesResult);
        //     setPageOfFiles({
        //         ...pageOfFiles,
        //         currentPage: 1,
        //         totalPages: Math.ceil(listFilesResult?.length / MAX_FILE_QUANTITY) * 10 || 10,
        //         minIndex: 0,
        //         maxIndex: MAX_FILE_QUANTITY,
        //     });
        // } else if (searchValue === '') {
        //     setListFiles(listFilesResult);
        //     setPageOfFiles({
        //         currentPage: 1,
        //         totalPages: Math.ceil(listFilesInit?.length / MAX_FILE_QUANTITY) * 10,
        //         minIndex: 0,
        //         maxIndex: MAX_FILE_QUANTITY,
        //     });
        // }
    };

    //When change page of Saved File View

    const handleChangePageFiles = (current) => {
        setPageOfFiles({
            ...pageOfFiles,
            currentPage: current,
            minIndex: (current - 1) * MAX_FILE_QUANTITY,
            maxIndex: current * MAX_FILE_QUANTITY,
        });
    };

    const dataFiltered = applySortFilter({
        listFiles,
        comparator: getComparator(order, orderBy),
    });

    const handleEditFileInfo = async (fileInfo) => {
        setSelectedRow(fileInfo);
        setSheetMode(2);
        setOpenDialogNewSheet(true);
    };

    const handleCopyToNewFile = async (fileInfo) => {
        setSelectedRow(fileInfo);
        setSheetMode(3);
        setOpenDialogNewSheet(true);
    };

    const handleOpenSheet = async (fileInfo) => {
        setSelectedRow(fileInfo);
        setMainMode(2);
        await dispatch(getSheetById(fileInfo?.id));
        await dispatch(changeSection(1));
        navigate(`/${RouterConstants.SHEET}`, { state: { fileInfoInit: fileInfo } });
    };

    // const handleDialogClose = () => {
    //     setOpenDialogNewSheet(false);
    // };

    // const handleSubmitSheetDialog = async (values, resetForm) => {
    //     const valueSubmit = {
    //         id: values?.id ?? '',
    //         sheetName: values?.sheetName ?? '',
    //         tool: values?.tool ?? '',
    //         reviewer: { id: values?.reviewer.id ?? values?.reviewer },
    //         editor: { id: values?.editor.id ?? values?.editor },
    //         description: values?.description ?? '',
    //     };

    //     // console.log(values, valueSubmit);
    //     //Update sheet
    //     if (sheetMode === 2) {
    //         const response = await SheetAPI.updateSheet(valueSubmit);
    //         if (response.status === 200) {
    //             if (response.data.errorMessage === 'Sheet name had exist!') {
    //                 ToastShowError(t('toastNoti.existed'));
    //             } else {
    //                 setOpenDialogNewSheet(false);
    //                 dispatch(getListSheets());
    //                 ToastShowSuccess(t('toastNoti.updated'));
    //             }
    //         }
    //     } else if (sheetMode === 1 || sheetMode === 3) {
    //         const response = await SheetAPI.createSheet(valueSubmit);
    //         if (response.status === 200) {
    //             if (response.data.errorMessage === 'Sheet name had exist!') {
    //                 ToastShowError(t('toastNoti.existed'));
    //             } else {
    //                 setOpenDialogNewSheet(false);
    //                 dispatch(getListSheets());
    //                 resetForm();
    //                 ToastShowSuccess(t('toastNoti.created'));
    //             }
    //         }
    //     }
    // };

    const handleDeleteSheet = async (values) => {
        const sheetId = values.id;
        //Delete sheet
        const response = await SheetAPI.ToggleSheet(sheetId);
        if (response.status === 200) {
            dispatch(getListSheets());
            ToastShowSuccess(t('toastNoti.moveToTrash'));
        } else {
            ToastShowError(t('toastNoti.error'));
        }
    };

    const [downloadingSheet, setDownloadingSheet] = useState({
        sheetId: '',
        downloading: false,
    });
    const handleDownloadSheet = async (fileInfo) => {
        setDownloadingSheet({
            sheetId: fileInfo.id,
            downloading: true,
        });
        const response = await SheetAPI.downloadSheet(fileInfo?.id);

        const outputFilename = `${fileInfo?.sheetName}.xlsx`;

        fileDownload(response.data, outputFilename);
        setDownloadingSheet({
            sheetId: fileInfo.id,
            downloading: false,
        });
    };

    // console.log('dataFiltered ', dataFiltered);
    //Export list saved file
    const listFilesView = dataFiltered?.map((file, index) => {
        if (index >= pageOfFiles?.minIndex && index < pageOfFiles?.maxIndex) {
            return (
                <SheetRow
                    key={index}
                    currentUser={user}
                    fileInfo={file}
                    downloadingSheet={downloadingSheet}
                    onEditFile={() => handleEditFileInfo(file)}
                    onCopyToNewFile={() => handleCopyToNewFile(file)}
                    onDownloadSheet={() => handleDownloadSheet(file)}
                    onOpenSheet={() => handleOpenSheet(file)}
                    onDeleteSheet={() => handleDeleteSheet(file)}
                />
            );
        }
    });

    return (
        <>
            <Stack>
                <Box
                    sx={{
                        pt: 2,
                        pb: 2,
                    }}
                >
                    {/* SearchBox */}

                    <ListFilesFunction searchKeywords={searchKeywords} searchStatus={searchStatus} setSearchStatus={setSearchStatus} handleSearchInput={handleSearchInput} />
                </Box>

                {/* Main View Tool Manager */}

                <Stack
                    sx={{
                        m: 2,
                        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                        borderRadius: '5px',
                        width: '100wh',
                        height: '82vh',
                    }}
                >
                    <Box sx={{ width: '100%', height: '100%' }}>
                        <Table size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} rowCount={listFilesInit.length} numSelected={selected.length} onSort={onSort} />

                            <TableBody height="60vh">{listFilesView}</TableBody>
                        </Table>
                    </Box>

                    <Box component="div" sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                        {listFiles?.length > 0 ? (
                            <Pagination defaultCurrent={1} current={pageOfFiles?.currentPage} total={pageOfFiles?.totalPages} showSizeChanger={false} onChange={handleChangePageFiles} />
                        ) : null}
                    </Box>
                </Stack>

                {/* <SheetDialog open={openDialogNewSheet} onClose={handleDialogClose} initValues={selectedRow} onSubmitSheetDialog={handleSubmitSheetDialog} sheetMode={sheetMode} /> */}
                <CustomToastComponent />
            </Stack>
        </>
    );
};

export default View3D;
