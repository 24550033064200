// project import
import { Stack } from '@mui/material';
import { MainController } from 'pages/MainServices';
import { useContext } from 'react';
import AccountCard from './AccountCard';
import LogoCard from './LogoCard';
import NavManagement from './Navigation';
import Nav3DView from './Navigation/Nav3DView';
import NavCalculate from './Navigation/NavCalculate';
import SheetInfoCard from './Navigation/SheetInfoCard';

// ----------------------------------------------------------------------

// ==============================|| HZMainSideBar ||============================== //

const HZMainSideBar = () => {
    const { mainMode } = useContext(MainController);

    return (
        <>
            <Stack
                spacing={3}
                sx={{
                    pt: 3,
                    pb: 2,
                    px: 2.5,
                    flexShrink: 0,
                    height: window.innerHeight,
                }}
                direction="column"
            >
                <LogoCard />
                <div className="hidden 2xl:block">{mainMode !== 3 ? <AccountCard /> : <SheetInfoCard />}</div>

                {mainMode === 1 ? <NavManagement /> : mainMode === 2 ? <NavCalculate /> : <Nav3DView />}
            </Stack>
        </>
    );
};

export default HZMainSideBar;
