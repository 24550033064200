import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import CommentAPI from 'api/CommentAPI';

export const getCommentsBySheetId = createAsyncThunk('comments/getCommentsBySheetId', async (payload) => {
    try {
        const res = await CommentAPI.getCommentBySheetId(payload);
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getCommentsBySheetId!!!');
        return error.message;
    }
});

const commentsSlice = createSlice({
    name: 'comments',
    initialState: {
        comments: [],
        status: 'idle',
        error: null,
    },
    reducers: [],
    extraReducers: {
        [getCommentsBySheetId.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getCommentsBySheetId.fulfilled]: (state, action) => {
            state.comments = action.payload;
            state.status = 'succeeded';
        },
        [getCommentsBySheetId.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { reducer } = commentsSlice;

export default reducer;
