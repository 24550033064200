import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import RowTextResult from '../share-components/RowTextResult';
import RowTextThreeInput from '../share-components/RowThreeInput';
import SectionTitle, { InstructRow, SectionTitleSub } from '../share-components/Tilte';
import image from './2-1.png';
import Table0201 from './Table0201';

const Block0201 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version100?.[`block006`];

    const AF59AO59 = blockData?._AF59AO59;
    const aF60AO60 = blockData?.aF60AO60;
    const aF61AO61 = blockData?.aF61AO61;
    const AF62AO62 = blockData?._AF62AO62;
    const AF63AO63 = blockData?._AF63AO63;
    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                {/* <SectionTitle title="チェーン取付部の計算" /> */}
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="block0201" height="375" />

                        <Typography className="absolute w-full flex top-[0.1rem] left-[0.7rem] text-sm text-center justify-center">
                            {isNaN(blockData?._U73) ? 'd' : `d = ${parseFloat(blockData?._U73)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[12rem] left-[-12rem]  text-sm text-center -rotate-90">
                            {isNaN(blockData?._U72) ? 'φ' : `φ = ${parseFloat(blockData?._U72)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[12rem] left-[-14.5rem]  text-sm text-center -rotate-90">
                            {isNaN(blockData?._U69) ? 'd1' : `d1 = ${parseFloat(blockData?._U69)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[15rem] left-[-3.5rem] text-sm text-center">
                            {isNaN(blockData?._U69) ? 'r1' : `r1 = ${(parseFloat(blockData?._U69) / 2).toFixed(2)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center m-auto top-[14.5rem] left-[5rem] text-sm text-center rotate-45">
                            {isNaN(blockData?._U73) ? 'r' : `r = ${parseFloat(blockData?._U73) / 2}`}
                        </Typography>
                    </article>
                    <Table0201 AF59AO59={AF59AO59} aF60AO60={aF60AO60} aF61AO61={aF61AO61} AF62AO62={AF62AO62} AF63AO63={AF63AO63} />
                </div>

                <InstructRow />

                <RowTextThreeInput
                    blockNumber="block006"
                    name="q69"
                    initValue={{ looking: blockData?._M69, input: blockData?.q69, calculate: blockData?._U69 }}
                    description="孔径"
                    symbol={{ normalText: 'd1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block006"
                    name="q70"
                    initValue={{ looking: blockData?._M70, input: blockData?.q70, calculate: blockData?._U70 }}
                    description="トッププレートの板厚"
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block006"
                    name="q71"
                    description="補強板の板厚"
                    initValue={{ looking: blockData?._M71, input: blockData?.q71, calculate: blockData?._U71 }}
                    symbol={{ normalText: "t'", mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block006"
                    name="q72"
                    description="シャックルのピン径"
                    initValue={{ looking: blockData?._M72, input: blockData?.q72, calculate: blockData?._U72 }}
                    tooltipText="d1 = φ + 4"
                    symbol={{ normalText: 'φ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block006"
                    name="q73"
                    initValue={{ looking: blockData?._M73, input: blockData?.q73, calculate: blockData?._U73 }}
                    description="取付部全体径"
                    symbol={{ normalText: 'd', mathSymbol: '=' }}
                    tooltipText="r/r1は4以下にする"
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitle title="緩衝チェーン部の設計" />
                </Box>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1. 移動余裕量の検討" />
                </Box>

                <RowTextComponent
                    description="1) 移動可能量"
                    physicSymbol="ΔL"
                    isEqualTo={true}
                    unit={UNIT.mm}
                    valueCalculate={blockData?._AP67}
                    mathSymbol={blockData?._AV67}
                    valueStandard={blockData?._AW67}
                    status={blockData?._BC67}
                ></RowTextComponent>

                <RowTextResult paddingLeftFit="58%" description={`0.75 × 桁かかり長`} />
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2. チェーン取付部の設計" />
                </Box>

                <RowTextComponent
                    description="1) ａ－ａ断面の曲げ引張応力度"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP70}
                    mathSymbol={blockData?._AV70}
                    valueStandard={blockData?._AW70}
                    status={blockData?._BC70}
                ></RowTextComponent>

                <RowTextComponent
                    description="2) ｂ－ｂ断面の曲げ引張応力度"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP71}
                    mathSymbol={blockData?._AV71}
                    valueStandard={blockData?._AW71}
                    status={blockData?._BC71}
                ></RowTextComponent>

                <RowTextComponent
                    description="3) ａ－ａ断面のせん断応力度"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP72}
                    mathSymbol={blockData?._AV72}
                    valueStandard={blockData?._AW72}
                    status={blockData?._BC72}
                ></RowTextComponent>

                <RowTextComponent
                    description="4) ａ－ａ断面 合成応力度"
                    physicSymbol=""
                    valueCalculate={blockData?._AP73}
                    mathSymbol={blockData?._AV73}
                    valueStandard={blockData?._AW73}
                    status={blockData?._BC73}
                ></RowTextComponent>

                <RowTextComponent
                    description="5) s点の支圧応力度"
                    physicSymbol="σb"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP74}
                    mathSymbol={blockData?._AV74}
                    valueStandard={blockData?._AW74}
                    status={blockData?._BC74}
                ></RowTextComponent>

                <RowTextComponent
                    description="6) 補強リンクの溶接"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP75}
                    mathSymbol={blockData?._AV75}
                    valueStandard={blockData?._AW75}
                    status={blockData?._BC75}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0201;
