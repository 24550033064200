import Block0601 from './Block0601';
import Block0602 from './Block0602';

const Section06 = (props) => {
    return (
        <>
            <Block0601 />
            <Block0602 />
        </>
    );
};

export default Section06;
