import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

const Block0403 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block004`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="各照査事項" />
                <SectionTitleSub title="① ベースプレート照査" />
                <RowTextComponent description="1) ベースプレートの必要厚さ"></RowTextComponent>
                <RowTextComponent
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 't', supText: 'req' }}
                    valueLeft={blockData?._G192?.value}
                    tooltipLeft={blockData?._G192?.toolTip}
                    unit={UNIT.mm}
                    mathSymbol={blockData?._I192?.value}
                    physicSymbolRight={{ normalText: 't' }}
                    valueRight={blockData?._K192?.value}
                    tooltipRight={blockData?._K192?.toolTip}
                    status={blockData?._S192?.value}
                ></RowTextComponent>

                <SectionTitleSub title="② ブラケット本体照査" />
                <RowTextComponent description="1) 面直角方向 (橋軸)"></RowTextComponent>
                <RowTextComponent
                    isEqualTo={true}
                    paddingLeftFit={3}
                    description="・曲げ応力度"
                    physicSymbolLeft={{ normalText: 'σ1' }}
                    valueLeft={blockData?._G195?.value}
                    tooltipLeft={blockData?._G195?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I195?.value}
                    physicSymbolRight={{ normalText: 'σca' }}
                    valueRight={blockData?._K195?.value}
                    tooltipRight={blockData?._K195?.toolTip}
                    status={blockData?._S195?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σ2' }}
                    valueLeft={blockData?._G196?.value}
                    tooltipLeft={blockData?._G196?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I196?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K196?.value}
                    tooltipRight={blockData?._K196?.toolTip}
                    status={blockData?._S196?.value}
                ></RowTextComponent>

                <RowTextComponent description="2) 面方向 (橋軸直角)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description="・曲げ応力度"
                    physicSymbolLeft={{ normalText: 'σ1' }}
                    valueLeft={blockData?._G198?.value}
                    tooltipLeft={blockData?._G198?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I198?.value}
                    physicSymbolRight={{ normalText: 'σca' }}
                    valueRight={blockData?._K198?.value}
                    tooltipRight={blockData?._K198?.toolTip}
                    status={blockData?._S198?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σ2' }}
                    valueLeft={blockData?._G199?.value}
                    tooltipLeft={blockData?._G199?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I199?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K199?.value}
                    tooltipRight={blockData?._K199?.toolTip}
                    status={blockData?._S199?.value}
                ></RowTextComponent>

                <SectionTitleSub title="③ 上フランジプレート照査" />
                <RowTextComponent description="1) 面直角方向 (橋軸)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・曲げ応力度"
                    physicSymbolLeft={{ normalText: 'σ1' }}
                    valueLeft={blockData?._G202?.value}
                    tooltipLeft={blockData?._G202?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I202?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K202?.value}
                    tooltipRight={blockData?._K202?.toolTip}
                    status={blockData?._S202?.value}
                ></RowTextComponent>

                <RowTextComponent description="2) 面方向 (橋軸直角)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・曲げ応力度"
                    physicSymbolLeft={{ normalText: 'σ2' }}
                    valueLeft={blockData?._G204?.value}
                    tooltipLeft={blockData?._G204?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I204?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K204?.value}
                    tooltipRight={blockData?._K204?.toolTip}
                    status={blockData?._S204?.value}
                ></RowTextComponent>

                <RowTextComponent description="3) 面直角方向 (橋軸) と面方向 (橋軸直角) の同時性"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・曲げ応力度"
                    physicSymbolLeft={{ normalText: 'σ3' }}
                    valueLeft={blockData?._G206?.value}
                    tooltipLeft={blockData?._G206?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I206?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K206?.value}
                    tooltipRight={blockData?._K206?.toolTip}
                    status={blockData?._S206?.value}
                ></RowTextComponent>

                <RowTextComponent description="4) ボルト縁端距離照査"></RowTextComponent>
                <RowTextComponent
                    valueLeft={blockData?._G208?.value}
                    tooltipLeft={blockData?._G208?.toolTip}
                    mathSymbol={blockData?._I208?.value}
                    valueRight={blockData?._K208?.value}
                    tooltipRight={blockData?._K208?.toolTip}
                    status={blockData?._S208?.value}
                    unit={UNIT.hidden}
                ></RowTextComponent>

                <SectionTitleSub title="④ 溶接部照査" />
                <RowTextComponent description="1) ベースプレートとリブプレートの溶接部"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・せん断応力度"
                    physicSymbolLeft={{ normalText: 'τ' }}
                    valueLeft={blockData?._G211?.value}
                    tooltipLeft={blockData?._G211?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I211?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K211?.value}
                    tooltipRight={blockData?._K211?.toolTip}
                    status={blockData?._S211?.value}
                ></RowTextComponent>

                <RowTextComponent description="2) 上フランジプレートとリブプレートの溶接部"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・せん断応力度"
                    physicSymbolLeft={{ normalText: 'τ' }}
                    valueLeft={blockData?._G213?.value}
                    tooltipLeft={blockData?._G213?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I213?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K213?.value}
                    tooltipRight={blockData?._K213?.toolTip}
                    status={blockData?._S213?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'τ1' }}
                    valueLeft={blockData?._G214?.value}
                    tooltipLeft={blockData?._G214?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I214?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K214?.value}
                    tooltipRight={blockData?._K214?.toolTip}
                    status={blockData?._S214?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'τ2' }}
                    valueLeft={blockData?._G215?.value}
                    tooltipLeft={blockData?._G215?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I215?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K215?.value}
                    tooltipRight={blockData?._K215?.toolTip}
                    status={blockData?._S215?.value}
                ></RowTextComponent>

                <SectionTitleSub title="⑤ コンクリート破壊照査" />
                <RowTextComponent description="1) 面直角方向 (橋軸)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・コーン破壊耐力"
                    physicSymbolLeft={{ normalText: 'Tcd' }}
                    valueLeft={blockData?._G218?.value}
                    tooltipLeft={blockData?._G218?.toolTip}
                    unit={UNIT.N}
                    mathSymbol={blockData?._I218?.value}
                    physicSymbolRight={{ normalText: 'Pt' }}
                    valueRight={blockData?._K218?.value}
                    tooltipRight={blockData?._K218?.toolTip}
                    status={blockData?._S218?.value}
                ></RowTextComponent>

                <RowTextComponent description="2) 面方向 (橋軸直角)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    isEqualTo={true}
                    description=" ・コーン破壊耐力"
                    physicSymbolLeft={{ normalText: 'Tcd' }}
                    valueLeft={blockData?._G220?.value}
                    tooltipLeft={blockData?._G220?.toolTip}
                    unit={UNIT.N}
                    mathSymbol={blockData?._I220?.value}
                    physicSymbolRight={{ normalText: 'Pt' }}
                    valueRight={blockData?._K220?.value}
                    tooltipRight={blockData?._K220?.toolTip}
                    status={blockData?._S220?.value}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0403;
