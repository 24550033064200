export function applySortFilter({ listFiles, comparator }) {
    if (listFiles.length > 0) {
        const stabilizedThis = listFiles?.map((el, index) => [el, index]);

        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });

        listFiles = stabilizedThis.map((el) => el[0]);

        return listFiles;
    } else return [];
}

export const searchFilesFnc = (listValue, keyword, searchStatus) => {
    const searchKey = keyword.toLowerCase();
    return listValue.filter((sheet) => {
        const sheetName = sheet.sheetName.toLowerCase();

        const reviewerName = `${sheet.reviewer?.firstName} ${sheet.reviewer?.lastName}`.toLowerCase();
        const editorName = `${sheet.editor?.firstName} ${sheet.editor?.lastName}`.toLowerCase();

        const toolName = `${sheet.tool?.toolName} ${sheet.tool?.versionId}`.toLowerCase();

        const createdDate = sheet.createdDate?.toLowerCase();

        const containsSearchKey = [sheetName, reviewerName, editorName, toolName, createdDate].some((field) => field.includes(searchKey));

        // console.log(searchKey);

        if (containsSearchKey && (searchStatus === 'All' || searchStatus === sheet.status || (searchStatus === 'Editing' && (sheet.status === 'Editing' || sheet.status === 'Checked')))) {
            return true;
        }

        return false;
    });
};

export const searchToolsFnc = (listTools, keyword) => {
    const searchKey = keyword.toLowerCase();
    return listTools.filter((tool) => {
        const toolName = tool.toolName.toLowerCase();
        const description = tool.description.toLowerCase();

        if (toolName.includes(searchKey) || description.includes(searchKey)) {
            return true;
        }
        return false;
    });
};
