import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';

import RowTextComponent, { RowTextSpecial } from '../share-components/RowText';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

import image62 from './6-2.png';

const Block0602 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block010`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="下部工ブラケット断面照査" />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1. トッププレートの断面照査" />
                </Box>
                <RowTextComponent
                    description="1)	曲げ応力度"
                    physicSymbol="σt"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL235?.value}
                    mathSymbol={blockData?._AR235?.value}
                    valueStandard={blockData?._AS235?.value}
                    resultTooltip={blockData?._AL235?.toolTip}
                    standardTooltip={blockData?._AS235?.toolTip}
                    status={blockData?._BC235?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description=""
                    physicSymbol="σc"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL236?.value}
                    mathSymbol={blockData?._AR236?.value}
                    valueStandard={blockData?._AS236?.value}
                    resultTooltip={blockData?._AL236?.toolTip}
                    standardTooltip={blockData?._AR236?.toolTip}
                    status={blockData?._BC236?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="2)	せん断応力度"
                    physicSymbol="τs"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL237?.value}
                    mathSymbol={blockData?._AR237?.value}
                    valueStandard={blockData?._AS237?.value}
                    resultTooltip={blockData?._AL237?.toolTip}
                    standardTooltip={blockData?._AS237?.toolTip}
                    status={blockData?._BC237?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="3) 合成応力度"
                    unit={UNIT.none}
                    valueCalculate={blockData?._AL238?.value}
                    mathSymbol={blockData?._AR238?.value}
                    valueStandard={blockData?._AS238?.value}
                    resultTooltip={blockData?._AL238?.toolTip}
                    standardTooltip={blockData?._AS238?.toolTip}
                    status={blockData?._BC237?.value}
                ></RowTextComponent>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2. アンカーボルトの設計" />
                </Box>

                <RowTextComponent
                    description="1) 曲げモーメントによる応力度照査"
                    physicSymbol="σt"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL242?.value}
                    mathSymbol={blockData?._AR242?.value}
                    valueStandard={blockData?._AS242?.value}
                    resultTooltip={blockData?._AL242?.toolTip}
                    standardTooltip={blockData?._AS242?.toolTip}
                    status={blockData?._BC242?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="2) せん断力によるせん断応力度照査"
                    physicSymbol="τｓ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL244?.value}
                    mathSymbol={blockData?._AR244?.value}
                    valueStandard={blockData?._AS244?.value}
                    resultTooltip={blockData?._AL244?.toolTip}
                    standardTooltip={blockData?._AS244?.toolTip}
                    status={blockData?._BC244?.value}
                ></RowTextComponent>

                <RowTextComponent description="3) 合成応力度の照査"></RowTextComponent>

                <RowTextSpecial
                    description={{ description1: '(σ/σa) ', description2: ' + (τ/τa)' }}
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL246?.value}
                    mathSymbol={blockData?._AR246?.value}
                    valueStandard={blockData?._AS246?.value}
                    resultTooltip={blockData?._AL246?.toolTip}
                    standardTooltip={blockData?._AS246?.toolTip}
                    status={blockData?._BC246?.value}
                ></RowTextSpecial>

                <RowTextComponent
                    description="5) 付着力の照査"
                    physicSymbol="Tbd"
                    unit={UNIT.N}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL249?.value}
                    mathSymbol={blockData?._AR249?.value}
                    valueStandard={blockData?._AS249?.value}
                    resultTooltip={blockData?._AL249?.toolTip}
                    standardTooltip={blockData?._AS249?.toolTip}
                    status={blockData?._BC249?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="6) 軸引張力に対するコーン破壊の照査"
                    physicSymbol="Tcd"
                    unit={UNIT.N}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL251?.value}
                    mathSymbol={blockData?._AR251?.value}
                    valueStandard={blockData?._AS251?.value}
                    resultTooltip={blockData?._AL251?.toolTip}
                    standardTooltip={blockData?._AS251?.toolTip}
                    status={blockData?._BC251?.value}
                ></RowTextComponent>

                <div className="flex justify-center"></div>

                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image62} alt="background" height="375px" />
                        <Typography className="absolute w-full flex justify-center top-[3rem] left-[1rem] text-sm text-center">橋座面</Typography>
                        <Typography className="absolute w-full flex justify-center top-[15.5rem] left-[3.5rem] text-sm text-center">アンカーボルト</Typography>
                    </article>
                </div>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="3. ベースプレート断面照査" />
                </Box>

                <RowTextComponent description="1)	リブ－トッププレート断面検討"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL255?.value}
                    mathSymbol={blockData?._AR255?.value}
                    valueStandard={blockData?._AS255?.value}
                    resultTooltip={blockData?._AL255?.toolTip}
                    standardTooltip={blockData?._AS255?.toolTip}
                    status={blockData?._BC255?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL257?.value}
                    mathSymbol={blockData?._AR257?.value}
                    valueStandard={blockData?._AS257?.value}
                    resultTooltip={blockData?._AL257?.toolTip}
                    standardTooltip={blockData?._AS257?.toolTip}
                    status={blockData?._BC257?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL259?.value}
                    mathSymbol={blockData?._AR259?.value}
                    valueStandard={blockData?._AS259?.value}
                    resultTooltip={blockData?._AL259?.toolTip}
                    standardTooltip={blockData?._AS259?.toolTip}
                    status={blockData?._BC259?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・曲げせん断応力度"
                    isEqualTo={true}
                    physicSymbol="τb"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL260?.value}
                    mathSymbol={blockData?._AR260?.value}
                    valueStandard={blockData?._AS260?.value}
                    resultTooltip={blockData?._AL260?.toolTip}
                    standardTooltip={blockData?._AS260?.toolTip}
                    status={blockData?._BC260?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・せん断の応力度"
                    physicSymbol="τs"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL261?.value}
                    mathSymbol={blockData?._AR261?.value}
                    valueStandard={blockData?._AS261?.value}
                    resultTooltip={blockData?._AL261?.toolTip}
                    standardTooltip={blockData?._AS261?.toolTip}
                    status={blockData?._BC261?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL262?.value}
                    mathSymbol={blockData?._AR262?.value}
                    valueStandard={blockData?._AS262?.value}
                    resultTooltip={blockData?._AL262?.toolTip}
                    standardTooltip={blockData?._AS262?.toolTip}
                    status={blockData?._BC262?.value}
                ></RowTextComponent>

                <RowTextComponent description="2)	リブ－ベースプレート断面検討"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL266?.value}
                    mathSymbol={blockData?._AR266?.value}
                    valueStandard={blockData?._AS266?.value}
                    resultTooltip={blockData?._AL266?.toolTip}
                    standardTooltip={blockData?._AS266?.toolTip}
                    status={blockData?._BC266?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL268?.value}
                    mathSymbol={blockData?._AR268?.value}
                    valueStandard={blockData?._AS268?.value}
                    resultTooltip={blockData?._AL268?.toolTip}
                    standardTooltip={blockData?._AS268?.toolTip}
                    status={blockData?._BC268?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL270?.value}
                    mathSymbol={blockData?._AR270?.value}
                    valueStandard={blockData?._AS270?.value}
                    resultTooltip={blockData?._AL270?.toolTip}
                    standardTooltip={blockData?._AS270?.toolTip}
                    status={blockData?._BC270?.value}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0602;
