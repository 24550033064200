// @mui
import { Button, Dialog, DialogActions, DialogTitle, Stack, Typography } from '@mui/material';
import { Trans } from 'react-i18next';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import Checkbox from '@mui/material/Checkbox';
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';

// ----------------------------------------------------------------------
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
export default function DownloadDialog({ open, onClose, initValues, onSubmitSheetDialog }) {
    const [checked, setChecked] = useState([false, false, false, false]);

    const handleChange1 = (event) => {
        setChecked([event.target.checked, event.target.checked, event.target.checked, event.target.checked]);
    };

    const handleChange2 = (event) => {
        setChecked([event.target.checked, checked[1], checked[2], checked[3]]);
    };

    const handleChange3 = (event) => {
        setChecked([checked[0], event.target.checked, checked[2], checked[3]]);
    };

    const handleChange4 = (event) => {
        setChecked([checked[0], checked[1], event.target.checked, checked[3]]);
    };

    const handleChange5 = (event) => {
        setChecked([checked[0], checked[1], checked[2], event.target.checked]);
    };

    const handleDownload = (event) => {
        console.log('🐳 ~ checked:', checked);
    };

    const children = (
        <>
            <FormControlLabel
                // label="Child Element 1"
                control={
                    <Box component="div" sx={{ display: 'inline', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>2D</Typography>
                        <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>■REVIT: サンプル 3D - File 5.rvt</Typography>
                            <Checkbox {...label} checked={checked[0]} onChange={handleChange2} />
                        </Box>
                    </Box>
                }
            />
            <FormControlLabel
                // label="Child Element 2"
                control={
                    <Box component="div" sx={{ display: 'inline', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>3D</Typography>
                        <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>■CAD: サンプル 3D - File 5.dwg</Typography>
                            <Checkbox {...label} checked={checked[1]} onChange={handleChange3} />
                        </Box>
                    </Box>
                }
            />

            <FormControlLabel
                // label="Child Element 2"
                control={
                    <Box component="div" sx={{ display: 'inline', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>3D</Typography>
                        <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>■CAD: サンプル 3D - File 5.dwg</Typography>
                            <Checkbox {...label} checked={checked[2]} onChange={handleChange4} />
                        </Box>
                    </Box>
                }
            />

            <FormControlLabel
                // label="Child Element 2"
                control={
                    <Box component="div" sx={{ display: 'inline', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontWeight: 'bold' }}>CSV</Typography>
                        <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography>■CSV: サンプル 3D - File 5.csv</Typography>
                            <Checkbox {...label} checked={checked[3]} onChange={handleChange5} />
                        </Box>
                    </Box>
                }
            />
        </>
    );

    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogTitle align="center">ダウンロード</DialogTitle>
            <Stack spacing={0.5} sx={{ p: 3, pb: 0 }}>
                <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                    {/* <Checkbox {...label} /> */}
                    <FormControlLabel
                        sx={{ marginRight: 0 }}
                        control={
                            <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                <Checkbox
                                    checked={checked[0] && checked[1] && checked[2] && checked[3]}
                                    indeterminate={checked[0] !== checked[1] || checked[1] !== checked[2] || checked[2] !== checked[3]}
                                    onChange={handleChange1}
                                />
                            </Box>
                        }
                    />
                </Box>

                {children}

                {/* <Box component="div" sx={{ display: 'inline', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Typography>2D</Typography>
                    <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography>■CAD: サンプル 3D - File 5.dwg</Typography>
                        <Checkbox {...label} />
                    </Box>
                </Box>

                <Box component="div" sx={{ display: 'inline', width: '100%', alignItems: 'flex-start', justifyContent: 'space-between' }}>
                    <Typography>CSV</Typography>
                    <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography>■CAD: サンプル 3D - File 5.csv</Typography>
                        <Checkbox {...label} />
                    </Box>
                </Box> */}
            </Stack>

            <DialogActions style={{ padding: '12px 24px 12px 24px', marginTop: '24px', backgroundColor: '#f2f2f4', width: '100%' }}>
                <Button variant="outlined" onClick={onClose}>
                    <Trans i18nKey="sheetDialog.btnCancel" />
                </Button>

                <Button variant="contained" onClick={handleDownload}>
                    ダウンロード
                </Button>
            </DialogActions>
        </Dialog>
    );
}
