import { Card, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowSelectComponent from '../share-components/RowSelect';
import { RowInputAndInput } from '../share-components/RowTwoInput';
import SectionTitle from '../share-components/Tilte';

const Block0502 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block005`];

    const listF250 = [
        { id: 1, value: 45 },
        { id: 2, value: 60 },
    ];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="溶接部に関する寸法" />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 10 }}>
                    <Typography>・開先溶接</Typography>
                </Box>

                <RowInputAndInput
                    paddingLeftDes={3}
                    description="開先深さ (手入力・自動計算)"
                    blockNumber="block005"
                    name="f249"
                    symbol={{ normalText: 'D', mathSymbol: '=' }}
                    valueLeft={blockData?.f249?.value}
                    tooltipLeft={blockData?.f249?.toolTip}
                    valueRight={blockData?._G249?.value}
                    tooltipRight={blockData?._G249?.toolTip}
                    unit={UNIT.mm}
                />

                <RowSelectComponent
                    paddingLeftDes={3}
                    blockNumber="block005"
                    symbol={{ normalText: 'α1', mathSymbol: '=' }}
                    name="f250"
                    description="開先角度 (部分開先溶接)"
                    initValue={blockData?.f250?.value}
                    listValue={listF250}
                    unit={UNIT.do}
                />
                <RowSelectComponent
                    paddingLeftDes={3}
                    blockNumber="block005"
                    symbol={{ normalText: 'α2', mathSymbol: '=' }}
                    name="f251"
                    description="開先角度 (両側開先溶接)"
                    initValue={blockData?.f251?.value}
                    listValue={listF250}
                    unit={UNIT.do}
                />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 10 }}>
                    <Typography>・すみ肉溶接</Typography>
                </Box>

                <RowInputAndInput
                    paddingLeftDes={3}
                    description="すみ肉溶接の脚長 (フランジ部) (手入力・自動計算)"
                    blockNumber="block005"
                    name="f255"
                    symbol={{ normalText: 'S1', mathSymbol: '=' }}
                    valueLeft={blockData?.f255?.value}
                    tooltipLeft={blockData?.f255?.toolTip}
                    valueRight={blockData?._G255?.value}
                    tooltipRight={blockData?._G255?.toolTip}
                    unit={UNIT.mm}
                />

                <RowInputAndInput
                    paddingLeftDes={3}
                    description="すみ肉溶接の脚長 (ウェブ部) (手入力・自動計算)"
                    blockNumber="block005"
                    name="f256"
                    symbol={{ normalText: 'S2', mathSymbol: '=' }}
                    valueLeft={blockData?.f256?.value}
                    tooltipLeft={blockData?.f256?.toolTip}
                    valueRight={blockData?._G256?.value}
                    tooltipRight={blockData?._G256?.toolTip}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0502;
