import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { CommentType } from 'utils/StringDefinition';
import CommentContent from './CommentContent';

const CommentTimeline = (props) => {
    const lastIndex = props?.listComments.length - 1;

    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 1,
                pb: 1,
            }}
        >
            <Timeline
                sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                        flex: 0.2,
                    },
                    margin: '0px',
                    p: '0px',
                }}
            >
                {props?.listComments.map((comment, index) => {
                    return (
                        <TimelineItem key={comment.id}>
                            <TimelineOppositeContent sx={{ pl: '0px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }} color="textSecondary">
                                <Box sx={{ color: 'black' }}> {moment(comment?.createdDate).format('MM/DD') || ''}</Box>
                                <Box sx={{ color: 'black' }}> {moment(comment?.createdDate).format('HH:mm') || ''}</Box>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot
                                    color={
                                        comment?.commentType === CommentType.COMMENT
                                            ? 'warning'
                                            : comment?.commentType === CommentType.REQUEST
                                            ? 'primary'
                                            : comment?.commentType === CommentType.MARK_COMPLETED
                                            ? 'success'
                                            : 'warning'
                                    }
                                />
                                {index !== lastIndex && <TimelineConnector />}
                            </TimelineSeparator>

                            <TimelineContent sx={{ pl: 1, pr: 0, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography>{comment?.commentTitle}</Typography>
                                {comment?.commentBody && <CommentContent comment={comment} onUpdateComment={props?.onUpdateComment}></CommentContent>}
                            </TimelineContent>
                        </TimelineItem>
                    );
                })}
            </Timeline>
        </Box>
    );
};

export default CommentTimeline;
