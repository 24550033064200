import PropTypes from 'prop-types';
// @mui
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { ListSubheader, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/system';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { CompanyDefinition, SheetStatus } from 'utils/StringDefinition';
import SheetForgeAPI from '../../../../../api/SheetForgeAPI';
import ButtonView3D from './ButtonView3D';
import ParamView from './ParamView';
// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

Nav3DView.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array,
};
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function Nav3DView({ isCollapse = false, ...other }) {
    const sheetForgeInfo = useSelector((state) => state.sheetForge.sheetForgeActive);
    const isCompleted = sheetForgeInfo?.status === SheetStatus.COMPLETED ? true : false;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.current);

    //fetch data
    const location = useLocation();
    const fileInfoInit = location?.state?.fileInfoInit;

    const sheetId = fileInfoInit?.id ?? sheetForgeInfo?.id;
    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getListSectionsOfSheet(sheetId));
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const isNKEStaff = user.company?.companyName === CompanyDefinition.NKE;
    const theme = useTheme();

    const handleFileUpload = async (event) => {
        const file = event.target.files[0]; // Get the first selected file

        if (file) {
            // Do something with the file, for example, you can read its content
            // const reader = new FileReader();

            // reader.onload = (e) => {
            //     const content = e.target.result;
            //     // Here, you can work with the file content (e.g., parse CSV data)
            //     // console.log(content);
            // };

            // reader.readAsText(file);
            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            // console.log(formData, sheetId);
            const res = await SheetForgeAPI.getUploadJSONForge(formData, sheetId);
        }
    };
    return (
        <div className="h-[80%] w-full flex flex-col content-between">
            <Stack
                component="div"
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        paddingBottom: 1.5,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                        インポート JSON
                        <VisuallyHiddenInput type="file" accept=".json" onChange={handleFileUpload} />
                    </Button>
                </Box>
                <Box
                    sx={{
                        paddingBottom: 1.5,
                    }}
                >
                    <Typography variant="h5" color="initial">
                        Components
                    </Typography>
                </Box>

                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Stack elevation={0.5} spacing={1.5} sx={{ bgcolor: '#f0efeb', p: 2, border: '1px solid', borderRadius: 2, borderColor: theme.palette.grey.A800 }}>
                        <ParamView />
                    </Stack>
                </Box>
            </Stack>
            <Box
                sx={{
                    flex: '0 1 50px',
                    width: '100%',
                }}
            >
                <div style={{ p: 2, bgcolor: '#fafafa', marginTop: '15px', marginBottom: '5px' }}>
                    <ButtonView3D isCompleted={isCompleted} isNKEStaff={isNKEStaff} />
                </div>
            </Box>
        </div>
    );
}
