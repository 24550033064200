import { Box, Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

const Block0202 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block006`];

    let rDivR1 = parseFloat(blockData?._U73?.value) / parseFloat(blockData?._U69?.value);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="緩衝チェーン部の設計" />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1. 移動余裕量の検討" />
                </Box>

                <RowTextComponent
                    description="1) 移動可能量"
                    physicSymbol="ΔL"
                    isEqualTo={true}
                    unit={UNIT.mm}
                    valueCalculate={blockData?._AP67?.value}
                    resultTooltip={blockData?._AP67?.toolTip}
                    standardTooltip={blockData?._AW67?.toolTip}
                    mathSymbol={blockData?._AV67?.value}
                    valueStandard={blockData?._AW67?.value}
                    status={blockData?._BC67?.value}
                ></RowTextComponent>

                <RowTextResult paddingLeftFit="58%" description={`0.75 × 桁かかり長`} />
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2. チェーン取付部の設計" />
                </Box>

                <RowTextComponent
                    description="1) ａ－ａ断面の曲げ引張応力度"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP70?.value}
                    mathSymbol={blockData?._AV70?.value}
                    valueStandard={blockData?._AW70?.value}
                    resultTooltip={blockData?._AP70?.toolTip}
                    standardTooltip={blockData?._AW70?.toolTip}
                    status={blockData?._BC70?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="2) ｂ－ｂ断面の曲げ引張応力度"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP71?.value}
                    mathSymbol={blockData?._AV71?.value}
                    valueStandard={blockData?._AW71?.value}
                    resultTooltip={blockData?._AP71?.toolTip}
                    standardTooltip={blockData?._AW71?.toolTip}
                    status={blockData?._BC71?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="3) ａ－ａ断面のせん断応力度"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP72?.value}
                    mathSymbol={blockData?._AV72?.value}
                    valueStandard={blockData?._AW72?.value}
                    resultTooltip={blockData?._AP72?.toolTip}
                    standardTooltip={blockData?._AW72?.toolTip}
                    status={blockData?._BC72?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="4) ａ－ａ断面 合成応力度"
                    physicSymbol=""
                    valueCalculate={blockData?._AP73?.value}
                    mathSymbol={blockData?._AV73?.value}
                    valueStandard={blockData?._AW73?.value}
                    resultTooltip={blockData?._AP73?.toolTip}
                    standardTooltip={blockData?._AW73?.toolTip}
                    status={blockData?._BC73?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="5) s点の支圧応力度"
                    physicSymbol="σb"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP74?.value}
                    mathSymbol={blockData?._AV74?.value}
                    valueStandard={blockData?._AW74?.value}
                    resultTooltip={blockData?._AP74?.toolTip}
                    standardTooltip={blockData?._AW72?.toolTip}
                    status={blockData?._BC74?.value}
                ></RowTextComponent>

                <RowTextComponent description="6) 補強リンクの溶接"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    descriptionWidth="38.5%"
                    resultInRowWidth="41.5%"
                    physicSymbolWidth="14%"
                    description="最小すみ肉サイズ"
                    isEqualTo={true}
                    physicSymbol="√(2 × t1)"
                    mathSymbol={blockData?._AC76?.value}
                    unit={UNIT.mm}
                    valueCalculate={blockData?._AC75?.value}
                    status={blockData?._AC78?.value}
                    valueStandard={blockData?._AC77?.value}
                ></RowTextComponent>

                <RowTextComponent
                    description="補強リンクの溶接"
                    physicSymbol="τ"
                    paddingLeftFit={2}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AP75?.value}
                    resultTooltip={blockData?._AP75?.toolTip}
                    mathSymbol={blockData?._AV75?.value}
                    valueStandard={blockData?._AW75?.value}
                    status={blockData?._BC75?.value}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0202;
