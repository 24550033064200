import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';

import RowTextInputComponent from '../share-components/RowInput';
import RowSelectComponent from '../share-components/RowSelect';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';

const bufferChainTypeList = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 8 },
    { id: 8, value: 9 },
    { id: 9, value: 10 },
];

const Block01 = (props) => {
    const { blockData } = props;

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="反力、移動量" />

                <RowTextInputComponent
                    blockNumber="block001"
                    name="o4"
                    description="① １支承線あたりの死荷重反力"
                    initValue={blockData?.o4}
                    symbol={{ normalText: 'R', subText: 'd', mathSymbol: '=' }}
                    unit={UNIT.kN}
                />
                <RowTextInputComponent blockNumber="block001" name="o6" initValue={blockData?.o6} description="② 緩衝チエーン取付箇所数" allowNegativeNumber={false} unit={UNIT.hon} />
                <RowTextResult name="_O8" initValue={blockData?._O8} description="③ １箇所あたりの反力" unit={UNIT.kN} />

                <RowTextResult
                    name="_O10"
                    paddingLeftDes={3}
                    initValue={blockData?._O10}
                    description="作用力"
                    symbol={{ normalText: 'HF', mathSymbol: '=' }}
                    unit={`${UNIT.kN}	/	${blockData?._V10 ?? ''} ${UNIT.basis}`}
                />
                <RowTextResult name="_O11" initValue={blockData?._O11} description="" symbol={{ normalText: 'HR', mathSymbol: '=' }} unit={`${UNIT.kN}	/	${blockData?._V11} ${UNIT.basis}`} />

                <RowSelectComponent blockNumber="block001" name="o13" paddingLeftDes={3} description="緩衝チェーンタイプ" initValue={blockData?.o13} listValue={bufferChainTypeList} unit={UNIT.kata} />
                <RowTextResult
                    description="チェーンの許容荷重"
                    paddingLeftDes={3}
                    initValue={`${blockData?._K14 ?? ''}		${blockData?._N14 ?? ''}			${blockData?._P14 ?? ''}`}
                    status={blockData?._V14}
                    unit={UNIT.kN}
                />

                <RowTextResult blockNumber="block001" name="_O16" initValue={blockData?._O16} description="④ 必要桁かかり長" unit={UNIT.mm} />

                <RowTextInputComponent blockNumber="block001" name="o18" initValue={blockData?.o18} description="⑤ 支承移動量" unit={UNIT.mm} />

                <RowTextResult name="_O20" initValue={blockData?._O20} description="⑥ 移動可能量" unit={UNIT.mm} />
                <RowTextResult paddingLeftFit={10} description={`${blockData?._D21}		mm	＜ΔL＜			${blockData?._J21}		mm`} />

                <RowTextInputComponent blockNumber="block001" name="o23" initValue={blockData?.o23} description="⑦ 斜角" unit={UNIT.do} />

                <RowTextInputComponent blockNumber="block001" name="o25" initValue={blockData?.o25} description="⑧ 支間長" unit={UNIT.m} />

                <RowTextInputComponent blockNumber="block001" name="o27" initValue={blockData?.o27} description="⑨ 支承移動量" unit={UNIT.kN} />
            </Stack>
        </Card>
    );
};

export default Block01;
