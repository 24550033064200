import PropTypes from 'prop-types';
// @mui
import { Box, Typography } from '@mui/material';
import { Trans } from 'react-i18next';

// ----------------------------------------------------------------------

LogoCardLogin.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object,
};

export default function LogoCardLogin({ disabledLink = false, sx }) {
    const logo = (
        <>
            <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                <Box sx={{ width: 40, height: 40, ...sx }}>
                    <svg id="Layer_5" enableBackground="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <g>
                                <g>
                                    <path
                                        d="m16 40c0 1.1.9 2 2 2-.55 0-1.05.22-1.41.59-.37.36-.59.86-.59 1.41 0 1.1.9 2 2 2-.55 0-1.05.22-1.41.59-.37.36-.59.86-.59 1.41 0 1.1.9 2 2 2h2v2c0 1.1-.9 2-2 2h-4c-1.1 0-2-.9-2-2v-9c0-1.26.59-2.44 1.6-3.2l1.48-1.11c.58-.43.92-1.12.92-1.84v-.55c0-.64-.26-1.21-.67-1.63-.37-.36-.85-.6-1.39-.66l.02-.16c.66.1 1.35.15 2.04.15 1.39 0 2.74-.2 4-.58v.58 4h-2c-.55 0-1.05.22-1.41.59-.37.36-.59.86-.59 1.41z"
                                        fill="#969faa"
                                    />
                                </g>
                                <g>
                                    <path
                                        d="m20 33.42c-1.26.38-2.61.58-4 .58-.69 0-1.38-.05-2.04-.15-6.76-.99-11.96-6.81-11.96-13.85 0-7.73 6.27-14 14-14 3.7 0 7.06 1.44 9.57 3.79 2.73 2.55 4.43 6.18 4.43 10.21 0 6.34-4.22 11.7-10 13.42z"
                                        fill="#fcd770"
                                    />
                                </g>
                                <g>
                                    <path d="m16 10c2.66 0 5.07 1.04 6.86 2.73l-.86.94-4 4.33-4-4-4 4-3.83 3.83c-.11-.59-.17-1.2-.17-1.83 0-5.52 4.48-10 10-10z" fill="#69d6f4" />
                                </g>
                                <g>
                                    <path
                                        d="m22.86 12.73-.86.94-4 4.33-4-4-4 4-3.83 3.83c.46 2.51 1.87 4.69 3.83 6.16v.01c1.16.87 2.52 1.5 4 1.8.64.13 1.31.2 2 .2s1.36-.07 2-.2c1.48-.3 2.84-.93 4-1.8v-.01c2.43-1.82 4-4.72 4-7.99 0-2.86-1.21-5.45-3.14-7.27z"
                                        fill="#ff826e"
                                    />
                                </g>
                                <g>
                                    <path d="m58 2h-20c-2.21 0-4 1.79-4 4v32c0 2.21 1.79 4 4 4h24v-36c0-2.21-1.79-4-4-4z" fill="#aab2bd" />
                                </g>
                                <g>
                                    <path d="m58 7v6c0 .55-.45 1-1 1h-18c-.55 0-1-.45-1-1v-6c0-.55.45-1 1-1h18c.55 0 1 .45 1 1z" fill="#69d6f4" />
                                </g>
                                <g>
                                    <path d="m58 35v3l-4-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m58 27v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m58 19v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m50 19v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m42 19v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m42 27v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m42 35v2c0 .55-.45 1-1 1h-2c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1h2c.55 0 1 .45 1 1z" fill="#e6e9ed" />
                                </g>
                                <g>
                                    <path d="m60 62h-16c0-1.28.51-2.51 1.41-3.41l.59-.59h12l.59.59c.9.9 1.41 2.13 1.41 3.41z" fill="#ff826e" />
                                </g>
                                <g>
                                    <path d="m20 58 2 4h-16l2-4z" fill="#ff826e" />
                                </g>
                                <g>
                                    <path
                                        d="m60.49 38.62c.89.22 1.51 1.02 1.51 1.94v1.44 8.9c0 1.36-.46 2.68-1.31 3.74l-2.69 3.36h-12l-2.69-3.36c-.85-1.06-1.31-2.38-1.31-3.74v-4.48c0-.94-.22-1.85-.63-2.69l-.87-1.73-.33-.66c-.11-.22-.17-.47-.17-.72 0-.9.72-1.62 1.62-1.62 1.21 0 2.3.49 3.1 1.28.79.8 1.28 1.89 1.28 3.1v-15.38c0-1.1.9-2 2-2 .55 0 1.05.22 1.41.59.37.36.59.86.59 1.41v8l4 1 4 1z"
                                        fill="#f0d0b4"
                                    />
                                </g>
                                <g>
                                    <path
                                        d="m20 52v6h-12v-1l-3.63-10.9c-.25-.73-.37-1.49-.37-2.26 0-1.2.3-2.36.85-3.39.55-1.02 1.35-1.91 2.34-2.58l5.23-3.48c.38-.26.82-.39 1.28-.39.08 0 .16 0 .24.01.54.06 1.02.3 1.39.66.41.42.67.99.67 1.63v.55c0 .72-.34 1.41-.92 1.84l-1.48 1.11c-1.01.76-1.6 1.94-1.6 3.2v9c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2z"
                                        fill="#f0d0b4"
                                    />
                                </g>
                                <g>
                                    <path d="m22 46c1.1 0 2 .9 2 2 0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h-2-2c-1.1 0-2-.9-2-2 0-.55.22-1.05.59-1.41.36-.37.86-.59 1.41-.59z" fill="#f0d0b4" />
                                </g>
                                <g>
                                    <path d="m22 42c1.1 0 2 .9 2 2 0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h-4c-1.1 0-2-.9-2-2 0-.55.22-1.05.59-1.41.36-.37.86-.59 1.41-.59z" fill="#f0d0b4" />
                                </g>
                                <g>
                                    <path d="m22 38c1.1 0 2 .9 2 2 0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h-4c-1.1 0-2-.9-2-2 0-.55.22-1.05.59-1.41.36-.37.86-.59 1.41-.59h2z" fill="#f0d0b4" />
                                </g>
                                <g>
                                    <path d="m26 34c1.1 0 2 .9 2 2 0 .55-.22 1.05-.59 1.41-.36.37-.86.59-1.41.59h-4-2v-4z" fill="#f0d0b4" />
                                </g>
                            </g>
                            <g>
                                <path d="m31 20c0-3.079-.926-6.038-2.679-8.557l-1.642 1.143c1.519 2.182 2.321 4.746 2.321 7.414 0 7.168-5.832 13-13 13s-13-5.832-13-13 5.832-13 13-13c2.315 0 4.588.616 6.573 1.781l1.013-1.725c-2.292-1.345-4.915-2.056-7.586-2.056-8.271 0-15 6.729-15 15 0 6.518 4.187 12.063 10.006 14.127l-4.368 2.913c-2.278 1.518-3.638 4.059-3.638 6.796 0 .88.141 1.749.419 2.583l3.581 10.743v.602l-2.618 5.236h19.236l-2.618-5.236v-5.764-1h1c1.654 0 3-1.346 3-3 0-.771-.301-1.468-.78-2 .48-.532.78-1.229.78-2s-.301-1.468-.78-2c.48-.532.78-1.229.78-2 0-.352-.072-.686-.184-1h1.184c1.654 0 3-1.346 3-3s-1.346-3-3-3h-2.549c4.503-2.591 7.549-7.442 7.549-13zm-8 24c0 .551-.449 1-1 1h-4c-.551 0-1-.449-1-1s.449-1 1-1h4c.551 0 1 .449 1 1zm-6.289-9.036c.778-.037 1.546-.115 2.289-.266v2.302h-1c-.367 0-.716.076-1.041.197.012-.116.041-.23.041-.348v-.546c0-.478-.106-.929-.289-1.339zm-11.395 10.823c-.21-.629-.316-1.286-.316-1.951 0-2.067 1.027-3.986 2.747-5.133l5.227-3.484c.215-.143.465-.219.723-.219.719 0 1.303.584 1.303 1.303v.546c0 .408-.195.797-.521 1.042l-1.479 1.109c-1.252.939-2 2.435-2 4v9c0 1.654 1.346 3 3 3h4c.352 0 .686-.072 1-.184v2.184h-10v-.162zm2.302 15.213 1-2h10.764l1 2zm11.382-9c0 .551-.449 1-1 1h-4c-.551 0-1-.449-1-1v-9c0-.939.449-1.837 1.2-2.4l.8-.6c0 .771.301 1.468.78 2-.48.532-.78 1.229-.78 2s.301 1.468.78 2c-.48.532-.78 1.229-.78 2 0 1.654 1.346 3 3 3h1zm3-3h-4c-.551 0-1-.449-1-1s.449-1 1-1h4c.551 0 1 .449 1 1s-.449 1-1 1zm1-9c0 .551-.449 1-1 1h-4c-.551 0-1-.449-1-1s.449-1 1-1h2 2c.551 0 1 .449 1 1zm4-4c0 .551-.449 1-1 1h-4-1v-2h5c.551 0 1 .449 1 1z" />
                                <path d="m58 1h-20c-2.757 0-5 2.243-5 5v32c0 2.757 2.243 5 5 5h1.882l.59 1.18c.345.691.528 1.465.528 2.237v4.479c0 1.583.545 3.136 1.534 4.373l2.128 2.66c-1.071 1.094-1.662 2.537-1.662 4.071v1h18v-1c0-1.534-.591-2.977-1.662-4.072l2.128-2.66c.989-1.236 1.534-2.789 1.534-4.372v-8.896-1.438-34.562c0-2.757-2.243-5-5-5zm-23 37v-32c0-1.654 1.346-3 3-3h20c1.654 0 3 1.346 3 3v31.744c-.09-.032-.178-.069-.272-.093l-1.728-.432v-2.219c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v.719l-2-.5v-7.219c0-1.654-1.346-3-3-3s-3 1.346-3 3v11.23c-.669-.546-1.457-.95-2.336-1.124.212-.317.336-.697.336-1.106v-2c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h.576c-.355.447-.576 1.004-.576 1.618 0 .128.026.255.045.382h-1.045c-1.654 0-3-1.346-3-3zm22-1.281-2-.5v-1.219h2zm-18 .281v-2h2l.001 2zm6.131 24c.172-.64.509-1.226.99-1.707l.293-.293h11.172l.293.293c.481.481.818 1.067.99 1.707zm15.869-10.104c0 1.131-.389 2.24-1.096 3.124l-2.384 2.98h-11.04l-2.385-2.981c-.706-.884-1.095-1.993-1.095-3.123v-4.479c0-1.081-.255-2.164-.739-3.131l-1.196-2.391c-.043-.086-.065-.182-.065-.277 0-.341.277-.618.618-.618 1.865 0 3.382 1.517 3.382 3.382v1.618 3h2v-3-1.618-15.382c0-.551.449-1 1-1s1 .449 1 1v6.719 13.281h2v-10.719l2 .5v10.219h2v-9.719l2 .5v9.219h2v-8.719l1.242.311c.446.111.758.51.758.97v1.438z" />
                                <path d="m39 15h18c1.103 0 2-.897 2-2v-6c0-1.103-.897-2-2-2h-18c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h18l.001 6h-18.001z" />
                                <path d="m39 23h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2zm0-4h2l.001 2h-2.001z" />
                                <path d="m49 17h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-2 4v-2h2l.001 2z" />
                                <path d="m57 17h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-2 4v-2h2l.001 2z" />
                                <path d="m39 31h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2zm0-4h2l.001 2h-2.001z" />
                                <path d="m57 25h-2c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2h2c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2zm-2 4v-2h2l.001 2z" />
                                <path d="m25 6h2.716l-9.745 10.557-3.971-3.971-6.978 6.978c.23-4.759 4.163-8.564 8.978-8.564 1.271 0 2.5.26 3.652.771l.813-1.827c-1.41-.626-2.912-.944-4.465-.944-6.065 0-11 4.935-11 11s4.935 11 11 11 11-4.935 11-11c0-1.913-.499-3.796-1.442-5.447l-1.736.992c.771 1.349 1.178 2.89 1.178 4.455 0 2.136-.752 4.099-2 5.644v-11.587l6-6.499v2.442h2v-6h-6zm-14 12.414 2-2v12.061c-.711-.252-1.384-.583-2-.997zm-3.731 3.731 1.731-1.731v5.23c-.816-1.01-1.411-2.2-1.731-3.499zm7.731 6.796v-12.527l2 2v10.527c-.329.037-.662.059-1 .059s-.671-.022-1-.059zm6-1.463c-.616.413-1.289.744-2 .997v-10.085l2-2.167z" />
                            </g>
                        </g>
                    </svg>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="h3"
                        sx={{
                            pl: 2,
                            underline: 'none',
                            textDecoration: 'none !important',
                            color: 'unset',
                            fontSize: {
                                xl: 25,
                                lg: 15,
                                md: 12,
                            },
                        }}
                    >
                        <Trans i18nKey="content.systemName" />
                    </Typography>
                </Box>
            </Box>
        </>
    );

    return (
        // <Link href="#/" underline="none" color="inherit">
        //     {logo}
        // </Link>
        <>{logo}</>
    );
}
