// @mui
import { Button, Dialog, DialogActions, DialogTitle, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import SelectTool from 'components/main_components/components/SelectTool';
import { Formik } from 'formik';
import ButtonSummit from 'pages/authentication/components/ButtonSummit';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import SelectComponent from '../../../../components/main_components/components/SelectComponent';

// ----------------------------------------------------------------------

const FORM_VALIDATION = Yup.object().shape({
    sheetName: Yup.string().max(255).required('必須'),
});

export default function SheetDialog({ open, onClose, initValues, sheetMode, onSubmitSheetDialog }) {
    const listEditorsRedux = useSelector((state) => state.usersManagement.listEditors);
    const listEditors = [...listEditorsRedux].map((person) => {
        return { ...person, fullName: `${person.firstName} ${person.lastName}` };
    });
    const listReviewersRedux = useSelector((state) => state.usersManagement.listReviewers);
    const listReviewers = [...listReviewersRedux].map((person) => {
        return { ...person, fullName: `${person.firstName} ${person.lastName}` };
    });
    const listToolsRedux = useSelector((state) => state.menu.listTools);
    const listTools = [...listToolsRedux].map((tool) => {
        return { ...tool, toolNameVersion: `${tool.toolName}     ${tool.versionId}` };
    });
    // console.log(initValues, sheetMode);

    const NEW_SHEET_INFO = {
        sheetName: '',
        reviewer: { id: listReviewers[0]?.id },
        editor: { id: listEditors[0]?.id },
        tool: { toolCode: listTools[0]?.toolCode, toolName: listTools[0]?.toolName, versionId: listTools[0]?.versionId },
    };

    return (
        <Formik
            enableReinitialize
            initialValues={sheetMode === 1 ? NEW_SHEET_INFO : initValues}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values, { resetForm }) => {
                onSubmitSheetDialog(values, resetForm);
            }}
        >
            {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, props }) => (
                <form noValidate onSubmit={handleSubmit}>
                    {/* //Check error formik */}
                    {/* {console.log('Error Formik :', errors, null, 2)} */}
                    <Dialog open={open} fullWidth maxWidth="xs">
                        <DialogTitle align="center">
                            <Trans i18nKey="sheetDialog.dialogName" />
                        </DialogTitle>
                        <Stack spacing={3} sx={{ p: 3, pb: 0 }}>
                            <Stack alignItems="left" spacing={1}>
                                <Typography>
                                    <Trans i18nKey="sheetDialog.sheetName" />
                                </Typography>
                                <TextField fullWidth name="sheetName" value={values.sheetName} onBlur={handleBlur} onChange={handleChange} />

                                {/* {errors.sheetName && <FormHelperText error>{errors.sheetName}</FormHelperText>} */}
                                {errors.sheetName && touched.sheetName && (
                                    <FormHelperText error id="standard-weight-helper-text-email-login">
                                        {errors.sheetName}
                                    </FormHelperText>
                                )}
                            </Stack>
                            {/* {console.log('values.reviewerId ', values.reviewerId)} */}
                            <Stack alignItems="left" spacing={1}>
                                <Typography>
                                    <Trans i18nKey="sheetDialog.toolName" />
                                </Typography>
                                {Array.isArray(listTools) && (
                                    <SelectTool disable={sheetMode === 1 ? false : true} fieldName="toolNameVersion" name="tool" options={listTools} valueInit={initValues?.tool}></SelectTool>
                                )}
                            </Stack>
                            <Stack alignItems="left" spacing={1}>
                                <Typography>
                                    <Trans i18nKey="sheetDialog.editorName" />
                                </Typography>
                                {Array.isArray(listEditors) && (
                                    <SelectComponent
                                        name="editor"
                                        options={listEditors}
                                        fieldName="fullName"
                                        valueInit={sheetMode === 1 ? listEditors[0]?.id : initValues?.editor?.id}
                                    ></SelectComponent>
                                )}
                            </Stack>
                            <Stack alignItems="left" spacing={1}>
                                <Typography>
                                    <Trans i18nKey="sheetDialog.reviewerName" />
                                </Typography>
                                {Array.isArray(listReviewers) && (
                                    <SelectComponent
                                        name="reviewer"
                                        fieldName="fullName"
                                        options={listReviewers}
                                        valueInit={sheetMode === 1 ? listReviewers[0]?.id : initValues?.reviewer?.id}
                                    ></SelectComponent>
                                )}
                            </Stack>
                            <Stack alignItems="left" spacing={1}>
                                <Typography>
                                    <Trans i18nKey="sheetDialog.description" />
                                </Typography>
                                <TextField fullWidth multiline rows={5} name="description" id="description" value={values.description ?? ' '} onBlur={handleBlur} onChange={handleChange} />
                            </Stack>
                        </Stack>

                        <DialogActions style={{ padding: '12px 24px 12px 24px', marginTop: '24px', backgroundColor: '#f2f2f4', width: '100%' }}>
                            <Button variant="outlined" onClick={onClose}>
                                <Trans i18nKey="sheetDialog.btnCancel" />
                            </Button>

                            <ButtonSummit variant="contained">{sheetMode !== 3 ? <Trans i18nKey="sheetDialog.btnSave" /> : <Trans i18nKey="sheetDialog.btnCopy" />}</ButtonSummit>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    );
}
