import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import RowSelectComponent from '../share-components/RowSelect';
import SectionTitle from '../share-components/Tilte';

const Block03 = () => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block001`];

    const listF32 = [
        { id: 1, value: 'SS400' },
        { id: 2, value: 'SM400' },
        { id: 3, value: 'SMA400W' },
        { id: 4, value: 'SM490' },
        { id: 5, value: 'SM490Y' },
        { id: 6, value: 'SM520' },
        { id: 7, value: 'SMA490W' },
        { id: 8, value: 'SM570' },
        { id: 9, value: 'SM570W' },
    ];

    const listF38 = [
        { id: 1, value: 'F8T' },
        { id: 2, value: 'F10T' },
        { id: 3, value: 'S10T' },
        { id: 4, value: 'S14T' },
    ];

    const listI38 = [
        { id: 1, value: 'M20' },
        { id: 2, value: 'M22' },
        { id: 3, value: 'M24' },
    ];

    const listF40 = [
        { id: 1, value: 8.8, textValue: '強度区分 8.8' },
        { id: 2, value: 4.6, textValue: '強度区分 4.6' },
        { id: 3, value: 10.9, textValue: '強度区分 10.9' },
    ];

    const listF42 = [
        { id: 1, value: 'SD345' },
        { id: 2, value: 'SS400' },
        { id: 3, value: 'S35CN' },
        { id: 4, value: 'S45CN' },
    ];

    const listF44 = [
        { id: 1, value: 21 },
        { id: 2, value: 24 },
        { id: 3, value: 27 },
        { id: 4, value: 30 },
        { id: 5, value: 40 },
        { id: 6, value: 50 },
        { id: 7, value: 60 },
    ];

    return (
        <Card
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%', paddingBottom: 2 }} spacing={0.25}>
                <SectionTitle title="使用材料" />
                <RowSelectComponent blockNumber="block001" name="f32" description="上・下部工ブラケット" initValue={blockData?.f32?.value} listValue={listF32} />
                <RowSelectComponent blockNumber="block001" name="f34" description="調整プレート" initValue={blockData?.f34?.value} listValue={listF32} />
                <RowSelectComponent blockNumber="block001" name="f36" description="主桁補強材" initValue={blockData?.f36?.value} listValue={listF32} />
                <RowSelectComponent blockNumber="block001" name="f38" description="高力ボルト" initValue={blockData?.f38?.value} listValue={listF38} />
                <RowSelectComponent blockNumber="block001" name="i38" description="ボルト径" initValue={blockData?.i38?.value} listValue={listI38} />
                <RowSelectComponent blockNumber="block001" name="f40" description="仕上げボルト" initValue={blockData?.f40?.value} listValue={listF40} />
                <RowSelectComponent blockNumber="block001" name="f42" description="アンカーボルト (下部工側)" initValue={blockData?.f42?.value} listValue={listF42} />
                <RowSelectComponent blockNumber="block001" name="f44" description="既設コンクリート (下部工側)" initValue={blockData?.f44?.value} listValue={listF44} />
            </Stack>
        </Card>
    );
};

export default Block03;
