import { TextField, Typography } from '@mui/material';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const TableG70G71 = ({ arrayValue }) => {
    const [arrayInput, setArrayInput] = useState(arrayValue);
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);

    const blockData = sectionData?.data?.tool002version100?.[`block002`];
    const dispatch = useDispatch();

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;
    const numberOfBolts = Math.floor(blockData?.g70G71 / 2);

    const handleChange = (index, event) => {
        const allowNegativeNumber = true;

        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = event.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            replaceElement(index, newValue);
        }
    };

    const handleBlur = (index, event) => {
        let newValue = event.target.value;

        const newArr = [...arrayInput]; // Create a copy of the original array
        newArr[index] = {
            value: newValue === '' ? '' : parseFloat(newValue).toString(),
            type: 'number',
        };

        let blockDataChanged = {
            ...blockData,
            g70G71: newArr,
        };

        let sectionDataChanged = {
            ...sectionData.data,
            tool002version100: { ...sectionData.data?.tool002version100, block002: blockDataChanged },
        };

        if (!_.isEqual(blockData, blockDataChanged)) {
            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;
            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const replaceElement = (index, newValue) => {
        const newArray = [...arrayInput]; // Create a copy of the original array
        newArray[index] = {
            value: newValue,
        }; // Replace the element at the specified index
        setArrayInput(newArray); // Update the component's state with the new array
    };
    useEffect(() => {
        setArrayInput(arrayValue);
    }, [arrayValue]);
    return (
        <>
            <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-start flex-col">
                <div className="inline-block pl-1 pb-1">
                    <Typography>面方向 (橋軸直角方向)</Typography>
                </div>

                <table className="text-sm pb-3">
                    <thead className="bg-white border-b dark:bg-gray-900 text-black text-left uppecase">
                        <tr className="h-[32px]">
                            <td colSpan={2} className="w-[135px] border border-solid border-gray-500 px-1 py-0.5">
                                <Typography sx={{ justifyContent: 'center' }}>列目</Typography>
                            </td>

                            {Array.from({ length: 2 }, (_, index) => (
                                <td className="w-[80px] border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'center' }}>{index + 1}</Typography>
                                </td>
                            ))}
                        </tr>
                    </thead>

                    <tbody className="border-b dark:bg-gray-900 dark:border-gray-700">
                        <tr className="h-[32px] odd:bg-[#d0fecd] hover:bg-sky-100">
                            <td colSpan={2} className="border border-solid border-gray-500 px-1 py-0.5">
                                <Typography sx={{ justifyContent: 'center' }}>値</Typography>
                            </td>

                            {Array.from({ length: 2 }, (_, index) => {
                                const isError =
                                    (index < Math.floor(numberOfBolts / 2) && parseFloat(arrayInput && arrayInput[index]?.value) > 0) ||
                                    (index >= Math.floor(numberOfBolts / 2) && parseFloat(arrayInput && arrayInput[index]?.value) < 0);

                                return (
                                    <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                        <TextField
                                            value={arrayInput ? arrayInput[index]?.value : ''}
                                            onChange={(e) => handleChange(index, e)}
                                            onBlur={(e) => handleBlur(index, e)}
                                            onPaste={(e) => handleBlur(index, e)}
                                            placeholder="入力"
                                            disabled={canEdited}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    height: '32px',
                                                    padding: 0,
                                                    border: `${isError ? '1px solid #dc2626' : ''}`,
                                                    backgroundColor: isError ? '#f4e6e6' : '',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: `${isError ? '#dc2626' : ''}`,
                                                },
                                                '&.Mui-focused': {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        border: `${isError ? '1px solid #dc2626' : ''}`,
                                                    },
                                                },
                                            }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                        ></TextField>
                                    </td>
                                );
                            })}
                        </tr>
                    </tbody>
                </table>

                <div className="inline-block pl-1 pb-1">
                    <Typography>※便宜上、ストッパー中心からの距離を入力すること。</Typography>
                </div>
            </div>
        </>
    );
};

export default TableG70G71;
