import { Select, Tooltip, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';

const ResultInRow = (props) => {
    // console.log(props);
    const { isEqualTo, physicSymbolWidth, physicSymbolLeft, tooltipLeft, valueLeft, unit, physicSymbolRight, tooltipRight, valueRight, paintColor, mathSymbol } = props;

    return (
        <>
            <Typography sx={{ width: physicSymbolWidth ? physicSymbolWidth : '10%' }}>
                {physicSymbolLeft?.normalText}
                {physicSymbolLeft?.supText && <sup>{physicSymbolLeft?.supText}</sup>}
            </Typography>
            <Box sx={{ width: '5%' }}> {isEqualTo && <Typography>=</Typography>} </Box>

            {tooltipLeft !== undefined ? (
                <Tooltip title={`${tooltipLeft}`} placement="top" arrow>
                    <Typography sx={{ width: '20%', textAlign: 'right', color: valueLeft === 'エラー' ? 'red' : '' }}>{!isNaN(parseFloat(valueLeft)) ? valueLeft : ''}</Typography>
                </Tooltip>
            ) : (
                <Typography sx={{ width: '20%', textAlign: 'right', color: valueLeft === 'エラー' ? 'red' : '' }}>{!isNaN(parseFloat(valueLeft)) ? valueLeft : ''}</Typography>
            )}

            {unit && <Unit unit={unit} />}

            <Typography sx={{ width: '15%', display: 'flex', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>

            <Typography sx={{ width: physicSymbolWidth ? physicSymbolWidth : '20%' }}>
                {physicSymbolRight?.normalText}
                {physicSymbolRight?.supText && <sup>{physicSymbolRight?.supText}</sup>}
            </Typography>
            <Box sx={{ width: '5%' }}> {isEqualTo && <Typography>=</Typography>} </Box>

            {tooltipRight !== undefined ? (
                <Tooltip title={`${tooltipRight}`} placement="top" arrow>
                    <Typography sx={{ width: '20%', textAlign: 'right', color: valueRight === 'エラー' ? 'red' : '' }}>{valueRight !== '' ? valueRight : '0'}</Typography>
                </Tooltip>
            ) : (
                <Typography sx={{ width: '20%', textAlign: 'right', color: valueRight === 'エラー' ? 'red' : '' }}>{valueRight !== '' ? valueRight : '0'}</Typography>
            )}

            {unit && <Unit unit={unit} />}
        </>
    );
};

export default ResultInRow;

export const ShowValue = (props) => {
    const { physicSymbolWidth, physicSymbol, isEqualTo, resultTooltip, valueResult, unit } = props;

    return (
        <>
            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', width: '17%', paddingRight: 1.5 }}>
                <Typography sx={{ width: '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {physicSymbol?.normalText}
                    {physicSymbol?.subText && <sub>{physicSymbol?.subText}</sub>}
                    {physicSymbol?.supText && <sup>{physicSymbol?.supText}</sup>}
                </Typography>

                {physicSymbol?.mathSymbol && <Typography sx={{ width: '10%' }}>{` ${physicSymbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '20%' }}>
                {resultTooltip !== undefined ? (
                    <Tooltip title={`${resultTooltip}`} placement="top" arrow>
                        <Typography sx={{ justifyContent: 'right' }}>{valueResult}</Typography>
                    </Tooltip>
                ) : (
                    <Typography sx={{ justifyContent: 'right' }}>{valueResult}</Typography>
                )}
            </Box>

            {isEqualTo ? (
                <Box component="div" sx={{ display: 'flex', paddingLeft: 2.5, width: '15%' }}>
                    <Typography>{unit}</Typography>
                </Box>
            ) : (
                <Typography sx={{ width: '20%' }}></Typography>
            )}
        </>
    );
};

export const ShowValueSelect = (props) => {
    const { physicSymbolWidth, physicSymbol, isEqualTo, canEdit, resultTooltip, listValue, valueResult, unit, handleChange } = props;

    return (
        <>
            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', width: physicSymbolWidth ? physicSymbolWidth : '10%' }}>
                <Typography sx={{ width: '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {physicSymbol?.normalText}
                    {physicSymbol?.subText && <sub>{physicSymbol?.subText}</sub>}
                    {physicSymbol?.supText && <sup>{physicSymbol?.supText}</sup>}
                </Typography>

                {physicSymbol?.mathSymbol && <Typography sx={{ width: '30%' }}>{` ${physicSymbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box sx={{ width: '5%' }}> {isEqualTo && <Typography>=</Typography>} </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '20%' }}>
                {resultTooltip !== undefined ? (
                    <Tooltip title={`${resultTooltip}`} placement="top" arrow>
                        <Select
                            defaultValue={valueResult?.value || listValue[0].value}
                            value={valueResult ?? ''}
                            disabled={canEdit}
                            onChange={handleChange}
                            placeholder="入力"
                            sx={{
                                textAlign: 'right',
                                width: '100%',
                                height: '32px',
                                padding: 0,
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#272343',
                                    cursor: 'not-allowed',
                                },
                            }}
                        >
                            {listValue.map((item, index) => {
                                return (
                                    <MenuItem key={item.id} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Tooltip>
                ) : (
                    <Select
                        defaultValue={valueResult?.value || listValue[0].value}
                        value={valueResult ?? ''}
                        onChange={handleChange}
                        placeholder="入力"
                        sx={{
                            textAlign: 'right',
                            width: '100%',
                            height: '32px',
                            padding: 0,
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#272343',
                                cursor: 'not-allowed',
                            },
                        }}
                    >
                        {listValue.map((item, index) => {
                            return (
                                <MenuItem key={item.id} value={item.value}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            </Box>

            {isEqualTo ? (
                <Box component="div" sx={{ display: 'flex', paddingLeft: 2.5, width: '20%' }}>
                    <Typography>{unit}</Typography>
                </Box>
            ) : (
                <Typography sx={{ width: '20%' }}></Typography>
            )}
        </>
    );
};

export const Select1 = (props) => {
    const { physicSymbolWidth, physicSymbol, isEqualTo, resultTooltip, listValue, valueResult, unit, handleChange } = props;

    return (
        <>
            <Typography sx={{ width: props?.physicSymbolWidth ? props?.physicSymbolWidth : '10%' }}>
                <Box component="div" sx={{ display: 'flex' }}>
                    <Typography sx={{ width: '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                        {physicSymbol?.normalText}
                        {physicSymbol?.subText && <sub>{physicSymbol?.subText}</sub>}
                        {physicSymbol?.supText && <sup>{physicSymbol?.supText}</sup>}
                    </Typography>

                    {physicSymbol?.mathSymbol && <Typography sx={{ width: '20%' }}>{` ${physicSymbol?.mathSymbol}`}</Typography>}
                </Box>
            </Typography>
            <Box component="div" sx={{ display: 'inline-block', width: '20%' }}>
                {resultTooltip !== undefined ? (
                    <Tooltip title={`${resultTooltip}`} placement="top" arrow>
                        <Select
                            defaultValue={valueResult?.value || listValue[0].value}
                            value={valueResult ?? ''}
                            onChange={handleChange}
                            placeholder="入力"
                            sx={{
                                textAlign: 'right',
                                width: '100%',
                                height: '32px',
                                padding: 0,
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#272343',
                                    cursor: 'not-allowed',
                                },
                            }}
                        >
                            {listValue.map((item, index) => {
                                return (
                                    <MenuItem key={item.id} value={item.value}>
                                        {item.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Tooltip>
                ) : (
                    <Select
                        defaultValue={valueResult?.value || listValue[0].value}
                        value={valueResult ?? ''}
                        onChange={handleChange}
                        placeholder="入力"
                        sx={{
                            textAlign: 'right',
                            width: '100%',
                            height: '32px',
                            padding: 0,
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#272343',
                                cursor: 'not-allowed',
                            },
                        }}
                    >
                        {listValue.map((item, index) => {
                            return (
                                <MenuItem key={item.id} value={item.value}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                )}
            </Box>

            {isEqualTo ? (
                <Box component="div" sx={{ display: 'flex', paddingLeft: 2.5 }}>
                    <Typography>{unit}</Typography>
                </Box>
            ) : (
                <Typography sx={{ width: '20%' }}></Typography>
            )}
        </>
    );
};

export const Unit = ({ unit }) => {
    function RenderUnitText(unit) {
        switch (unit) {
            case UNIT.mm:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>{UNIT.mm}</Typography>
                    </Box>
                );

            case UNIT.Nmm2:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>N/mm</Typography>
                        <sup>2</sup>
                    </Box>
                );
            case UNIT.kN:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>kN</Typography>
                    </Box>
                );
            case UNIT.N:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>N</Typography>
                    </Box>
                );

            case UNIT.kNmm2:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>KN/mm</Typography>
                        <sup>2</sup>
                    </Box>
                );

            case UNIT.kata:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.kata}</Typography>
                    </Box>
                );

            case UNIT.hon:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.hon}</Typography>
                    </Box>
                );

            case UNIT.sheet:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.sheet}</Typography>
                    </Box>
                );

            case UNIT.step:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.step}</Typography>
                    </Box>
                );

            case UNIT.column:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.column}</Typography>
                    </Box>
                );

            case UNIT.description1:
                return (
                    <Box component="div">
                        <Typography>{UNIT.description1}</Typography>
                    </Box>
                );

            case UNIT.description2:
                return (
                    <Box component="div">
                        <Typography>{UNIT.description2}</Typography>
                    </Box>
                );

            case UNIT.do:
                return (
                    <Box component="div">
                        <Typography>{UNIT.do}</Typography>
                    </Box>
                );

            case UNIT.kNdivBasis:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.kNdivBasis}</Typography>
                    </Box>
                );

            case UNIT.mmDivKatagawa:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.mmDivKatagawa}</Typography>
                    </Box>
                );

            case `${UNIT.degree}   ※標準は3とする`:
                return (
                    <Box component="div" sx={{ display: 'flex' }}>
                        <Typography>{UNIT.degree} ※標準は3とする</Typography>
                    </Box>
                );

            case '※異形棒鋼：1，丸鋼：2':
                return (
                    <Box component="div" sx={{ display: 'flex' }}>
                        <Typography>※異形棒鋼：1、丸鋼：2</Typography>
                    </Box>
                );

            case UNIT.hidden:
                return <Box component="div" sx={{ display: 'flex', width: '20%' }}></Box>;

            default:
                return <Box component="div" sx={{ display: 'flex', width: '20%' }}></Box>;
        }
    }

    const renderView = RenderUnitText(unit);

    return <>{renderView}</>;
};
