import { Box, Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent, { RowNumberOfBolts } from '../share-components/RowText';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

const Block0502 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block009`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="主桁の補強検討" />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1) 補強板の照査" />
                </Box>
                <RowTextComponent
                    description="箇所　①の溶接に対して"
                    physicSymbol="τ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL186?.value}
                    mathSymbol={blockData?._AR186?.value}
                    valueStandard={blockData?._AS186?.value}
                    resultTooltip={blockData?._AL186?.toolTip}
                    standardTooltip={blockData?._AS186?.toolTip}
                    status={blockData?._BC186?.value}
                ></RowTextComponent>
                <RowTextComponent description="箇所　②の溶接に対して"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    valueCalculate={blockData?._AL189?.value}
                    mathSymbol={blockData?._AR189?.value}
                    valueStandard={blockData?._AS189?.value}
                    resultTooltip={blockData?._AL189?.toolTip}
                    standardTooltip={blockData?._AS189?.toolTip}
                    status={blockData?._BC189?.value}
                    physicSymbol="σ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    valueCalculate={blockData?._AL191?.value}
                    mathSymbol={blockData?._AR191?.value}
                    valueStandard={blockData?._AS191?.value}
                    resultTooltip={blockData?._AL191?.toolTip}
                    standardTooltip={blockData?._AS191?.toolTip}
                    status={blockData?._BC191?.value}
                    physicSymbol="τ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    valueCalculate={blockData?._AL193?.value}
                    mathSymbol={blockData?._AR193?.value}
                    valueStandard={blockData?._AS193?.value}
                    resultTooltip={blockData?._AL193?.toolTip}
                    standardTooltip={blockData?._AS193?.toolTip}
                    status={blockData?._BC193?.value}
                    unit={UNIT.Nmm2}
                ></RowTextComponent>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2) ボルトの照査" />
                </Box>
                <RowNumberOfBolts
                    description="(ウェブに取り付けるボルト本数の照査）"
                    valueAM197={blockData?._AM197?.value}
                    mathSymbol="/"
                    valueAS197={blockData?._AS197?.value}
                    physicSymbol="ｎ"
                    isEqualTo={true}
                ></RowNumberOfBolts>

                <RowTextComponent
                    paddingLeftFit={2}
                    valueCalculate={blockData?._AM198?.value}
                    resultTooltip={blockData?._AM198?.toolTip}
                    mathSymbol={blockData?._AQ198?.value}
                    valueStandard={blockData?._AR198?.value}
                    status={blockData?._BC198?.value}
                    unit={UNIT.hon}
                    isEqualTo={true}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0502;
