// ==============================|| OVERRIDES - CARD CONTENT ||============================== //

export default function Card() {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    padding: 16,
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.05),rgba(0, 0, 0, 0.05) 0px 20px 25px -5px,rgba(0, 0, 0, 0.04) 0px 10px 10px -5px',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                },
            },
        },

        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '&:last-child': {
                        padding: 0,
                    },
                },
            },
        },
    };
}
