import { useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton } from '@mui/material';

import { useTranslation } from 'react-i18next';
import MenuPopover from './MenuPopover';

// Flag icon
import EnglandFlag from './ic_flag_en.svg';
import JapanFlag from './ic_flag_jp.svg';
// import VietNamFlag from './ic_flag_vn.svg';

// ----------------------------------------------------------------------

const LANGS = [
    {
        value: 'jp',
        label: '日本語',
        icon: JapanFlag,
        disabled: false,
    },
    {
        value: 'en',
        label: '英語',
        icon: EnglandFlag,
        disabled: true,
    },
    // {
    //     value: 'vn',
    //     label: 'ベトナム語',
    //     icon: VietNamFlag,
    // },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const [optionChanged, setOptionChanged] = useState(LANGS[0]);

    const [t, i18n] = useTranslation('common');

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (option) => {
        setOpen(false);

        if (LANGS.indexOf(option) >= 0) {
            setOptionChanged(option);
            i18n.changeLanguage(option.value);
        }
    };

    return (
        <Box sx={{ pr: 1 }}>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    ...(open && {
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
                    }),
                }}
            >
                <img src={optionChanged.icon} alt={optionChanged.label} width="45" height="20" />
            </IconButton>

            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{
                    mt: 1.5,
                    ml: 0.75,
                    width: 150,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <Stack spacing={0.75}>
                    {LANGS.map((option) => (
                        <MenuItem key={option.value} disabled={option.disabled} selected={option.value === optionChanged.value} onClick={() => handleClose(option)}>
                            <Box component="img" alt={option.label} src={option.icon} sx={{ width: 30, mr: 2, height: 20 }} />
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </Box>
    );
}
