import axios from 'axios';
import queryString from 'query-string';
import UrlConstant from 'utils/url/UrlConstant';
import axiosClient from './configs/axiosClient';
import TokenServices from './token/TokenServices';

const axiosPublic = axios.create({
    baseURL: UrlConstant.HOZEN_API_URL,
    headers: {
        'content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin,DNT,X-Mx-ReqToken,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type',
    },
    paramsSerializer: (params) => queryString.stringify(params),
});

const AuthApi = {
    Login(data) {
        const url = '/authentication/login';
        return axiosPublic.request({
            method: 'POST',
            url: url,
            data: data,
        });
    },

    RefreshToken(data) {
        const url = '/authentication/refresh';
        return axiosPublic.request({
            method: 'POST',
            url: url,
            data: data,
        });
    },

    Logout() {
        const accessToken = TokenServices.getLocalAccessToken();
        const url = '/authentication/revoke';
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: { token: accessToken },
        });
    },

    GetUserCredentialDetail(accessTokenFirst) {
        const url = `users/detail`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },
};

export default AuthApi;
