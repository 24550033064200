// routes
import { ReactSVG } from 'react-svg';
import { RouterConstants } from 'utils/constants/RouterConstants';
import { PermissionsDefinition } from 'utils/StringDefinition';
// components

// ----------------------------------------------------------------------

// const getIcon = (name) => <SvgIconStyle src={`/${name}.svg`} sx={{ width: 1, height: 1 }} />;
const getIcon = (name) => <ReactSVG src={`/${name}.svg`} sx={{ mr: 2 }} />;

const ICONS = {
    user: getIcon('ic_user'),
    list: getIcon('ic_list'),
    recycleBin: getIcon('ic_recycle'),
    tool: getIcon('ic_tool'),
    add: getIcon('ic_add'),
};

const navConfig = [
    // Calculation
    // ----------------------------------------------------------------------
    {
        subheader: 'calculation',
        items: [
            {
                title: 'newCalculation',
                path: RouterConstants.NEW_CALCULATION,
                icon: ICONS.add,
            },
            {
                title: 'calculationList',
                path: RouterConstants.MENU,
                icon: ICONS.list,
            },
            //show hide 3D view
            // {
            //     title: 'view3D',
            //     path: RouterConstants.VIEW_3D,
            //     icon: ICONS.list,
            // },
            {
                title: 'recycleBin',
                path: RouterConstants.RECYCLE_BIN,
                icon: ICONS.recycleBin,
            },
        ],
        permission: [PermissionsDefinition.EDITOR, PermissionsDefinition.REVIEWER],
    },

    // USER MANAGEMENT
    // ----------------------------------------------------------------------
    {
        permission: [PermissionsDefinition.ADMIN],
        subheader: 'management',
        items: [
            {
                title: 'toolManagement',
                path: RouterConstants.TOOL_MANAGEMENT,
                icon: ICONS.tool,
            },
            { title: 'user', path: RouterConstants.USER_MANAGEMENT, icon: ICONS.user },
        ],
        // items: [
        //     // USER
        //     {
        //         title: 'user',
        //         path: RouterConstants.USER,
        //         icon: ICONS.user,
        //         children: [
        //             { title: 'profile', path: RouterConstants.USER },
        //             { title: 'list', path: RouterConstants.USER },
        //             { title: 'create', path: RouterConstants.USER },
        //             { title: 'edit', path: RouterConstants.USER },
        //             { title: 'account', path: RouterConstants.USER },
        //         ],
        //     },
        // ],
    },
];

export default navConfig;
