import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import { RowInputAndInput } from '../share-components/RowTwoInput';
import SectionTitle from '../share-components/Tilte';
import image from './5_1.png';

const Block0501 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block005`];

    const cacheData = cacheStore((state) => state.cacheData);
    // Dùng để lưu cache, nó là 1 bộ nhớ đệm, loại bỏ trường hợp khi nhập dữ liệu quá nhanh, dữ liệu sẽ bị mất
    const setCacheData = cacheStore((state) => state.setCacheData);

    const mergedObject = { ...blockData };

    for (const key in cacheData) {
        if (cacheData.hasOwnProperty(key)) {
            mergedObject[key] = cacheData[key];
        }
    }

    // console.log('🐳 ~ mergedObject:', mergedObject);

    useEffect(() => {
        setCacheData(mergedObject);
    }, [blockData]);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="Block0501" height="550px" />
                    </article>
                    <Typography className="absolute w-full flex top-[3rem] left-[-7.75rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f238?.value)) ? 'LR1 = 0' : `LR1 = ${blockData?.f238?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[4.5rem] left-[-9.5rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f242?.value)) ? 't8 = 0' : `t8 = ${blockData?.f242?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[23.5rem] left-[-15.25rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f243?.value)) ? 't9 = 0' : `t9 = ${blockData?.f243?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[29rem] left-[-15.25rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(parseFloat(blockData?.sheet8Data?.aK12?.value)) ? 'h = 0' : `h = ${blockData?.sheet8Data?.aK12?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[23rem] left-[-10.25rem] text-sm text-center justify-center">PR1</Typography>
                    <Typography className="absolute w-full flex top-[23rem] left-[-6.25rem] text-sm text-center justify-center">PR1</Typography>
                    <Typography className="absolute w-full flex top-[29.25rem] left-[-8rem] text-sm text-center justify-center">Hfe'</Typography>
                    <Typography className="absolute w-full flex top-[27.75rem] left-[-0.5rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f229?.value)) ? '調整プレート t5 = 0' : `調整プレート t5 = ${blockData?.f229?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[28.75rem] left-[-0.5rem] text-sm text-center justify-center">せん断ストッパー </Typography>
                    <Typography className="absolute w-full flex top-[16.5rem] left-[-0.75rem] text-sm text-center justify-center -rotate-90">桁WEB高の1/2以上</Typography>
                    <Typography className="absolute w-full flex top-[23.75rem] left-[-2rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f231?.value)) ? 't6 = 0' : `t6 = ${blockData?.f231?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[18.5rem] left-[-5rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f231?.value)) ? "h' = 0" : `h' = ${blockData?.f231?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[5rem] left-[3.25rem] text-sm text-center justify-center">高力ボルト</Typography>
                    <Typography className="absolute w-full flex top-[6rem] left-[3.25rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f235?.value)) ? 'n4 = 0' : `n4 = ${blockData?.f235?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[1.75rem] left-[6rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f232?.value)) ? 't7 = 0' : `t7 = ${blockData?.f232?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[3rem] left-[9.75rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f241?.value)) ? 'w2 = 0' : `w2 = ${blockData?.f241?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[4.5rem] left-[8.25rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.sheet8Data.aK51?.value)) ? 'LR2 = 0' : `LR2 = ${blockData?.sheet8Data?.aK51?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[11.25rem] left-[6.75rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.sheet8Data?.aK30?.value)) ? '0' : `${blockData?.sheet8Data?.aK30?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[15rem] left-[9.85rem] text-sm text-center justify-center">PR2</Typography>
                    <Typography className="absolute w-full flex top-[18.5rem] left-[6.5rem] text-sm text-center justify-center">PR2</Typography>
                    <Typography className="absolute w-full flex top-[15.5rem] left-[12.25rem] text-sm text-center justify-center -rotate-90">
                        {isNaN(parseFloat(blockData?.f239?.value)) ? 'h1 = 0' : `h1 = ${blockData?.f239?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[28.25rem] left-[10rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f244?.value)) ? 't10 = 0' : `t10 = ${blockData?.f244?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex top-[28.25rem] left-[6rem] text-sm text-center justify-center">
                        {isNaN(parseFloat(blockData?.f240?.value)) ? 'w1 = 0' : `w1 = ${blockData?.f240?.value}`}
                    </Typography>
                </div>

                <SectionTitle title="主桁補強材に関する寸法" />

                <RowTextInputComponent
                    blockNumber="block005"
                    name="f229"
                    description="上調整プレート厚さ"
                    initValue={blockData?.f229?.value}
                    symbol={{ normalText: 't5', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f231"
                    description="既設下フランジ厚さ"
                    initValue={blockData?.f231?.value}
                    symbol={{ normalText: 't6', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f232"
                    description="既設ウェブ厚さ"
                    initValue={blockData?.f232?.value}
                    symbol={{ normalText: 't7', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent blockNumber="block005" name="f233" description="既設ウェブ高さ" initValue={blockData?.f233?.value} unit={UNIT.mm} />

                <RowInputAndInput
                    description="ボルト本数n (手入力・自動計算)"
                    blockNumber="block005"
                    name="f235"
                    symbol={{ normalText: 'n4', mathSymbol: '=' }}
                    valueLeft={blockData?.f235?.value}
                    tooltipLeft={blockData?.f235?.toolTip}
                    valueRight={blockData?._G235?.value}
                    tooltipRight={blockData?._G235?.toolTip}
                    unit="本 ※M22の場合"
                />

                <RowTextInputComponent blockNumber="block005" name="f237" description="RIB枚数 2n/1基" initValue={blockData?.f237?.value} unit={UNIT.sheet} />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f238"
                    description="補強RIBプレート間隔"
                    initValue={blockData?.f238?.value}
                    symbol={{ normalText: 'LR', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowInputAndInput
                    description="補強RIBプレート縦 (高さ) (手入力・自動計算)"
                    blockNumber="block005"
                    name="f239"
                    symbol={{ normalText: 'h1', mathSymbol: '=' }}
                    valueLeft={blockData?.f239?.value}
                    tooltipLeft={blockData?.f239?.toolTip}
                    valueRight={blockData?._G239?.value}
                    tooltipRight={blockData?._G239?.toolTip}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f240"
                    description="補強RIBプレート横 (有効幅)"
                    initValue={blockData?.f240?.value}
                    symbol={{ normalText: 'w1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f241"
                    description="補強RIBプレート横 (全幅)"
                    initValue={blockData?.f241?.value}
                    symbol={{ normalText: 'w2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f242"
                    description="補強RIBプレート厚さ"
                    initValue={blockData?.f242?.value}
                    symbol={{ normalText: 't8', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f243"
                    description="補強Baseプレート厚さ (主桁下フランジ側)"
                    initValue={blockData?.f243?.value}
                    symbol={{ normalText: 't9', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextInputComponent
                    blockNumber="block005"
                    name="f244"
                    description="補強Baseプレート厚さ (主桁ウェブ側)"
                    initValue={blockData?.f244?.value}
                    symbol={{ normalText: 't10', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0501;
