import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FieldStatus } from 'utils/StringDefinition';
import { CompletedIcon, ErrorIcon, NAIcon } from './StatusIcon';

const RowTextResult = (props) => {
    const { description, descriptionWidth, formulaWidth, initValueWidth, unit, initValue, status, symbol, mathSymbolWidth, paddingLeftFit, paddingLeftDes } = props;

    return (
        <Box
            component="div"
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: paddingLeftFit ? paddingLeftFit : 0,
                paddingBottom: 0.75,
                marginBottom: '4px !important',
                marginTop: '0px !important',
            }}
        >
            <Box
                component="div"
                sx={{ display: 'inline-block', width: descriptionWidth ? descriptionWidth : '33%', alignItems: 'center', marginLeft: 10, paddingLeft: paddingLeftDes ? paddingLeftDes : 0 }}
            >
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', width: formulaWidth ? formulaWidth : '7%' }}>
                <Typography sx={{ width: symbol?.mathSymbol ? '70%' : '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>

                {symbol?.mathSymbol && <Typography sx={{ width: mathSymbolWidth ? mathSymbolWidth : '30%' }}>{` ${symbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', width: initValueWidth ? initValueWidth : '15%', justifyContent: 'flex-end' }}>
                <Typography sx={{ paddingRight: 1.5, color: initValue === FieldStatus.ERROR ? 'red' : '' }}>{initValue}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', textAlign: 'left', paddingLeft: 3 }}>
                <Typography>{unit}</Typography>
            </Box>
            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', marginLeft: 7.5 }}>
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.ERROR ? <NAIcon /> : <ErrorIcon />}
                </Box>
            )}
        </Box>
    );
};
export default RowTextResult;
