// material-ui
import { Grid, Stack, Typography } from '@mui/material';

import AuthWrapper from './AuthWrapper';
import AuthLogin from './auth_forms/AuthLogin';
import LogoCardLogin from './components/LogoCardLogin/LogoCardLogin';

function SignIn(props) {
    const { changeMode } = props;

    return (
        <AuthWrapper changeMode={changeMode}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mb: 3 }}>
                        <LogoCardLogin />
                    </Stack>
                </Grid>
                <Grid item xs={12} sx={{ mb: 3 }} justifyContent="center">
                    <AuthLogin changeMode={changeMode} />
                </Grid>
                <Grid item xs={12} alignItems="right" justifyContent="center">
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        <Typography variant="body1" sx={{ textDecoration: 'none' }} color="error" align="left">
                            アカウントをお待ちではない場合、管理者にご連絡ください。
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
        </AuthWrapper>
    );
}

export default SignIn;
