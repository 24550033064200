import { Select, Tooltip, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { FieldStatus, SheetStatus } from 'utils/StringDefinition';
import { ShowValue, Unit } from './ResultInRow';
import { CompletedIcon, ErrorIcon, NAIcon } from './StatusIcon';

const RowTwoInputResult = (props) => {
    const { description, unit, mathSymbol, valueLeft, tooltipLeft, physicSymbolRight, valueRight, tooltipRight, status } = props;
    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '25%', alignItems: 'center', marginLeft: 10 }}>
                <Typography sx={{ paddingLeft: 5 }}>{description}</Typography>
            </Box>
            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: '75%' }}>
                <ShowValue isEqualTo={true} paintColor={paintColor} unit={unit} valueResult={valueLeft} resultTooltip={tooltipLeft} />
                <Typography sx={{ width: '10%', display: 'flex', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>
                <ShowValue physicSymbol={physicSymbolRight} isEqualTo={true} paintColor={paintColor} unit={unit} valueResult={valueRight} resultTooltip={tooltipRight} />
            </Box>
            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '8%' }}>
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.ERROR ? <NAIcon /> : <ErrorIcon />}
                </Box>
            )}
        </Box>
    );
};
export default RowTwoInputResult;

export const RowSelectAndInputResult = (props) => {
    const dispatch = useDispatch();

    const { description, status, unitLeft, unitRight, mathSymbol, blockNumber, valueLeft, resultInRowWidth, physicSymbolRight, valueRight, tooltipRight, descriptionWidth, paddingLeftFit } = props;

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);
    const cacheData = cacheStore((state) => state.cacheData);
    const setCacheData = cacheStore((state) => state.setCacheData);

    const isEditor = user?.id === sheetInfo?.editor?.id;
    const paintColor = status === FieldStatus.OK ? false : true;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;
    const blockData = sectionData?.data?.tool002version100?.[blockNumber];

    const listValue = [
        { id: 1, value: 0, textValue: 'F' },
        { id: 2, value: 30, textValue: '30' },
        { id: 3, value: 50, textValue: '50' },
        { id: 4, value: 80, textValue: '80' },
        { id: 5, value: 110, textValue: '110' },
        { id: 6, value: 140, textValue: '140' },
        { id: 7, value: 170, textValue: '170' },
        { id: 8, value: 200, textValue: '200' },
        { id: 9, value: 230, textValue: '230' },
    ];

    const [num, setNum] = useState(valueLeft || listValue[0]?.value);

    // This effect will be called every time initValue changes.
    useEffect(() => {
        setNum(valueLeft);
    }, [valueLeft]);

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        // console.log(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const handleChange = (event) => {
        let newValue = event.target.value;

        setNum(newValue);

        if (newValue !== valueLeft?.value) {
            let cacheDataChanged = {
                ...cacheData,
                f28: { value: newValue.toString(), type: 'number' },
            };

            setCacheData(cacheDataChanged);

            let sectionDataChanged = {
                ...sectionData.data,
                tool002version100: { ...sectionData.data?.tool002version100, [blockNumber]: cacheDataChanged },
            };

            if (!_.isEqual(blockData, cacheDataChanged)) {
                updateData(sectionDataChanged);
            }
        }
    };

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: descriptionWidth ? descriptionWidth : '40%', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 10 : 10 }}>
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: resultInRowWidth ? resultInRowWidth : '50%' }}>
                <Typography sx={{ width: ' 10%' }}></Typography>
                <Box sx={{ width: '5%' }}></Box>
                <Box sx={{ width: '20%' }}>
                    <Select
                        defaultValue={valueLeft || listValue[0]?.value}
                        disabled={canEdited}
                        value={num ?? ''}
                        onChange={handleChange}
                        placeholder="入力"
                        sx={{
                            textAlign: 'right',
                            width: '90%',
                            height: '32px',
                            padding: 0,
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#272343',
                                cursor: 'not-allowed',
                            },
                        }}
                    >
                        {listValue.map((item, index) => {
                            return (
                                <MenuItem key={item.id} value={item.value}>
                                    {item.textValue}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Box>

                {unitLeft && <Unit unit={unitLeft} />}

                <Typography sx={{ width: '15%', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>

                <Typography sx={{ width: '20%' }}>
                    {physicSymbolRight?.normalText}
                    {physicSymbolRight?.supText && <sup>{physicSymbolRight?.supText}</sup>}
                </Typography>

                <Box sx={{ width: '5.5%', display: 'flex' }}>
                    <Typography>=</Typography>
                </Box>

                {tooltipRight !== undefined ? (
                    <Tooltip title={`${tooltipRight}`} placement="top" arrow>
                        <Typography sx={{ width: '21%', textAlign: 'right', color: valueRight === 'エラー' ? 'red' : '' }}>{valueRight !== '' ? valueRight : '0'}</Typography>
                    </Tooltip>
                ) : (
                    <Typography sx={{ width: '21%', textAlign: 'right', color: valueRight === 'エラー' ? 'red' : '' }}>{valueRight !== '' ? valueRight : '0'}</Typography>
                )}

                {unitRight && <Unit unit={unitRight} />}
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 4.5 }}>
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};

export const RowEnduranceResult = (props) => {
    const { tooltipLeft, description, status, unitLeft, unitRight, mathSymbol, valueLeft, resultInRowWidth, physicSymbolRight, valueRight, tooltipRight, descriptionWidth, paddingLeftFit } = props;

    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: descriptionWidth ? descriptionWidth : '40%', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 10 : 10 }}>
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: resultInRowWidth ? resultInRowWidth : '50%' }}>
                <Typography sx={{ width: ' 10%' }}></Typography>
                <Box sx={{ width: '5%' }}></Box>

                {tooltipLeft !== undefined ? (
                    <Tooltip title={`${tooltipLeft}`} placement="top" arrow>
                        <Typography sx={{ width: '20%', textAlign: 'right', color: valueLeft === 'エラー' ? 'red' : '' }}>{!isNaN(parseFloat(valueLeft)) ? valueLeft : ''}</Typography>
                    </Tooltip>
                ) : (
                    <Typography sx={{ width: '20%', textAlign: 'right', color: valueLeft === 'エラー' ? 'red' : '' }}>{!isNaN(parseFloat(valueLeft)) ? valueLeft : ''}</Typography>
                )}

                {unitLeft && <Unit unit={unitLeft} />}

                <Typography sx={{ width: '15%', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>

                <Typography sx={{ width: '20%' }}>
                    {physicSymbolRight?.normalText}
                    {physicSymbolRight?.supText && <sup>{physicSymbolRight?.supText}</sup>}
                </Typography>

                <Box sx={{ width: '5.5%', display: 'flex' }}>
                    <Typography>=</Typography>
                </Box>

                {tooltipRight !== undefined ? (
                    <Tooltip title={`${tooltipRight}`} placement="top" arrow>
                        <Typography sx={{ width: '21%', textAlign: 'right', color: valueRight === 'エラー' ? 'red' : '' }}>{valueRight !== '' ? valueRight : '0'}</Typography>
                    </Tooltip>
                ) : (
                    <Typography sx={{ width: '21%', textAlign: 'right', color: valueRight === 'エラー' ? 'red' : '' }}>{valueRight !== '' ? valueRight : '0'}</Typography>
                )}

                {unitRight && <Unit unit={unitRight} />}
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 4.5 }}>
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};
