import { MenuItem, Select } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

const SelectTool = ({ valueInit, name, fieldName, options, disable, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const handleChange = (e) => {
        let tool = e.target.value;
        // console.log(tool);
        setFieldValue(name, { toolCode: tool?.toolCode, toolName: tool?.toolName, versionId: tool?.versionId });
    };

    // console.log(valueInit);

    useEffect(() => {
        setFieldValue(name, {
            toolCode: valueInit?.toolCode ?? options[0]?.toolCode,
            toolName: valueInit?.toolName ?? options[0]?.toolName,
            versionId: valueInit?.versionId ?? options[0]?.versionId,
        });
    }, []);

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange,
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    let optionSelected = options.find((option) => option.toolCode === valueInit?.toolCode && option.versionId === valueInit?.versionId);

    return (
        <Select onChange={handleChange} defaultValue={optionSelected || options[0]} disabled={disable}>
            {options &&
                options.map((item, index) => {
                    return (
                        <MenuItem key={`${item?.toolCode}${item?.versionId}`} value={item}>
                            {item[`${fieldName}`]}
                            {/* {console.log(item)} */}
                        </MenuItem>
                    );
                })}
        </Select>
    );
};

export default SelectTool;
