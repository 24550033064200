import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Trans, useTranslation } from 'react-i18next';
import { UserStatus } from 'utils/StringDefinition';

export default function ActiveDialog(props) {
    const { openActivePopup, setOpenActivePopup, handleChangeUserStatus, userInfo } = props;

    const { t } = useTranslation();

    const handleClose = () => {
        setOpenActivePopup(false);
    };

    return (
        <>
            <Dialog open={openActivePopup} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle align="center">{userInfo?.userStatus?.statusName === UserStatus.ACTIVE ? t('deactiveDialog.titleDeactive') : t('deactiveDialog.titleActive')} </DialogTitle>
                <DialogContent sx={{ marginTop: 3 }}>
                    <DialogContentText id="alert-dialog-description">
                        {userInfo?.userStatus?.statusName === UserStatus.ACTIVE
                            ? `${t('deactiveDialog.user')} ${userInfo.email} (${userInfo.firstName} ${userInfo.lastName}) ${t('deactiveDialog.deactiveContent')}`
                            : `${t('deactiveDialog.user')} ${userInfo.email} (${userInfo.firstName} ${userInfo.lastName}) ${t('deactiveDialog.activeContent')}`}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ padding: '12px 24px 12px 24px', backgroundColor: '#f2f2f4' }}>
                    <Button variant="outlined" onClick={handleClose}>
                        <Trans i18nKey="sheetDialog.btnCancel" />
                    </Button>
                    <Button onClick={handleChangeUserStatus} autoFocus variant="contained" color={userInfo?.userStatusId === 1 ? 'warning' : 'success'}>
                        {userInfo?.userStatus?.statusName === UserStatus.ACTIVE ? (
                            <>
                                <Trans i18nKey="userTable.deactiveUser" />
                            </>
                        ) : (
                            <>
                                <Trans i18nKey="userTable.activeUser" />
                            </>
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
