import { Typography } from '@mui/material';

const Table0101 = ({ arrayValue }) => {
    function TableCell({ value }) {
        return (
            <td className="border text-right border-solid border-gray-500 px-1 py-0.5">
                <Typography sx={{ justifyContent: 'flex-end' }}>{value}</Typography>
            </td>
        );
    }
    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-start flex-col">
            <div className="inline-block pl-1 pb-1">
                <Typography>参考　リンク数別</Typography>
                <Typography>上部工設置位置 下部工縦型奇数リンク、横型は偶数リンク採用</Typography>
                <Typography>※各橋梁の設置条件によって、「縦型」「横型」を手動で選択すること。</Typography>
            </div>

            <table className="text-sm text-right pb-3">
                <thead className="bg-white border-b dark:bg-gray-900 text-black text-right uppecase">
                    <tr className="h-[32px]">
                        {['リンク数', "取付位置L0'", '緩衝ﾁｪｰﾝ長さ', '緩衝部長さ', 'リンク部長さ', '取付間隔CL'].map((header) => (
                            <td key={header} scope="col" className="border border-solid border-gray-500 px-1 py-0.5">
                                <Typography sx={{ justifyContent: 'flex-end' }}>{header}</Typography>
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody className="border-b dark:bg-gray-900 dark:border-gray-700">
                    {arrayValue &&
                        arrayValue.map((value, index) => (
                            <tr className="h-[32px] odd:bg-[#d0fecd] hover:bg-sky-100">
                                <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value[0]}</Typography>
                                </td>
                                <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value[1]}</Typography>
                                </td>
                                <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value[2]}</Typography>
                                </td>
                                <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value[3]}</Typography>
                                </td>
                                <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value[4]}</Typography>
                                </td>
                                <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value[5]}</Typography>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
};

export default Table0101;
