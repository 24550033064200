import ThemeCustomization from 'components/main_components/themes';
import MainServices from 'pages/MainServices';
import { Route, Routes } from 'react-router';

const App = () => {
    return (
        <>
            <ThemeCustomization>
                <Routes>
                    <Route path="/*" element={<MainServices />} />
                </Routes>
            </ThemeCustomization>
        </>
    );
};

export default App;
