import axiosClient from './configs/axiosClient';

const ToolAPI = {
    async GetListTools() {
        const url = `/tools`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },
};

export default ToolAPI;
