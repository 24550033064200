import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

const Block0203 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block002`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="各照査事項" />
                <SectionTitleSub title="① 調整プレート照査" />
                <RowTextComponent description="1) 面直角方向 (橋軸)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    description=" ・曲げ応力度"
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σs' }}
                    valueLeft={blockData?._G82?.value}
                    tooltipLeft={blockData?._G82?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I82?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K82?.value}
                    tooltipRight={blockData?._K82?.toolTip}
                    status={blockData?._S82?.value}
                ></RowTextComponent>

                <RowTextComponent description="2) 面方向 (橋軸直角)"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    description=" ・曲げ応力度"
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σs' }}
                    valueLeft={blockData?._G84?.value}
                    tooltipLeft={blockData?._G84?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I84?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K84?.value}
                    tooltipRight={blockData?._K84?.toolTip}
                    status={blockData?._S84?.value}
                ></RowTextComponent>

                <SectionTitleSub title="② 連結ボルト" />

                <RowTextComponent description="1) 面直角方向 (橋軸)"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={3}
                    description="・引張応力度"
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σt' }}
                    valueLeft={blockData?._G87?.value}
                    tooltipLeft={blockData?._G87?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I87?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K87?.value}
                    tooltipRight={blockData?._K87?.toolTip}
                    status={blockData?._S87?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    description="・せん断応力度"
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'τt' }}
                    valueLeft={blockData?._G88?.value}
                    tooltipLeft={blockData?._G88?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I88?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K88?.value}
                    tooltipRight={blockData?._K88?.toolTip}
                    status={blockData?._S88?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    description=" ・合成応力度"
                    isEqualTo={false}
                    unit={UNIT.hidden}
                    valueLeft={blockData?._G89?.value}
                    tooltipLeft={blockData?._G89?.toolTip}
                    mathSymbol={blockData?._I89?.value}
                    valueRight={blockData?._K89?.value}
                    tooltipRight={blockData?._K89?.toolTip}
                    status={blockData?._S89?.value}
                ></RowTextComponent>

                <RowTextComponent description="2) 面方向 (橋軸直角)"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    description="・引張応力度"
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σt' }}
                    valueLeft={blockData?._G92?.value}
                    tooltipLeft={blockData?._G92?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I92?.value}
                    physicSymbolRight={{ normalText: 'σta' }}
                    valueRight={blockData?._K92?.value}
                    tooltipRight={blockData?._K92?.toolTip}
                    status={blockData?._S92?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    description="・せん断応力度"
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'τt' }}
                    valueLeft={blockData?._G93?.value}
                    tooltipLeft={blockData?._G93?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I93?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K93?.value}
                    tooltipRight={blockData?._K93?.toolTip}
                    status={blockData?._S93?.value}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={3}
                    description="・合成応力度"
                    isEqualTo={false}
                    valueLeft={blockData?._G94?.value}
                    tooltipLeft={blockData?._G94?.toolTip}
                    unit={UNIT.hidden}
                    mathSymbol={blockData?._I94?.value}
                    valueRight={blockData?._K94?.value}
                    tooltipRight={blockData?._K94?.toolTip}
                    status={blockData?._S94?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={false}
                    valueLeft={blockData?._G95?.value}
                    tooltipLeft={blockData?._G95?.toolTip}
                    unit={UNIT.hidden}
                    mathSymbol={blockData?._I95?.value}
                    valueRight={blockData?._K95?.value}
                    tooltipRight={blockData?._K95?.toolTip}
                    status={blockData?._S95?.value}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0203;
