import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthApi from 'api/AuthApi';
import TokenServices from 'api/token/TokenServices';

export const login = createAsyncThunk('authentication/Login', async (payload) => {
    try {
        TokenServices.removeUser();
        TokenServices.removeToken();

        const response = await AuthApi.Login(payload);
        TokenServices.addToken(response.data);
        const accessTokenFirst = response.data?.accessToken;

        const responseUser = await AuthApi.GetUserCredentialDetail(accessTokenFirst);
        if (responseUser.status === 200) TokenServices.setUser(responseUser?.data);

        return responseUser?.data;
    } catch (error) {
        console.log('Authentication Failed: ', error);
        return error.message;
    }
});

export const refreshUserInfo = createAsyncThunk('authentication/refreshUserInfo', async (payload) => {
    try {
        const responseUser = await AuthApi.GetUserCredentialDetail();
        return responseUser?.data;
    } catch (error) {
        console.log('refreshUserInfo Failed: ', error);
        return error.message;
    }
});

export const refreshToken = createAsyncThunk('authentication/refresh', async (payload) => {
    try {
        const response = await AuthApi.RefreshToken(payload);
        TokenServices.removeToken();
        if (response.status === 200) {
            if (response.data === 'Expired refresh token.') {
                TokenServices.removeUser();
                window.location.reload(true);
            } else {
                TokenServices.addToken(response.data);
            }
        } else if (response.status === 400) {
            TokenServices.removeUser();
            window.location.reload(true);
        }
        return response.data;
    } catch (error) {
        console.log('Refresh Token Failed: ', error);
        TokenServices.removeUser();
        TokenServices.removeToken();
        window.location.reload(true);
        console.log(error.message);
        return error.message;
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        current: TokenServices.getUser() || {},
        status: 'idle',
        error: null,
    },

    reducers: {
        logout(state) {
            state.current = {};
            TokenServices.removeUser();
        },
    },
    extraReducers: {
        [login.pending]: (state, action) => {
            state.status = 'loading';
        },
        [login.fulfilled]: (state, action) => {
            state.current = action.payload;
            state.status = 'succeeded';
        },
        [login.rejected]: (state, action) => {
            state.current = {};
            state.status = action.error.message;
            TokenServices.removeUser();
        },

        [refreshUserInfo.pending]: (state, action) => {
            state.status = 'loading';
        },
        [refreshUserInfo.fulfilled]: (state, action) => {
            state.current = action.payload;
            state.status = 'succeeded';
        },
        [refreshUserInfo.rejected]: (state, action) => {
            state.status = action.error.message;
        },

        [refreshToken.pending]: (state, action) => {
            state.status = 'loading';
        },
        [refreshToken.fulfilled]: (state, action) => {
            if (!!action.payload.accessToken === true) {
                TokenServices.addToken(action.payload);
                state.status = 'succeeded';
            } else {
                state.current = {};
                state.status = action.payload;
                TokenServices.removeUser();
            }
        },
        [refreshToken.rejected]: (state, action) => {
            state.current = {};
            state.status = action.error.message;
            TokenServices.removeUser();
        },
    },
});

const { actions, reducer } = userSlice;
export const { logout } = actions;
export default reducer;
