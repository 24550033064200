import { useCallback, useEffect, useState } from 'react';

// material-ui
import { Box, Table, TableBody } from '@mui/material';

// component
import { Stack } from '@mui/system';
import { Pagination } from 'antd';
import UserAPI from 'api/UserAPI';
import { CustomToastComponent } from 'components/popup_components/CustomToastComponent';
import { ToastShowError, ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import useTable, { getComparator } from 'custom_hook/useTable';
import Page404 from 'pages/not-found/NotFound';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCompany, getListUsersRedux, getPermissions } from 'store/reducers/userManagementSlice';
import { PermissionsDefinition, UserStatus } from 'utils/StringDefinition';
import ActiveDialog from './components/ActivePopup/ActivePopup';
import ChangePasswordPopup from './components/ChangePasswordPopup/ChangePasswordPopup';
import SearchUserComponent from './components/SearchUserComponent/SearchUserComponent';
import TableHeadUser from './components/TableHeadUser/TableHeadUser';
import UserDialog from './components/UserDialog/UserDialog';
import UserRow from './components/UserRowComponent/UserRow';
import { applySortFilterUsers, searchUserFnc } from './ultils/UserUltils';

// ==============================|| USER MANAGEMENT ||============================== //

const USER_TABLE_HEAD = [
    { id: 'firstName', label: 'First Name', align: 'center', width: 2.5 },
    { id: 'lastName', label: 'Last Name', align: 'center', width: 2.5 },
    { id: 'email', label: 'Email', align: 'center', width: 2.5 },
    { id: 'phoneNumber', label: 'Phone Number', align: 'center', width: 1.5 },
    { id: 'company', label: 'Company', align: 'center', width: 1.5 },
    { id: 'role', label: 'Role', align: 'center', width: 1.5 },
    { id: 'statusName', label: 'Status', align: 'center', width: 1.5 },
    { id: 'action', label: 'Action', align: 'center', width: 1.5 },
];

const UserManagement = () => {
    const user = useSelector((state) => state.user.current);
    const isAdmin = user?.permission === PermissionsDefinition.ADMIN ? true : false;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const INIT_VALUE = { password: '', confirmPassword: '' };
    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getListUsersRedux());
        dispatch(getPermissions());
        dispatch(getCompany());
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const listUsersInit = useSelector((state) => state.usersManagement.listAllUsers);

    const currentHeight = useSelector((state) => state.calculatationSection.screenHeight);
    // console.log(currentHeight);

    let MAX_USER_QUANTITY;

    switch (true) {
        case currentHeight <= 768:
            MAX_USER_QUANTITY = 5;
            break;
        case currentHeight < 969:
            MAX_USER_QUANTITY = 6;
            break;
        case currentHeight >= 969:
            MAX_USER_QUANTITY = 10;
            break;
        default:
            MAX_USER_QUANTITY = 8;
            break;
    }

    const { order, orderBy, selected, onSelectAllRows, onSort } = useTable();

    const [listUsers, setListUsers] = useState(listUsersInit || []);

    const [searchKeywords, setSearchKeywords] = useState('');

    const [pageOfUsers, setPageOfUsers] = useState({
        currentPage: 1,
        totalPages: Math.ceil(listUsers?.length / MAX_USER_QUANTITY) * 10,
        minIndex: 0,
        maxIndex: MAX_USER_QUANTITY,
    });

    const [openModal, setOpenModal] = useState(false);
    const [userPopupMode, setUserPopupMode] = useState(1);

    const [selectedRow, setSelectedRow] = useState({});
    const [openActivePopup, setOpenActivePopup] = useState(false);

    const [openChangePasswordPopup, setOpenChangePasswordPopup] = useState(false);

    useEffect(() => {
        setListUsers(listUsersInit);
        setPageOfUsers({ ...pageOfUsers, totalPages: Math.ceil(listUsersInit.length / MAX_USER_QUANTITY) * 10 });
    }, [listUsersInit]);

    const handleShowUserInfo = (userInfo) => {
        setUserPopupMode(2);
        setSelectedRow(userInfo);
        setOpenModal(true);
    };

    const handleClickOpenModal = () => {
        setUserPopupMode(1);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    //When search box typing
    const handleSearchInput = (e) => {
        var searchValue = e.target.value;

        setSearchKeywords(searchValue);
        let listUsersResult = searchUserFnc(listUsersInit, searchValue);
        if (searchValue !== '') {
            setListUsers(listUsersResult);
            setPageOfUsers({
                ...pageOfUsers,
                currentPage: 1,
                totalPages: Math.ceil(listUsersResult?.length / MAX_USER_QUANTITY) * 10 || 10,
                minIndex: 0,
                maxIndex: MAX_USER_QUANTITY,
            });
        } else if (searchValue === '') {
            setListUsers(listUsersInit);
            setPageOfUsers({
                currentPage: 1,
                totalPages: Math.ceil(listUsersInit?.length / MAX_USER_QUANTITY) * 10,
                minIndex: 0,
                maxIndex: MAX_USER_QUANTITY,
            });
        }
    };

    const handleChangePageFiles = (current) => {
        setPageOfUsers({
            ...pageOfUsers,
            currentPage: current,
            minIndex: (current - 1) * MAX_USER_QUANTITY,
            maxIndex: current * MAX_USER_QUANTITY,
        });
    };

    const dataFiltered = applySortFilterUsers({
        listUsers,
        comparator: getComparator(order, orderBy),
    });

    const handleChangeUserStatus = (userInfo) => {
        setSelectedRow(userInfo);
        setOpenActivePopup(true);
    };

    const handleToggleUserStatus = async () => {
        const response = await UserAPI.ToggleUserStatus(selectedRow.id);
        if (response.status === 200) {
            setOpenActivePopup(false);
            dispatch(getListUsersRedux());
            let message = selectedRow.userStatus.statusName === UserStatus.ACTIVE ? t('deactiveDialog.deactivedNoti') : t('deactiveDialog.activedNoti');
            ToastShowSuccess(message);
        } else {
            ToastShowError(t('toastNoti.error'));
        }
    };

    // const handleSubmitUserForm = async (valueSubmit, files) => {
    //     //Create new user
    //     if (userPopupMode === 1) {
    //         const response = await UserAPI.CreateNewUser(valueSubmit);
    //         if (response.status === 200) {
    //             setOpenModal(false);
    //             dispatch(getListUsersRedux());
    //             ToastShowSuccess('User has been created.');
    //         } else if (response.status === 400 && response.data === 'User already existed.') {
    //             ToastShowError('Error.');
    //         }

    //         //Update user info
    //     } else if (userPopupMode === 2) {
    //         const response = await UserAPI.UpdateNewUser(valueSubmit);
    //         if (response.status === 200) {
    //             setOpenModal(false);
    //             dispatch(getListUsersRedux());
    //             ToastShowSuccess('User info has been updated');
    //         } else if (response.status === 400 && response.data === 'User already existed.') {
    //             ToastShowError('Error.');
    //         }
    //     } else {
    //         ToastShowError('Error.');
    //     }
    // };

    const handleSubmitUserForm = async (formData, userId) => {
        let successMsg = '';
        let errorMsg = t('toastNoti.error');
        let response;

        if (userPopupMode === 1) {
            //Create new user
            response = await UserAPI.CreateNewUser(formData);
            successMsg = t('toastNoti.userCreated');
        } else if (userPopupMode === 2) {
            // Update user
            response = await UserAPI.UpdateUser(formData, userId);
            successMsg = t('toastNoti.userUpdated');
        } else {
            ToastShowError(errorMsg);
            return;
        }

        if (response.status === 200 && response?.data?.errorMessage === 'User already existed.') {
            ToastShowError(t('toastNoti.userExisted'));
        } else if (response.status === 200) {
            setOpenModal(false);
            dispatch(getListUsersRedux());
            ToastShowSuccess(successMsg);
        }
    };

    const handleChangeUserPassword = async (data) => {
        let values = {
            userName: selectedRow.email,
            password: data.password,
        };
        // console.log('value ', values);
        const response = await UserAPI.ChangePassword(selectedRow.id, values);
        if (response.status === 200) {
            dispatch(getListUsersRedux());

            ToastShowSuccess(t('toastNoti.passwordChanged'));
            setOpenChangePasswordPopup(false);
        } else {
            setOpenChangePasswordPopup(false);
            ToastShowError('Error.');
        }
    };

    const handleOpenChangePasswordPopup = (userInfo) => {
        setSelectedRow(userInfo);
        setOpenChangePasswordPopup(true);
    };

    const handleCloseChangePasswordPopup = (userInfo) => {
        setOpenChangePasswordPopup(false);
    };

    // prepare data push to table
    const listUserData = dataFiltered?.map((user, index) => {
        if (index >= pageOfUsers?.minIndex && index < pageOfUsers?.maxIndex) {
            return (
                <UserRow
                    key={user.id}
                    userInfo={user}
                    onShowUser={() => handleShowUserInfo(user)}
                    onChangeUserStatus={() => handleChangeUserStatus(user)}
                    onOpenChangePassword={() => handleOpenChangePasswordPopup(user)}
                />
            );
        }
    });

    let changePasswordPopup = null;
    if (openChangePasswordPopup === true) {
        changePasswordPopup = (
            <ChangePasswordPopup
                openChangePasswordPopup={openChangePasswordPopup}
                setOpenChangePasswordPopup={setOpenChangePasswordPopup}
                handleChangeUserPassword={handleChangeUserPassword}
                handleCloseChangePassword={handleCloseChangePasswordPopup}
                userInfo={selectedRow}
                initValues={INIT_VALUE}
            />
        );
    }

    return (
        <>
            {isAdmin ? (
                <>
                    <Stack>
                        <Box
                            sx={{
                                pt: 2,
                                pb: 2,
                                width: '100wh',
                            }}
                        >
                            {/* SearchBox */}

                            <SearchUserComponent searchKeywords={searchKeywords} handleSearchInput={handleSearchInput} handleClickOpenModal={handleClickOpenModal} />
                        </Box>

                        {/* Main View User Management */}

                        <Stack
                            sx={{
                                m: 2,
                                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                                borderRadius: '5px',
                                width: '100wh',
                                height: '82vh',
                            }}
                        >
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Table>
                                    <TableHeadUser
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={USER_TABLE_HEAD}
                                        rowCount={listUsersInit.length}
                                        numSelected={selected.length}
                                        onSort={onSort}
                                        onSelectAllRows={(checked) =>
                                            onSelectAllRows(
                                                checked,
                                                listUsersInit.map((row) => row.id),
                                            )
                                        }
                                    />

                                    <TableBody>{listUserData}</TableBody>
                                </Table>
                            </Box>

                            <Box component="div" sx={{ display: 'flex', width: '100%' }}>
                                {listUsers?.length > 0 ? (
                                    <Box component="div" sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center', margin: 2 }}>
                                        {/* <Typography sx={{ fontWeight: 'medium' }}>
                                            Total user {MAX_USER_QUANTITY < listUsersInit?.length ? MAX_USER_QUANTITY : listUsersInit?.length}/{listUsersInit?.length}
                                        </Typography> */}
                                        <Pagination defaultCurrent={1} current={pageOfUsers?.currentPage} total={pageOfUsers?.totalPages} showSizeChanger={false} onChange={handleChangePageFiles} />
                                    </Box>
                                ) : null}
                            </Box>
                        </Stack>

                        <UserDialog open={openModal} onClose={handleCloseModal} userInfo={selectedRow} userPopupMode={userPopupMode} onSubmitUserForm={handleSubmitUserForm} />
                        <ActiveDialog openActivePopup={openActivePopup} setOpenActivePopup={setOpenActivePopup} handleChangeUserStatus={handleToggleUserStatus} userInfo={selectedRow} />

                        {changePasswordPopup}
                        <CustomToastComponent />
                    </Stack>
                </>
            ) : (
                <Page404 />
            )}
        </>
    );
};

export default UserManagement;
