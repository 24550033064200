// material-ui
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Component
import MainCard from 'components/main_components/components/MainCard';
import UrlConstant from 'utils/url/UrlConstant';

// ==============================|| ACCOUNT CARD ||============================== //

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const AccountCard = () => {
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.current);
    const companyName = user.company?.companyName;
    // const userPermissionsArr = user?.permissions.map((permission) => permission.permissionName);
    const permissionText = user?.permission;

    return (
        <>
            <MainCard sx={{ bgcolor: 'grey.50' }}>
                <AccountStyle>
                    <Avatar alt="photoURL" src={`${UrlConstant.HOZEN_HOST_URL}${user?.avatar}`} {...stringAvatar(`${user.firstName} ${user.lastName}`)} />
                    <Box sx={{ ml: 2 }}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: 'text.primary',
                                fontSize: {
                                    xl: 16,
                                    lg: 13,
                                    md: 11,
                                },
                            }}
                        >
                            {user.firstName} {user.lastName}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'text.primary',
                                fontSize: {
                                    xl: 14,
                                    lg: 11,
                                    md: 9,
                                },
                            }}
                        >
                            {t(`permissions.${permissionText}`)}
                        </Typography>
                        <Typography
                            sx={{
                                color: 'text.primary',
                                fontSize: {
                                    xl: 14,
                                    lg: 11,
                                    md: 9,
                                },
                            }}
                        >
                            {companyName}
                        </Typography>
                    </Box>
                </AccountStyle>
            </MainCard>
        </>
    );
};

export default AccountCard;
