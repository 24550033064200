import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import AuthCard from './AuthCard';

const AuthWrapper = (props) => {
    return (
        <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100%', backgroundColor: '#f7f8fb' }}>
            <AuthCard>{props.children}</AuthCard>
        </Grid>
    );
};

AuthWrapper.propTypes = {
    children: PropTypes.node,
};

export default AuthWrapper;
