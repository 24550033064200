import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SheetAPI from 'api/SheetAPI';
import { changeIsLoading } from './calculatationSlice';

export const getSheetById = createAsyncThunk('sheet/getSheetById', async (payload) => {
    try {
        const startTime = performance.now(); // get current time before making API call
        const res = await SheetAPI.getSheetById(payload);
        const endTime = performance.now(); // get current time after receiving API response
        const elapsedTime = (endTime - startTime) / 1000; // calculate time difference

        console.log(`API getSheetById call took ${elapsedTime} s`);
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getSheetBySheetId!!!');
        return error.message;
    }
});

export const getDataSection = createAsyncThunk('sheet/getDataSection', async (payload) => {
    try {
        // console.log('call getDataSection');
        changeIsLoading(true);
        const startTime = performance.now(); // get current time before making API call
        const res = await SheetAPI.getDataSection(payload);
        const endTime = performance.now(); // get current time after receiving API response
        const elapsedTime = (endTime - startTime) / 1000; // calculate time difference
        changeIsLoading(false);
        console.log(`API getDataSection ${payload?.section} call took ${elapsedTime} s`);

        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getDataSection!!!');
        return error.message;
    }
});

export const getListSectionsOfSheet = createAsyncThunk('sheet/getListSectionsOfSheet', async (payload) => {
    try {
        const res = await SheetAPI.getListSectionsOfSheet(payload);
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListSectionOfSheet!!!');
        return error.message;
    }
});

const sheetSlice = createSlice({
    name: 'sheet',
    initialState: {
        sheetActive: {},
        dataSection: [],
        listSections: [],
        status: 'idle',
        error: null,
    },
    reducers: {
        setDataSection: (state, action) => {
            state.dataSection = action.payload;
        },
    },
    extraReducers: {
        [getSheetById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getSheetById.fulfilled]: (state, action) => {
            state.sheetActive = action.payload;
            state.status = 'succeeded';
        },
        [getSheetById.rejected]: (state, action) => {
            state.status = action.error.message;
        },

        [getDataSection.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getDataSection.fulfilled]: (state, action) => {
            state.dataSection = action.payload;
            state.status = 'succeeded';
        },
        [getDataSection.rejected]: (state, action) => {
            state.status = action.error.message;
        },

        [getListSectionsOfSheet.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListSectionsOfSheet.fulfilled]: (state, action) => {
            state.listSections = action.payload;
            state.status = 'succeeded';
        },
        [getListSectionsOfSheet.rejected]: (state, action) => {
            state.status = action.error.message;
        },

        // [getListSheetStatus.pending]: (state, action) => {
        //     state.status = 'loading';
        // },
        // [getListSheetStatus.fulfilled]: (state, action) => {
        //     state.listSheetStatus = action.payload;
        //     state.status = 'succeeded';
        // },
        // [getListSheetStatus.rejected]: (state, action) => {
        //     state.status = action.error.message;
        // },
    },
});

export const { setDataSection } = sheetSlice.actions;
const { reducer } = sheetSlice;

export default reducer;
