import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';

const TextFieldFormik = ({ name, placeholder, inputProps, ...otherProps }) => {
    const [field, meta] = useField(name);

    const configTextField = {
        fullWidth: true,
        variant: 'outlined',
        ...field,
        ...otherProps,
    };

    // meta object containes
    // submitForm, isSubmitting, touched, errors
    if (meta && meta.touched && meta.error) {
        configTextField.error = true;
        configTextField.helperText = meta.error;
    }

    return <TextField {...configTextField} placeholder={placeholder} inputProps={inputProps} />;
};

export default TextFieldFormik;
