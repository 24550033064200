import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';
import RowSelectComponent from '../share-components/RowSelect';
import SectionTitle from '../share-components/Tilte';

const Block02 = (props) => {
    const { blockData } = props;

    const listUpperFittingGrade = [
        { id: 1, value: 'SM400' },
        { id: 2, value: 'SM490' },
        { id: 3, value: 'SM490Y' },
    ];
    const listHighStrengthBoltGrade = [
        { id: 1, value: 'F8T' },
        { id: 2, value: 'F10T' },
        { id: 3, value: 'S10T' },
    ];
    const listAnchorBoltGrade = [
        { id: 1, value: 'SD295' },
        { id: 2, value: 'SD345' },
        { id: 3, value: 'S35CN' },
        { id: 4, value: 'S45CN' },
    ];

    const listConcrete = [
        { id: 1, value: 21 },
        { id: 2, value: 24 },
        { id: 3, value: 27 },
        { id: 4, value: 30 },
        { id: 5, value: 40 },
        { id: 6, value: 50 },
        { id: 7, value: 60 },
    ];

    return (
        <Card
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }}>
                <SectionTitle title="使用鋼材材種" />

                <RowSelectComponent blockNumber="block002" name="aK4" description="上部工金具" initValue={blockData?.aK4} listValue={listUpperFittingGrade} />
                <RowSelectComponent blockNumber="block002" name="aK5" description="下部工金具" initValue={blockData?.aK5} listValue={listUpperFittingGrade} />
                <RowSelectComponent blockNumber="block002" name="aK6" description="主桁補強材" initValue={blockData?.aK6} listValue={listUpperFittingGrade} />
                <RowSelectComponent blockNumber="block002" name="aK8" description="高力ボルト" initValue={blockData?.aK8} listValue={listHighStrengthBoltGrade} />
                <RowSelectComponent blockNumber="block002" name="aK13" description="アンカーボルト" initValue={blockData?.aK13} listValue={listAnchorBoltGrade} unit={UNIT.description1} />
                <RowSelectComponent
                    blockNumber="block002"
                    name="aK15"
                    description="コンクリート設計基準強度"
                    symbol={{ normalText: 'σck', mathSymbol: '=' }}
                    initValue={blockData?.aK15}
                    listValue={listConcrete}
                    unit={UNIT.Nmm2}
                />
            </Stack>
        </Card>
    );
};

export default Block02;
