import SheetAPI from 'api/SheetAPI';
import TokenServices from 'api/token/TokenServices';
import HZMainSideBar from 'components/main_components/MainLayout/HZMainSideBar';
import NavigationBar from 'components/main_components/NavigationBar';
import { ToastShowError, ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getListSheets } from 'store/reducers/menuSlice';
import { RouterConstants } from 'utils/constants/RouterConstants';
import Authentication from './authentication';
import SignUp from './authentication/SignUp';
import MenuHZ from './menu';
import SheetDialog from './menu/components/SheetDialog/SheetDialog';
import Page404 from './not-found/NotFound';
import RecycleBin from './recycle-bin';
import Show3DModel from './show-3d-model';
import ToolCalculate from './tool-calculate';
import ToolDetail from './tool-detail';
import SheetCardInfo from './tool-detail/components/SheetCard';
import ToolManagement from './tool-management';
import UserManagement from './user-management';
import UserSettings from './user-setting';
import ListView3D from './view-3d';

export const MainController = createContext();

function MainServices(props) {
    const user = useSelector((state) => state.user.current);
    let navigate = useNavigate();
    const changeMode = (value) => {
        navigate(`/${value}`);
    };
    let location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [mainMode, setMainMode] = useState(1);

    const [sheetMode, setSheetMode] = useState(1);

    useEffect(() => {
        if (location.pathname.includes('/sheet')) {
            setMainMode(2);
        } else if (location.pathname.includes('/show-3d-model')) {
            setMainMode(3);
        } else {
            setMainMode(1);
        }
    }, [location]);

    const [openDialogNewSheet, setOpenDialogNewSheet] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    // useEffect bên dưới : Nếu button Remember me không được tick thì dữ liệu Local Storage sẽ bị clear()
    useEffect(() => {
        const handleTabClose = (event) => {
            event.preventDefault();

            const rememberUser = TokenServices.getLocalStorageRemember() === 'true' ? true : false;
            if (!rememberUser) {
                TokenServices.removeToken();
                TokenServices.removeUser();
            }
        };

        window.addEventListener('beforeunload', handleTabClose);
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    const handleDialogClose = () => {
        setOpenDialogNewSheet(false);
    };

    const handleSubmitSheetDialog = async (values, resetForm) => {
        const valueSubmit = {
            id: values?.id ?? '',
            sheetName: values?.sheetName ?? '',
            tool: values?.tool ?? '',
            reviewer: { id: values?.reviewer.id ?? values?.reviewer },
            editor: { id: values?.editor.id ?? values?.editor },
            description: values?.description ?? '',
        };

        //Update sheet
        if (sheetMode === 2) {
            const response = await SheetAPI.updateSheet(valueSubmit);
            if (response.status === 200) {
                if (response.data.errorMessage === 'Sheet name had exist!') {
                    ToastShowError(t('toastNoti.existed'));
                } else {
                    setOpenDialogNewSheet(false);
                    dispatch(getListSheets());
                    ToastShowSuccess(t('toastNoti.updated'));
                }
            }
        } else if (sheetMode === 1) {
            const response = await SheetAPI.createSheet(valueSubmit);
            if (response.status === 200) {
                if (response.data.errorMessage === 'Sheet name had exist!') {
                    ToastShowError(t('toastNoti.existed'));
                } else {
                    setOpenDialogNewSheet(false);
                    dispatch(getListSheets());
                    resetForm();
                    ToastShowSuccess(t('toastNoti.created'));
                }
            }
        } else if (sheetMode === 3) {
            let sheetInfo = {
                id: '',
                ...valueSubmit,
            };

            // console.log(valueSubmit);
            const response = await SheetAPI.copySheet(sheetInfo);
            if (response.status === 200) {
                if (response.data.errorMessage === 'Sheet name had exist!') {
                    ToastShowError(t('toastNoti.existed'));
                } else {
                    setOpenDialogNewSheet(false);
                    dispatch(getListSheets());
                    resetForm();
                    ToastShowSuccess(t('toastNoti.created'));
                }
            }
        }
    };

    return (
        <>
            {!user.id ? (
                <Authentication changeMode={changeMode} />
            ) : (
                <div className="flex w-full h-screen">
                    <MainController.Provider
                        value={{
                            openDialogNewSheet,
                            sheetMode,
                            setSheetMode,
                            setOpenDialogNewSheet,
                            setSelectedRow,
                            mainMode,
                            setMainMode,
                        }}
                    >
                        {/* SIDE BAR */}
                        <div className="basis-72 hidden xl:block min-w-[18%] overflow-hidden h-screen border-solid border-0 border-r border-gray-300 xl:basis-60 2xl:basis-72 ">
                            <HZMainSideBar />
                        </div>
                        <div className="flex flex-col flex-auto h-screen">
                            {/* NavigationBar */}
                            <div className={`flex flex-end ${mainMode === 2 ? 'justify-between' : 'justify-end'} border-solid border-0 border-b border-gray-300 pr-2 pl-2`}>
                                <NavigationBar />
                            </div>
                            {/* Main View */}

                            <div className="flex flex-col w-full h-full overflow-auto">
                                {/* Routes */}
                                <Routes>
                                    <Route path="/" element={<MenuHZ />} />
                                    <Route path={`/${RouterConstants.USER}/*`} element={<UserSettings />} />
                                    <Route path={`/${RouterConstants.MENU}/*`} element={<MenuHZ />} />
                                    <Route path={`/${RouterConstants.VIEW_3D}/*`} element={<ListView3D />} />
                                    <Route path={`/${RouterConstants.RECYCLE_BIN}/*`} element={<RecycleBin />} />
                                    <Route path={`/${RouterConstants.TOOL_MANAGEMENT}/*`} element={<ToolManagement />} />
                                    <Route path={`/${RouterConstants.SHOW_3D_MODEL}/*`} element={<Show3DModel />} />
                                    <Route path={`/${RouterConstants.SHEET}/*`} element={<ToolCalculate />} />
                                    <Route path={`/${RouterConstants.USER_MANAGEMENT}/*`} element={<UserManagement />} />
                                    <Route path={`/${RouterConstants.SIGNUP}`} element={<SignUp changeMode={changeMode} />} />
                                    <Route path={`/${RouterConstants.TOOL_DETAIL}`} element={<ToolDetail />} />
                                    <Route path={`/${RouterConstants.SHEET_DETAIL}`} element={<SheetCardInfo />} />
                                    <Route path={`/${RouterConstants.NOT_FOUND}`} element={<Page404 />} />
                                </Routes>
                            </div>
                        </div>
                        <SheetDialog open={openDialogNewSheet} initValues={selectedRow} onClose={handleDialogClose} onSubmitSheetDialog={handleSubmitSheetDialog} sheetMode={sheetMode} />
                    </MainController.Provider>
                </div>
            )}
        </>
    );
}

export default MainServices;
