import { Box } from '@mui/material';
import DrawerBar from 'pages/user-setting/components/DrawerBar';
import { Route, Routes } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { RouterConstants } from 'utils/constants/RouterConstants';
import UserAccount from './UserAccount';
import UserProfile from './UserProfile';

UserSettings.propTypes = {};

function UserSettings(props) {
    const navigate = useNavigate();
    const changeRouterUser = (value) => {
        navigate(value);
    };
    return (
        <Box>
            <DrawerBar changeRouterUser={changeRouterUser} />

            <Box sx={{ marginLeft: { sm: '260px', xs: '0px' }, display: 'flex', alignItems: 'center', justifyItems: 'center', direction: 'column' }}>
                <Routes>
                    <Route path="/" element={<UserProfile />} />
                    <Route path={`/${RouterConstants.PROFILE}`} element={<UserProfile />} />
                    <Route path={`/${RouterConstants.ACCOUNT}`} element={<UserAccount />} />
                </Routes>
            </Box>
        </Box>
    );
}

export default UserSettings;
