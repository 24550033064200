import axiosDownloadPDF from './configs/axiosDownloadPDF';

const PrintAPI = {
    async printPDF(sheetId) {
        const url = `/sheets/exportPdf/${sheetId}`;

        return axiosDownloadPDF.request({
            method: 'GET',
            url: url,
            responseType: 'blob',
        });
    },
};

export default PrintAPI;
