// @mui
import { Dialog, DialogTitle, Stack } from '@mui/material';
import RegisterForm from 'pages/authentication/auth_forms/RegisterForm';
import { Trans } from 'react-i18next';

// ----------------------------------------------------------------------

export default function UserDialog({ open, onClose, userInfo, userPopupMode, onSubmitUserForm, editUserSetting }) {
    return (
        <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose}>
            <DialogTitle align="center" sx={{ pb: 2.5 }}>
                {userPopupMode === 1 ? <Trans i18nKey="signup.createAccount" /> : <Trans i18nKey="signup.updateInfo" />}
            </DialogTitle>

            <Stack spacing={3}>
                <RegisterForm userInfo={userInfo} userPopupMode={userPopupMode} onSubmitUserForm={onSubmitUserForm} onClose={onClose} editUserSetting={editUserSetting} />
            </Stack>
        </Dialog>
    );
}
