import { Chip } from '@mui/material';
export function CompletedIcon(props) {
    return <Chip label={props?.label || 'OK'} size="medium" sx={{ width: props?.widthSet || '55px' }} variant="outlined" color="success" />;
}

export function ErrorIcon(props) {
    return <Chip label={props?.label || 'NG'} size="medium" sx={{ width: props?.widthSet || '55px' }} variant="outlined" color="error" />;
}

export function NAIcon(props) {
    return <Chip label={props?.label || '#N/A'} size="medium" sx={{ width: props?.widthSet || '55px' }} variant="outlined" color="error" />;
}
