import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent, { RowTextSpecial } from '../share-components/RowText';
import RowTextThreeInput from '../share-components/RowThreeInput';
import RowTextThreeSelect from '../share-components/RowThreeSelect';
import SectionTitle, { InstructRow, SectionTitleSub } from '../share-components/Tilte';
import image61 from './6-1.png';
import Table0701 from './Table0701';
import Table0702 from './Table0702';
import Table0703 from './Table0703';

const Block0601 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version100?.[`block010`];

    const tableData = sheetData?.data?.version100?.[`block011`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                {/* <SectionTitle title="下部工ブラケット" />
                <Box component="div" sx={{ display: 'flex', flexDirection: 'column', rowGap: 0.5, paddingLeft: 2, paddingBottom: 2 }}>
                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">下部工ブラケット設置形式は、縦型、横型、回転型の中から選択する。</Typography>
                    </Box>
                    <RowInputSubConstructionType blockNumber="block001" name="subConstructionType" initValue={blockData?._K201} />

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・斜角６０度程度までは、縦型を選択する。</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・建築限界に支障がある場合は、横型を選択する。</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・※斜角のある橋では斜角なしの場合と下記照査が異なる為、本計算書は斜角が無い場合にのみ使用すること。</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 3 }}>
                        <Typography variant="h5">「・アンカーボルトのせん断力の照査・補強リブの形状に合わせた断面照査・溶接設計」</Typography>
                    </Box>

                    <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                        <Typography variant="h5">・斜角が６０度以下で横型を採用できない場合は回転型を採用する</Typography>
                    </Box>
                </Box> */}

                <article className="h-full w-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image61} alt="background" height="375" />

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[-26.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T230) ? 'L1' : `L1 = ${blockData?._T230}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[-24rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T239) ? 'L4' : `L4 = ${blockData?._T239}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[11.5rem] left-[-22.2rem]  text-sm text-center -rotate-90">
                        {isNaN(blockData?._T51) ? 'e' : `e = ${blockData?._T251}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[10.5rem] left-[-5.5rem] text-sm -rotate-90">
                        {isNaN(blockData?._T230) ? 'L1' : `L1 = ${blockData?._T230}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[-20rem] text-sm text-center">
                        {isNaN(blockData?._T233) ? 't2' : `t2 = ${blockData?._T233}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[19rem] left-[-17.5rem] text-sm text-center">
                        {isNaN(blockData?._T232) ? 'h1' : `h1 = ${blockData?._T232}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[4.5rem] left-[1.5rem] text-sm text-center">
                        {isNaN(blockData?._AL234) ? 'σc' : `σc = ${blockData?._AL234}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[16rem] left-[1.5rem] text-sm text-center">
                        {isNaN(blockData?._AL233) ? 'σt' : `σt = ${blockData?._AL233}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[4rem] left-[-8rem] text-sm text-center">{isNaN(blockData?._T231) ? 't1' : `t1 = ${blockData?._T231}`}</Typography>
                    <Typography className="absolute w-full flex justify-center top-[41.5rem] left-[2.5rem] text-sm -rotate-90">
                        {isNaN(blockData?._T230) ? 'L2' : `L2 = ${blockData?._T230}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[53rem] left-[7rem] text-sm text-center">{isNaN(blockData?._T243) ? 'h2' : `h2 = ${blockData?._T243}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[1.25rem] left-[12rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[0][0]) ? 'X1L' : `X1L = ${tableData?._AO275AX278[0][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.25rem] left-[21.5rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[0][2]) ? 'X1R' : `X1R = ${tableData?._AO275AX278[0][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[2.5rem] left-[8rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[1][0]) ? 'X2L' : `X2L = ${tableData?._AO275AX278[1][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[2.5rem] left-[26rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[1][2]) ? 'X2R' : `X2R = ${tableData?._AO275AX278[1][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[16.2rem] left-[8rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[2][0]) ? 'X3L' : `X3L = ${tableData?._AO275AX278[2][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[16.2rem] left-[26rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[2][2]) ? 'X3R' : `X3R = ${tableData?._AO275AX278[2][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[19.5rem] left-[12rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[3][0]) ? 'X4L' : `X4L = ${tableData?._AO275AX278[3][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[19.5rem] left-[21rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[3][3]) ? 'X4R' : `X4R = ${tableData?._AO275AX278[3][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[12.7rem] left-[9.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[3][1]) ? 'Y4L' : `Y4L = ${tableData?._AO275AX278[3][1]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[8rem] left-[9.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[0][1]) ? 'Y1L' : `Y1L = ${tableData?._AO275AX278[0][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[8.5rem] left-[11.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[1][1]) ? 'Y2L' : `Y2L = ${tableData?._AO275AX278[1][1]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[12.7rem] left-[11.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[2][1]) ? 'Y3L' : `Y3L = ${tableData?._AO275AX278[2][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[24rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[3][3]) ? 'Y4R' : `Y4R = ${tableData?._AO275AX278[3][3]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[8rem] left-[24rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[0][3]) ? 'Y1R' : `Y1R = ${tableData?._AO275AX278[0][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[12.5rem] left-[20.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[2][3]) ? 'Y3R' : `Y3R = ${tableData?._AO275AX278[2][3]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[8.5rem] left-[20.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[1][3]) ? 'Y2R' : `Y2R = ${tableData?._AO275AX278[1][3]}`}
                    </Typography>
                </article>

                {/* <article className="h-full w-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image61} alt="background" height="650" width="100%" />

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[-33.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T230) ? 'L1' : `L1 = ${blockData?._T230}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[-30.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T239) ? 'L4' : `L4 = ${blockData?._T239}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[14.3rem] left-[-28.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T51) ? 'e' : `e = ${blockData?._T251}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[13rem] left-[-7rem] text-sm -rotate-90">{isNaN(blockData?._T230) ? 'L1' : `L1 = ${blockData?._T230}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[4.5rem] left-[-26rem] text-sm text-center">
                        {isNaN(blockData?._T233) ? 't2' : `t2 = ${blockData?._T233}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[24rem] left-[-22rem] text-sm text-center">{isNaN(blockData?._T232) ? 'h1' : `h1 = ${blockData?._T232}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[6rem] left-[1.5rem] text-sm text-center">
                        {isNaN(blockData?._AL234) ? 'σc' : `σc = ${blockData?._AL234}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[20rem] left-[2.5rem] text-sm text-center">
                        {isNaN(blockData?._AL233) ? 'σt' : `σt = ${blockData?._AL233}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[5rem] left-[-10.5rem] text-sm text-center">
                        {isNaN(blockData?._T231) ? 't1' : `t1 = ${blockData?._T231}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[41.5rem] left-[2.5rem] text-sm -rotate-90">
                        {isNaN(blockData?._T230) ? 'L2' : `L2 = ${blockData?._T230}`}
                    </Typography>

                   <Typography className="absolute w-full flex justify-center top-[5rem] left-[25rem] text-sm text-center">{isNaN(blockData?._T233) ? 't2' : `t2 = ${blockData?._T233}`}</Typography> 

                    <Typography className="absolute w-full flex justify-center top-[53rem] left-[7rem] text-sm text-center">{isNaN(blockData?._T243) ? 'h2' : `h2 = ${blockData?._T243}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[9rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[1][0]) ? 'X2L' : `X2L = ${tableData?._AO275AX278[1][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[16rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[0][0]) ? 'X1L' : `X1L = ${tableData?._AO275AX278[0][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[1.5rem] left-[27rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[0][2]) ? 'X1R' : `X1R = ${tableData?._AO275AX278[0][2]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[3.5rem] left-[32rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[1][2]) ? 'X2R' : `X2R = ${tableData?._AO275AX278[1][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[21rem] left-[9rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[2][0]) ? 'X3L' : `X3L = ${tableData?._AO275AX278[2][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[24.5rem] left-[15rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[3][0]) ? 'X4L' : `X4L = ${tableData?._AO275AX278[3][0]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[24.5rem] left-[27rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[3][3]) ? 'X4R' : `X4R = ${tableData?._AO275AX278[3][3]}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[21rem] left-[33rem] text-sm text-center">
                        {isNaN(tableData?._AO275AX278[2][2]) ? 'X3R' : `X3R = ${tableData?._AO275AX278[2][2]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15.5rem] left-[12rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[3][1]) ? 'Y4L' : `Y4L = ${tableData?._AO275AX278[3][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[9.5rem] left-[12rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[0][1]) ? 'Y1L' : `Y1L = ${tableData?._AO275AX278[0][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[15rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[1][1]) ? 'Y2L' : `Y2L = ${tableData?._AO275AX278[1][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[16rem] left-[15rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[2][1]) ? 'Y3L' : `Y3L = ${tableData?._AO275AX278[2][1]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[15.5rem] left-[29rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[3][3]) ? 'Y4R' : `Y4R = ${tableData?._AO275AX278[3][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[29rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[0][3]) ? 'Y1R' : `Y1R = ${tableData?._AO275AX278[0][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[16rem] left-[26.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[2][3]) ? 'Y3R' : `Y3R = ${tableData?._AO275AX278[2][3]}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[10rem] left-[26.5rem] text-sm text-center -rotate-90">
                        {isNaN(tableData?._AO275AX278[1][3]) ? 'Y2R' : `Y2R = ${tableData?._AO275AX278[1][3]}`}
                    </Typography>
                </article> */}

                <InstructRow />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p230"
                    description="トッププレート長さ"
                    initValue={{ looking: blockData?._L230, input: blockData?.p230, calculate: blockData?._T230 }}
                    symbol={{ normalText: 'L1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p231"
                    description="トッププレート厚"
                    initValue={{ looking: blockData?._L231, input: blockData?.p231, calculate: blockData?._T231 }}
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p232"
                    description="荷重の作用高さ"
                    initValue={{ looking: blockData?._L232, input: blockData?.p232, calculate: blockData?._T232 }}
                    symbol={{ normalText: 'h1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p233"
                    description="ベースプレート厚"
                    initValue={{ looking: blockData?._L233, input: blockData?.p233, calculate: blockData?._T233 }}
                    symbol={{ normalText: 't2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p234"
                    description="ベースプレート長さ"
                    initValue={{ looking: blockData?._L234, input: blockData?.p234, calculate: blockData?._T234 }}
                    symbol={{ normalText: 'L2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p235"
                    description="補強リブ厚"
                    initValue={{ looking: blockData?._L235, input: blockData?.p235, calculate: blockData?._T235 }}
                    symbol={{ normalText: 't3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p236"
                    description="補強リブ高さ"
                    initValue={{ looking: blockData?._L236, input: blockData?.p236, calculate: blockData?._T236 }}
                    symbol={{ normalText: 'h3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p237"
                    description="補強リブ幅"
                    initValue={{ looking: blockData?._L237, input: blockData?.p237, calculate: blockData?._T237 }}
                    symbol={{ normalText: 'w', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p238"
                    description="スカーラップ"
                    initValue={{ looking: blockData?._L238, input: blockData?.p238, calculate: blockData?._T238 }}
                    symbol={{ normalText: 'R', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p239"
                    description="リブ間距離"
                    initValue={{ looking: blockData?._L239, input: blockData?.p239, calculate: blockData?._T239 }}
                    symbol={{ normalText: 'L4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Typography sx={{ paddingLeft: '60%' }}>*リブ高さ０の場合は、採用値に手入力</Typography>

                <SectionTitleSub title="アンカーボルトの計算" paddingLeftFit={5} />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p243"
                    description="荷重の作用高さ"
                    initValue={{ looking: blockData?._L243, input: blockData?.p243, calculate: blockData?._T243 }}
                    symbol={{ normalText: 'h2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p244"
                    description="ボルト中心間隔"
                    initValue={{ looking: blockData?._L244, input: blockData?.p244, calculate: blockData?._T244 }}
                    symbol={{ normalText: 'b1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p245"
                    description="ボルト本数"
                    initValue={{ looking: blockData?._L245, input: blockData?.p245, calculate: blockData?._T245 }}
                    symbol={{ normalText: 'n', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p246"
                    description="1列のボルト本数"
                    initValue={{ looking: blockData?._L246, input: blockData?.p246, calculate: blockData?._T246 }}
                    symbol={{ normalText: 'n1', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />

                <RowTextThreeSelect
                    blockNumber="block010"
                    name="p248"
                    description="アンカーボルト径"
                    initValue={{ looking: blockData?._L248, input: blockData?.p248, calculate: blockData?._T248 }}
                    symbol={{ normalText: 'Ｄ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p249"
                    description="アンカーボルトネジ径"
                    initValue={{ looking: blockData?._L249, input: blockData?.p249, calculate: blockData?._T249 }}
                    symbol={{ normalText: 'Ｍ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p251"
                    description="偏心量"
                    initValue={{ looking: blockData?._L251, input: blockData?.p251, calculate: blockData?._T251 }}
                    symbol={{ normalText: 'e', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p252"
                    description="ボルトの縁端距離(縦)"
                    initValue={{ looking: blockData?._L252, input: blockData?.p252, calculate: blockData?._T252 }}
                    symbol={{ normalText: 'a1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block010"
                    name="p253"
                    description="ボルトの縁端距離(横)"
                    initValue={{ looking: blockData?._L253, input: blockData?.p253, calculate: blockData?._T253 }}
                    symbol={{ normalText: 'a2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block010"
                    name="p254"
                    description="へりあき寸法"
                    initValue={{ looking: blockData?._L254, input: blockData?.p254, calculate: blockData?._T254 }}
                    symbol={{ normalText: 'c', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitle title="下部工ブラケット断面照査" />
                </Box>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1. トッププレートの断面照査" />
                </Box>
                <RowTextComponent
                    description="1)	曲げ応力度"
                    physicSymbol="σt"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL233}
                    mathSymbol={blockData?._AR233}
                    valueStandard={blockData?._AS233}
                    status={blockData?._BC233}
                ></RowTextComponent>

                <RowTextComponent
                    description=""
                    physicSymbol="σc"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL234}
                    mathSymbol={blockData?._AR234}
                    valueStandard={blockData?._AS234}
                    status={blockData?._BC234}
                ></RowTextComponent>

                <RowTextComponent
                    description="2)	せん断応力度"
                    physicSymbol="τs"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL235}
                    mathSymbol={blockData?._AR235}
                    valueStandard={blockData?._AS235}
                    status={blockData?._BC235}
                ></RowTextComponent>

                <RowTextComponent
                    description="3) 合成応力度"
                    unit={UNIT.none}
                    valueCalculate={blockData?._AL236}
                    mathSymbol={blockData?._AR236}
                    valueStandard={blockData?._AS236}
                    status={blockData?._BC236}
                ></RowTextComponent>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2. アンカーボルトの設計" />
                </Box>

                <RowTextComponent
                    description="1) 曲げモーメントによる応力度照査"
                    physicSymbol="σt"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL240}
                    mathSymbol={blockData?._AR240}
                    valueStandard={blockData?._AS240}
                    status={blockData?._BC240}
                ></RowTextComponent>

                <RowTextComponent
                    description="2) せん断力によるせん断応力度照査"
                    physicSymbol="τｓ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL242}
                    mathSymbol={blockData?._AR242}
                    valueStandard={blockData?._AS242}
                    status={blockData?._BC242}
                ></RowTextComponent>

                <RowTextComponent description="3) 合成応力度の照査"></RowTextComponent>

                <RowTextSpecial
                    description={{ description1: '(σ/σa) ', description2: ' + (τ/τa)' }}
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL244}
                    mathSymbol={blockData?._AR244}
                    valueStandard={blockData?._AS244}
                    status={blockData?._BC244}
                ></RowTextSpecial>

                <RowTextComponent
                    description="5) 付着力の照査"
                    physicSymbol="Tbd"
                    unit={UNIT.N}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL247}
                    mathSymbol={blockData?._AR247}
                    valueStandard={blockData?._AS247}
                    status={blockData?._BC247}
                ></RowTextComponent>

                <RowTextComponent
                    description="6) 軸引張力に対するコーン破壊の照査"
                    physicSymbol="Tcd"
                    unit={UNIT.N}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL249}
                    mathSymbol={blockData?._AR249}
                    valueStandard={blockData?._AS249}
                    status={blockData?._BC249}
                ></RowTextComponent>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="3. ベースプレート断面照査" />
                </Box>

                <RowTextComponent description="1)	リブ－トッププレート断面検討"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL253}
                    mathSymbol={blockData?._AR253}
                    valueStandard={blockData?._AS253}
                    status={blockData?._BC253}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL255}
                    mathSymbol={blockData?._AR255}
                    valueStandard={blockData?._AS255}
                    status={blockData?._BC255}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL257}
                    mathSymbol={blockData?._AR257}
                    valueStandard={blockData?._AS257}
                    status={blockData?._BC257}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・曲げせん断応力度"
                    isEqualTo={true}
                    physicSymbol="τb"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL258}
                    mathSymbol={blockData?._AR258}
                    valueStandard={blockData?._AS258}
                    status={blockData?._BC258}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・せん断の応力度"
                    physicSymbol="τs"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL259}
                    mathSymbol={blockData?._AR259}
                    valueStandard={blockData?._AS259}
                    status={blockData?._BC259}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL260}
                    mathSymbol={blockData?._AR260}
                    valueStandard={blockData?._AS260}
                    status={blockData?._BC260}
                ></RowTextComponent>

                <RowTextComponent description="2)	リブ－ベースプレート断面検討"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL264}
                    mathSymbol={blockData?._AR264}
                    valueStandard={blockData?._AS264}
                    status={blockData?._BC264}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL266}
                    mathSymbol={blockData?._AR266}
                    valueStandard={blockData?._AS266}
                    status={blockData?._BC266}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL268}
                    mathSymbol={blockData?._AR268}
                    valueStandard={blockData?._AS268}
                    status={blockData?._BC268}
                ></RowTextComponent>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 1 }}>
                    <SectionTitle title="ボルト間隔 (ブラケット中心からのアンカー位置)" />
                </Box>
                <div className="grid grid-cols-2 w-[90%]">
                    <Table0701 arrayValue={tableData?._M275V278} />

                    <Table0702 arrayValue={tableData?.aA275AJ278} />
                    <div className="col-span-2">
                        <Table0703 arrayValue={tableData?._AO275AX278} />
                    </div>
                </div>
            </Stack>
        </Card>
    );
};

export default Block0601;
