import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Grid, Stack, Typography } from '@mui/material';

import LabelCustom from 'pages/menu/components/ListFilesTableRow/LabelCustom';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SectionNameTool001, SectionNameTool002, SheetStatus } from 'utils/StringDefinition';

const CommentInfo = (props) => {
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    let toolCode = sheetInfo?.tool?.toolCode;
    const sectionIndex = useSelector((state) => state.calculatationSection.index);
    const sectionCode = `Section00${sectionIndex}`;

    const sectionNameMap = {
        Tool001: SectionNameTool001,
        Tool002: SectionNameTool002,
        // Add more mappings for other tool codes as needed
    };

    const selectedSectionName = sectionNameMap[toolCode][sectionCode] || {};

    // Return the JSX content here
    // return <div>{selectedSectionName[item.sectionCode] || 'セクション'}</div>;

    const [isExpanded, setExpanded] = useState(true);
    const toggleExpanded = () => {
        setExpanded(!isExpanded);
    };

    const { t } = useTranslation();

    // const useStyles = makeStyles((theme) => ({
    //     truncate: {
    //         overflow: 'hidden',
    //         textOverflow: 'ellipsis',
    //         whiteSpace: 'nowrap',
    //     },
    // }));
    // const classes = useStyles();

    return (
        <Stack
            sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
                pt: 1,
                pb: 1,
                borderBottom: '1px solid #d1d5db',
            }}
        >
            <Box component="div" sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h5">
                    <Trans i18nKey="calculationTable.sheetName" />: {sheetInfo?.sheetName}
                </Typography>
                <Box onClick={toggleExpanded} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                    {isExpanded ? <ExpandLessIcon sx={{ color: '#02457a' }} /> : <ExpandMoreIcon sx={{ color: '#02457a' }} />}
                </Box>
            </Box>

            {isExpanded && (
                <Box component="div" sx={{ display: 'inline', paddingTop: 3 }}>
                    <Grid container rowSpacing={1.5}>
                        <Grid item xs={3}>
                            <Typography variant="h7">{t('rightPanel.section')}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h7">:</Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                            <Typography variant="h7">{selectedSectionName}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h7">{t(`permissions.Editor`)}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h7">:</Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                            <Typography variant="h7"> {`${sheetInfo?.editor?.firstName} ${sheetInfo?.editor?.lastName}` || ''}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h7">{t(`permissions.Reviewer`)}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h7">:</Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                            <Typography variant="h7"> {`${sheetInfo?.reviewer?.firstName} ${sheetInfo?.reviewer?.lastName}` || ''}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="h7">{t(`userTable.statusName`)}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h7">:</Typography>
                        </Grid>
                        <Grid item xs={8.5}>
                            <LabelCustom
                                color={
                                    (sheetInfo?.status === SheetStatus.COMPLETED && 'success') ||
                                    (sheetInfo?.status === SheetStatus.EDITING && 'warning') ||
                                    (sheetInfo?.status === SheetStatus.CHECKED && 'warning') ||
                                    (sheetInfo?.status === SheetStatus.REVIEWING && 'primary') ||
                                    'primary'
                                }
                                sx={{ textTransform: 'capitalize', width: '50%' }}
                            >
                                <Trans i18nKey={`calculationTable.${sheetInfo?.status}`} />
                            </LabelCustom>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Stack>
    );
};

export default CommentInfo;
