import PropTypes from 'prop-types';
// @mui
import { Box, List, ListSubheader } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CompanyDefinition, PermissionsDefinition } from 'utils/StringDefinition';
import navConfig from './NavConfig';
import { NavListRoot } from './NavList';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: 16,
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavManagement.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function NavManagement({ isCollapse = false, ...other }) {
    const user = useSelector((state) => state.user.current);
    const { t } = useTranslation();

    const isAdmin = user?.permission === PermissionsDefinition.ADMIN ? true : false;
    const isNKEStaff = user.company?.companyName === CompanyDefinition.NKE ? true : false;
    let listNavMember = navConfig.filter((group) => !group.permission.includes(PermissionsDefinition.ADMIN));

    return (
        <Box {...other}>
            {isAdmin ? (
                <>
                    {navConfig.map((group) => (
                        <List key={group.subheader} disablePadding>
                            <ListSubheaderStyle
                                sx={{
                                    ...(isCollapse && {
                                        opacity: 0,
                                    }),
                                }}
                            >
                                {/* <Trans i18nKey={`sideBarContent.${group.subheader}`} /> */}
                                {t(`sideBarContent.${group.subheader}`)}
                            </ListSubheaderStyle>

                            {group.items.map((list) => (
                                <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />
                            ))}
                        </List>
                    ))}
                </>
            ) : (
                <>
                    {listNavMember.map((group) => (
                        <List key={group.subheader} disablePadding>
                            <ListSubheaderStyle
                                sx={{
                                    ...(isCollapse && {
                                        opacity: 0,
                                    }),
                                }}
                            >
                                <Trans i18nKey={`sideBarContent.${group.subheader}`} />
                            </ListSubheaderStyle>

                            {group.items.map((list) => {
                                if (list.title === 'recycleBin' || list.title === 'toolManagement') {
                                    return;
                                } else if (list.title === 'newCalculation') {
                                    if (isNKEStaff) {
                                        return <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />;
                                    }
                                } else {
                                    return <NavListRoot key={list.title} list={list} isCollapse={isCollapse} />;
                                }
                            })}
                        </List>
                    ))}
                </>
            )}
        </Box>
    );
}
