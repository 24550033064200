import { useState } from 'react';

// material-ui
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Modal } from '@mantine/core';
import { Box, Grid, Stack, Typography } from '@mui/material';

const SheetCardInfo = (props) => {
    let { sheetInfo, toolName } = props;
    let { fileName, modifiedDate } = sheetInfo;

    const [opened, setOpened] = useState(false);

    const handleEdit = () => {
        setOpened(true);
    };

    return (
        <>
            <Stack
                alignItems="left"
                sx={{
                    bgcolor: 'grey.50',
                    p: 2,
                    width: '95%',
                    borderRadius: 2,
                    border: '1px solid rgb(230, 235, 241)',
                    boxShadow: 'rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px',
                }}
            >
                <Grid container sx={{ p: 0, width: '100%' }} display="inline-block">
                    <Grid item xs={6} sx={{ width: '100%' }}>
                        <Box component="div" whiteSpace="normal">
                            <Typography variant="h5">Sheet name : {fileName}</Typography>
                            <Typography variant="h5">Project name : {toolName}</Typography>
                            <Typography variant="h5">Created date : {modifiedDate}</Typography>
                        </Box>
                    </Grid>

                    <Grid item container direction="row" justifyContent="flex-end" columnSpacing={10} sx={{ pt: 3 }}>
                        <DownloadOutlined style={{ fontSize: '30px', cursor: 'pointer', paddingRight: '15px' }} />
                        <EditOutlined style={{ fontSize: '30px', cursor: 'pointer', paddingRight: '15px' }} onClick={handleEdit} />
                        <DeleteOutlined style={{ fontSize: '30px', cursor: 'pointer', paddingRight: '15px' }} />
                    </Grid>
                </Grid>

                <Modal centered opened={opened} onClose={() => setOpened(false)} title="SHEET INFORMATION">
                    Coding here
                </Modal>
            </Stack>
        </>
    );
};
export default SheetCardInfo;
