import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SheetForgeAPI from '../../api/SheetForgeAPI';

export const getSheetForgeById = createAsyncThunk('sheetForge/getSheetForgeById', async (payload) => {
    try {
        const startTime = performance.now(); // get current time before making API call
        const res = await SheetForgeAPI.getSheetForgeById(payload);
        const endTime = performance.now(); // get current time after receiving API response
        const elapsedTime = (endTime - startTime) / 1000; // calculate time difference

        console.log(`API getSheetById call took ${elapsedTime} s`);
        return res.data.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getSheetBySheetId!!!');
        return error.message;
    }
});

export const getListSheetForge = createAsyncThunk('sheetForge/getListSheetForge', async (payload) => {
    try {
        const startTime = performance.now(); // get current time before making API call
        const res = await SheetForgeAPI.getListSheetForge(payload);
        const endTime = performance.now(); // get current time after receiving API response
        const elapsedTime = (endTime - startTime) / 1000; // calculate time difference

        console.log(`API getListSheetForge call took ${elapsedTime} s`);

        return res.data.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListSheetForge!!!');
        return error.message;
    }
});

const sheetForgeSlice = createSlice({
    name: 'sheetForge',
    initialState: {
        listSheetForge: [],
        sheetForgeActive: {},
        status: 'idle',
        error: null,
    },
    reducers: [],
    extraReducers: {
        [getSheetForgeById.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getSheetForgeById.fulfilled]: (state, action) => {
            state.sheetForgeActive = action.payload;
            state.status = 'succeeded';
        },
        [getSheetForgeById.rejected]: (state, action) => {
            state.status = action.error.message;
        },
        [getListSheetForge.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListSheetForge.fulfilled]: (state, action) => {
            state.listSheetForge = action.payload;
            state.status = 'succeeded';
        },
        [getListSheetForge.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { reducer } = sheetForgeSlice;

export default reducer;
