import Block0201 from './Block0201';
import Block0202 from './Block0202';

const Section02 = (props) => {
    return (
        <>
            <Block0201 />
            <Block0202 />
        </>
    );
};

export default Section02;
