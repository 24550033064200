import { TextField, Typography } from '@mui/material';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSection, getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const Table0402 = ({ arrayValue1, arrayValue2 }) => {
    const [arrayInput, setArrayInput] = useState(arrayValue1);
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);
    const blockData = sectionData?.data?.version110?.block008;
    const dispatch = useDispatch();

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const handleChange = (index, event) => {
        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = event.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || newValue !== '-' || regexNegativeNumber.test(newValue)) {
            // console.log('nhay vao day');
            replaceElement(index, newValue);
        }
    };

    const handleBlur = (index, e) => {
        let newValue = e.target.value;

        const newArr = [...arrayInput]; // Create a copy of the original array
        newArr[index] = {
            value: newValue === '' ? '' : parseFloat(newValue).toString(),
        };

        let blockDataChanged = {
            ...blockData,
            g160AE160: newArr,
        };

        // console.log(blockDataChanged);

        // let sectionDataChanged = {
        //     ...sectionData.data,
        //     block008: blockDataChanged,
        // };

        let sectionDataChanged = {
            ...sectionData.data,
            version110: { ...sectionData.data?.version110, block008: blockDataChanged },
        };

        if (!_.isEqual(blockData, blockDataChanged)) {
            // console.log(blockData, blockDataChanged);

            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const params = {
                sheetId: sheetId,
                section: `Section00${index}`,
            };
            dispatch(getDataSection(params));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const replaceElement = (index, newValue) => {
        const newArray = [...arrayInput]; // Create a copy of the original array
        console.log(newValue);
        newArray[index] = {
            value: newValue,
        }; // Replace the element at the specified index
        setArrayInput(newArray); // Update the component's state with the new array
    };

    useEffect(() => {
        setArrayInput(arrayValue1);
    }, [arrayValue1]);

    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-center flex-col">
            <div className="w-10/12 pb-1 flex pl-10 pr-10 justify-between">
                <Typography>
                    <strong style={{ color: '#02457a' }}>手入力値　 </strong>(ボルト間隔)
                </Typography>

                <Typography>※ L0を基点に右側は「-」にてボルトピッチを入力する </Typography>
            </div>

            <table className="text-sm text-right pb-3">
                <thead className="bg-white border-b dark:bg-gray-900 text-black text-right uppecase">
                    <tr className="h-[32px]">
                        {['L６', 'L５', 'L４', 'L３', 'L２', 'L1', 'L0', 'L１’', 'L２’', 'L３’', 'L４’', 'L５’', 'L６’'].map((header) => (
                            <td key={header} scope="col" className="w-[65px] border border-solid border-gray-500 px-1 py-1">
                                {header === 'L0' ? (
                                    <div className="flex justify-center">
                                        <Typography sx={{ justifyContent: 'center' }}>{header}</Typography>
                                        <Typography sx={{ justifyContent: 'center', color: 'red' }}>※</Typography>
                                    </div>
                                ) : (
                                    <Typography sx={{ justifyContent: 'center' }}>{header}</Typography>
                                )}
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                    <tr className="h-[32px]">
                        {/* {Array(13)
                            .fill()
                            .map((_, index) => (
                                <td key={index} className="w-auto">
                                    <TextField  inputProps={{ style: { textAlign: 'right' } }}></TextField>
                                </td>
                            ))} */}

                        {arrayInput &&
                            arrayInput.map((value, index) => (
                                <td key={index} className="w-[65px] border border-solid border-gray-500">
                                    <TextField
                                        value={value?.value}
                                        onChange={(e) => handleChange(index, e)}
                                        onBlur={(e) => handleBlur(index, e)}
                                        disabled={canEdited}
                                        placeholder="入力"
                                        sx={{
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: '#272343',
                                                cursor: 'not-allowed',
                                            },
                                            '& .MuiInputBase-root': {
                                                // backgroundColor: (index < 6 && parseFloat(value?.value) < 0) || (index > 6 && parseFloat(value?.value) > 0) ? '#d90429' : '#d2feff',
                                                border: `${(index < 6 && parseFloat(value?.value) < 0) || (index > 6 && parseFloat(value?.value) > 0) ? '1px solid red' : ''}`,
                                                backgroundColor: (index < 6 && parseFloat(value?.value) < 0) || (index > 6 && parseFloat(value?.value) > 0) ? '#f4e6e6' : '',
                                                height: '32px',
                                                padding: 0,
                                            },
                                        }}
                                        inputProps={{ style: { textAlign: 'right' } }}
                                    ></TextField>
                                </td>
                            ))}
                    </tr>

                    <tr className="text-sm text-right dark:bg-gray-700">
                        {['y６', 'y５', 'y４', 'y３', 'y２', 'y1', 'y0', 'y１’', 'y２’', 'y３’', 'y４’', 'y５’', 'y６’'].map((header) => (
                            <td key={header} className="w-[65px] border border-solid border-gray-500 px-1 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>{header}</Typography>
                            </td>
                        ))}
                    </tr>

                    <tr className="h-[32px]">
                        {arrayValue2 &&
                            arrayValue2.map((value, index) => (
                                <td className="bg-[#ffcc99] w-[65px] border border-solid border-gray-500 px-1 py-1" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value?.value}</Typography>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table0402;
