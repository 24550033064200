import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';

const Block03 = (props) => {
    const { blockData } = props;

    return (
        <Card
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }}>
                <SectionTitle title="取付関係" />

                <RowTextInputComponent blockNumber="block003" name="aK18" description="緩衝チエーンリンク数" initValue={blockData?.aK18} unit={UNIT.degree} />

                <RowTextResult name="_AK20" initValue={blockData?._AK20} description="緩衝チェーン長さ" unit={UNIT.mm} symbol={{ normalText: 'CLmax', mathSymbol: '=' }} />
                <RowTextInputComponent
                    blockNumber="block003"
                    name="aK23"
                    description="設置高さ（ピン間高さ）"
                    initValue={blockData?.aK23}
                    symbol={{ normalText: 'H1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextResult blockNumber="block003" name="_AK25" description="緩衝チェーン作用角度" initValue={blockData?._AK25} symbol={{ normalText: 'θ', mathSymbol: '=' }} unit={UNIT.do} />
                <RowTextInputComponent
                    blockNumber="block003"
                    name="aK27"
                    description="取付時のショックレスチェーン角度"
                    initValue={blockData?.aK27}
                    symbol={{ normalText: 'θ1', mathSymbol: '=' }}
                    unit={UNIT.do}
                />
            </Stack>
        </Card>
    );
};

export default Block03;
