import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import { RowEnduranceResult, RowSelectAndInputResult } from '../share-components/RowInputResult';
import RowSelectComponent from '../share-components/RowSelect';
import SectionTitle from '../share-components/Tilte';

const Block02 = () => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block001`];

    // Dùng để lưu cache, nó là 1 bộ nhớ đệm, loại bỏ trường hợp khi nhập dữ liệu quá nhanh, dữ liệu sẽ bị mất

    const listF24 = [
        { id: 1, value: '200kN型' },
        { id: 2, value: '300kN型' },
        { id: 3, value: '400kN型' },
        { id: 4, value: '500kN型' },
        { id: 5, value: '600kN型' },
        { id: 6, value: '700kN型' },
        { id: 7, value: '800kN型' },
        { id: 8, value: '900kN型' },
        { id: 9, value: '1000kN型' },
        { id: 10, value: '1200kN型' },
        { id: 11, value: '1400kN型' },
        { id: 12, value: '1600kN型' },
        { id: 13, value: '1800kN型' },
        { id: 14, value: '2000kN型' },
        { id: 15, value: '2200kN型' },
        { id: 16, value: '2400kN型' },
        { id: 17, value: '2600kN型' },
        { id: 18, value: '2800kN型' },
        { id: 19, value: '3000kN型' },
        { id: 20, value: '3500kN型' },
        { id: 21, value: '4000kN型' },
        { id: 22, value: '4500kN型' },
        { id: 23, value: '5000kN型' },
        { id: 24, value: '5500kN型' },
        { id: 25, value: '6000kN型' },
    ];

    return (
        <Card
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="せん断ストッパータイプ選択" />

                <RowSelectComponent blockNumber="block001" name="f24" description="せん断ストッパータイプ" initValue={blockData?.f24?.value} listValue={listF24} />

                <RowEnduranceResult
                    paddingLeftFit={3}
                    description="設計耐力、水平力"
                    valueLeft={blockData?._F25?.value}
                    tooltipLeft={blockData?._F25?.toolTip}
                    unitLeft={UNIT.kNdivBasis}
                    mathSymbol={blockData?._H25?.value}
                    physicSymbolRight={{ normalText: 'Hfe', supText: 'max' }}
                    valueRight={blockData?._J25?.value}
                    tooltipRight={blockData?._J25?.toolTip}
                    unitRight={UNIT.kNdivBasis}
                    status={blockData?._S25?.value}
                ></RowEnduranceResult>

                <RowEnduranceResult
                    paddingLeftFit={3}
                    description="設計耐力、上場力"
                    valueLeft={blockData?._F26?.value}
                    tooltipLeft={blockData?._F26?.toolTip}
                    unitLeft={UNIT.kNdivBasis}
                    mathSymbol={blockData?._H26?.value}
                    physicSymbolRight={{ normalText: 'V' }}
                    valueRight={blockData?._J26?.value}
                    tooltipRight={blockData?._J26?.toolTip}
                    unitRight={UNIT.kNdivBasis}
                    status={blockData?._S26?.value}
                ></RowEnduranceResult>

                <RowSelectAndInputResult
                    description="せん断ストッパー移動量"
                    blockNumber="block001"
                    name="f28"
                    unitLeft={UNIT.mmDivKatagawa}
                    mathSymbol={blockData?._H28?.value}
                    valueLeft={blockData?.f28?.value}
                    tooltipLeft={blockData?.f28?.toolTip}
                    valueRight={blockData?._J28?.value}
                    tooltipRight={blockData?._J28?.toolTip}
                    physicSymbolRight={{ normalText: '必要移動量' }}
                    unitRight={UNIT.mm}
                    status={blockData?._S28?.value}
                />
            </Stack>
        </Card>
    );
};

export default Block02;
