import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import KeyIcon from '@mui/icons-material/Key';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import AuthApi from 'api/AuthApi';
import UserAPI from 'api/UserAPI';
import { ToastShowError, ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import SectionSelection from 'pages/tool-calculate/tool001/version_1.0.0/section-selection/SectionSelection';
import SectionSelectionTool002 from 'pages/tool-calculate/tool002/version_1.0.0/section-selection/SectionSelection';
import ChangePasswordPopup from 'pages/user-management/components/ChangePasswordPopup/ChangePasswordPopup';
import UserDialog from 'pages/user-management/components/UserDialog/UserDialog';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getNotificationsByUserId } from 'store/reducers/NotificationSlice';
import { getCommentsBySheetId } from 'store/reducers/commentsSlice';
import { getSheetById } from 'store/reducers/sheetSlice';
import { getPermissions } from 'store/reducers/userManagementSlice';
import { logout, refreshUserInfo } from 'store/reducers/userSlice';
import { PermissionsDefinition } from 'utils/StringDefinition';
import { RouterConstants } from 'utils/constants/RouterConstants';
import UrlConstant from 'utils/url/UrlConstant';
import LanguagePopover from '../LanguagePopover/LanguagePopover';
import NotificationsPopover from '../NotificationPopover/NotificationsPopover';

const NavigationBar = (props) => {
    //TODO: CHANGE MODE internal code.
    const { t } = useTranslation();
    let location = useLocation();
    const { isLogin, changeMode } = props;
    const INIT_VALUE = { password: '', confirmPassword: '' };
    const user = useSelector((state) => state.user.current);
    const isAdmin = user?.permission === PermissionsDefinition.ADMIN ? true : false;

    const isCurrentUser = true;
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [openChangePasswordPopup, setOpenChangePasswordPopup] = React.useState(false);

    const [mainMode, setMainMode] = React.useState(1);

    React.useEffect(() => {
        if (location.pathname.includes('/sheet')) {
            setMainMode(2);
        } else {
            setMainMode(1);
        }
    }, [location]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const [openModal, setOpenModal] = React.useState(false);

    const handleLogOut = async () => {
        const action = logout();
        await dispatch(action);
        navigate(`${RouterConstants.HOME_PAGE}`);

        // call API để logout
        const response = await AuthApi.Logout();
        if (response.status === 200) {
            console.log('Logout success');
        } else {
            console.log('Logout fail');
        }
    };
    const handleGoToSetting = async () => {
        dispatch(getPermissions());
        setOpenModal(true);
        setAnchorElUser(null);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleChangePassword = async (data) => {
        let values = {
            userName: user?.email,
            password: data.password,
        };
        const response = await UserAPI.ChangePassword(user?.id, values);
        if (response.status === 200) {
            let message = 'Password of user has been changed.';
            ToastShowSuccess(message);
            setOpenChangePasswordPopup(false);
        } else {
            setOpenChangePasswordPopup(false);
            ToastShowError('Error.');
        }
    };

    const handleOpenChangePasswordPopup = () => {
        setAnchorElUser(false);
        setOpenChangePasswordPopup(true);
    };

    const handleCloseChangePasswordPopup = () => {
        setOpenChangePasswordPopup(false);
    };

    const handleSubmitUserForm = async (formData, userId) => {
        try {
            const response = await UserAPI.UpdateUser(formData, userId);
            if (response.status === 200) {
                setOpenModal(false);
                dispatch(refreshUserInfo());
                ToastShowSuccess('User info has been updated');
            }
        } catch (error) {
            ToastShowError('Error');
        }
    };

    const currentSheet = useSelector((state) => state.sheet.sheetActive);

    React.useEffect(() => {
        const connection = new HubConnectionBuilder().withUrl(`${UrlConstant.HOZEN_HOST_URL}/NotificationHub`).withAutomaticReconnect().configureLogging(LogLevel.Information).build();

        connection.start().then(function () {
            connection.on(`SheetStatusChanged`, () => {
                // Hàm này sẽ lắng nghe sự kiện SheetStatusChanged, nếu có tín hiệu báo từ Hub về thì bắn 1 dispatch lên Redux để fetch lại dữ liệu
                const action = getNotificationsByUserId();
                dispatch(action);

                if (currentSheet.id) {
                    const action2 = getSheetById(currentSheet.id);
                    dispatch(action2);
                    const action3 = getCommentsBySheetId(currentSheet.id);
                    dispatch(action3);
                }
            });

            connection
                .invoke('getConnectionId')
                .then(function (id) {
                    // console.log('connectionid:', id);
                })
                .catch(function (err) {
                    return console.log('ERROR in NotificationHub getConnectionId:', err.toString());
                });

            connection.invoke('LoginSession', user?.id).catch(function (err) {
                return console.log('ERROR in NotificationHub LoginSession:', err.toString());
            });

            connection.on(`JoinRoom`, (message) => {
                console.log(`JoinRoom: `, message);
            });

            connection.on(`FilePdfReadyDownload`, (message) => {
                console.log(`FilePdfReadyDownload: `, message);
            });

            // connection.on(`Login`, (message) => {
            //     console.log(`Login: `, message);
            // });
        });

        // connection
        //     .start()
        //     .then(() => connection.invoke('LoginSession', userIdString))
        //     .then(() => setConnection(connection))
        //     .catch(function (err) {
        //         return console.log('ERROR in NotificationHub  LoginSession:', err.toString());
        //     });

        return () => {
            if (connection) connection.stop();
        };
    }, [currentSheet]);

    const RenderSectionSelection = (toolCode) => {
        const listTools = {
            Tool001: <SectionSelection />,
            Tool002: <SectionSelectionTool002 />,
        };

        const selectionPanel = listTools[toolCode] || <SectionSelection />;

        return selectionPanel;
    };

    const sectionSelection = RenderSectionSelection(currentSheet?.tool?.toolCode);

    return (
        <>
            {/* Demo sử dụng thư viện chuyển đổi ngôn ngữ I18Next, phải có dòng thứ 29 nếu không sẽ không chạy được*/}

            {mainMode === 2 && <div>{sectionSelection}</div>}

            <div className="flex">
                <NotificationsPopover />

                <LanguagePopover />

                <IconButton onClick={handleOpenUserMenu}>
                    <Avatar alt="photoURL" src={`${UrlConstant.HOZEN_HOST_URL}${user?.avatar}`} sx={{ width: 30, height: 30 }} />
                </IconButton>

                <Menu
                    sx={{ mt: '30px', marginLeft: 'auto', marginBottom: '0px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <Typography
                        textAlign="left"
                        sx={{
                            marginLeft: '16px',
                            marginRight: '16px',
                            marginBottom: '5px',
                            marginTop: '5px',
                        }}
                    >
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Divider></Divider>
                    <MenuItem key={0} onClick={handleGoToSetting} sx={{ marginTop: '2px' }}>
                        <SettingsIcon sx={{ mr: 1 }}></SettingsIcon>
                        <Typography textAlign="left">
                            <Trans i18nKey="user.setting" />
                        </Typography>
                    </MenuItem>

                    <MenuItem key={1} onClick={handleOpenChangePasswordPopup} sx={{ marginTop: '2px' }}>
                        <KeyIcon sx={{ mr: 1 }}></KeyIcon>
                        <Typography textAlign="left">
                            <Trans i18nKey="user.changePassword" />
                        </Typography>
                    </MenuItem>
                    <MenuItem onClick={handleLogOut} key={2} sx={{ marginTop: '2px' }}>
                        <LogoutIcon sx={{ mr: 1 }}></LogoutIcon>
                        <Typography textAlign="left">
                            <Trans i18nKey="user.logout" />
                        </Typography>
                    </MenuItem>
                </Menu>
            </div>

            <UserDialog
                open={openModal}
                onClose={handleCloseModal}
                userPopupMode={2}
                isCurrentUser={isCurrentUser}
                onSubmitUserForm={handleSubmitUserForm}
                userInfo={user}
                editUserSetting={!isAdmin}
            />

            <ChangePasswordPopup
                openChangePasswordPopup={openChangePasswordPopup}
                setOpenChangePasswordPopup={setOpenChangePasswordPopup}
                handleChangeUserPassword={handleChangePassword}
                handleCloseChangePassword={handleCloseChangePasswordPopup}
                userInfo={user}
                initValues={INIT_VALUE}
            />
        </>
    );
};
export default NavigationBar;
