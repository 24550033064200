import React from 'react';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';

const TextFieldPassword = ({ name, ...otherProps }) => {
    const [field, mata] = useField(name);

    const configTextfield = {
        ...field,
        ...otherProps,
        variant: 'outlined',
        type: 'Password',
        placeholder: '******',
    };

    if (mata && mata.touched && mata.error) {
        configTextfield.error = true;
        configTextfield.helperText = mata.error;
    }

    return <TextField {...configTextfield}></TextField>;
};

export default TextFieldPassword;
