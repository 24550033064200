import SendIcon from '@mui/icons-material/Send';
import { Box, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CommentType } from 'utils/StringDefinition';

const InputComment = (props) => {
    const user = useSelector((state) => state.user.current);
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);

    const [commentInput, setCommentInput] = useState(props?.commentContent || '');
    const { t } = useTranslation();

    const handleSubmitComment = async (value) => {
        let valueSubmit = {
            commentTitle: `${user.firstName} ${user.lastName} ： コメントを追加しました。`,
            commentBody: commentInput,
            commentType: CommentType.COMMENT,
            sheet: {
                id: sheetInfo.id,
                creator: sheetInfo.creator,
                editor: sheetInfo.editor,
                reviewer: sheetInfo.reviewer,
                sheetName: sheetInfo.sheetName,
                tool: sheetInfo.tool,
            },
        };
        setCommentInput('');

        if (props?.onSaveComment) {
            props?.onSaveComment(valueSubmit);
        }
    };

    useEffect(() => {
        setCommentInput(props?.commentContent);
    }, [props]);

    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pt: 1,
                pb: 1,
                borderBottom: '1px solid #d1d5db',
            }}
        >
            <TextField
                sx={{
                    width: '100%',
                    p: 0,
                }}
                multiline
                id="comment"
                placeholder={t('rightPanel.addComment')}
                InputProps={{
                    endAdornment: <SendIcon sx={{ cursor: 'pointer', color: '#02457a' }} onClick={handleSubmitComment} />,
                    style: {
                        padding: '10px',
                    },
                }}
                style={{ wordBreak: 'break-word' }}
                value={commentInput}
                onChange={(newValue) => setCommentInput(newValue.target.value)}
            />
        </Box>
    );
};

export default InputComment;
