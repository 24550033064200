//Description of errorList
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Card, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeSection } from 'store/reducers/calculatationSlice';
import { getDataSection, getListSectionsOfSheet } from 'store/reducers/sheetSlice';
// import { SectionNameTool001 } from 'U_UTILS/StringDefinition';
import { SectionNameTool001, SectionNameTool002 } from 'utils/StringDefinition';

export default function ErrorCard({ listSection, currentSection }) {
    const dispatch = useDispatch();
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);

    let toolCode = sheetInfo?.tool?.toolCode;

    const updateDataSection = useCallback(
        async (sectionIndex) => {
            await dispatch(getListSectionsOfSheet(sheetInfo?.id));
        },
        [sheetInfo, dispatch, getDataSection],
    );

    const handleChangeSection = useCallback(
        async (index) => {
            const action = changeSection(index);
            await dispatch(action);
            await updateDataSection(index);
        },
        [dispatch, updateDataSection],
    );

    return (
        <>
            {Array.isArray(listSection) &&
                listSection.map((item, index) => {
                    return (
                        <Card
                            onClick={(e) => handleChangeSection(index + 1)}
                            elevation={2}
                            sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: index + 1 === listSection?.length ? 0 : 1.25, p: 1 }}
                            key={item.sectionCode}
                            style={{
                                cursor: 'pointer',
                                height: '10%',
                                ...(currentSection === item.sectionCode && {
                                    border: `1px solid blue`,
                                }),
                            }}
                        >
                            <Grid item xs={10}>
                                <Typography variant="h5">
                                    {(() => {
                                        const sectionNameMap = {
                                            Tool001: SectionNameTool001,
                                            Tool002: SectionNameTool002,
                                            // Add more mappings for other tool codes as needed
                                        };

                                        const selectedSectionName = sectionNameMap[toolCode] || {};

                                        // Return the JSX content here
                                        return <div>{selectedSectionName[item.sectionCode] || 'セクション'}</div>;
                                    })()}
                                </Typography>
                            </Grid>

                            <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {item.status ? <CheckCircleIcon style={{ fontSize: '22px', color: '#55a630' }} /> : <WarningIcon style={{ fontSize: '22px', color: '#ff9f1c' }} />}
                            </Grid>
                        </Card>
                    );
                })}
        </>
    );
}
