import React from 'react';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { Box, Grid, List, Stack, Typography } from '@mui/material';
import TextFieldPassword from '../components/TextFieldPassword';
import ButtonSummit from '../components/ButtonSummit';
import Textfield from '../components/Textfield';

const INITIAL_FORM_STATE = {
    name: '',
    password: '',
    newPassword: '',
    confirmNewPassword: '',
};
const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().max(255).required('First Name is required'),
    password: Yup.string().max(255).required('Password is required'),
    newPassword: Yup.string().max(255).required('New password is required'),
    confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Your new password and comfirmation password must match')
        .required('Confirm New Password is required'),
});

function UserAccount(props) {
    return (
        <Grid container xs={8} sx={{ bgcolor: 'background.paper' }}>
            <Stack direction="column" justifyContent="center" alignItems="center" sx={{ marginLeft: '50px' }}>
                <List
                    sx={{
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        width: '1610px',
                        height: '860px',
                        '& ul': { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    <Formik
                        initialValues={{
                            ...INITIAL_FORM_STATE,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => {}}
                    >
                        <Form>
                            {/* The basiss */}
                            <Box
                                sx={{
                                    border: 1,
                                    borderRadius: '9px',
                                    borderColor: 'text.primary',
                                    height: 510,
                                    width: 1500,
                                }}
                            >
                                <Typography variant="h3" sx={{ marginTop: '10px', marginLeft: '15px' }}>
                                    Security
                                </Typography>

                                <Stack sx={{ marginLeft: 10, marginTop: 3 }}>
                                    <Stack>
                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            Name
                                        </Typography>
                                        <Textfield name="name" />

                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            Current password
                                        </Typography>
                                        <TextFieldPassword name="password" />

                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            New password
                                        </Typography>
                                        <TextFieldPassword name="newPassword" />

                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            Confirm password
                                        </Typography>
                                        <TextFieldPassword name="confirmNewPassword" />
                                    </Stack>
                                </Stack>
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    marginRight: '110px',
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'right',
                                }}
                            >
                                <ButtonSummit>SAVE</ButtonSummit>
                            </Box>
                        </Form>
                    </Formik>
                </List>
            </Stack>
        </Grid>
    );
}

export default UserAccount;
