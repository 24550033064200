// ==============================|| OVERRIDES - TYPOGRAPHY ||============================== //

export default function Typography() {
    return {
        MuiTypography: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'flex-end',
                },
            },
        },
    };
}
