export const PermissionsDefinition = {
    ADMIN: 'Administrator',
    EDITOR: 'Editor',
    REVIEWER: 'Reviewer',
};

export const CompanyDefinition = {
    NKE: 'NKE',
};

export const CommentType = {
    REQUEST: 'REQUEST',
    COMMENT: 'COMMENT',
    MARK_COMPLETED: 'MARK_COMPLETED',
};

export const SheetStatus = {
    ALL: 'All',
    EDITING: 'Editing',
    CHECKED: 'Checked',
    REVIEWING: 'Reviewing',
    COMPLETED: 'Completed',
};

export const SheetForgeStatus = {
    ALL: 'All',
    NEW: 'New',
    UNCONVERTED: 'Unconverted',
    REVIEWING: 'Reviewing',
    COMPLETED: 'Completed',
};

export const SectionNameTool001 = {
    Section001: '反力・移動・材質・取付関係等',
    Section002: 'チェーン取付部',
    Section003: '上部工ブラケット',
    Section004: 'ボルト位置',
    Section005: '主桁の補強材',
    Section006: '下部工ブラケット (縦型)',
};

export const SectionNameTool002 = {
    Section001: '設計条件',
    Section002: '下部工下調整プレートの設計',
    Section003: 'アンカーボルトの設計',
    Section004: '下部工ブラケット',
    Section005: '主桁補強材',
};

export const UserStatus = {
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
};

export const FieldStatus = {
    OK: 'OK',
    ERROR: 'エラー',
    NG: 'OUT',
};

export const UNIT = {
    kN: 'kN',
    kNdivBearingLine: 'kN / 1支承線',
    kNdivBasis: 'kN / 基',
    mmDivKatagawa: 'mm / 片側',
    Nmm2: 'Nmm2',
    kNmm2: 'kNmm2',
    N: 'N',
    hon: '本',
    kata: '型',
    basis: '基',
    mm: 'mm',
    m: 'm',
    do: '°',
    step: '段',
    column: '列',
    sheet: '枚',
    celsius: '°',
    hidden: 'hidden',
    degree: 'リンク',
    description1: '※SD345を標準とする',
    description2: '※ボルト仕様を確認の上入力のこと',
    links: 'links',
};
