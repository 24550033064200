import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextThreeInput from '../share-components/RowThreeInput';
import { InstructRow } from '../share-components/Tilte';
import image from './2-1.png';
import Table0201 from './Table0201';

const Block0201 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block006`];

    let rDivR1 = parseFloat(blockData?._U73?.value) / parseFloat(blockData?._U69?.value);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                {/* <SectionTitle title="チェーン取付部の計算" /> */}
                <div className="h-full w-full relative overflow-hidden flex items-start justify-center xl:flex-col 2xl:flex-row pt-3">
                    <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                        <img src={image} alt="block0201" height="375" />

                        <Typography className="absolute w-full flex top-[1.5rem] left-[0.7rem] text-sm text-center justify-center">
                            {isNaN(blockData?._U73?.value) ? 'd' : `d = ${parseFloat(blockData?._U73?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[13.5rem] left-[-11.75rem]  text-sm text-center -rotate-90">
                            {isNaN(blockData?._U72?.value) ? 'φ' : `φ = ${parseFloat(blockData?._U72?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[13.5rem] left-[-14.25rem]  text-sm text-center -rotate-90">
                            {isNaN(blockData?._U69?.value) ? 'd1' : `d1 = ${parseFloat(blockData?._U69?.value)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center top-[16.5rem] left-[-3.5rem] text-sm text-center">
                            {isNaN(blockData?._U69?.value) ? 'r1' : `r1 = ${(parseFloat(blockData?._U69?.value) / 2).toFixed(2)}`}
                        </Typography>

                        <Typography className="absolute w-full flex justify-center m-auto top-[16.5rem] left-[4.25rem] text-sm text-center rotate-45">
                            {isNaN(blockData?._U73?.value) ? 'r' : `r = ${parseFloat(blockData?._U73?.value) / 2}`}
                        </Typography>

                        <Typography className="absolute w-full flex top-[4.5rem] left-[1rem] text-sm text-center justify-center">b</Typography>
                        <Typography className="absolute w-full flex top-[11rem] left-[1rem] text-sm text-center justify-center">b</Typography>

                        <Typography className="absolute w-full flex top-[13rem] left-[9.5rem] text-sm text-center justify-center">a</Typography>
                        <Typography className="absolute w-full flex top-[13rem] left-[1.5rem] text-sm text-center justify-center">a</Typography>

                        <Typography className="absolute w-full flex top-[11rem] left-[5.75rem] text-sm text-center justify-center">点</Typography>
                        <Typography className="absolute w-full flex top-[11rem] left-[5rem] text-sm text-center justify-center">S</Typography>

                        <Typography className="absolute w-full flex top-[13rem] left-[12.5rem] text-sm text-center justify-center">T</Typography>
                    </article>
                    <Table0201 blockData={blockData} />
                </div>

                <InstructRow />

                <RowTextThreeInput
                    blockNumber="block006"
                    name="q69"
                    initValue={{ looking: blockData?._M69?.value, input: blockData?.q69?.value, calculate: blockData?._U69?.value }}
                    description="孔径"
                    hidden="input"
                    symbol={{ normalText: 'd1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block006"
                    name="q70"
                    initValue={{ looking: blockData?._M70?.value, input: blockData?.q70?.value, calculate: blockData?._U70?.value }}
                    description="トッププレートの板厚"
                    hidden="input"
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block006"
                    name="q71"
                    hidden="input"
                    description="補強板の板厚"
                    initValue={{ looking: blockData?._M71?.value, input: blockData?.q71?.value, calculate: blockData?._U71?.value }}
                    symbol={{ normalText: "t'", mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block006"
                    name="q72"
                    hidden="input"
                    description="シャックルのピン径"
                    initValue={{ looking: blockData?._M72?.value, input: blockData?.q72?.value, calculate: blockData?._U72?.value }}
                    tooltipText="d1 = φ + 4"
                    symbol={{ normalText: 'φ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block006"
                    name="q73"
                    hidden="input"
                    initValue={{ looking: blockData?._M73?.value, input: blockData?.q73?.value, calculate: blockData?._U73?.value }}
                    description="取付部全体径"
                    symbol={{ normalText: 'd', mathSymbol: '=' }}
                    tooltipText={` r/r1=${rDivR1.toFixed(2)}`}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0201;
