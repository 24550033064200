import { Card } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import RowSelectComponent from '../share-components/RowSelect';
import RowTextResult from '../share-components/RowTextResult';
import SectionTitle from '../share-components/Tilte';

const Block03 = (props) => {
    const { blockData } = props;

    const listBufferChainLinks = [
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 },
        { id: 11, value: 11 },
        { id: 12, value: 12 },
    ];

    return (
        <Card
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 0px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="取付関係" />
                {/* <RowTextInputComponent blockNumber="block003" name="aK18" description="緩衝チエーンリンク数" initValue={blockData?.aK18} unit={`${UNIT.degree}   ※標準は3とする`} /> */}
                <RowSelectComponent
                    blockNumber="block003"
                    name="aK18"
                    description="緩衝チエーンリンク数"
                    initValue={blockData?.aK18?.value}
                    listValue={listBufferChainLinks}
                    unit={`${UNIT.degree}   ※標準は3とする`}
                />
                <RowTextResult name="_AK20" initValue={blockData?._AK20?.value} description="緩衝チェーン長さ" unit={UNIT.mm} symbol={{ normalText: 'CLmax', mathSymbol: '=' }} />
                <RowTextInputComponent
                    blockNumber="block003"
                    name="aK23"
                    description="設置高さ（ピン間高さ）"
                    initValue={blockData?.aK23?.value}
                    symbol={{ normalText: 'H1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="_AK25"
                    description="緩衝チェーン作用角度"
                    initValue={blockData?._AK25?.value}
                    symbol={{ normalText: 'θ', mathSymbol: '=' }}
                    unit={UNIT.do}
                />
                <RowTextInputComponent
                    blockNumber="block003"
                    name="aK27"
                    description="取付時の緩衝チェーン角度"
                    initValue={blockData?.aK27?.value}
                    symbol={{ normalText: 'θ1', mathSymbol: '=' }}
                    unit={UNIT.do}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />
                <RowTextResult blockNumber="block003" description="緩衝チェーンの仕様" />
                <RowTextResult
                    blockNumber="block003"
                    paddingLeftDes={3}
                    name="r97"
                    description="緩衝チェーンタイプ"
                    initValue={blockData?.sheet2Data?.r97?.value}
                    // symbol={{ normalText: 'θ', mathSymbol: '=' }}
                    unit={UNIT.kata}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r98"
                    paddingLeftDes={3}
                    description="'強力チェーンリンク数"
                    initValue={blockData?.sheet2Data?.r98?.value}
                    symbol={{ normalText: 'N', mathSymbol: '=' }}
                    unit={UNIT.degree}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r99"
                    paddingLeftDes={3}
                    description="緩衝チェーン全長"
                    initValue={blockData?.sheet2Data?.r99?.value}
                    symbol={{ normalText: 'CL', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r100"
                    paddingLeftDes={3}
                    description="ショックレスチェーン側"
                    initValue={blockData?.sheet2Data?.r100?.value}
                    symbol={{ normalText: 'AL', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r101"
                    paddingLeftDes={3}
                    description="強力チェーン長 (CL - AL)"
                    initValue={blockData?.sheet2Data?.r101?.value}
                    symbol={{ normalText: 'BL', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r102"
                    paddingLeftDes={3}
                    description="リンク長"
                    initValue={blockData?.sheet2Data?.r102?.value}
                    symbol={{ normalText: 'DL', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />
                <RowTextResult blockNumber="block003" description="緩衝チェーンの取付寸法" />
                <RowTextResult
                    blockNumber="block003"
                    name="r105"
                    paddingLeftDes={3}
                    description="取付高さ"
                    initValue={blockData?.sheet2Data?.r105?.value}
                    symbol={{ normalText: 'H1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r106"
                    paddingLeftDes={3}
                    description="作用水平距離"
                    initValue={blockData?.sheet2Data?.r106?.value}
                    symbol={{ normalText: 'L1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r107"
                    paddingLeftDes={3}
                    description="実際の取り付け角度"
                    initValue={blockData?.sheet2Data?.r107?.value}
                    symbol={{ normalText: 'θ1', mathSymbol: '=' }}
                    unit={UNIT.celsius}
                />

                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 3 }} />

                <RowTextResult
                    blockNumber="block003"
                    name="r109"
                    paddingLeftDes={3}
                    description="L2	＝	(AL－DL) × Cosθ1"
                    initValue={blockData?.sheet2Data?.r109?.value}
                    symbol={{ normalText: '', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r110"
                    paddingLeftDes={3}
                    description="H2	＝	(AL－DL) × Sinθ1"
                    initValue={blockData?.sheet2Data?.r110?.value}
                    symbol={{ normalText: '', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r111"
                    paddingLeftDes={3}
                    description="H3	＝	H1 － H2"
                    initValue={blockData?.sheet2Data?.r111?.value}
                    symbol={{ normalText: '', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r112"
                    paddingLeftDes={3}
                    description="L3	＝	√((BL＋DL)² － H3²)"
                    initValue={blockData?.sheet2Data?.r112?.value}
                    symbol={{ normalText: '', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextResult
                    blockNumber="block003"
                    name="r113"
                    paddingLeftDes={3}
                    description="L4	＝	L1 － L2 － L3"
                    initValue={blockData?.sheet2Data?.r113?.value}
                    symbol={{ normalText: '', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block03;
