import Block01 from './Block01';
import Block02 from './Block02';
import Block03 from './Block03';

const Section01 = (props) => {
    return (
        <>
            <Block01 />
            <Block02 />
            <Block03 />
        </>
    );
};

export default Section01;
