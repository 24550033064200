import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import DoneIcon from '@mui/icons-material/Done';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RestoreIcon from '@mui/icons-material/Restore';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import CommentAPI from 'api/CommentAPI';
import SheetAPI from 'api/SheetAPI';
import { CustomToastComponent } from 'components/popup_components/CustomToastComponent';
import { ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCommentsBySheetId } from 'store/reducers/commentsSlice';
import { getListSectionsOfSheet, getSheetById } from 'store/reducers/sheetSlice';
import { CommentType, SheetStatus } from 'utils/StringDefinition';
import RequestReviewPopup from './RequestReviewPopup/RequestReviewPopup';

const CommentNav = (props) => {
    const user = useSelector((state) => state.user.current);
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const sheetId = sheetInfo?.id;
    const currentStatus = sheetInfo?.status;
    const countReviewing = sheetInfo?.countReviewing ?? 1;

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [openRequestReview, setOpenRequestReview] = useState(false);

    const isEditor = user.id === sheetInfo?.editor?.id;
    const isReviewer = user.id === sheetInfo.reviewer?.id;
    const isOne = user.id === sheetInfo.reviewer?.id && user.id === sheetInfo?.editor?.id;

    const sheetSectionsData = useSelector((state) => state.sheet.listSections);

    const listSection = sheetSectionsData?.data;

    const isTrueAll = listSection?.every((section) => section.status === true);

    const handleRequestReview = async () => {
        setOpenRequestReview(!openRequestReview);
    };

    const fetchSheetInfo = async () => {
        dispatch(getSheetById(sheetId));
        dispatch(getListSectionsOfSheet(sheetId));
        dispatch(getCommentsBySheetId(sheetId));
    };

    const handleRequestSubmit = async () => {
        let commentContent = {
            commentTitle: `${user.firstName} ${user.lastName} : ${t('changeSheetMode.requestReview')}`,
            commentType: CommentType.REQUEST,
            sheet: {
                id: sheetInfo.id,
                creator: sheetInfo.creator,
                editor: sheetInfo.editor,
                reviewer: sheetInfo.reviewer,
                sheetName: sheetInfo.sheetName,
                tool: sheetInfo.tool,
            },
        };

        const responseChangeStatus = await SheetAPI.changeSheetStatus(sheetInfo?.id, SheetStatus.REVIEWING);
        const responsePostComment = await CommentAPI.postComment(commentContent);

        if (responseChangeStatus.status === 200 && responsePostComment.status === 200) {
            dispatch(getCommentsBySheetId(sheetInfo.id));
            fetchSheetInfo();
            setOpenRequestReview(false);
            ToastShowSuccess(t('toastNoti.restore'));
        }
    };

    const handleToogleInputAndReview = async () => {
        const newStatus = [SheetStatus.EDITING, SheetStatus.CHECKED].includes(currentStatus) ? SheetStatus.REVIEWING : SheetStatus.EDITING;
        const commentTitleContent = newStatus === SheetStatus.REVIEWING ? t('changeSheetMode.inputToReview') : t('changeSheetMode.revertInput');
        let commentContent = {
            commentTitle: `${user.firstName} ${user.lastName} : ${commentTitleContent}`,
            commentType: CommentType.REQUEST,
            sheet: {
                id: sheetInfo.id,
                creator: sheetInfo.creator,
                editor: sheetInfo.editor,
                reviewer: sheetInfo.reviewer,
                sheetName: sheetInfo.sheetName,
                tool: sheetInfo.tool,
            },
        };

        const responseChangeStatus = await SheetAPI.changeSheetStatus(sheetInfo?.id, newStatus);
        const responsePostComment = await CommentAPI.postComment(commentContent);

        if (responseChangeStatus.status === 200 && responsePostComment.status === 200) {
            fetchSheetInfo();
            ToastShowSuccess(t('toastNoti.restore'));
        }
    };

    const handleRevertReview = async () => {
        let commentContent = {
            commentTitle: `${user.firstName} ${user.lastName} : ${t('changeSheetMode.revertReview')}`,
            commentType: CommentType.REQUEST,
            sheet: {
                id: sheetInfo.id,
                creator: sheetInfo.creator,
                editor: sheetInfo.editor,
                reviewer: sheetInfo.reviewer,
                sheetName: sheetInfo.sheetName,
                tool: sheetInfo.tool,
            },
        };

        const responseChangeStatus = await SheetAPI.changeSheetStatus(sheetInfo?.id, SheetStatus.REVIEWING);
        const responsePostComment = await CommentAPI.postComment(commentContent);

        if (responseChangeStatus.status === 200 && responsePostComment.status === 200) {
            fetchSheetInfo();
            ToastShowSuccess(t('toastNoti.restore'));
        }
    };

    const handleRevertInput = async () => {
        let commentContent = {
            commentTitle: `${user.firstName} ${user.lastName} : ${t('changeSheetMode.completedToInput')}`,
            commentType: CommentType.REQUEST,
            sheet: {
                id: sheetInfo.id,
                creator: sheetInfo.creator,
                editor: sheetInfo.editor,
                reviewer: sheetInfo.reviewer,
                sheetName: sheetInfo.sheetName,
                tool: sheetInfo.tool,
            },
        };

        const responseChangeStatus = await SheetAPI.changeSheetStatus(sheetInfo?.id, SheetStatus.EDITING);
        const responsePostComment = await CommentAPI.postComment(commentContent);

        if (responseChangeStatus.status === 200 && responsePostComment.status === 200) {
            fetchSheetInfo();
            ToastShowSuccess(t('toastNoti.restore'));
        }
    };

    const handleMarkCompleted = async () => {
        let commentContent = {
            commentTitle: `${user.firstName} ${user.lastName} : ${t('changeSheetMode.markCompleted')}`,
            commentType: CommentType.MARK_COMPLETED,
            sheet: {
                id: sheetInfo.id,
                creator: sheetInfo.creator,
                editor: sheetInfo.editor,
                reviewer: sheetInfo.reviewer,
                sheetName: sheetInfo.sheetName,
                tool: sheetInfo.tool,
            },
        };

        const responseChangeStatus = await SheetAPI.changeSheetStatus(sheetInfo?.id, SheetStatus.COMPLETED);
        const responsePostComment = await CommentAPI.postComment(commentContent);

        if (responseChangeStatus.status === 200 && responsePostComment.status === 200) {
            fetchSheetInfo();
            ToastShowSuccess(t('toastNoti.restore'));
        }
    };

    function RenderIconTogle() {
        switch (sheetInfo?.status) {
            case SheetStatus.EDITING:
            case SheetStatus.CHECKED:
                return countReviewing === 0 ? (
                    <Tooltip title={t('tooltip.sheetInputing')}>
                        <LockOpenIcon />
                    </Tooltip>
                ) : (
                    <IconButton onClick={handleToogleInputAndReview} disabled={sheetInfo?.status === SheetStatus.COMPLETED || !isReviewer}>
                        <Tooltip title={t('tooltip.sheetInputing')}>
                            <LockOpenIcon sx={{ cursor: 'pointer', color: '#02457a' }} />
                        </Tooltip>
                    </IconButton>
                );

            case SheetStatus.REVIEWING:
                return (
                    <IconButton onClick={handleToogleInputAndReview} disabled={sheetInfo?.status === SheetStatus.COMPLETED || !isReviewer}>
                        <Tooltip title={t('tooltip.openLock')}>
                            <LockIcon sx={{ cursor: 'pointer', color: '#02457a' }} />
                        </Tooltip>
                    </IconButton>
                );

            case SheetStatus.COMPLETED:
                return (
                    <Tooltip title={t('tooltip.sheetCompleted')}>
                        <LockIcon />
                    </Tooltip>
                );

            default:
                return <LockOpenIcon />;
        }
    }

    const iconToggle = RenderIconTogle();

    return (
        <Box
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                pb: 1,
                borderBottom: '1px solid #d1d5db',
            }}
        >
            {isEditor ? (
                <Box component="div" sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} justifyContent="space-between">
                    <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        {[SheetStatus.CHECKED, SheetStatus.EDITING].includes(sheetInfo?.status) ? (
                            <Tooltip title={t('tooltip.sheetInputing')}>
                                <LockOpenIcon />
                            </Tooltip>
                        ) : sheetInfo?.status === SheetStatus.REVIEWING ? (
                            <Tooltip title={t('tooltip.sheetReviewing')}>
                                <LockIcon />
                            </Tooltip>
                        ) : (
                            <Tooltip title={t('tooltip.sheetCompleted')}>
                                <LockIcon />
                            </Tooltip>
                        )}
                    </Box>
                    {[SheetStatus.CHECKED, SheetStatus.EDITING].includes(sheetInfo?.status) && isOne ? (
                        <Button variant="outlined" startIcon={<DoneIcon />} onClick={handleMarkCompleted} disabled={!isTrueAll}>
                            <Trans i18nKey="buttonName.btnMarkCompleted" />
                        </Button>
                    ) : [SheetStatus.CHECKED, SheetStatus.EDITING].includes(sheetInfo?.status) && isEditor ? (
                        <Button variant="outlined" startIcon={<ContentPasteSearchIcon />} onClick={handleRequestReview}>
                            <Trans i18nKey="buttonName.btnRequestReview" />
                        </Button>
                    ) : sheetInfo?.status === SheetStatus.COMPLETED && isOne ? (
                        <Button variant="outlined" startIcon={<RestoreIcon />} onClick={handleRevertInput} disabled={!isReviewer}>
                            <Trans i18nKey="buttonName.btnRevertInput" />
                        </Button>
                    ) : null}
                </Box>
            ) : (
                <Box component="div" sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} justifyContent="space-between">
                    <Box component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* {[SheetStatus.CHECKED, SheetStatus.EDITING].includes(sheetInfo?.status) ? (
                            // <Tooltip title="example">
                            <IconButton onClick={handleToggleStatus} disabled={sheetInfo?.status === SheetStatus.COMPLETED || !isReviewer ? true : false}>
                                <LockOpenIcon sx={{ cursor: 'pointer', color: '#02457a' }} />
                            </IconButton>
                        ) : (
                            <IconButton onClick={handleToggleStatus} disabled={sheetInfo?.status === SheetStatus.COMPLETED || !isReviewer ? true : false}>
                               <Tooltip title="example"> 
                                <LockIcon sx={{ cursor: 'pointer', color: '#02457a' }} />
                               </Tooltip> 
                            </IconButton> 
                        )}  */}
                        {iconToggle}
                    </Box>
                    {sheetInfo?.status === SheetStatus.COMPLETED ? (
                        <Button variant="outlined" startIcon={<RestoreIcon />} onClick={handleRevertReview} disabled={!isReviewer}>
                            <Trans i18nKey="buttonName.btnRevertReview" />
                        </Button>
                    ) : sheetInfo?.status === SheetStatus.REVIEWING ? (
                        <Button variant="outlined" startIcon={<DoneIcon />} onClick={handleMarkCompleted} disabled={sheetInfo?.status !== SheetStatus.REVIEWING || !isReviewer ? true : false}>
                            <Trans i18nKey="buttonName.btnMarkCompleted" />
                        </Button>
                    ) : null}
                </Box>
            )}
            <RequestReviewPopup openPopup={openRequestReview} setOpenPopup={setOpenRequestReview} handleRequest={handleRequestSubmit} />
            <CustomToastComponent />
        </Box>
    );
};

export default CommentNav;
