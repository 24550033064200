import Block0201 from './Block0201';

const Section02 = (props) => {
    return (
        <>
            <Block0201 />
        </>
    );
};

export default Section02;
