// third-party
import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// project import
import userReducer from './reducers/userSlice';
import userManagementSlice from './reducers/userManagementSlice';
import menuSlice from './reducers/menuSlice';
import toolsSlice from './reducers/toolsSlice';
import calculatationSlice from './reducers/calculatationSlice';
import NotificationSlice from './reducers/NotificationSlice';
import commentsSlice from './reducers/commentsSlice';
import sheetSlice from './reducers/sheetSlice';
import sheetForgeSlice from './reducers/sheetForgeSlice';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const rootReducer = combineReducers({
    notifications: NotificationSlice,
    usersManagement: userManagementSlice,
    comments: commentsSlice,
    sheet: sheetSlice,
    sheetForge: sheetForgeSlice,
    user: userReducer,
    menu: menuSlice,
    tools: toolsSlice,
    calculatationSection: calculatationSlice,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['sheet', 'user', 'calculatationSection'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    // reducer: rootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export default store;
