import { Typography } from '@mui/material';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';
import CellOfTable from './CellOfTable';
import leftArrow from './left-arrow.png';
import rightArrow from './right-arrow.png';

const Tablec125P133 = () => {
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);

    const blockData = sectionData?.data?.tool002version100?.[`block003`];
    const [arrayInput, setArrayInput] = useState(blockData?.c125P133);
    // const numberOfColumns = blockData?.c125P133.length() - 2 || 11;
    const arrHeader = blockData?.c125P133[0];
    const arrayLength = blockData?.c125P133.length;

    const dispatch = useDispatch();
    const cacheData = cacheStore((state) => state.cacheData);
    const setCacheData = cacheStore((state) => state.setCacheData);

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    // const columnTitle = arrayE125O125 && arrayE125O125.filter((item) => item.value !== '-');

    const handleChange = (rowIndex, elementIndex, event) => {
        const allowNegativeNumber = true;

        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = event.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            replaceElement(rowIndex, elementIndex, newValue);
        }
    };

    const handleBlur = (rowIndex, elementIndex, event) => {
        let newValue = event.target.value;

        const newArray = [...arrayInput];
        let rowValue = [...newArray[rowIndex]];

        rowValue[elementIndex] = {
            value: newValue === '' ? '' : parseFloat(newValue).toString(),
            type: 'number',
        };

        newArray[rowIndex] = rowValue;

        setArrayInput(newArray);

        // let blockDataChanged = {
        //     ...blockData,
        //     c125P133: newArray,
        // };

        let cacheDataChanged = {
            ...cacheData,
            c125P133: newArray,
        };

        setCacheData(cacheDataChanged);

        let sectionDataChanged = {
            ...sectionData.data,
            tool002version100: { ...sectionData.data?.tool002version100, block003: cacheDataChanged },
        };

        // console.log('🐳 ~ blockDataChanged:', blockDataChanged);

        if (!_.isEqual(blockData, cacheDataChanged)) {
            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            // const params = {
            //     sheetId: sheetId,
            //     section: `Section00${index}`,
            // };
            // dispatch(getDataSection(params));
            const dataResult = response.data;
            dispatch(setDataSection(dataResult));

            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const replaceElement = (rowIndex, elementIndex, newValue) => {
        const newArray = [...arrayInput]; // Create a copy of the original array
        let rowValue = [...newArray[rowIndex]]; // Create a copy of the row array

        rowValue[elementIndex] = {
            value: newValue,
        }; // Replace the element at the specified index
        newArray[rowIndex] = rowValue;

        setArrayInput(newArray); // Update the component's state with the new array
    };

    useEffect(() => {
        setArrayInput(blockData?.c125P133);
    }, [blockData]);

    return (
        <div className="sm:rounded-lg flex justify-start items-start flex-row">
            <div className="overflow-x-auto sm:rounded-lg flex justify-center items-start flex-col">
                <div className="w-full inline-block pl-1 pb-1 w">
                    <Typography sx={{ justifyContent: 'flex-start' }}>連結ボルト位置</Typography>
                </div>

                <div className="inline-block pl-1 pb-1">
                    <Typography sx={{ justifyContent: 'flex-start' }}>※せん断ストッパー中心とブラケット下端を (0、0) とする。</Typography>
                </div>

                <div className="w-full flex flex-row pl-[15%]">
                    <article className="h-full w-full relative overflow-hidden flex items-start">
                        <Typography className="absolute w-full text-sm pl-8">x方向（面方向）</Typography>
                        <img src={leftArrow} alt="leftArrow" width="162px" />
                    </article>
                </div>

                <table className="text-sm pb-3 w-full text-center">
                    <thead className="bg-white border-b dark:bg-gray-900 text-black text-center uppecase">
                        <tr className="h-[60px]">
                            {arrHeader &&
                                arrHeader?.map((item, index) => {
                                    return (
                                        <td className="w-[73.5px] border text-center border-solid border-gray-500 px-1 py-0.5" key={index}>
                                            <Typography sx={{ justifyContent: 'center' }}>
                                                {(() => {
                                                    const value = item?.value;
                                                    if (index === 0 || index === arrHeader.length - 1 || value === '中心') {
                                                        return value;
                                                    } else if (index < arrHeader.length / 2) {
                                                        return `${value} 左列目`;
                                                    } else if (index > arrHeader.length / 2) {
                                                        return `${value} 右列目`;
                                                    }
                                                })()}
                                            </Typography>
                                        </td>
                                    );
                                })}
                        </tr>
                    </thead>

                    <tbody className="border-b dark:bg-gray-900 dark:border-gray-700">
                        {arrayInput &&
                            arrayInput.map((row, rowIndex) => {
                                // Skip rendering the row if rowIndex is 0
                                if (rowIndex === 0) {
                                    return null;
                                }

                                const isBottom = rowIndex === arrayLength - 1;
                                const arrYIndex = [];

                                for (let i = arrayLength - 3; i >= 1; i--) {
                                    arrYIndex.push(i);
                                }

                                const data = arrayInput[rowIndex];

                                return (
                                    <tr className="h-[32px] odd:bg-[#d0fecd] hover:bg-sky-100" key={rowIndex}>
                                        {data &&
                                            data?.map((item, index, array) => {
                                                const isFirst = index === 0;
                                                const isLast = index === array.length - 1;
                                                //Nếu là dòng đầu tiên thì hiển thị những ô text bình thường

                                                if (isFirst) {
                                                    return (
                                                        <td className="w-[225px] border text-center border-solid border-gray-500 px-1 py-0.5" key={index}>
                                                            <Typography sx={{ justifyContent: 'center' }}>{item?.value}</Typography>
                                                        </td>
                                                    );
                                                } else if (isLast) {
                                                    return rowIndex > 1 && !isBottom ? (
                                                        <>
                                                            <td className="w-[75px] border text-center border-solid border-gray-500 px-1 py-0.5" key={index}>
                                                                <Typography sx={{ justifyContent: 'center' }}>{item?.value}</Typography>
                                                            </td>
                                                            <td className="w-[75px] border text-center border-solid border-gray-500 px-1 py-0.5" key={`arrYIndex_${index}`}>
                                                                <Typography sx={{ justifyContent: 'center' }}>{arrYIndex[rowIndex - 2]}</Typography>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <td className="w-[75px] border text-center border-solid border-gray-500 px-1 py-0.5" key={index}>
                                                            <Typography sx={{ justifyContent: 'center' }}>{item?.value}</Typography>
                                                        </td>
                                                    );
                                                } else {
                                                    return (
                                                        <td className="w-[73.5px] border text-center border-solid border-gray-500" key={`input_${index}`}>
                                                            {isBottom ? (
                                                                <Typography sx={{ justifyContent: 'center' }}>{item?.value}</Typography>
                                                            ) : (
                                                                <CellOfTable
                                                                    initValue={data[index]?.value ?? ''}
                                                                    rowIndex={rowIndex}
                                                                    index={index}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    canEdited={canEdited}
                                                                />
                                                            )}
                                                        </td>
                                                    );
                                                }
                                            })}
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
            </div>
            <div className="w-[100px] flex flex-row pt-44">
                <article className="h-full w-full relative overflow-hidden flex items-start justify-center">
                    <Typography className="absolute w-full flex justify-center top-[4rem] left-[-1rem] text-sm text-center -rotate-90">y方向 (縦方向)</Typography>
                    <img src={rightArrow} alt="Your SVG" width="20px" />
                </article>
            </div>
        </div>
    );
};

export default Tablec125P133;
