import axiosClient from './configs/axiosClient';

const NotificationAPI = {
    async GetNotificationsByUserId() {
        const url = `/notifications/getnotificationbyuserid`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async markAllAsRead() {
        const url = `/notifications/markAllAsRead`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
        });
    },

    async markRead(notificationId) {
        const url = `/notifications/markReadNotification/${notificationId}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
        });
    },
};

export default NotificationAPI;
