import { MenuItem, Select } from '@mui/material';
import { useField, useFormikContext } from 'formik';

const SelectComponent = ({ valueInit, name, fieldName, options, disable, ...otherProps }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(name);
    const handleChange = (e) => {
        setFieldValue(name, e.target.value);
    };

    // console.log(name, valueInit);

    // useEffect(() => {
    //     setFieldValue(name, options[0]?.id);
    // }, []);

    const configSelect = {
        ...field,
        ...otherProps,
        select: true,
        variant: 'outlined',
        fullWidth: true,
        onChange: handleChange,
    };

    if (meta && meta.touched && meta.error) {
        configSelect.error = true;
        configSelect.helperText = meta.error;
    }

    return (
        <Select onChange={handleChange} defaultValue={valueInit || options[0]?.id} disabled={disable}>
            {options &&
                options.map((item, index) => {
                    return (
                        <MenuItem key={item?.id} value={item?.id}>
                            {item[`${fieldName}`]}
                        </MenuItem>
                    );
                })}
        </Select>
    );
};

export default SelectComponent;
