import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import 'simplebar';
import 'simplebar/dist/simplebar.css';

import { Provider as ReduxProvider } from 'react-redux';

import './components/main_components/assets/third-party/apex-chart.css';

// project import
import ThemeCustomization from 'components/main_components/themes';
import { I18nextProvider } from 'react-i18next';
import { HashRouter } from 'react-router-dom';
import App from './App';
import i18n from './dictionary/i18n.js';
import store, { persistor } from './store';

import { PersistGate } from 'redux-persist/integration/react';

// ==============================|| APP - REACT DOM RENDER  ||============================== //

i18n.init({
    interpolation: { escapeValue: false }, // React already does escaping
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Fragment>
        <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <I18nextProvider i18n={i18n}>
                    <ThemeCustomization>
                        <HashRouter>
                            <App />
                        </HashRouter>
                    </ThemeCustomization>
                </I18nextProvider>
            </PersistGate>
        </ReduxProvider>
    </React.Fragment>,
);

// ReactDOM.render(
//     <React.Fragment>
//         <ReduxProvider store={store}>
//             <PersistGate loading={null} persistor={persistor}>
//                 <I18nextProvider i18n={i18n}>
//                     <ThemeCustomization>
//                         <HashRouter>
//                             <App />
//                         </HashRouter>
//                     </ThemeCustomization>
//                 </I18nextProvider>
//             </PersistGate>
//         </ReduxProvider>
//     </React.Fragment>,
//     document.getElementById('root'),
// );

// reportWebVitals();
