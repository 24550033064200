export const RouterConstants = {
    //HOME PAGE
    HOME_PAGE: '/',

    //AUTHENTICATION
    SIGN_IN: 'signin',
    RESET: 'reset',
    SIGNUP: 'signup',
    SINGUPSUCCESS: 'signup-success',
    NOT_FOUND: '404',

    //USER SETTING
    USER: 'user',
    PROFILE: 'profile',
    ACCOUNT: 'account',

    //MAIN SERVICES
    TOOL_DETAIL: 'tool-detail',
    MENU: 'menu',
    NEW_CALCULATION: 'new-calculation',
    VIEW_3D: 'view-3d',
    RECYCLE_BIN: 'recycle-bin',
    TOOL_MANAGEMENT: 'tool-management',

    USER_MANAGEMENT: 'user-management',

    //DETAIL SERVICES
    SHEET_DETAIL: 'sheet-detail',
    SHEET: 'sheet',
    SHOW_3D_MODEL: 'show-3d-model',
};
