import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

// material-ui
import { Grid, InputLabel, Stack, Typography } from '@mui/material';

import { MailOutlined } from '@ant-design/icons';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import UserAPI from 'api/UserAPI';
import { useNavigate } from 'react-router';

const INITIAL_FORM_STATE = {
    email: '',
};
const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string().email('有効な電子メールである必要があります。').max(255).required('必須'),
});

const AuthForgetPassword = () => {
    const [isSubmitted, setSubmit] = React.useState(false);
    const [forgotContent, setForgotContent] = React.useState('');
    const handleForgotPasswordSubmit = async (values) => {
        const response = await UserAPI.forgotPassword(values?.email);
        if (response.status === 200) {
            setSubmit(true);
            // console.log(response.data.errorMessage);
            if (response.data.errorMessage === 'Email Not Found!') {
                setForgotContent('メールは存在しません！');
            } else {
                setForgotContent('管理者宛にパスワード再発行のリクエストを送信しました。');
            }
        }
    };

    let navigate = useNavigate();
    const backToLoginPage = (value) => {
        navigate(`/signin`);
    };

    return (
        <>
            <Formik
                initialValues={{
                    ...INITIAL_FORM_STATE,
                }}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values) => {
                    console.log(values);
                    handleForgotPasswordSubmit(values);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sx={{ marginBottom: 2 }}>
                                <Stack spacing={1}>
                                    <InputLabel htmlFor="email-login" sx={{ color: 'black' }}>
                                        メール
                                    </InputLabel>
                                    <OutlinedInput
                                        id="email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        placeholder="メールアドレス"
                                        fullWidth
                                        error={touched.email && !!errors.email}
                                        sx={{
                                            '& legend': {
                                                display: 'none',
                                            },
                                            '& fieldset': {
                                                top: 0,
                                            },
                                        }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <MailOutlined />
                                            </InputAdornment>
                                        }
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error id="standard-weight-helper-text-email-login">
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Button fullWidth size="large" type="submit" variant="contained" sx={{ backgroundColor: '##02457a', width: '65%' }}>
                                    送信
                                </Button>
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width: '100%' }}>
                                {isSubmitted && (
                                    <>
                                        <Typography sx={{ marginBottom: 2 }}>{forgotContent}</Typography>
                                    </>
                                )}
                            </Grid>

                            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'row', width: '100%' }}>
                                <Typography variant="body1" sx={{ textDecoration: 'none', cursor: 'pointer', paddingBottom: 2 }} color="primary" align="left" onClick={backToLoginPage}>
                                    ログイン画面へ
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default AuthForgetPassword;
