import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextThreeInput from '../share-components/RowThreeInput';
import { InstructRow } from '../share-components/Tilte';
import image1 from './5-1.png';

const Block0501 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block009`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <article className="h-full w-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image1} alt="background" height="375" />

                    <Typography className="absolute w-full flex justify-center top-[1.25rem] left-[-6.5rem] text-sm text-center">
                        {isNaN(blockData?._T187?.value) ? 'L4' : `L4 = ${blockData?._T187?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[-12.75rem] text-sm text-center">
                        {isNaN(blockData?._T186?.value) ? 't4' : `t4 = ${blockData?._T186?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[1.25rem] left-[15.75rem] text-sm text-center">
                        {isNaN(blockData?._T185?.value) ? 'b' : `b = ${blockData?._T185?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[8.5rem] left-[23rem] text-sm -rotate-90">
                        {isNaN(blockData?._T184?.value) ? 'h4' : `h4 = ${blockData?._T184?.value}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[16rem] left-[5rem] text-sm -rotate-90">
                        {isNaN(blockData?._iH2?.value) ? 'h2' : `h2 = ${blockData?._iH2?.value}`}
                    </Typography>
                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[5rem] text-sm ">高力ボルト</Typography>
                    <Typography className="absolute w-full flex justify-center top-[3rem] left-[11rem] text-sm ">高力ボルト</Typography>

                    <Typography className="absolute w-full flex justify-center top-[1.25rem] left-[25rem] text-sm ">①溶接個所</Typography>
                    <Typography className="absolute w-full flex justify-center top-[14rem] left-[25rem] text-sm ">②溶接個所</Typography>
                </article>

                <InstructRow />

                <RowTextThreeInput
                    blockNumber="block009"
                    name="p184"
                    description="補強プレート高"
                    initValue={{ looking: blockData?._L184?.value, input: blockData?.p184?.value, calculate: blockData?._T184?.value }}
                    symbol={{ normalText: 'h4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block009"
                    name="p185"
                    description="補強プレート幅"
                    initValue={{ looking: blockData?._L185?.value, input: blockData?.p185?.value, calculate: blockData?._T185?.value }}
                    symbol={{ normalText: 'b', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block009"
                    name="p186"
                    description="補強プレート厚"
                    initValue={{ looking: blockData?._L186?.value, input: blockData?.p186?.value, calculate: blockData?._T186?.value }}
                    symbol={{ normalText: 't4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block009"
                    name="p187"
                    description="補強プレート間隔"
                    initValue={{ looking: blockData?._L187?.value, input: blockData?.p187?.value, calculate: blockData?._T187?.value }}
                    symbol={{ normalText: 'L4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block009"
                    name="p188"
                    description="ボルト本数"
                    initValue={{ looking: blockData?._L188?.value, input: blockData?.p188?.value, calculate: blockData?._T188?.value }}
                    symbol={{ normalText: 'n', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <RowTextThreeInput
                    blockNumber="block009"
                    name="p189"
                    description="すみ肉溶接"
                    initValue={{ looking: blockData?._L189?.value, input: blockData?.p189?.value, calculate: blockData?._T189?.value }}
                    symbol={{ normalText: 'S', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
            </Stack>
        </Card>
    );
};

export default Block0501;
