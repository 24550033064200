import { TextField, Typography } from '@mui/material';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const Tablei165I178 = ({ arrayValue }) => {
    const [arrayInput, setArrayInput] = useState(arrayValue);
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);

    const blockData = sectionData?.data?.tool002version100?.[`block004`];
    const numOfRows = parseFloat(blockData?.f166?.value) + 1;
    const dispatch = useDispatch();

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const handleChange = (index, event) => {
        const allowNegativeNumber = true;

        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = event.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            replaceElement(index, newValue);
        }
    };

    const handleBlur = (index, event) => {
        let newValue = event.target.value;

        const newArr = [...arrayInput]; // Create a copy of the original array
        newArr[index] = {
            value: newValue === '' ? '' : parseFloat(newValue).toString(),
            type: 'number',
        };

        let blockDataChanged = {
            ...blockData,
            i165I178: newArr,
        };

        let sectionDataChanged = {
            ...sectionData.data,
            tool002version100: { ...sectionData.data?.tool002version100, block004: blockDataChanged },
        };

        console.log('🐳 ~ sectionDataChanged:', sectionDataChanged);
        if (!_.isEqual(blockData, blockDataChanged)) {
            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;
            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const replaceElement = (index, newValue) => {
        const newArray = [...arrayInput]; // Create a copy of the original array
        newArray[index] = {
            value: newValue,
            type: 'number',
        }; // Replace the element at the specified index
        setArrayInput(newArray); // Update the component's state with the new array
    };
    useEffect(() => {
        setArrayInput(arrayValue);
    }, [arrayValue]);
    return (
        <>
            {arrayInput && (
                <div className="overflow-x-auto sm:rounded-lg flex justify-center items-start flex-col">
                    <div className="inline-block pl-1 pb-1">
                        <Typography>RIB位置</Typography>
                    </div>

                    <table className="text-sm pb-3 w-[85%]">
                        <thead className="bg-white border-b  dark:bg-gray-900 text-black text-center uppecase">
                            <tr className="h-[32px]">
                                <td colSpan={2} className="w-[135px] border border-solid border-gray-500 px-1 py-0.5">
                                    <Typography sx={{ justifyContent: 'center' }}>橋軸直角方向</Typography>
                                </td>
                            </tr>
                        </thead>

                        <tbody className="border-b dark:bg-gray-900 dark:border-gray-700">
                            {arrayInput &&
                                arrayInput.slice(0, numOfRows).map((element, index) => (
                                    <tr className="h-[32px] odd:bg-[#d0fecd] hover:bg-sky-100" key={index}>
                                        <td className="border border-solid border-gray-500 px-1 py-0.5 w-1/2">
                                            <Typography sx={{ justifyContent: 'center' }}>{index === 0 ? 'Base' : `RIB ${index}`}</Typography>
                                        </td>
                                        <td className="border text-right border-solid border-gray-500 px-1 py-0.5" key={index}>
                                            <TextField
                                                value={element?.value}
                                                onChange={(e) => handleChange(index, e)}
                                                onBlur={(e) => handleBlur(index, e)}
                                                onPaste={(e) => handleBlur(index, e)}
                                                placeholder="入力"
                                                disabled={canEdited}
                                                sx={{
                                                    '& .MuiInputBase-input.Mui-disabled': {
                                                        WebkitTextFillColor: '#272343',
                                                        cursor: 'not-allowed',
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        height: '32px',
                                                        padding: 0,
                                                    },
                                                }}
                                                inputProps={{ style: { textAlign: 'right' } }}
                                            ></TextField>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>

                    <div className="inline-block pl-1 pb-1">
                        <Typography>※便宜上、ブラケット中心からの距離を入力すること。</Typography>
                    </div>
                </div>
            )}
        </>
    );
};

export default Tablei165I178;
