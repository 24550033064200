import { SearchOutlined } from '@ant-design/icons';
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const SearchFile3DView = (props) => {
    const { t } = useTranslation();
    const { searchKeywords, handleSearchInput, searchStatus, openImportDialog, setOpenImportDialog } = props;

    const handleOnClick = (e) => {
        setOpenImportDialog(!openImportDialog);
    };

    return (
        <>
            {/* SearchBox */}
            <Box
                component="main"
                sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    pl: 2,
                    pr: 2,
                }}
            >
                <FormControl
                    sx={{
                        width: '30%',
                        pl: 2,
                    }}
                >
                    <OutlinedInput
                        size="large"
                        id="margin-normal"
                        startAdornment={
                            <InputAdornment
                                position="start"
                                sx={{
                                    mr: -0.5,
                                }}
                            >
                                <SearchOutlined />
                            </InputAdornment>
                        }
                        value={searchKeywords}
                        onChange={(e) => {
                            handleSearchInput(e.target.value, searchStatus);
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        sx={{
                            '& legend': {
                                display: 'none',
                            },
                            '& fieldset': {
                                top: 0,
                            },
                        }}
                        placeholder={t('calculationTable.searchBoxPalaceHolder')}
                    />
                </FormControl>
                <Button fullWidth size="large" type="submit" variant="contained" sx={{ backgroundColor: '##02457a', width: '10%' }} onClick={handleOnClick}>
                    Create New
                </Button>
            </Box>
        </>
    );
};

export default SearchFile3DView;
