import Block0501 from './Block0501';
import Block0502 from './Block0502';
import Block0503 from './Block0503';

const Section05 = (props) => {
    return (
        <>
            <Block0501 />
            <Block0502 />
            <Block0503 />
        </>
    );
};

export default Section05;
