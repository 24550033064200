import axiosClient from './configs/axiosClient';

const UserAPI = {
    //get list all tools
    async getListRoles() {
        const url = `651e4f6a-5b2f-4adb-b54d-68d4dbdf3b32`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async GetPermissions() {
        const url = `permissions`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async GetAllUsers() {
        const url = `users`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async GetReviewers() {
        const url = `users/reviewers`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async GetEditors() {
        const url = `users/editors`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async GetCompany() {
        const url = `companies`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async CreateNewUser(params) {
        const url = `users`;
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    async UpdateUser(formData, userId) {
        const url = `users/${userId}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },

    async ToggleUserStatus(userId) {
        const url = `users/toggleactive/${userId}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
        });
    },

    async ChangePassword(userId, params) {
        const url = `/users/changepassword/${userId}`;
        return axiosClient.request({
            method: 'PUT',
            url: url,
            data: params,
        });
    },

    async forgotPassword(email) {
        const url = `/users/forgotpassword/${email}`;
        return axiosClient.request({
            method: 'POST',
            url: url,
        });
    },
};

export default UserAPI;
