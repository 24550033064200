import { TextField, Tooltip, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const RowTextThreeSelect = (props) => {
    const { name, description, initValue, symbol, unit, allowNegativeNumber, tooltipText, blockNumber } = props;
    const sectionData = useSelector((state) => state.sheet.dataSection);
    var index = useSelector((state) => state.calculatationSection.index);
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const sheetId = sectionData?.data?.sheetId;
    const blockData = sectionData?.data?.version110?.[blockNumber];

    const listValue = [
        { id: 1, value: 22 },
        { id: 2, value: 25 },
        { id: 3, value: 29 },
        { id: 4, value: 32 },
        { id: 5, value: 35 },
        { id: 6, value: 38 },
        { id: 7, value: 41 },
    ];

    const [num, setNum] = React.useState(initValue?.input ?? 0);
    const dispatch = useDispatch();

    // console.log('initValue?.input ', initValue?.input);

    const handleChange = (e) => {
        let newValue = e.target.value;
        setNum(newValue);

        if (newValue !== initValue) {
            let dataChanged = {
                ...blockData,
                [name]: newValue,
            };
            // let sectionDataChanged = {
            //     ...sectionData.data,
            //     [blockNumber]: dataChanged,
            // };

            let sectionDataChanged = {
                ...sectionData.data,
                version100: { ...sectionData.data?.version100, [blockNumber]: dataChanged },
            };

            // console.log(sectionDataChanged);

            if (!_.isEqual(blockData, dataChanged)) {
                // console.log(blockData, dataChanged);
                updateData(sectionDataChanged);
            }
        }
    };

    // This effect will be called every time initValue changes.
    useEffect(() => {
        setNum(initValue?.input);
    }, [initValue?.input]);

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '35%', alignItems: 'center', marginLeft: 10 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '5%' }}>
                <Typography sx={{ width: '50%' }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>
                <Typography sx={{ width: '50%' }}>{symbol?.mathSymbol && ` ${symbol?.mathSymbol}`}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>
                {tooltipText ? (
                    <Tooltip title={tooltipText} placement="top-end">
                        <TextField
                            value={initValue?.looking ?? ''}
                            disabled={true}
                            onChange={handleChange}
                            // placeholder="標準値"
                            inputProps={{
                                style: { textAlign: 'right', cursor: 'not-allowed' },
                            }}
                            sx={{
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#272343',
                                },
                                '& .MuiInputBase-root': {
                                    backgroundColor: '#fdff9d',
                                    height: '32px',
                                    padding: 0,
                                },
                                width: '30%',
                            }}
                        ></TextField>
                    </Tooltip>
                ) : (
                    <TextField
                        value={initValue?.looking ?? ''}
                        disabled={true}
                        onChange={handleChange}
                        // placeholder="標準値"
                        inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed' } }}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: '#272343',
                            },
                            '& .MuiInputBase-root': {
                                backgroundColor: '#fdff9d',
                                height: '32px',
                                padding: 0,
                            },

                            width: '30%',
                        }}
                    ></TextField>
                )}

                <Select
                    defaultValue={initValue?.input || listValue[0].value}
                    disabled={canEdited}
                    value={num ?? ''}
                    onChange={handleChange}
                    sx={{
                        width: '30%',
                        textAlign: 'right',
                        backgroundColor: '#d2feff',
                        color: 'red',
                        height: '32px',
                        padding: 0,
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                    }}
                >
                    {listValue.map((item, index) => {
                        return (
                            <MenuItem key={item.id} value={item.value}>
                                {item.value}
                            </MenuItem>
                        );
                    })}
                </Select>

                <TextField
                    value={initValue?.calculate ?? ''}
                    disabled={true}
                    onChange={handleChange}
                    inputProps={{ style: { textAlign: 'right', cursor: 'not-allowed', color: 'white' } }}
                    sx={{
                        '& .MuiInputBase-root': {
                            backgroundColor: '#d0fecd',
                            height: '32px',
                            padding: 0,
                        },
                        '& .MuiInputBase-input': {
                            WebkitTextFillColor: 'black',
                        },
                        width: '30%',
                    }}
                    // placeholder="採用値"
                ></TextField>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 3 }}>
                <Typography>{unit}</Typography>
            </Box>
        </Box>
    );
};
export default RowTextThreeSelect;
