import { Select, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { getDataSection, getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';
import { ShowValue } from './ResultInRow';

const RowTwoInput = (props) => {
    const { description, unit, mathSymbol, valueLeft, tooltipLeft, physicSymbolRight, valueRight, tooltipRight, paddingLeftDes } = props;
    const paintColor = !['＜', '＞', '/'].includes(mathSymbol);

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '25%', alignItems: 'center', marginLeft: 10, paddingLeft: paddingLeftDes ? paddingLeftDes : 0 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: '75%' }}>
                <ShowValue isEqualTo={true} paintColor={paintColor} unit={unit} valueResult={valueLeft} resultTooltip={tooltipLeft} />
                <Typography sx={{ width: '10%', display: 'flex', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>
                <ShowValue physicSymbol={physicSymbolRight} isEqualTo={true} paintColor={paintColor} unit={unit} valueResult={valueRight} resultTooltip={tooltipRight} />
            </Box>
        </Box>
    );
};
export default RowTwoInput;

export const RowSelectAndInput = (props) => {
    const dispatch = useDispatch();

    const { name, description, defaultDisable, blockNumber, valueLeft, valueRight, paddingLeftDes } = props;

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id;

    const canEdited = defaultDisable ? defaultDisable : !(sheetInfo && [SheetStatus.EDITING, SheetStatus.CHECKED].includes(sheetInfo.status) && isEditor);

    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);
    const blockData = sectionData?.data?.tool002version100?.[blockNumber];
    // console.log(initValue);

    const [num, setNum] = useState(valueLeft);

    // This effect will be called every time initValue changes.
    useEffect(() => {
        setNum(valueLeft);
    }, [valueLeft]);

    // console.log('🚀 ~ sectionDataChanged:', valueLeft);

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        // console.log(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    const handleChange = (event) => {
        let newValue = event.target.value;

        if (newValue !== valueLeft) {
            let dataChanged = {
                ...blockData,
                [name]: { value: newValue.toString() },
            };

            let sectionDataChanged = {
                ...sectionData.data,
                tool002version100: { ...sectionData.data?.tool002version100, [blockNumber]: dataChanged },
            };
            console.log(sectionDataChanged.tool002version100.block003.f112);

            if (!_.isEqual(blockData, dataChanged)) {
                updateData(sectionDataChanged);
            }
        }
    };

    const listValue = [
        { id: 1, value: 18 },
        { id: 2, value: 20 },
        { id: 3, value: 22 },
        { id: 4, value: 24 },
        { id: 5, value: 27 },
        { id: 6, value: 30 },
        { id: 7, value: 33 },
        { id: 8, value: 36 },
        { id: 9, value: 39 },
        { id: 10, value: 42 },
        { id: 11, value: 45 },
        { id: 12, value: 48 },
        { id: 13, value: 52 },
        { id: 14, value: 56 },
        { id: 15, value: 60 },
        { id: 16, value: 64 },
        { id: 17, value: 68 },
        { id: 18, value: 72 },
        { id: 19, value: 76 },
        { id: 20, value: 80 },
    ];

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '33%', alignItems: 'center', marginLeft: 10, paddingLeft: paddingLeftDes ? paddingLeftDes : 0 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '7%' }}></Box>

            <Box component="div" sx={{ display: 'inline-block', width: '15%' }}>
                <Select
                    defaultValue={valueLeft ?? listValue[0]?.value}
                    value={valueLeft ?? listValue[0]?.value}
                    onChange={handleChange}
                    disabled={canEdited}
                    sx={{
                        textAlign: 'right',
                        height: '32px',
                        width: '100%',
                        padding: 0,
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                    }}
                >
                    {listValue.map((item, index) => {
                        return (
                            <MenuItem key={item.id} value={item.value}>
                                {`M${item.value}`}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '15%' }}>
                <TextField
                    disabled={true}
                    value={valueRight ?? ''}
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                        '& .MuiInputBase-root': {
                            height: '32px',
                            padding: 0,
                            backgroundColor: '#FFF2CC',
                        },
                    }}
                    inputProps={{ style: { textAlign: 'right' } }}
                ></TextField>
            </Box>
        </Box>
    );
};

export const RowInputAndInput = React.memo((props) => {
    const dispatch = useDispatch();

    const { name, description, defaultDisable, symbol, blockNumber, valueLeft, valueRight, paddingLeftDes, numDigitsAfterDot, allowNegativeNumber, unit } = props;

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const cacheData = cacheStore((state) => state.cacheData);
    const setCacheData = cacheStore((state) => state.setCacheData);
    var index = useSelector((state) => state.calculatationSection.index);
    const sectionData = useSelector((state) => state.sheet.dataSection);

    const sheetId = sectionData?.data?.sheetId;
    const isEditor = user?.id === sheetInfo?.editor?.id;
    const canEdited = defaultDisable ? defaultDisable : !(sheetInfo && [SheetStatus.EDITING, SheetStatus.CHECKED].includes(sheetInfo.status) && isEditor);
    const blockData = sectionData?.data?.tool002version100?.[blockNumber];

    const [num, setNum] = useState(valueLeft);

    // This effect will be called every time initValue changes.
    useEffect(() => {
        setNum(valueLeft);
    }, [valueLeft]);

    const handleChange = (e) => {
        // For positive numbers
        const regex = numDigitsAfterDot ? new RegExp(`^[0-9]*\\.?[0-9]{0,${numDigitsAfterDot}}$`) : /^[0-9]*\.?[0-9]*$/;

        // For negative numbers
        const regexNegativeNumber = numDigitsAfterDot ? new RegExp(`^-?[0-9]*\\.?[0-9]{0,${numDigitsAfterDot}}$`) : /^-?[0-9]*\.?[0-9]*$/;

        let newValue = e.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            setNum(newValue);
        }
    };

    function getTypeByName(name) {
        // Check if the name exists in the JSON object
        if (blockData.hasOwnProperty(name)) {
            // Extract the "type" based on the given "name"
            return blockData[name].type;
        } else {
            // Return an error message or handle the case when the name is not found
            return 'Name not found in the JSON object';
        }
    }

    const handleBlur = (e) => {
        let newValue = e.target.value;

        if (newValue === '.' || newValue === '-.' || newValue === '.-' || newValue === '-') {
            setNum('');
        } else {
            let cacheDataChanged = {
                ...cacheData,
                [name]: {
                    value: newValue === '' ? '' : parseFloat(newValue).toString(),
                    type: getTypeByName(name),
                },
            };

            setCacheData(cacheDataChanged);

            let sectionDataChanged = {
                ...sectionData.data,
                tool002version100: { ...sectionData.data?.tool002version100, [blockNumber]: cacheDataChanged },
            };

            if (!_.isEqual(blockData, cacheDataChanged)) {
                // console.log(blockData, dataChanged);
                updateData(sectionDataChanged);
            }
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            // console.log(response.data);

            const params = {
                sheetId: sheetId,
                section: `Section00${index}`,
            };
            dispatch(getDataSection(params));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '33%', alignItems: 'center', marginLeft: 10, paddingLeft: paddingLeftDes ? paddingLeftDes : 0 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '7%' }}>
                <Typography sx={{ width: symbol?.mathSymbol ? '70%' : '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>

                {symbol?.mathSymbol && <Typography sx={{ width: '30%' }}>{` ${symbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '15%' }}>
                <TextField
                    disabled={canEdited}
                    value={num ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onPaste={handleBlur}
                    placeholder={defaultDisable ? '' : '入力'}
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: defaultDisable ? '#FFF2CC' : '',
                            height: '32px',
                            padding: 0,
                        },
                    }}
                    inputProps={{ style: { textAlign: 'right' } }}
                ></TextField>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '15%' }}>
                <TextField
                    disabled={true}
                    value={valueRight ?? ''}
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                        '& .MuiInputBase-root': {
                            height: '32px',
                            padding: 0,
                            backgroundColor: '#FFF2CC',
                        },
                    }}
                    inputProps={{ style: { textAlign: 'right' } }}
                ></TextField>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', paddingLeft: 3 }}>
                <Typography>{unit}</Typography>
            </Box>
        </Box>
    );
});
