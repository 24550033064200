import { Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import RowTextInputComponent from '../share-components/RowInput';
import image1 from './4-1-1.png';
import Table0401 from './table/Table01';
import Table0402 from './table/Table02';
import Table0403 from './table/Table03';

const Block0401 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version110?.[`block008`];

    const boltPositionManualValueArr = blockData?.g160AE160;

    const boltPositionTypicalArr = blockData?._G157AE157;

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <article className="h-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image1} alt="background" height="500" />

                    {/* <Typography className="absolute w-full flex justify-center w-full flex justify-center top-[20rem] left-[-4rem] text-sm text-center"></Typography> */}

                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[-17rem] text-sm text-center`}>L6</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[-12.65rem] text-sm text-center`}>L5</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[-9.5rem] text-sm text-center`}>L4</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[-6.25rem] text-sm text-center`}>L3</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[-3rem] text-sm text-center`}>L2</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[-0.7rem] text-sm text-center`}>L1</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[0.85rem] text-sm text-center`}>L1'</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[3.35rem] text-sm text-center`}>L2'</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[6.5rem] text-sm text-center`}>L3'</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[9.5rem] text-sm text-center`}>L4'</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[12.75rem] text-sm text-center`}>L5'</Typography>
                    <Typography className={`absolute w-full flex justify-center top-[27.5rem] left-[17rem] text-sm text-center`}>L6'</Typography>
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[-17rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[0]?.value) ? '' : `${boltPositionManualValueArr[0]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[-12.65rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[1]?.value) ? '' : `${boltPositionManualValueArr[1]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[-9.5rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[2]?.value) ? '' : `${boltPositionManualValueArr[2]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[-6.25rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[3]?.value) ? '' : `${boltPositionManualValueArr[3]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[-3rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[4]?.value) ? '' : `${boltPositionManualValueArr[4]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[-0.7rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[5]?.value) ? '' : `${boltPositionManualValueArr[5]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[0.85rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[6]?.value) ? '' : `${boltPositionManualValueArr[7]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[3.35rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[7]?.value) ? '' : `${boltPositionManualValueArr[8]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[6.25rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[8]?.value) ? '' : `${boltPositionManualValueArr[9]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[9.5rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[9]?.value) ? '' : `${boltPositionManualValueArr[10]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[12.75rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[10]?.value) ? '' : `${boltPositionManualValueArr[11]?.value}`}
                        </Typography>
                    )}
                    {boltPositionManualValueArr && (
                        <Typography className={`absolute w-full flex justify-center top-[29rem] left-[17rem] text-sm text-center`}>
                            {isNaN(boltPositionManualValueArr[11]?.value) ? '' : `${boltPositionManualValueArr[12]?.value}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[0.75rem] left-[-10rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[0]?.value) ? 'y6' : `y6=${boltPositionTypicalArr[0]?.value}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[0.75rem] left-[10rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[12]?.value) ? 'y6' : `y6'=${boltPositionTypicalArr[12]?.value}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[3.5rem] left-[-7rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[1]?.value) ? 'y5' : `y5=${boltPositionTypicalArr[1]?.value}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[3.5rem] left-[7.5rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[11]?.value) ? 'y5' : `y5'=${boltPositionTypicalArr[11]?.value}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[6rem] left-[-5.25rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[2]?.value) ? 'y4' : `y4=${boltPositionTypicalArr[2]?.value}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[6rem] left-[6rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[10]?.value) ? 'y4' : `y4'=${boltPositionTypicalArr[10]?.value}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[8.75rem] left-[-3.9rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[3]?.value) ? 'y3' : `y3=${boltPositionTypicalArr[3]?.value}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[8.75rem] left-[4.25rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[9]?.value) ? 'y3' : `y3'=${boltPositionTypicalArr[9]?.value}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[11.35rem] left-[-2.5rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[4]?.value) ? 'y2' : `y2=${boltPositionTypicalArr[4]?.value}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[11.35rem] left-[2.5rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[8]?.value) ? 'y2' : `y2'=${boltPositionTypicalArr[8]?.value}`}
                        </Typography>
                    )}

                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[14rem] left-[-1.7rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[5]?.value) ? 'y1' : `y1=${boltPositionTypicalArr[5]?.value}`}
                        </Typography>
                    )}
                    {boltPositionTypicalArr && (
                        <Typography className={`absolute w-full flex justify-center top-[14rem] left-[1.75rem] text-sm text-center`}>
                            {isNaN(boltPositionTypicalArr[5]?.value) ? 'y1' : `y1'=${boltPositionTypicalArr[5]?.value}`}
                        </Typography>
                    )}
                </article>

                <Table0401 arrayValue={blockData?._G157AE157} />

                <Table0402 arrayValue1={blockData?.g160AE160} arrayValue2={blockData?._G162AE162} />

                <div className="pl-[7rem]">
                    <Typography color="error" sx={{ paddingLeft: '7%', marginBottom: '4px !important', marginTop: '4px !important' }}>
                        <strong>※手入力時、センタにボルトを入れる場合は１を入力する</strong>
                    </Typography>
                    {/* <RowTextInputComponent blockNumber="block008" name="numberOfBoltsPerRow" initValue={blockData?.numberOfBoltsPerRow} description="1列のボルト本数" /> */}
                    <RowTextInputComponent
                        blockNumber="block008"
                        name="_AY162"
                        initValue={blockData?._AY162?.value}
                        backgroundColor="#d0fecd"
                        widthTextField="65px"
                        defaultDisable={true}
                        description="手入力ボルト間隔の場合のトッププレート長"
                    />
                    <RowTextInputComponent
                        blockNumber="block008"
                        name="_AY163"
                        initValue={blockData?._AY163?.value}
                        backgroundColor="#d0fecd"
                        widthTextField="65px"
                        defaultDisable={true}
                        description="手入力ボルト間隔の場合のベースプレート長"
                    />
                </div>

                <Table0403 arrayValue1={blockData?._G164AE164} arrayValue2={blockData?._G165AE165} arrayCheck={blockData?.g160AE160} />
            </Stack>
        </Card>
    );
};

export default Block0401;
