import Block0301 from './Block0301';

const Section03 = (props) => {
    return (
        <>
            {/* {sectionContent.map((x) => (
                <Block01 key={x.id} content={x} />
            ))} */}
            <Block0301 />
        </>
    );
};

export default Section03;
