import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'simplebar-react/dist/simplebar.min.css';
import { getDataSection } from 'store/reducers/sheetSlice';
import Section03 from './Section03/Section03';
import Section01 from './section01/Section01';
import Section02 from './section02/Section02';
import Section04 from './section04/Section04';
import Section05 from './section05/Section05';

const Tool002V100 = (props) => {
    //Selectors optimization
    const sheetActive = useSelector((state) => state.sheet.sheetActive);
    const index = useSelector((state) => state.calculatationSection.index);
    const isLoading = useSelector((state) => state.calculatationSection.isLoading);

    const sheetId = sheetActive?.id;
    const section = useMemo(() => `Section00${index}`, [index]);

    const dispatch = useDispatch();

    //Callback memoization
    const initFetch = useCallback(() => {
        if (sheetId && section) {
            const params = { sheetId, section };
            dispatch(getDataSection(params));
        }
    }, [dispatch, section, sheetId]);

    //Component optimization
    const componentsMap = {
        1: <Section01 />,
        2: <Section02 />,
        3: <Section03 />,
        4: <Section04 />,
        5: <Section05 />,
    };

    const RenderSection = ({ index }) => {
        const Component = componentsMap[index] || <Section01 />;
        return Component;
    };

    //default props
    RenderSection.defaultProps = {
        index: 1,
    };

    const renderView = <RenderSection index={index} />;

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    return <>{renderView}</>;
};

export default Tool002V100;
