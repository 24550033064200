import { TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { getListSectionsOfSheet, setDataSection } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const RowTextInputComponent = (props) => {
    const dispatch = useDispatch();

    const { name, description, initValue, symbol, unit, allowNegativeNumber, defaultDisable, blockNumber, backgroundColor } = props;
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id;

    const canEdited = defaultDisable ? defaultDisable : !(sheetInfo && [SheetStatus.EDITING, SheetStatus.CHECKED].includes(sheetInfo.status) && isEditor);

    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var index = useSelector((state) => state.calculatationSection.index);

    const cacheData = cacheStore((state) => state.cacheData);
    const setCacheData = cacheStore((state) => state.setCacheData);
    const blockData = sectionData?.data?.version100?.[blockNumber];
    // console.log(initValue);

    const [num, setNum] = useState(initValue);

    // This effect will be called every time initValue changes.
    useEffect(() => {
        setNum(initValue);
    }, [initValue]);

    const handleChange = (e) => {
        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = e.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            setNum(newValue);
        }
    };

    const handleBlur = (e) => {
        let newValue = e.target.value;

        let dataChanged = {
            ...cacheData,
            [name]: newValue === '' ? '' : parseFloat(newValue).toString(),
        };

        setCacheData(dataChanged);

        let sectionDataChanged = {
            ...sectionData.data,
            version100: { ...sectionData.data?.version100, [blockNumber]: dataChanged },
        };

        if (!_.isEqual(blockData, dataChanged)) {
            console.log(blockData, dataChanged);
            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const dataResult = response.data;

            dispatch(setDataSection(dataResult));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '33%', alignItems: 'center', marginLeft: 10 }}>
                <Typography>{description}</Typography>
            </Box>

            <Box component="div" sx={{ display: 'flex', width: '7%' }}>
                <Typography sx={{ width: symbol?.mathSymbol ? '70%' : '100%', justifyContent: 'flex-end', paddingRight: 1.5 }}>
                    {symbol?.normalText}
                    {symbol?.subText && <sub>{symbol?.subText}</sub>}
                    {symbol?.supText && <sup>{symbol?.supText}</sup>}
                </Typography>

                {symbol?.mathSymbol && <Typography sx={{ width: '30%' }}>{` ${symbol?.mathSymbol}`}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '15%' }}>
                <TextField
                    disabled={canEdited}
                    value={num ?? ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: '#272343',
                            cursor: 'not-allowed',
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: backgroundColor && '#d0fecd',
                            height: '32px',
                            padding: 0,
                        },
                    }}
                    inputProps={{ style: { textAlign: 'right' } }}
                ></TextField>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', paddingLeft: 3 }}>
                <Typography>{unit}</Typography>
            </Box>
        </Box>
    );
};
export default RowTextInputComponent;
