export function applySortFilterTools({ listTools, comparator }) {
    const stabilizedThis = listTools.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    listTools = stabilizedThis.map((el) => el[0]);

    return listTools;
}

export const searchFilesFnc = (listValue, keyword) => {
    const searchKey = keyword.toLowerCase();
    return listValue.filter((file) => {
        const sheetName = file.sheetName.toLowerCase();
        const reviewerName = file.reviewerName.toLowerCase();
        const editorName = file.editorName.toLowerCase();

        if (sheetName.includes(searchKey) || reviewerName.includes(searchKey) || editorName.includes(searchKey)) {
            return true;
        }
        return false;
    });
};
