import numeral from 'numeral';

export function applySortFilterUsers({ listUsers, comparator }) {
    const stabilizedThis = listUsers.map((el, index) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    listUsers = stabilizedThis.map((el) => el[0]);

    return listUsers;
}

export const searchUserFnc = (listValue, keyword) => {
    const searchKey = keyword.toLowerCase();
    return listValue.filter((user) => {
        const firstName = user.firstName.toLowerCase();
        const lastName = user.lastName.toLowerCase();
        const email = user.email.toLowerCase();
        const companyName = user.company?.companyName.toLowerCase();
        const phoneNumber = String(user.phoneNumber).toLowerCase();

        if (firstName.includes(searchKey) || lastName.includes(searchKey) || email.includes(searchKey) || companyName.includes(searchKey) || phoneNumber.includes(searchKey)) {
            return true;
        }

        return false;
    });
};

export function fData(number) {
    return numeral(number).format('0.0 b');
}
