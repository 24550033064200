import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserAPI from 'api/UserAPI';

export const getListUsersRedux = createAsyncThunk('users/getListUsersRedux', async () => {
    try {
        const res = await UserAPI.GetAllUsers();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListUsersRedux!!!');
        return error.message;
    }
});

export const getPermissions = createAsyncThunk('users/getPermissions', async () => {
    try {
        const res = await UserAPI.GetPermissions();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getPermissions!!!');
        return error.message;
    }
});

export const getCompany = createAsyncThunk('users/getCompany', async () => {
    try {
        const res = await UserAPI.GetCompany();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name GetCompany!!!');
        return error.message;
    }
});

export const getReviewers = createAsyncThunk('users/getReviewers', async () => {
    try {
        const res = await UserAPI.GetReviewers();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name GetReviewers!!!');
        return error.message;
    }
});

export const getEditors = createAsyncThunk('users/getEditors', async () => {
    try {
        const res = await UserAPI.GetEditors();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getEditors!!!');
        return error.message;
    }
});

const userManagementSlice = createSlice({
    name: 'usersManagement',
    initialState: {
        listAllUsers: [],
        listPermissions: [],
        listCompanies: [],
        listReviewers: [],
        listEditors: [],
        status: 'idle',
        error: null,
        isLoading: false,
    },
    reducers: {
        searchToolByToolName: (state) => {
            console.log('state', state);
        },
    },
    extraReducers: {
        [getListUsersRedux.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListUsersRedux.fulfilled]: (state, action) => {
            state.listAllUsers = action.payload.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
            state.status = 'succeeded';
        },
        [getListUsersRedux.rejected]: (state, action) => {
            state.status = action.error.message;
        },
        [getPermissions.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getPermissions.fulfilled]: (state, action) => {
            state.listPermissions = action.payload;
            state.status = 'succeeded';
        },
        [getPermissions.rejected]: (state, action) => {
            state.status = action.error.message;
        },
        [getReviewers.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getReviewers.fulfilled]: (state, action) => {
            state.listReviewers = action.payload;
            state.status = 'succeeded';
        },
        [getReviewers.rejected]: (state, action) => {
            state.status = action.error.message;
        },
        [getEditors.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getEditors.fulfilled]: (state, action) => {
            state.listEditors = action.payload;
            state.status = 'succeeded';
        },
        [getEditors.rejected]: (state, action) => {
            state.status = action.error.message;
        },
        [getCompany.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getCompany.fulfilled]: (state, action) => {
            state.listCompanies = action.payload;
            state.status = 'succeeded';
        },
        [getCompany.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { actions, reducer } = userManagementSlice;
export const {} = actions;
export default reducer;
