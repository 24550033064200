import { TextField, Typography } from '@mui/material';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSection, getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { SheetStatus } from 'utils/StringDefinition';

const Table0702 = ({ arrayValue }) => {
    const [arrayInput, setArrayInput] = useState(arrayValue);
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var sectionIndex = useSelector((state) => state.calculatationSection.index);
    const blockData = sectionData?.data?.version100?.block011;

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id ? true : false;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const dispatch = useDispatch();

    const handleChange = (rowIndex, event, valueIndex) => {
        // console.log(rowIndex, event.target.value, valueIndex);
        const allowNegativeNumber = true;

        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = event.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            replaceElement(rowIndex, newValue, valueIndex);
        }
    };

    const replaceElement = (rowIndex, newValue, valueIndex) => {
        const newArr = arrayInput.slice();
        newArr[rowIndex] = arrayInput[rowIndex].slice();
        newArr[rowIndex][valueIndex] = newValue;

        // console.log('newArr ', newArr);
        setArrayInput(newArr); // Update the component's state with the new array
    };

    const handleBlur = (rowIndex, event, valueIndex) => {
        let newValue = event.target.value;
        const newArr = arrayInput.slice();
        newArr[rowIndex] = arrayInput[rowIndex].slice();
        newArr[rowIndex][valueIndex] = newValue;

        let blockDataChanged = {
            ...blockData,
            aA275AJ278: newArr,
        };
        // let sectionDataChanged = {
        //     ...sectionData.data,
        //     block011: blockDataChanged,
        // };

        let sectionDataChanged = {
            ...sectionData.data,
            version100: { ...sectionData.data?.version100, block011: blockDataChanged },
        };
        if (!_.isEqual(blockData, blockDataChanged)) {
            // console.log(blockData, blockDataChanged);
            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const params = {
                sheetId: sheetId,
                section: `Section00${sectionIndex}`,
            };
            dispatch(getDataSection(params));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    useEffect(() => {
        setArrayInput(arrayValue);
    }, [arrayValue]);

    return (
        <div className="w-full overflow-x-auto flex justify-start items-center">
            <div className="w-[100%] pb-3 ml-[7%]">
                <div className="pb-1">
                    <Typography sx={{ color: '#02457a' }}>
                        <strong>手入力値</strong>
                    </Typography>
                </div>

                <table className="w-full text-sm text-right pb-3">
                    <thead className="text-xs text-white text-left bg-sky-600 dark:bg-gray-700">
                        <tr className="h-[32px]">
                            <th scope="col" className="px-2 py-1"></th>
                            <th scope="col" colSpan={2} className="px-2 py-1 col-span-2">
                                <Typography sx={{ justifyContent: 'center' }}>L側アンカーボルト</Typography>
                            </th>

                            <th scope="col" colSpan={2} className="px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>R側アンカーボルト</Typography>
                            </th>
                        </tr>

                        <tr className="h-[32px]">
                            <th scope="col" className="px-2 py-1"></th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>X軸</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>Y軸</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>X軸</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>Y軸</Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {arrayInput &&
                            arrayInput.map((value, index) => (
                                <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100" key={index}>
                                    <td scope="row" className="px-2 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        <Typography>{index + 1}</Typography>
                                    </td>
                                    <td>
                                        <TextField
                                            name="leftAnchorBoltX"
                                            value={value[0]}
                                            onBlur={(e) => handleBlur(index, e, 0)}
                                            onChange={(e) => handleChange(index, e, 0)}
                                            disabled={canEdited}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                width: '100%',
                                                '& .MuiInputBase-root': {
                                                    height: '32px',
                                                    padding: 0,
                                                    backgroundColor: '#d2feff',
                                                },
                                            }}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                        ></TextField>
                                    </td>
                                    <td>
                                        <TextField
                                            name="leftAnchorBoltY"
                                            value={value[1]}
                                            onBlur={(e) => handleBlur(index, e, 1)}
                                            onChange={(e) => handleChange(index, e, 1)}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            disabled={canEdited}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    height: '32px',
                                                    padding: 0,
                                                    backgroundColor: '#d2feff',
                                                },
                                                width: '100%',
                                            }}
                                        ></TextField>
                                    </td>
                                    <td>
                                        <TextField
                                            name="rightAnchorBoltX"
                                            value={value[2]}
                                            onBlur={(e) => handleBlur(index, e, 2)}
                                            onChange={(e) => handleChange(index, e, 2)}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            disabled={canEdited}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    height: '32px',
                                                    padding: 0,
                                                    backgroundColor: '#d2feff',
                                                },
                                                width: '100%',
                                            }}
                                        ></TextField>
                                    </td>
                                    <td>
                                        <TextField
                                            name="rightAnchorBoltY"
                                            value={value[3]}
                                            onBlur={(e) => handleBlur(index, e, 3)}
                                            onChange={(e) => handleChange(index, e, 3)}
                                            inputProps={{ style: { textAlign: 'right' } }}
                                            disabled={canEdited}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    height: '32px',
                                                    padding: 0,
                                                    backgroundColor: '#d2feff',
                                                },
                                                width: '100%',
                                            }}
                                        ></TextField>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table0702;
