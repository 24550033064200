import PropTypes from 'prop-types';
// @mui
import { Card, ListSubheader, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/system';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { CompanyDefinition, SheetStatus } from 'utils/StringDefinition';
import ButtonGroup from './ButtonGroup';
import ErrorCard from './ErrorCard';
// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.overline,
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
}));

// ----------------------------------------------------------------------

NavCalculate.propTypes = {
    isCollapse: PropTypes.bool,
    navConfig: PropTypes.array,
};

export default function NavCalculate({ isCollapse = false, ...other }) {
    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const isCompleted = sheetInfo?.status === SheetStatus.COMPLETED ? true : false;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const user = useSelector((state) => state.user.current);
    const sheetSectionsData = useSelector((state) => state.sheet.listSections);
    const listSection = sheetSectionsData?.data;

    //fetch data
    const location = useLocation();
    const fileInfoInit = location?.state?.fileInfoInit;

    const sheetId = fileInfoInit?.id ?? sheetInfo?.id;
    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getListSectionsOfSheet(sheetId));
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const isNKEStaff = user.company?.companyName === CompanyDefinition.NKE;

    const currentIndex = useSelector((state) => state.calculatationSection.index);
    const currentSection = `Section00${currentIndex}`;

    return (
        <div className="h-[80%] w-full flex flex-col content-between">
            <Stack
                component="div"
                sx={{
                    display: 'flex',
                    flex: '1 1 auto',
                    height: '100%',
                    width: '100%',
                }}
            >
                <Box
                    sx={{
                        paddingBottom: 1.5,
                    }}
                >
                    <Typography variant="h5" color="initial">
                        {t('sideBarContent.sectionStatus')}
                    </Typography>
                </Box>

                <Box
                    sx={{
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <Card elevation={0} sx={{ bgcolor: '#f0efeb', p: 2 }}>
                        <ErrorCard listSection={listSection} currentSection={currentSection} />
                    </Card>
                </Box>
            </Stack>
            <Box
                sx={{
                    flex: '0 1 50px',
                    width: '100%',
                }}
            >
                <div style={{ p: 2, bgcolor: '#fafafa', marginTop: '15px', marginBottom: '5px' }}>
                    <ButtonGroup isCompleted={isCompleted} isNKEStaff={isNKEStaff} />
                </div>
            </Box>
        </div>
    );
}
