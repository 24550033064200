// material-ui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Button, Stack } from '@mui/material';
import { CustomToastComponent } from 'components/popup_components/CustomToastComponent';
import { ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router';

const linkDownload = ' https://ttd-share-document.s3.ap-northeast-1.amazonaws.com/HozenProject/%E7%B7%A9%E8%A1%9D%E3%83%81%E3%82%A7%E3%83%BC%E3%83%B3_3D_DWG.dwg';

// ==============================|| DRAWER CONTENT - BUTTON GROUP ||============================== //
function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

function ButtonView3D(props) {
    const { isCompleted, isNKEStaff } = props;
    let navigate = useNavigate();

    const handleGoBackMenu = () => {
        navigate(`/view-3d`);
    };

    const handleDownloadSheet = async () => {
        // const response = await ForgeAPI.downloadDWG();

        // const outputFilename = `${sheetInfo?.sheetName}.dwg`;

        // fileDownload(response.data, outputFilename);
        // console.log('handleDownloadSheet');
        // sleep(2000);
        // ToastShowSuccess('Download file successfully.');
        sleep(2000).then(() => {
            ToastShowSuccess('ファイルが正常にアップロードされました。');
        });
    };

    return (
        <Stack sx={{ display: 'flex', alignItem: 'flex-end', width: '100%' }}>
            <div className="w-full flex justify-center">
                <Button variant="contained" sx={{ width: '70%', marginBottom: '10px' }} onClick={handleDownloadSheet} href={linkDownload}>
                    <FileDownloadIcon sx={{ pr: 0.5 }} />
                    <Trans i18nKey="buttonName.btnDownload3D" />
                </Button>
            </div>

            <div className="w-full flex justify-center">
                <Button variant="contained" sx={{ width: '70%', marginBottom: '10px' }} onClick={handleDownloadSheet} href={linkDownload}>
                    <FileDownloadIcon sx={{ pr: 0.5 }} />
                    <Trans i18nKey="buttonName.btnDownload2D" />
                </Button>
            </div>

            <div className="w-full flex justify-center">
                <Button variant="contained" sx={{ width: '70%', marginBottom: '10px' }} disabled={!isCompleted || !isNKEStaff}>
                    <ImportExportIcon sx={{ pr: 0.5 }} />
                    <Trans i18nKey="buttonName.btnExportCSV" />
                </Button>
            </div>

            <div className="w-full flex justify-center">
                <Button variant="contained" sx={{ width: '70%' }} onClick={handleGoBackMenu}>
                    <ArrowBackIcon sx={{ pr: 0.5 }} />
                    <Trans i18nKey="buttonName.btnCancel" />
                </Button>
            </div>

            <CustomToastComponent />
        </Stack>
    );
}

export default ButtonView3D;
