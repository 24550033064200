// ==============================|| OVERRIDES - LIST ITEM ICON ||============================== //

export default function ListItemIcon() {
    return {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 28,
                    paddingRight: 5,
                },
            },
        },
    };
}
