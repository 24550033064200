import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { cacheStore } from 'store/bearStore';
import { UNIT } from 'utils/StringDefinition';
import RowTextInputComponent from '../share-components/RowInput';
import SectionTitle from '../share-components/Tilte';

const Block0402 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block004`];

    const setCacheData = cacheStore((state) => state.setCacheData);

    useEffect(() => {
        setCacheData(blockData);
    }, [blockData]);

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="上フランジプレートに関する寸法" />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f181"
                    description="上フランジプレート厚さ"
                    initValue={blockData?.f181?.value}
                    symbol={{ normalText: 't4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent
                    blockNumber="block004"
                    name="f183"
                    description="面直角方向 (橋軸方向) 検討時の有効幅"
                    initValue={blockData?.f183?.value}
                    symbol={{ normalText: 'λ', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextInputComponent blockNumber="block004" name="f185" description="リブの内間隔" initValue={blockData?.f185?.value} symbol={{ normalText: 'L2', mathSymbol: '=' }} unit={UNIT.mm} />

                <RowTextInputComponent blockNumber="block004" name="f186" description="左からの距離" initValue={blockData?.f186?.value} symbol={{ normalText: 'a', mathSymbol: '=' }} unit={UNIT.mm} />
                <RowTextInputComponent blockNumber="block004" name="f187" description="右からの距離" initValue={blockData?.f187?.value} symbol={{ normalText: 'b', mathSymbol: '=' }} unit={UNIT.mm} />
            </Stack>
        </Card>
    );
};

export default Block0402;
