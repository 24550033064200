import { Typography } from '@mui/material';
import { Box } from '@mui/system';

const SectionTitle = (props) => {
    const { title } = props;

    return (
        <Box component="div" sx={{ display: 'inline-block', alignItems: 'center', marginLeft: 3, marginTop: 2.5, marginBottom: 1.5 }}>
            <Typography style={{ color: '#02457a' }} variant="h5">
                <strong>{title}</strong>
            </Typography>
        </Box>
    );
};
export default SectionTitle;

export const SectionTitleSub = (props) => {
    const { title, paddingLeftFit } = props;

    return (
        <Box component="div" sx={{ display: 'inline-block', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 4 : 4, marginTop: 1.5, marginBottom: 1.5 }}>
            <Typography style={{ color: '#02457a' }}>
                <strong>{title}</strong>
            </Typography>
        </Box>
    );
};

export const TableTitle = (props) => {
    const { title } = props;

    return (
        <Box component="div" sx={{ display: 'inline-block', alignItems: 'center', marginLeft: 4, marginBottom: 1.5, marginTop: 1.5 }}>
            <Typography variant="h4" style={{ color: '#02457a' }}>
                {title}
            </Typography>
        </Box>
    );
};

export const InstructRow = () => {
    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '35%', alignItems: 'center', marginLeft: 10 }}></Box>

            <Box component="div" sx={{ display: 'inline-block', width: '5%' }}></Box>

            <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', width: '40%' }}>
                <Typography sx={{ width: '30%', justifyContent: 'center' }}>標準値</Typography>
                <Typography sx={{ width: '30%', justifyContent: 'center' }}>手入力値</Typography>
                <Typography sx={{ width: '30%', justifyContent: 'center' }}>採用値</Typography>
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 10 }}></Box>
        </Box>
    );
};
