import { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import { RouterConstants } from 'utils/constants/RouterConstants';
import ForgotPassword from './ForgotPassword';
import SignIn from './SignIn';
import SignUpSuccess from './SignUpSuccess';

function Authentication(props) {
    const changeMode = props.changeMode;

    let location = useLocation();
    let navigate = useNavigate();
    let arr = [RouterConstants.SIGN_IN, RouterConstants.RESET, RouterConstants.SINGUPSUCCESS];
    useEffect(() => {
        if (!arr.includes(location.pathname)) {
            navigate(`/#`);
        }
    }, []);

    return (
        <div style={{ width: '100wh', height: '100vh' }}>
            <Routes>
                <Route path="/" element={<SignIn changeMode={changeMode} />} />
                <Route path={`/${RouterConstants.SIGN_IN}`} element={<SignIn changeMode={changeMode} />} />
                <Route path={`/${RouterConstants.RESET}`} element={<ForgotPassword changeMode={changeMode} />} />
                <Route path={`/${RouterConstants.SINGUPSUCCESS}`} element={<SignUpSuccess />} />
            </Routes>
        </div>
    );
}

export default Authentication;
