import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Box, Grid, List, Stack, Typography } from '@mui/material';
import AvatarComponent from 'pages/user-setting/components/AvatarComponent';
import ButtonSummit from 'pages/user-setting/components/ButtonSummit';
import DataTimePickerComponent from 'pages/user-setting/components/DataTimePickerComponent';
import SelectComponent from 'pages/user-setting/components/SelectComponent';
import Textfield from 'pages/user-setting/components/Textfield';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const INITIAL_FORM_STATE = {
    name: '',
    imgLink: 'https://www.nj.com/resizer/zovGSasCaR41h_yUGYHXbVTQW2A=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/SJGKVE5UNVESVCW7BBOHKQCZVE.jpg',
    birthDay: new Date(),
    jobtitile: 1,
    company: '',
    phoneNumber: '',
    email: '',
};
const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string().max(255).required('First Name is required'),
    email: Yup.string().email('Must be a valid email').max(255).required('Please enter a vaild email address'),
    company: Yup.string().max(255).required('Please enter your company'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Please enter your company'),
});
function UserProfile(props) {
    return (
        <Grid container xs={8} sx={{ bgcolor: 'background.paper' }}>
            <Stack direction="column" justifyContent="center" alignItems="center" sx={{ marginLeft: '50px' }}>
                <List
                    sx={{
                        bgcolor: 'background.paper',
                        position: 'relative',
                        overflow: 'auto',
                        width: '1610px',
                        height: '860px',
                        '& ul': { padding: 0 },
                    }}
                    subheader={<li />}
                >
                    <Formik
                        initialValues={{
                            ...INITIAL_FORM_STATE,
                        }}
                        validationSchema={FORM_VALIDATION}
                        onSubmit={(values) => {}}
                    >
                        <Form>
                            {/* The basiss */}
                            <Box
                                sx={{
                                    border: 1,
                                    borderRadius: '9px',
                                    borderColor: 'text.primary',
                                    height: 470,
                                    width: 1500,
                                }}
                            >
                                <Typography variant="h3" sx={{ marginTop: '10px', marginLeft: '15px' }}>
                                    The Basics
                                </Typography>

                                <Stack sx={{ marginLeft: 10, marginTop: 3 }}>
                                    <AvatarComponent name={'imgLink'}></AvatarComponent>

                                    <Stack>
                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            Name
                                        </Typography>
                                        <Textfield name="name" />

                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            Day of birth
                                        </Typography>
                                        <DataTimePickerComponent name="birthDay" />
                                    </Stack>
                                </Stack>
                            </Box>

                            {/* Professional */}
                            <Box
                                sx={{
                                    border: 1,
                                    borderRadius: '9px',
                                    borderColor: 'text.primary',
                                    height: 260,
                                    width: 1500,
                                    marginTop: '20px',
                                }}
                            >
                                <Typography variant="h3" sx={{ marginTop: '10px', marginLeft: '15px' }}>
                                    Professional Info
                                </Typography>

                                <Stack sx={{ marginLeft: 10, marginTop: 2 }}>
                                    <Stack spacing={1}>
                                        <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                            Job Titile
                                        </Typography>
                                        <SelectComponent
                                            name="jobtitile"
                                            options={['Engineer', 'Manager', 'Design']}
                                            selectedValue={INITIAL_FORM_STATE.jobtitile}
                                            placeholder="Job Title"
                                        ></SelectComponent>
                                    </Stack>

                                    <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                        Company
                                    </Typography>
                                    <Textfield name="company" />
                                </Stack>
                            </Box>

                            {/* Contact */}
                            <Box
                                sx={{
                                    border: 1,
                                    borderRadius: '9px',
                                    borderColor: 'text.primary',
                                    height: 290,
                                    width: 1500,
                                    marginTop: '20px',
                                }}
                            >
                                <Typography variant="h3" sx={{ marginTop: '10px', marginLeft: '15px' }}>
                                    Contract
                                </Typography>

                                <Stack sx={{ marginLeft: 10, marginTop: 2 }}>
                                    <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                        Phone Number
                                    </Typography>
                                    <Textfield name="phoneNumber" />

                                    <Typography variant="h5" sx={{ marginTop: '15px' }}>
                                        Email
                                    </Typography>
                                    <Textfield name="email" />
                                </Stack>
                            </Box>

                            <Box
                                sx={{
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                    marginRight: '90px',
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    alignItems: 'right',
                                }}
                            >
                                <ButtonSummit>SAVE</ButtonSummit>
                            </Box>
                        </Form>
                    </Formik>
                </List>
            </Stack>
        </Grid>
    );
}

export default UserProfile;
