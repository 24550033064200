import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

const AvatarComponent = ({ name, ...otherProps }) => {
    const [field] = useField(name);
    const { setFieldValue } = useFormikContext();
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [preview, setPreview] = useState(null);

    const handleFileSelect = (event) => {
        // console.log(event.target.files[0]);
        setSelectedFile(event.target.files[0]);
    };

    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined);
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl);
    }, [selectedFile]);

    return (
        <Box>
            <Typography variant="h5">Photo</Typography>

            <Stack direction="row" alignItems="flex-end">
                {!!preview ? (
                    <img src={preview} style={{ width: '250px', height: '180px' }} alt="avatar"></img>
                ) : (
                    <img src={field.value} style={{ width: '250px', height: '180px' }} alt="avatar"></img>
                )}
                <Button variant="contained" component="label" sx={{ width: '150px', height: '35px', padding: '6px', marginLeft: '30px' }}>
                    Upload New Picture
                    <input
                        name="imgLink"
                        type="file"
                        hidden
                        accept="image/png, image/gif, image/jpeg"
                        onChange={(event) => {
                            setFieldValue('imgLink', event.currentTarget.files[0]);
                            handleFileSelect(event);
                        }}
                    />
                    {/* <input type="file" hidden accept="image/png, image/gif, image/jpeg" name="imgLink" /> */}
                </Button>
            </Stack>
        </Box>
    );
};

export default AvatarComponent;
