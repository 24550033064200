import axiosClient from './configs/axiosClient';

const SheetForgeAPI = {
    async createSheetForgeAPI(params) {
        const url = `/sheetForge`;
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: params,
        });
    },

    async getListSheetForge() {
        const url = `/sheetForge`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getSheetForgeById(sheetId) {
        const url = `/sheetForge/${sheetId}`;
        return axiosClient.request({
            method: 'GET',
            url: url,
        });
    },

    async getUploadJSONForge(formData, sheetId) {
        const url = `/sheetForge/upload/${sheetId}`;
        return axiosClient.request({
            method: 'POST',
            url: url,
            data: formData,
        });
    },

    async deleteSheetForge(sheetId) {
        const url = `//sheetForge/delete/${sheetId}`;
        return axiosClient.request({
            method: 'DELETE',
            url: url,
        });
    },
};

export default SheetForgeAPI;
