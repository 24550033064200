import React from 'react';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const StyledContainer = styled(ToastContainer)`
    &&&.Toastify__toast-container {
    }
    .Toastify__toast--success {
        background: #5bb318;
    }
    .Toastify__toast--error {
        background: #ec7272;
    }
`;

export const CustomToastComponent = () => {
    return <StyledContainer style={{ width: '500px' }} />;
};
