import Block0201 from './Block0201';
import Block0202 from './Block0202';
import Block0203 from './Block0203';

const Section02 = (props) => {
    return (
        <>
            <Block0201 />
            <Block0202 />
            <Block0203 />
        </>
    );
};

export default Section02;
