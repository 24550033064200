// material-ui
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import PrintAPI from 'api/PrintAPI';
import SheetAPI from 'api/SheetAPI';
import fileDownload from 'js-file-download';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

// ==============================|| DRAWER CONTENT - BUTTON GROUP ||============================== //
function ButtonGroup(props) {
    const { isCompleted, isNKEStaff } = props;
    let navigate = useNavigate();

    const handleGoBackMenu = () => {
        navigate(`/menu`);
    };

    const [downloading, setDownloading] = useState(false);
    const [PdfDownloading, setPdfDownloading] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState();

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);

    const handleDownloadSheet = async () => {
        setDownloading(true);
        const response = await SheetAPI.downloadSheet(sheetInfo?.id);

        const outputFilename = `${sheetInfo?.sheetName}.xlsx`;

        fileDownload(response.data, outputFilename);
        setDownloading(false);
    };

    const handlePrintPDF = async () => {
        setPdfDownloading(true);
        const response = await PrintAPI.printPDF(sheetInfo?.id);

        if (response.status === 200) {
            const outputFilename = `${sheetInfo?.sheetName}.pdf`;

            fileDownload(response.data, outputFilename);
            setPdfDownloading(false);
            setDownloadStatus(200);
        } else {
            setDownloadStatus(400);
        }
    };

    return (
        <Stack sx={{ display: 'flex', alignItem: 'flex-end', width: '100%' }}>
            {isNKEStaff && downloading ? (
                <div className="w-full flex justify-center">
                    <LoadingButton loading loadingPosition="start" sx={{ width: '70%', marginBottom: '10px' }} variant="outlined">
                        <FileDownloadIcon sx={{ pr: 0.5 }} />
                        <Trans i18nKey="buttonName.btnDownload" />
                    </LoadingButton>
                </div>
            ) : (
                <div className="w-full flex justify-center">
                    <Button variant="contained" sx={{ width: '70%', marginBottom: '10px' }} onClick={handleDownloadSheet} disabled={!isCompleted || !isNKEStaff}>
                        <FileDownloadIcon sx={{ pr: 0.5 }} />
                        <Trans i18nKey="buttonName.btnDownload" />
                    </Button>
                </div>
            )}

            {isNKEStaff && PdfDownloading ? (
                <div className="w-full flex justify-center">
                    <LoadingButton loading loadingPosition="start" sx={{ width: '70%', marginBottom: '10px' }} variant="outlined">
                        <PrintIcon sx={{ pr: 0.5 }} />
                        <Trans i18nKey="buttonName.btnPrint" />
                    </LoadingButton>
                </div>
            ) : (
                <>
                    <div className="w-full flex justify-center">
                        <Button variant="contained" sx={{ width: '70%', marginBottom: '10px' }} onClick={handlePrintPDF} disabled={!isCompleted}>
                            <PrintIcon sx={{ pr: 0.5 }} />
                            <Trans i18nKey="buttonName.btnPrint" />
                        </Button>
                    </div>
                </>
            )}

            <div className="w-full flex justify-center">
                <Button variant="contained" sx={{ width: '70%' }} onClick={handleGoBackMenu}>
                    <ArrowBackIcon sx={{ pr: 0.5 }} />
                    <Trans i18nKey="buttonName.btnCancel" />
                </Button>
            </div>
        </Stack>
    );
}

export default ButtonGroup;
