import React from 'react';
import { useField } from 'formik';

import TextField from '@mui/material/TextField';

const Textfield = ({ name, ...otherProps }) => {
    const [field, mata] = useField(name);

    const configTextfield = {
        ...field,
        ...otherProps,
        variant: 'outlined',
    };

    if (mata && mata.touched && mata.error) {
        configTextfield.error = true;
        configTextfield.helperText = mata.error;
    }

    return <TextField {...configTextfield} />;
};

export default Textfield;
