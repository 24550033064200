import Block0401 from './Block0401';

const Section04 = (props) => {
    return (
        <>
            {/* {sectionContent.map((x) => (
                <Block01 key={x.id} content={x} />
            ))} */}
            <Block0401 />
        </>
    );
};

export default Section04;
