import { yupResolver } from '@hookform/resolvers/yup';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { t } from 'i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';
import * as Yup from 'yup';

export default function ChangePasswordPopup(props) {
    const { openChangePasswordPopup, setOpenChangePasswordPopup, handleChangeUserPassword } = props;

    const validationSchema = Yup.object().shape({
        password: Yup.string().required(t('signup.firstNameRequired')),
        confirmPassword: Yup.string()
            .required(t('signup.firstNameRequired'))
            .when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: () => Yup.string().oneOf([Yup.ref('password')], t('dialogContent.mustMatchPassword')),
            }),
    });

    const {
        register,

        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    const [showPassword, setShowPassword] = useState(true);

    const [showConfirmPassword, setShowConfirmPassword] = useState(true);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = (data) => {
        handleChangeUserPassword(data);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleClose = () => {
        setOpenChangePasswordPopup(false);
    };

    return (
        <>
            <Dialog open={openChangePasswordPopup} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle align="center">{t('dialogContent.changePassword')}</DialogTitle>
                <DialogContent sx={{ marginTop: 3 }}>
                    <Typography>{t('dialogContent.newPassword')}</Typography>

                    <TextField
                        required
                        id="password"
                        name="password"
                        fullWidth
                        margin="dense"
                        {...register('password')}
                        error={errors.password ? true : false}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ color: '#02457a', marginRight: 0 }}>
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" onClick={handleClickShowPassword} style={{ cursor: 'pointer', color: '#02457a' }}>
                                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </InputAdornment>
                            ),
                            autoComplete: 'new-password',
                        }}
                    />
                    <Typography variant="inherit" color="error">
                        {errors.password?.message}
                    </Typography>

                    <Typography sx={{ paddingTop: 2 }}>{t('dialogContent.confirmPassword')}</Typography>
                    <TextField
                        required
                        id="confirmPassword"
                        name="confirmPassword"
                        fullWidth
                        margin="dense"
                        {...register('confirmPassword')}
                        type={showConfirmPassword ? 'text' : 'password'}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{ color: '#02457a', marginRight: 0 }}>
                                    <LockIcon />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="end" onClick={handleClickShowConfirmPassword} style={{ cursor: 'pointer', color: '#02457a' }}>
                                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </InputAdornment>
                            ),
                            autoComplete: 'new-password',
                        }}
                    />
                    <Typography variant="inherit" color="error">
                        {errors.confirmPassword?.message}
                    </Typography>
                </DialogContent>

                <DialogActions style={{ padding: '12px 24px 12px 24px', backgroundColor: '#f2f2f4' }}>
                    <Button variant="outlined" onClick={handleClose}>
                        <Trans i18nKey="sheetDialog.btnCancel" />
                    </Button>
                    <Button autoFocus onClick={handleSubmit(onSubmit)} variant="contained" color="success" form="myForm">
                        {t('dialogContent.btnChange')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
