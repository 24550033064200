import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import ViewerForge from './3DView/Viewer';

// ==============================|| Show3DModel||============================== //

const Show3DModel = () => {
    const location = useLocation();
    // const dispatch = useDispatch();
    const fileInfoInit = location?.state?.fileInfoInit;
    //fetch data
    const sheetCurrent = useSelector((state) => state.sheet.sheetActive);

    const currentId = fileInfoInit?.id ?? sheetCurrent.id;
    // //fetch data
    // const initFetch = useCallback(() => {
    //     dispatch(getSheetById(currentId));
    // }, [dispatch]);

    // useEffect(() => {
    //     initFetch();
    // }, [initFetch]);

    return <ViewerForge sheetId={currentId} />;
};

export default Show3DModel;
