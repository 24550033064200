import { Box, Button, DialogActions, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
// Component
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import TextFieldFormik from 'pages/user-management/components/UserDialog/TextFieldFormik';
import SelectCompany, { SelectPermission } from 'pages/user-setting/components/SelectComponent';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CompanyDefinition } from 'utils/StringDefinition';
import { UploadAvatar } from './upload';

function getDifference(array1, array2) {
    return array1.filter((object1) => {
        return !array2.some((object2) => {
            return object1.id === object2.id;
        });
    });
}

const RegisterForm = (props) => {
    const { changeMode, userInfo, userPopupMode, onSubmitUserForm, onClose, editUserSetting } = props;
    const { t } = useTranslation();

    const listPermissions = useSelector((state) => state.usersManagement.listPermissions);

    const listCompanies = useSelector((state) => state.usersManagement.listCompanies);

    // console.log(listCompanies);

    const [showPassword, setShowPassword] = useState(false);
    const [showComfirmPassword, setShowComfirmPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [files, setFiles] = useState([]);

    const NEW_USER_INFO = {
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: new Date(),
        company: listCompanies[0],
        phoneNumber: '',
        password: '',
        permission: listPermissions[0]?.permissionName,
        confirmPassword: '',
        submit: null,
    };

    const handleClickShowComfirmPassword = () => {
        setShowComfirmPassword(!showComfirmPassword);
    };

    const handleMouseEvents = (event) => {
        event.preventDefault();
    };

    const NEW_USER_SCHEMA = Yup.object().shape({
        firstName: Yup.string()
            .max(255)
            .required(<Trans i18nKey="signup.firstNameRequired" />),
        lastName: Yup.string()
            .max(255)
            .required(<Trans i18nKey="signup.lastNameRequired" />),
        email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required(<Trans i18nKey="signup.emailRequired" />),
        password: Yup.string()
            .max(255)
            .required(<Trans i18nKey="signup.passwordRequired" />),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], <Trans i18nKey="signup.passwordMustMatch" />)
            .required(<Trans i18nKey="signup.confirmPasswordRequired" />),
    });

    const EDIT_USER_SCHEMA = Yup.object().shape({
        firstName: Yup.string()
            .max(255)
            .required(<Trans i18nKey="signup.firstNameRequired" />),
        lastName: Yup.string()
            .max(255)
            .required(<Trans i18nKey="signup.lastNameRequired" />),
        email: Yup.string()
            .email(<Trans i18nKey="signup.emailRequired" />)
            .max(255)
            .required(<Trans i18nKey="signup.emailRequired" />),
    });

    // const handleChangePermission = (event) => {
    //     const {
    //         target: { value },
    //     } = event;

    //     console.log('value', value);
    //     setPersonName(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value,
    //     );
    // };

    return (
        <>
            <Formik
                initialValues={userPopupMode === 1 ? NEW_USER_INFO : userInfo}
                validationSchema={userPopupMode === 1 ? NEW_USER_SCHEMA : EDIT_USER_SCHEMA}
                onSubmit={async (values) => {
                    const formData = new FormData();
                    formData.append('UserInfo', JSON.stringify({ ...values, email: values?.email.toLowerCase() }));

                    if (files[0]) {
                        formData.append('Images', files[0]);
                    }

                    console.log(formData);
                    onSubmitUserForm(formData, userInfo?.id);
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, submitForm, resetForm, isSubmitting, touched, values }) => (
                    <Form>
                        {/* {console.log('Error Formik :', errors, null, 2)} */}
                        <Grid container spacing={3} sx={{ m: 0, width: '100%' }}>
                            <Box sx={{ width: '100%', display: 'inline-block', paddingLeft: 3, paddingRight: 3 }}>
                                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', columnGap: 3 }}>
                                    <Stack spacing={1} sx={{ gridRow: 'span 2', gridColumn: '1' }}>
                                        <UploadAvatar
                                            name="avatarUrl"
                                            files={files}
                                            setFiles={setFiles}
                                            userInfo={userInfo}
                                            helperText={
                                                <Typography
                                                    variant="caption"
                                                    sx={{
                                                        mt: 2,
                                                        mx: 'auto',
                                                        display: 'block',
                                                        textAlign: 'center',
                                                        color: 'text.secondary',
                                                    }}
                                                >
                                                    {t('signup.allowedFile')}
                                                </Typography>
                                            }
                                        />
                                    </Stack>

                                    <Stack spacing={1} sx={{ paddingTop: 1 }}>
                                        <InputLabel htmlFor="firstname-signup">
                                            <Trans i18nKey="signup.firstName" />
                                        </InputLabel>
                                        <TextFieldFormik name="firstName" type="text" placeholder={t('signup.firstName')} />
                                        {touched.firstname && errors.firstname && (
                                            <FormHelperText error id="helper-text-firstname-signup">
                                                {errors.firstname}
                                            </FormHelperText>
                                        )}
                                    </Stack>

                                    <Stack spacing={1} sx={{ paddingTop: 1 }}>
                                        <InputLabel htmlFor="lastname-signup">
                                            <Trans i18nKey="signup.lastName" />
                                        </InputLabel>

                                        <TextFieldFormik name="lastName" type="text" placeholder={t('signup.lastName')} />
                                        {touched.lastname && errors.lastname && (
                                            <FormHelperText error id="helper-text-lastname-signup">
                                                {errors.lastname}
                                            </FormHelperText>
                                        )}
                                    </Stack>

                                    <Stack spacing={1} sx={{ paddingTop: 2 }}>
                                        <InputLabel htmlFor="email-signup">
                                            <Trans i18nKey="signup.emailAdress" />
                                        </InputLabel>

                                        <TextFieldFormik name="email" type="text" placeholder={t('signup.emailAdress')} inputProps={{ autoComplete: 'new-email' }} disabled={editUserSetting} />
                                        {/* {touched.email && errors.email && (
                                            <FormHelperText error id="helper-text-email">
                                                {errors.email}
                                            </FormHelperText>
                                        )} */}
                                    </Stack>

                                    <Stack spacing={1} sx={{ paddingTop: 2 }}>
                                        {/* <InputLabel htmlFor="dateOfBirth">
                                            <Trans i18nKey="signup.dateOfBirth" />
                                        </InputLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={values.dateOfBirth ?? new Date()}
                                                inputFormat="DD/MM/YYYY"
                                                onChange={(newValue) => {
                                                    // setValue(newValue);
                                                    setFieldValue('dateOfBirth', moment(new Date(newValue)).format());
                                                }}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider> */}
                                        {/* Permissions */}
                                        <Grid item sm={12} sx={{ width: '100%' }}>
                                            <InputLabel htmlFor="company-signup" sx={{ paddingBottom: 1 }}>
                                                <Trans i18nKey="signup.company" />
                                            </InputLabel>
                                            <SelectCompany
                                                options={listCompanies}
                                                name="company"
                                                valueInit={userInfo?.company ? userInfo?.company : listCompanies[0]}
                                                editUserSetting={editUserSetting}
                                            ></SelectCompany>
                                        </Grid>
                                    </Stack>
                                </Box>

                                {userPopupMode === 1 ? (
                                    <Grid item container sm={12} columnSpacing={3}>
                                        <Grid item sm={6} sx={{ paddingTop: 2 }}>
                                            <InputLabel htmlFor="password-signup" sx={{ paddingBottom: 1 }}>
                                                <Trans i18nKey="signup.password" />
                                            </InputLabel>

                                            <OutlinedInput
                                                sx={{
                                                    '& legend': {
                                                        display: 'none',
                                                    },
                                                    '& fieldset': {
                                                        top: 0,
                                                    },
                                                }}
                                                fullWidth
                                                error={Boolean(touched.password && errors.password)}
                                                id="password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={values.password}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                autoComplete="on"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseEvents}
                                                            onMouseUp={handleMouseEvents}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder="******"
                                                inputProps={{ autoComplete: 'new-password' }}
                                            />
                                            {touched.password && errors.password && (
                                                <FormHelperText error id="helper-text-password-signup">
                                                    {errors.password}
                                                </FormHelperText>
                                            )}
                                        </Grid>

                                        <Grid item sm={6} sx={{ width: '90%', paddingTop: 2 }}>
                                            <InputLabel htmlFor="confirmPassword-signup" sx={{ paddingBottom: 1 }}>
                                                <Trans i18nKey="signup.confirmPassword" />
                                            </InputLabel>
                                            <OutlinedInput
                                                sx={{
                                                    '& legend': {
                                                        display: 'none',
                                                    },
                                                    '& fieldset': {
                                                        top: 0,
                                                    },
                                                }}
                                                fullWidth
                                                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                                id="confirmPassword"
                                                type={showComfirmPassword ? 'text' : 'password'}
                                                value={values.confirmPassword}
                                                name="confirmPassword"
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                autoComplete="on"
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowComfirmPassword}
                                                            onMouseDown={handleMouseEvents}
                                                            onMouseUp={handleMouseEvents}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showComfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                                placeholder="******"
                                            />
                                            {touched.confirmPassword && errors.confirmPassword && (
                                                <FormHelperText error id="helper-text-comfirmpassword-signup">
                                                    {errors.confirmPassword}
                                                </FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                ) : null}

                                <Grid item container sm={12} columnSpacing={3}>
                                    <Grid item sm={6} sx={{ paddingTop: 2 }}>
                                        <InputLabel htmlFor="phoneNumber" sx={{ paddingBottom: 1 }}>
                                            <Trans i18nKey="signup.phoneNumber" />
                                        </InputLabel>

                                        <TextFieldFormik name="phoneNumber" type="text" placeholder={t('signup.phoneNumber')} />
                                    </Grid>

                                    <Grid item sm={6} sx={{ width: '90%', paddingTop: 2 }}>
                                        <Stack spacing={1}>
                                            <InputLabel htmlFor="job-signup">
                                                <Trans i18nKey="signup.role" />
                                            </InputLabel>

                                            {/* <Autocomplete
                                            multiple
                                            options={getDifference(listPermissions, values?.permissions)}
                                            getOptionLabel={(option) => t(`permissions.${option.permissionName}`)}
                                            value={values?.permissions ?? null}
                                            filterSelectedOptions
                                            onChange={(event, newValue) => {
                                                setFieldValue('permissions', newValue);
                                            }}
                                            disabled={editUserSetting}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => {
                                                    return (
                                                        <Chip
                                                            sx={{
                                                                borderRadius: 1,
                                                                backgroundColor:
                                                                    option.permissionName === PermissionsDefinition.ADMIN
                                                                        ? '#2e7d32'
                                                                        : option.permissionName === PermissionsDefinition.REVIEWER
                                                                        ? '#ed6c02'
                                                                        : '#1976d2',
                                                                color: 'white',
                                                                '& > .MuiChip-deleteIcon': {
                                                                    color: 'white',
                                                                    '&:hover': {
                                                                        color: '#e1e1e1',
                                                                    },
                                                                },
                                                            }}
                                                            variant="outlined"
                                                            label={option ? t(`permissions.${option.permissionName}`) : ''}
                                                            {...getTagProps({ index })}
                                                        />
                                                    );
                                                })
                                            }
                                            renderInput={(params) => <TextField {...params} />}
                                        /> */}

                                            {/* <FormControl>
                                            <Select
                                                value={values?.permissions?.permissionName}
                                                onChange={handleChangePermission}
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                        {selected.map((value) => (
                                                            <Chip
                                                                key={value}
                                                                label={t(`permissions.${selected}`)}
                                                                sx={{
                                                                    borderRadius: 1,
                                                                    backgroundColor:
                                                                        selected === PermissionsDefinition.ADMIN ? '#2e7d32' : selected === PermissionsDefinition.REVIEWER ? '#ed6c02' : '#1976d2',
                                                                    color: 'white',
                                                                    '& > .MuiChip-deleteIcon': {
                                                                        color: 'white',
                                                                        '&:hover': {
                                                                            color: '#e1e1e1',
                                                                        },
                                                                    },
                                                                    width: '150px',
                                                                }}
                                                            />
                                                        ))}

                                                        {console.log('selected', selected)}
                                                    </Box>
                                                )}
                                            >
                                                {listPermissions.map((per) => (
                                                    <MenuItem key={per.id} value={per.permissionName}>
                                                        {t(`permissions.${per.permissionName}`)}
                                                    </MenuItem>
                                                ))}

                                                {console.log(values?.permissions[0]?.permissionName)}
                                            </Select>
                                        </FormControl> */}

                                            <SelectPermission
                                                options={values?.company?.companyName == CompanyDefinition.NKE ? listPermissions : listPermissions.slice(0, -1)}
                                                name="permission"
                                                valueInit={userInfo?.permission ? userInfo?.permission : listPermissions[0]?.permissionName}
                                                editUserSetting={editUserSetting}
                                            ></SelectPermission>

                                            {touched.permission && errors.permission && <FormHelperText error>{errors.permission}</FormHelperText>}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>

                            <DialogActions style={{ padding: '12px 24px 12px 24px', marginTop: '24px', backgroundColor: '#f2f2f4', width: '100%' }}>
                                <Button variant="outlined" onClick={onClose}>
                                    <Trans i18nKey="sheetDialog.btnCancel" />
                                </Button>

                                <Button onClick={submitForm} sx={{ width: '125px' }} variant="contained" form="myForm">
                                    {userPopupMode === 1 ? <Trans i18nKey="signup.btnCreate" /> : <Trans i18nKey="signup.btnUpdate" />}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default RegisterForm;
