import { Card } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import SectionTitle, { SectionTitleSub } from '../share-components/Tilte';

const Block0503 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block005`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="各照査事項" />
                <SectionTitleSub title="①フランジ溶接部照査" />

                <RowTextComponent
                    isEqualTo={true}
                    description="1) 両側すみ肉溶接"
                    physicSymbolLeft={{ normalText: 'τN' }}
                    valueLeft={blockData?._G260?.value}
                    tooltipLeft={blockData?._G260?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I260?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K260?.value}
                    tooltipRight={blockData?._K260?.toolTip}
                    status={blockData?._S260?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={true}
                    description="2) 部分溶け込み開先溶接"
                    physicSymbolLeft={{ normalText: 'τN' }}
                    valueLeft={blockData?._G261?.value}
                    tooltipLeft={blockData?._G261?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I261?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K261?.value}
                    tooltipRight={blockData?._K261?.toolTip}
                    status={blockData?._S261?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={true}
                    description="3) 両側開先溶接"
                    physicSymbolLeft={{ normalText: 'τN' }}
                    valueLeft={blockData?._G262?.value}
                    tooltipLeft={blockData?._G262?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I262?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K262?.value}
                    tooltipRight={blockData?._K262?.toolTip}
                    status={blockData?._S262?.value}
                ></RowTextComponent>

                <SectionTitleSub title="② ウェブ溶接部照査" />
                <RowTextComponent
                    isEqualTo={true}
                    description="1) 両側すみ肉溶接"
                    physicSymbolLeft={{ normalText: 'τN' }}
                    valueLeft={blockData?._G265?.value}
                    tooltipLeft={blockData?._G265?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I265?.value}
                    physicSymbolRight={{ normalText: 'τa' }}
                    valueRight={blockData?._K265?.value}
                    tooltipRight={blockData?._K265?.toolTip}
                    status={blockData?._S265?.value}
                ></RowTextComponent>

                <SectionTitleSub title="③ ボルト照査" />

                <RowTextComponent
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'ρt' }}
                    valueLeft={blockData?._G267?.value}
                    tooltipLeft={blockData?._G267?.toolTip}
                    unit={UNIT.kN}
                    mathSymbol={blockData?._I267?.value}
                    physicSymbolRight={{ normalText: 'ρa' }}
                    valueRight={blockData?._K267?.value}
                    tooltipRight={blockData?._K267?.toolTip}
                    status={blockData?._S267?.value}
                ></RowTextComponent>

                <SectionTitleSub title="④ 断面照査" />
                <RowTextComponent
                    isEqualTo={true}
                    description="1) 曲げ応力度"
                    physicSymbolLeft={{ normalText: 'σs1' }}
                    valueLeft={blockData?._G270?.value}
                    tooltipLeft={blockData?._G270?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I270?.value}
                    physicSymbolRight={{ normalText: 'σsa' }}
                    valueRight={blockData?._K270?.value}
                    tooltipRight={blockData?._K270?.toolTip}
                    status={blockData?._S270?.value}
                ></RowTextComponent>

                <RowTextComponent
                    isEqualTo={true}
                    physicSymbolLeft={{ normalText: 'σs2' }}
                    valueLeft={blockData?._G271?.value}
                    tooltipLeft={blockData?._G271?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I271?.value}
                    physicSymbolRight={{ normalText: 'σsa' }}
                    valueRight={blockData?._K271?.value}
                    tooltipRight={blockData?._K271?.toolTip}
                    status={blockData?._S271?.value}
                ></RowTextComponent>

                <SectionTitleSub title="⑤ 座屈照査" />
                <RowTextComponent
                    isEqualTo={true}
                    description=" 1) 応力度照査"
                    physicSymbolLeft={{ normalText: 'σc' }}
                    valueLeft={blockData?._G273?.value}
                    tooltipLeft={blockData?._G273?.toolTip}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._I273?.value}
                    physicSymbolRight={{ normalText: 'σca' }}
                    valueRight={blockData?._K273?.value}
                    tooltipRight={blockData?._K273?.toolTip}
                    status={blockData?._S273?.value}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0503;
