// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    fontWeight: 400,
                },
                contained: {
                    backgroundColor: theme.palette.custom.action,
                    borderColor: theme.palette.custom.action,
                    '&:hover': { backgroundColor: theme.palette.custom.action },
                    '&:disabled': { opacity: 0.85, backgroundColor: '#e5e5e5', cursor: 'not-allowed', pointerEvents: 'all !important', color: 'black' },
                },
                outlined: {
                    color: theme.palette.custom.action,
                    borderColor: theme.palette.custom.action,
                    '&:hover': { color: theme.palette.primary.main, borderColor: theme.palette.primary.main },
                    '&:disabled': { opacity: 0.85, backgroundColor: '#e5e5e5', cursor: 'not-allowed', pointerEvents: 'all !important', color: 'black' },
                },
            },
        },
    };
}
