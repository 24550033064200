import { TextField, Typography } from '@mui/material';
import SheetAPI from 'api/SheetAPI';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataSection, getListSectionsOfSheet } from 'store/reducers/sheetSlice';
import { FieldStatus, SheetStatus } from 'utils/StringDefinition';
import { CompletedIcon, ErrorIcon } from '../share-components/StatusIcon';

const Table0201 = ({ aF60AO60, aF61AO61, AF59AO59, AF62AO62, AF63AO63 }) => {
    const [arrayInput1, setArrayInput1] = useState(aF60AO60);
    const [arrayInput2, setArrayInput2] = useState(aF61AO61);
    const sectionData = useSelector((state) => state.sheet.dataSection);
    const sheetId = sectionData?.data?.sheetId;
    var sectionIndex = useSelector((state) => state.calculatationSection.index);
    const blockData = sectionData?.data?.version100?.block006;

    const sheetInfo = useSelector((state) => state.sheet.sheetActive);
    const user = useSelector((state) => state.user.current);
    const isEditor = user?.id === sheetInfo?.editor?.id;
    const canEdited = (sheetInfo?.status === SheetStatus.EDITING || sheetInfo?.status === SheetStatus.CHECKED) && isEditor ? false : true;

    const dispatch = useDispatch();

    const handleChange = (name, event, index) => {
        // console.log(rowIndex, event.target.value, valueIndex);
        const allowNegativeNumber = true;

        // For positive numbers
        const regex = /^[0-9]*\.?[0-9]*$/;

        // For negative number
        const regexNegativeNumber = /^-?[0-9]*\.?[0-9]*$/;

        let newValue = event.target.value;
        if (newValue !== null || newValue !== undefined || newValue !== '' || allowNegativeNumber ? regexNegativeNumber.test(newValue) : regex.test(newValue)) {
            replaceElement(name, newValue, index);
        }
    };

    const replaceElement = (name, newValue, index) => {
        if (name === 'aF60AO60') {
            const newArr = [...arrayInput1];
            newArr[index] = newValue;

            // console.log(newValue, index, newArr);
            setArrayInput1(newArr);
        } else if (name === 'aF61AO61') {
            const newArr = [...arrayInput2];
            newArr[index] = newValue;

            // console.log(newValue, index, newArr);
            setArrayInput2(newArr);
        }
    };

    const handleBlur = (name, event, index) => {
        let newValue = event.target.value;

        let newArr;
        if (name === 'aF60AO60') {
            newArr = [...arrayInput1];
            newArr[index] = newValue;

            // console.log(newValue, index, newArr);
            setArrayInput1(newArr);
        } else if (name === 'aF61AO61') {
            newArr = [...arrayInput2];
            newArr[index] = newValue;

            // console.log(newValue, index, newArr);
            setArrayInput2(newArr);
        }

        let blockDataChanged = {
            ...blockData,
            [name]: newArr,
        };
        // let sectionDataChanged = {
        //     ...sectionData.data,
        //     block006: blockDataChanged,
        // };

        let sectionDataChanged = {
            ...sectionData.data,
            version100: { ...sectionData.data?.version100, block006: blockDataChanged },
        };
        if (!_.isEqual(blockData, blockDataChanged)) {
            // console.log(sectionDataChanged);
            updateData(sectionDataChanged);
        }
    };

    const updateData = async (sectionDataChanged) => {
        const response = await SheetAPI.putDataSection(sectionDataChanged);
        if (response.status === 200) {
            const params = {
                sheetId: sheetId,
                section: `Section00${sectionIndex}`,
            };
            dispatch(getDataSection(params));
            dispatch(getListSectionsOfSheet(sheetId));
        }
    };

    useEffect(() => {
        setArrayInput1(aF60AO60);
        setArrayInput2(aF61AO61);
    }, [aF60AO60, aF61AO61]);

    return (
        <div className="w-full overflow-x-auto flex justify-start items-center">
            <div className="w-[85%] ml-[6.5%]">
                <Typography sx={{ color: '#02457a', paddingBottom: 1.5 }}>
                    <strong>移動可能量一覧表</strong>
                </Typography>

                <table className="w-full text-sm text-right pb-1">
                    <thead className="text-xs text-white bg-sky-600 dark:bg-gray-700">
                        <tr className="h-[32px] text-center">
                            <th scope="col" className="px-2 py-1"></th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>G1</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>G2</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>G3</Typography>
                            </th>
                            <th scope="col" className="w-1/4 px-2 py-1">
                                <Typography sx={{ justifyContent: 'center' }}>G4</Typography>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100">
                            <th scope="row" className="px-2 py-1 text-left text-gray-900 whitespace-nowrap dark:text-white">
                                <Typography>θ(°)</Typography>
                            </th>
                            {AF59AO59 &&
                                AF59AO59.map((value, index) => (
                                    <td key={index}>
                                        <TextField
                                            name={`input-${index}-${index}`}
                                            value={value}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'right',
                                                    color: 'red',
                                                },
                                            }}
                                            disabled
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#d0fecd',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                                width: '100%',
                                            }}
                                        />
                                    </td>
                                ))}
                        </tr>

                        <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100">
                            <th scope="row" className="px-2 py-1 text-left text-gray-900 whitespace-nowrap dark:text-white">
                                <Typography>取付高さ (mm)</Typography>
                            </th>
                            {arrayInput1 &&
                                arrayInput1.map((value, index) => (
                                    <td key={index}>
                                        <TextField
                                            name={`input-${index}-${index}`}
                                            value={value}
                                            onBlur={(e) => handleBlur('aF60AO60', e, index)}
                                            onChange={(e) => handleChange('aF60AO60', e, index)}
                                            disabled={canEdited}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'right',
                                                    color: 'red',
                                                },
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#d2feff',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                                width: '100%',
                                            }}
                                        />
                                    </td>
                                ))}
                        </tr>

                        <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100">
                            <th scope="row" className="px-2 py-1 text-left text-gray-900 whitespace-nowrap dark:text-white">
                                <Typography>移動可能量 (mm)</Typography>
                            </th>
                            {arrayInput2 &&
                                arrayInput2.map((value, index) => (
                                    <td key={index}>
                                        <TextField
                                            name={`input-${index}-${index}`}
                                            value={value}
                                            onBlur={(e) => handleBlur('aF61AO61', e, index)}
                                            onChange={(e) => handleChange('aF61AO61', e, index)}
                                            disabled={canEdited}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'right',
                                                    color: 'red',
                                                },
                                            }}
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#d2feff',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                                width: '100%',
                                            }}
                                        />
                                    </td>
                                ))}
                        </tr>

                        <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100">
                            <th scope="row" className="px-2 py-1 text-left text-gray-900 whitespace-nowrap dark:text-white">
                                <Typography>桁かかり長&nbsp;×&nbsp;0.75 (mm)</Typography>
                            </th>
                            {AF62AO62 &&
                                AF62AO62.map((value, index) => (
                                    <td key={index}>
                                        <TextField
                                            name={`input-${index}-${index}`}
                                            value={value}
                                            inputProps={{
                                                style: {
                                                    textAlign: 'right',
                                                    color: 'red',
                                                },
                                            }}
                                            disabled
                                            sx={{
                                                '& .MuiInputBase-input.Mui-disabled': {
                                                    WebkitTextFillColor: '#272343',
                                                    cursor: 'not-allowed',
                                                },
                                                '& .MuiInputBase-root': {
                                                    backgroundColor: '#d0fecd',
                                                    height: '32px',
                                                    padding: 0,
                                                },
                                                width: '100%',
                                            }}
                                        />
                                    </td>
                                ))}
                        </tr>

                        <tr className="border-b bg-gray-50 dark:bg-gray-800  hover:bg-sky-100" key={0}>
                            <th scope="row" className="px-2 py-1 text-left text-gray-900 whitespace-nowrap dark:text-white">
                                <Typography>検討</Typography>
                            </th>
                            {AF63AO63 && AF63AO63.map((value, index) => <td key={index}>{value === FieldStatus.OK ? <CompletedIcon widthSet="100%" /> : <ErrorIcon widthSet="100%" />}</td>)}
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table0201;
