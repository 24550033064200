import { Delete } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { IconButton, TableCell, TableRow } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyDefinition, SheetStatus } from 'utils/StringDefinition';
import { SheetForgeStatus } from '../../../../utils/StringDefinition';
import LabelCustom from './LabelCustom';
// ==============================|| File Row ||============================== //

const Sheet3DRow = (props) => {
    const { id, sheetName, tool, creator, createdDate, reviewer, status } = props.fileInfo;
    const { currentUser } = props;
    const { t } = useTranslation();

    return (
        <TableRow hover key={id}>
            <TableCell align="left" width="20%">
                {sheetName}
            </TableCell>

            <TableCell align="left" sx={{ textTransform: 'capitalize' }} width="20%">
                {`${tool?.toolName} ${tool?.versionId}`}
            </TableCell>

            <TableCell align="center" width="15%">
                {`${creator?.firstName} ${creator?.lastName}`}
            </TableCell>

            <TableCell align="center" width="15%">
                {reviewer ? `${reviewer?.firstName} ${reviewer?.lastName}` : ''}
            </TableCell>

            <TableCell align="center" width="8%">
                {moment(createdDate).format('YYYY-MM-DD')}
            </TableCell>

            <TableCell align="center" width="8%">
                <LabelCustom
                    color={
                        (status === SheetForgeStatus.COMPLETED && 'success') ||
                        (status === SheetForgeStatus.EDITING && 'warning') ||
                        (status === SheetForgeStatus.UNCONVERTED && 'warning') ||
                        (status === SheetForgeStatus.NEW && 'primary') ||
                        (status === null && 'primary')
                    }
                    sx={{ textTransform: 'capitalize', width: '100%' }}
                >
                    {t(`calculationTable.${status}`)}
                </LabelCustom>
            </TableCell>

            {currentUser?.company?.companyName === CompanyDefinition.NKE && (
                <TableCell align="center" width="12%">
                    <>
                        <IconButton onClick={() => props.onDownloadSheet(props.fileInfo)} disabled={status !== SheetStatus.COMPLETED}>
                            {props?.downloadingSheet?.sheetId === id && props?.downloadingSheet?.downloading ? (
                                <Tooltip title={<Trans i18nKey="calculationTable.downloading" />} placement="bottom">
                                    <CircularProgress size="1.5rem" style={{ color: '#02457a' }} />
                                </Tooltip>
                            ) : (
                                <Tooltip title={<Trans i18nKey="calculationTable.download" />} placement="bottom">
                                    <DownloadIcon style={{ marginRight: '10%', cursor: 'pointer' }} />
                                </Tooltip>
                            )}
                        </IconButton>

                        <IconButton
                            onClick={() => props.onDeleteSheet(props.fileInfo)}
                            sx={{
                                fontSize: '1.25rem',
                            }}
                        >
                            <Tooltip title={<Trans i18nKey="calculationTable.moveToTrash" />} placement="bottom">
                                <Delete style={{ marginRight: '10%', cursor: 'pointer' }} />
                            </Tooltip>
                        </IconButton>
                    </>
                </TableCell>
            )}

            <TableCell align="center" width="8%">
                <IconButton onClick={() => props.onOpen3DView(props.fileInfo)}>
                    <Tooltip title={<Trans i18nKey="calculationTable.navigate" />} placement="bottom">
                        <ViewInArIcon style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default Sheet3DRow;
