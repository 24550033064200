// material-ui
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

// Component
import MainCard from 'components/main_components/components/MainCard';

// ==============================|| ACCOUNT CARD ||============================== //

export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const SheetInfoCard = () => {
    const { t } = useTranslation();

    const sheetInfo = useSelector((state) => state.sheetForge.sheetForgeActive);

    return (
        <>
            <MainCard sx={{ bgcolor: 'grey.50' }}>
                <Box component="div" sx={{ display: 'inline', paddingTop: 3 }}>
                    <Grid container rowSpacing={1.5}>
                        <Grid item xs={3.5}>
                            <Typography variant="h5">{t(`calculationTable.sheetName`)}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h5">:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h5"> {`${sheetInfo?.sheetName}` || ''}</Typography>
                        </Grid>

                        <Grid item xs={3.5}>
                            <Typography variant="h7">{t(`permissions.Editor`)}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h7">:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h7"> {`${sheetInfo?.creator?.firstName} ${sheetInfo?.creator?.lastName}` || ''}</Typography>
                        </Grid>
                        <Grid item xs={3.5}>
                            <Typography variant="h7">{t(`permissions.Reviewer`)}</Typography>
                        </Grid>
                        <Grid item xs={0.5}>
                            <Typography variant="h7">:</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h7"> {sheetInfo?.reviewer ? `${sheetInfo?.reviewer?.firstName} ${sheetInfo?.reviewer?.lastName}` : ''}</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </MainCard>
        </>
    );
};

export default SheetInfoCard;
