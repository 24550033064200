import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ToolAPI from 'api/ToolAPI';

export const getListTools = createAsyncThunk('tools/getListTools', async () => {
    try {
        const res = await ToolAPI.GetListTools();
        return res.data;
    } catch (error) {
        console.log('Error when fetch data from server, function name getListTools!!!');
        return error.message;
    }
});

const toolsSlice = createSlice({
    name: 'tools',
    initialState: {
        listTools: [],
        status: 'idle',
        error: null,
    },
    reducers: [],
    extraReducers: {
        [getListTools.pending]: (state, action) => {
            state.status = 'loading';
        },
        [getListTools.fulfilled]: (state, action) => {
            state.listTools = action.payload?.data;
            state.status = 'succeeded';
        },
        [getListTools.rejected]: (state, action) => {
            state.status = action.error.message;
        },
    },
});

const { reducer } = toolsSlice;

export default reducer;
