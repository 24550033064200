import { Box } from '@mui/system';
import { useNavigate } from 'react-router';
import AuthWrapper from './AuthWrapper';
import AuthForgetPassword from './auth_forms/AuthForgetPassword';
import LogoCardLogin from './components/LogoCardLogin/LogoCardLogin';

ForgotPassword.propTypes = {};

function ForgotPassword(props) {
    const { changeMode } = props;

    let navigate = useNavigate();

    return (
        <AuthWrapper changeMode={changeMode}>
            <Box component="div" sx={{ display: 'inline-block', height: '500px', width: '100%' }}>
                <Box component="div" sx={{ display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'center' }}>
                    <LogoCardLogin />
                </Box>
                <Box component="div" sx={{ paddingTop: '15%', display: 'flex', width: '100%', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'row' }}>
                    <AuthForgetPassword />
                </Box>
            </Box>
        </AuthWrapper>
    );
}

export default ForgotPassword;
