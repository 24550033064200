// ----------------------------------------------------------------------

export default function Dialog(theme) {
    return {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    boxShadow: theme.customShadows.dialog,
                    '&.MuiPaper-rounded': {
                        borderRadius: Number(theme.shape.borderRadius) * 2,
                    },
                    '&.MuiDialog-paperFullScreen': {
                        borderRadius: 0,
                    },
                    '&.MuiDialog-paper .MuiDialogActions-root': {
                        padding: theme.spacing(3),
                    },
                    '@media (max-width: 600px)': {
                        margin: theme.spacing(2),
                    },
                    '@media (max-width: 663.95px)': {
                        '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
                            maxWidth: '100%',
                        },
                    },
                },
                paperFullWidth: {
                    width: '100%',
                },
            },
        },

        MuiStack: {
            styleOverrides: {
                root: {
                    '& .MuiTypography-root ': {
                        margin: 0,
                    },
                },
            },
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(3, 3, 0),
                    fontSize: 20,
                    fontWeight: 'bold',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                },
            },
        },

        MuiDialogContent: {
            styleOverrides: {
                root: {
                    borderTop: 0,
                    borderBottom: 0,
                    padding: theme.spacing(3),
                },
            },
        },

        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: 'black',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& > fieldset': {
                        top: 0,
                        '& > legend': {
                            display: 'none',
                        },
                    },
                },
            },
        },

        MuiDialogActions: {
            styleOverrides: {
                root: {
                    justifyContent: 'space-between',
                    paddingTop: 1,
                    paddingBottom: 1,
                    '& .MuiButtonBase-root': {
                        width: '120px',
                    },
                },
            },
        },
    };
}
