import { SearchOutlined } from '@ant-design/icons';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Box, Button, FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const SearchUserComponent = (props) => {
    const { t } = useTranslation();
    const { searchKeywords, handleSearchInput, handleClickOpenModal } = props;

    return (
        <Box component="main" sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft: 2, paddingRight: 2 }}>
            <FormControl
                sx={{
                    width: '30%',
                }}
            >
                <OutlinedInput
                    size="large"
                    id="margin-normal"
                    startAdornment={
                        <InputAdornment
                            position="start"
                            sx={{
                                mr: -0.5,
                            }}
                        >
                            <SearchOutlined />
                        </InputAdornment>
                    }
                    value={searchKeywords}
                    onChange={handleSearchInput}
                    aria-describedby="outlined-weight-helper-text"
                    sx={{
                        '& legend': {
                            display: 'none',
                        },
                        '& fieldset': {
                            top: 0,
                        },
                    }}
                    placeholder={t('calculationTable.searchUserPalaceHolder')}
                />
            </FormControl>
            {/* Add Button */}
            <Button variant="contained" onClick={handleClickOpenModal}>
                <PersonAddIcon sx={{ pr: 0.5 }} />
                <Trans i18nKey="userTable.newUser" />
            </Button>
        </Box>
    );
};

export default SearchUserComponent;
