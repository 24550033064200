import { useState } from 'react';

// material-ui
import { PlusOutlined } from '@ant-design/icons';
import { Button, Grid, Typography } from '@mui/material';
import { Pagination } from 'antd';

import listToolsData from 'store/reducers/dataFake';
import SheetCardInfo from './components/SheetCard';

// ==============================|| ToolDetail LAYOUT ||============================== //

const ToolDetail = () => {
    const MAX_FILE_QUANTITY = 6;

    const listSheets = listToolsData.find((tool) => tool.id === 6).sheets;

    const [pageOfSheet, setPageOfSheet] = useState({
        currentPage: 1,
        totalPages: Math.ceil(listSheets?.length / MAX_FILE_QUANTITY) * 10,
        minIndex: 0,
        maxIndex: MAX_FILE_QUANTITY,
    });

    const handleChangePageNumber = (current) => {
        setPageOfSheet({
            ...pageOfSheet,
            currentPage: current,
            minIndex: (current - 1) * MAX_FILE_QUANTITY,
            maxIndex: current * MAX_FILE_QUANTITY,
        });
    };
    //Export list saved file
    const listSheetView = listSheets?.map((sheet, index) => {
        if (index >= pageOfSheet?.minIndex && index < pageOfSheet?.maxIndex) {
            return (
                <Grid item xs={4}>
                    <SheetCardInfo key={sheet.sheetId} toolName={listToolsData[0].toolName} sheetInfo={sheet} />
                </Grid>
            );
        }
    });

    return (
        <>
            <Grid container>
                {/* SIDE BAR */}
                <Grid item xs={2}></Grid>

                {/* MAIN VIEW */}

                <Grid item container xs={10} display="inline-block" sx={{ pt: 10, pr: 3, pb: 10, pl: 3 }}>
                    <Grid item>
                        <Button variant="contained" sx={{ textTransform: 'capitalize', bgcolor: 'primary.main', width: '125px' }}>
                            <PlusOutlined />
                            New
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ pt: 5, pb: 5 }}>
                        <Typography variant="h3">SAVED FILES</Typography>
                    </Grid>
                    <Grid item container columnSpacing={3} rowSpacing={3}>
                        {listSheetView}
                    </Grid>
                    <Grid item display="flex" sx={{ justifyContent: 'flex-end', paddingTop: '20px' }}>
                        {listSheets?.length > 0 ? (
                            <Pagination defaultCurrent={1} current={pageOfSheet?.currentPage} total={pageOfSheet?.totalPages} showSizeChanger={false} onChange={handleChangePageNumber} />
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default ToolDetail;
