import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import RowTextThreeInput from '../share-components/RowThreeInput';
import SectionTitle, { InstructRow, SectionTitleSub } from '../share-components/Tilte';
import image1 from './3-1.png';
import image2 from './3-2.png';

const Block0301 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version100?.[`block007`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <article className="h-full w-full relative overflow-hidden flex flex-col items-center justify-center m-auto">
                    <img src={image1} alt="block0301_1" height="375" />
                    <img src={image2} alt="block0301_2" height="375" />

                    {/* //Text image 1 */}
                    <Typography className="absolute w-full flex justify-center top-[2.5rem] left-[-15.8rem] text-sm text-center">
                        {isNaN(blockData?._T97) ? 'L1' : `L1 = ${blockData?._T97}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[17.2rem] left-[-22.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T98) ? 't1' : `t1 = ${blockData?._T98}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[6.5rem] left-[-8.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T99) ? 'h1' : `h1 = ${blockData?._T99}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[7.5rem] left-[-23.5rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T100) ? 't2' : `t2 = ${blockData?._T100}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[17.5rem] left-[-16rem] text-sm text-center">{isNaN(blockData?._T97) ? 'L1' : `L1 = ${blockData?._T97}`}</Typography>

                    {/* //Text image 2 */}
                    <Typography className="absolute w-full flex justify-center top-[2.5rem] left-[12rem] text-sm text-center">{isNaN(blockData?._T101) ? 'L2' : `L2 = ${blockData?._T101}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[7.5rem] left-[4rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T100) ? 't2' : `t2 = ${blockData?._T100}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[6.5rem] left-[19rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T111) ? 'h2' : `h2 = ${blockData?._T111}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[14.5rem] left-[4rem] text-sm text-center -rotate-90">{isNaN(blockData?._T112) ? 'b' : `b`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[16.5rem] left-[4rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T117) ? 'a1' : `${blockData?._T117}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[19rem] left-[5rem] text-sm text-center">{isNaN(blockData?._T118) ? 'a2' : `${blockData?._T118}`}</Typography>
                    <Typography className="absolute w-full flex justify-center top-[20.5rem] left-[9.5rem] text-sm text-center">{isNaN(blockData?._T118) ? 'yn' : `yn`}</Typography>
                    <Typography className="absolute w-full flex justify-center top-[20.5rem] left-[15rem] text-sm text-center">{isNaN(blockData?._T118) ? "y'n" : "y'n"}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[18.5rem] left-[9.5rem] text-sm text-center">{isNaN(blockData?._T118) ? 'y1' : `y1`}</Typography>
                    <Typography className="absolute w-full flex justify-center top-[18.5rem] left-[13.5rem] text-sm text-center">{isNaN(blockData?._T118) ? 'y1' : `y1`}</Typography>

                    {/* <Typography className="absolute w-full flex justify-center top-[23.3rem] left-[42.6rem] text-sm text-center">{isNaN(blockData?._T118) ? 'a2' : `${blockData?._T118}`}</Typography> */}

                    <Typography className="absolute w-full flex justify-center top-[23.3rem] left-[42.6rem] text-sm text-center">{isNaN(blockData?._T118) ? 'yn' : `${blockData?._T118}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[26.5rem] left-[-17rem] text-sm text-center">{isNaN(blockData?._T104) ? 'w' : `w = ${blockData?._T104}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[28.2rem] left-[-16rem] text-sm text-center">{isNaN(blockData?._iL3) ? 'L3' : `L3 = ${blockData?._iL3}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[36.5rem] left-[-12rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T104) ? 't2' : `${blockData?._T100}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[34rem] left-[-12rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T105) ? 'R' : `${blockData?._T105}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[32rem] left-[-12rem] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T104) ? 'h3' : `${blockData?._T103}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[36.5rem] left-[0] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T104) ? 't2' : `${blockData?._T100}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[33.8rem] left-[0] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T105) ? 'R' : `${blockData?._T105}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[31.5rem] left-[0] text-sm text-center -rotate-90">
                        {isNaN(blockData?._T104) ? 'h3' : `${blockData?._T103}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[28rem] left-[3.7rem] text-sm text-center">{isNaN(blockData?._T102) ? 't3' : `t3 = ${blockData?._T102}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[28rem] left-[10.2rem] text-sm text-center">
                        {isNaN(blockData?._T102) ? 't3' : `t3 = ${blockData?._T102}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[39rem] left-[9rem] text-sm text-center">{isNaN(blockData?._T101) ? 'L2' : `L2 = ${blockData?._T101}`}</Typography>
                </article>
                <InstructRow />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p97"
                    initValue={{ looking: blockData?._L97, input: blockData?.p97, calculate: blockData?._T97 }}
                    description="トッププレート長さ"
                    symbol={{ normalText: 'L1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p98"
                    initValue={{ looking: blockData?._L98, input: blockData?.p98, calculate: blockData?._T98 }}
                    description="トッププレート厚"
                    symbol={{ normalText: 't1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p99"
                    description="荷重の作用高さ"
                    initValue={{ looking: blockData?._L99, input: blockData?.p99, calculate: blockData?._T99 }}
                    symbol={{ normalText: 'h1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p100"
                    description="ベースプレート厚"
                    initValue={{ looking: blockData?._L100, input: blockData?.p100, calculate: blockData?._T100 }}
                    symbol={{ normalText: 't2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p101"
                    initValue={{ looking: blockData?._L101, input: blockData?.p101, calculate: blockData?._T101 }}
                    description="ベースプレート長さ"
                    symbol={{ normalText: 'L2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p102"
                    initValue={{ looking: blockData?._L102, input: blockData?.p102, calculate: blockData?._T102 }}
                    description="補強リブ幅"
                    symbol={{ normalText: 't3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p103"
                    initValue={{ looking: blockData?._L103, input: blockData?.p103, calculate: blockData?._T103 }}
                    description="補強リブ高さ"
                    symbol={{ normalText: 'h3', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p104"
                    initValue={{ looking: blockData?._L104, input: blockData?.p104, calculate: blockData?._T104 }}
                    description="補強リブ幅"
                    symbol={{ normalText: 'w', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p105"
                    initValue={{ looking: blockData?._L105, input: blockData?.p105, calculate: blockData?._T105 }}
                    description="スカーラップ"
                    symbol={{ normalText: 'R', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ display: 'inline-block', alignItems: 'center', paddingLeft: 10, marginTop: 1.5, marginBottom: 1.5 }}>
                    <Typography style={{ color: '#02457a' }}>
                        <strong>ＨＴＢの計算</strong>
                    </Typography>
                </Box>

                <InstructRow />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p111"
                    initValue={{ looking: blockData?._L111, input: blockData?.p111, calculate: blockData?._T111 }}
                    description="荷重の作用高さ"
                    symbol={{ normalText: 'h2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p112"
                    initValue={{ looking: blockData?._L112, input: blockData?.p112, calculate: blockData?._T112 }}
                    description="ボルト中心間隔"
                    symbol={{ normalText: 'b1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p113"
                    initValue={{ looking: blockData?._L113, input: blockData?.p113, calculate: blockData?._T113 }}
                    description="ボルト本数"
                    symbol={{ normalText: 'n', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p114"
                    initValue={{ looking: blockData?._L114, input: blockData?.p114, calculate: blockData?._T114 }}
                    description="1列のボルト本数"
                    symbol={{ normalText: 'n1', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />
                <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingBottom: 2 }} />

                <RowTextThreeInput
                    blockNumber="block007"
                    name="p116"
                    initValue={{ looking: blockData?._L116, input: blockData?.p116, calculate: blockData?._T116 }}
                    description="偏心量"
                    symbol={{ normalText: 'e', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p117"
                    initValue={{ looking: blockData?._L117, input: blockData?.p117, calculate: blockData?._T117 }}
                    description="ボルトの縁端距離 (横)"
                    symbol={{ normalText: 'a1', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p118"
                    initValue={{ looking: blockData?._L118, input: blockData?.p118, calculate: blockData?._T118 }}
                    description="ボルトの縁端距離 (縦)"
                    symbol={{ normalText: 'a2', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p119"
                    initValue={{ looking: blockData?._L119, input: blockData?.p119, calculate: blockData?._T119 }}
                    description="ボルトからリブ縁までの距離"
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block007"
                    name="p120"
                    initValue={{ looking: blockData?._L120, input: blockData?.p120, calculate: blockData?._T120 }}
                    description="主桁下フランジ材厚"
                    symbol={{ normalText: "t'", mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitle title="上部工ブラケット断面照査" />
                </Box>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1. トッププレートの断面照査" />
                </Box>
                <RowTextComponent
                    description="1)	曲げ応力度"
                    physicSymbol="σt"
                    valueCalculate={blockData?._AL97}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._AR97}
                    valueStandard={blockData?._AS97}
                    status={blockData?._BC97}
                ></RowTextComponent>
                <RowTextComponent
                    description=""
                    physicSymbol="σc"
                    valueCalculate={blockData?._AL98}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._AR98}
                    valueStandard={blockData?._AS98}
                    status={blockData?._BC98}
                ></RowTextComponent>
                <RowTextComponent
                    description="2)	せん断応力度"
                    physicSymbol="τs"
                    valueCalculate={blockData?._AL99}
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    mathSymbol={blockData?._AR99}
                    valueStandard={blockData?._AS99}
                    status={blockData?._BC99}
                ></RowTextComponent>
                <RowTextComponent
                    description="3)	合成応力度"
                    unit={UNIT.none}
                    valueCalculate={blockData?._AL100}
                    mathSymbol={blockData?._AR100}
                    valueStandard={blockData?._AS100}
                    status={blockData?._BC100}
                ></RowTextComponent>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2. ボルトの作用力" />
                </Box>
                <RowTextComponent description="1)	ボルトの作用力照査"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・引張力"
                    physicSymbol="PV"
                    unit={UNIT.N}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL104}
                    mathSymbol={blockData?._AR104}
                    valueStandard={blockData?._AS104}
                    status={blockData?._BC104}
                ></RowTextComponent>
                <RowTextComponent
                    description="2)	てこ反力を考慮したボルトの照査"
                    physicSymbol="ρt"
                    unit={UNIT.kN}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL108}
                    mathSymbol={blockData?._AR108}
                    valueStandard={blockData?._AS108}
                    status={blockData?._BC108}
                ></RowTextComponent>
                <RowTextComponent
                    description="3)	引張力とせん断力が同時に作用するﾎﾞﾙﾄの照査"
                    physicSymbol="PH"
                    unit={UNIT.kN}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL110}
                    mathSymbol={blockData?._AR110}
                    valueStandard={blockData?._AS110}
                    status={blockData?._BC110}
                ></RowTextComponent>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="3. ベースプレート断面照査" />
                </Box>
                <RowTextComponent description="1)	リブ－トッププレート断面検討"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL115}
                    mathSymbol={blockData?._AR115}
                    valueStandard={blockData?._AS115}
                    status={blockData?._BC115}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL117}
                    mathSymbol={blockData?._AR117}
                    valueStandard={blockData?._AS117}
                    status={blockData?._BC117}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL119}
                    mathSymbol={blockData?._AR119}
                    valueStandard={blockData?._AS119}
                    status={blockData?._BC119}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・曲げせん断応力度"
                    physicSymbol="τb"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL120}
                    mathSymbol={blockData?._AR120}
                    valueStandard={blockData?._AS120}
                    status={blockData?._BC120}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・せん断応力度"
                    physicSymbol="τs"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL121}
                    mathSymbol={blockData?._AR121}
                    valueStandard={blockData?._AS121}
                    status={blockData?._BC121}
                ></RowTextComponent>
                <RowTextComponent description="2)	リブ－ベースプレート断面検討"></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    physicSymbol="σ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL125}
                    mathSymbol={blockData?._AR125}
                    valueStandard={blockData?._AS125}
                    status={blockData?._BC125}
                ></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    physicSymbol="τ"
                    isEqualTo={true}
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL127}
                    mathSymbol={blockData?._AR127}
                    valueStandard={blockData?._AS127}
                    status={blockData?._BC127}
                ></RowTextComponent>
                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    unit={UNIT.Nmm2}
                    valueCalculate={blockData?._AL129}
                    mathSymbol={blockData?._AR129}
                    valueStandard={blockData?._AS129}
                    status={blockData?._BC129}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0301;
