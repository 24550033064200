import { Typography } from '@mui/material';

const Table0401 = ({ arrayValue }) => {
    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-center flex-col">
            <div className="w-9/12 pb-1">
                <Typography style={{ color: '#02457a' }}>
                    <strong>標準値</strong>
                </Typography>
            </div>

            <table className="w-9/12 text-sm text-right pb-3">
                <thead className="bg-white border-b dark:bg-gray-900 text-black text-right uppecase">
                    <tr className="h-[32px]">
                        {['Y６', 'Y５', 'Y４', 'Y３', 'Y２', 'Y1', 'Y0', 'Y１’', 'Y２’', 'Y３’', 'Y４’', 'Y５’', 'Y６’'].map((header) => (
                            <td key={header} scope="col" className="border border-solid border-gray-500 px-1 py-1">
                                <Typography sx={{ justifyContent: 'flex-end' }}>{header}</Typography>
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody className="bg-[#fdff9d] border-b dark:bg-gray-900 dark:border-gray-700">
                    <tr className="h-[32px]">
                        {arrayValue &&
                            arrayValue.map((value, index) => (
                                <td className="border text-right border-solid border-gray-500 px-1 py-1" key={index}>
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value}</Typography>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table0401;
