import { useCallback, useEffect, useState } from 'react';

// material-ui
import { Table, TableBody } from '@mui/material';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
// component
import useTable, { getComparator } from 'custom_hook/useTable';

import { Box, Stack } from '@mui/system';
import MenuAPI from 'api/MenuAPI';
import { CustomToastComponent } from 'components/popup_components/CustomToastComponent';
import { ToastShowError, ToastShowSuccess } from 'components/popup_components/CustomToastComponent/Notify';
import { searchToolsFnc } from 'pages/menu/ultils/FileUltis';
import Page404 from 'pages/not-found/NotFound';
import { useTranslation } from 'react-i18next';
import { getListTools } from 'store/reducers/toolsSlice';
import { PermissionsDefinition } from 'utils/StringDefinition';
import ToolRow from './components/ToolRow';
import ToolTableHead from './components/ToolTableHead';
import { applySortFilterTools } from './components/ultils/ToolUtils';

// ==============================|| Menu List Files ||============================== //

const ToolManagement = () => {
    const user = useSelector((state) => state.user.current);
    const isAdmin = user?.permission === PermissionsDefinition.ADMIN ? true : false;

    const { t } = useTranslation();
    const MAX_FILE_QUANTITY = 10;

    const { dense, order, orderBy, selected, onSort } = useTable();

    const TABLE_TOOL_HEAD = [
        { id: 'toolName', label: 'Tool', align: 'center', width: 4 },
        { id: 'description', label: 'Editor', align: 'center', width: 1.5 },
        { id: 'versionId', label: 'Status', align: 'center', width: 1.5 },
        { id: 'createdDate', label: 'Created Date', align: 'center', width: 1.5 },
        { id: 'modifiedDate', label: 'Deleted Date', align: 'center', width: 1.5 },
    ];
    const dispatch = useDispatch();

    //fetch data
    const initFetch = useCallback(() => {
        dispatch(getListTools());
    }, [dispatch]);

    useEffect(() => {
        initFetch();
    }, [initFetch]);

    const listToolsInit = useSelector((state) => state.tools.listTools);

    const [listTools, setListTools] = useState(listToolsInit || []);

    const [searchKeywords, setSearchKeywords] = useState('');

    const [pageOfFiles, setPageOfFiles] = useState({
        currentPage: 1,
        totalPages: Math.ceil(listTools?.length / MAX_FILE_QUANTITY) * 10,
        minIndex: 0,
        maxIndex: MAX_FILE_QUANTITY,
    });

    useEffect(() => {
        setListTools(listToolsInit);
        setPageOfFiles({ ...pageOfFiles, totalPages: Math.ceil(listToolsInit.length / MAX_FILE_QUANTITY) * 10 });
    }, [listToolsInit]);

    //When search box typing
    const handleSearchInput = (e) => {
        var searchValue = e.target.value;

        setSearchKeywords(searchValue);
        let listFilesResult = searchToolsFnc(listToolsInit, searchValue);
        if (searchValue !== '') {
            setListTools(listFilesResult);
            setPageOfFiles({
                ...pageOfFiles,
                currentPage: 1,
                totalPages: Math.ceil(listFilesResult?.length / MAX_FILE_QUANTITY) * 10 || 10,
                minIndex: 0,
                maxIndex: MAX_FILE_QUANTITY,
            });
        } else if (searchValue === '') {
            setListTools(listToolsInit);
            setPageOfFiles({
                currentPage: 1,
                totalPages: Math.ceil(listToolsInit?.length / MAX_FILE_QUANTITY) * 10,
                minIndex: 0,
                maxIndex: MAX_FILE_QUANTITY,
            });
        }
    };

    //When change page of Saved File View

    const handleChangePageFiles = (current) => {
        setPageOfFiles({
            ...pageOfFiles,
            currentPage: current,
            minIndex: (current - 1) * MAX_FILE_QUANTITY,
            maxIndex: current * MAX_FILE_QUANTITY,
        });
    };

    const dataFiltered = applySortFilterTools({
        listTools,
        comparator: getComparator(order, orderBy),
    });

    const handleRestoreSheet = async (values) => {
        const sheetId = values.id;

        //Restore sheet

        const response = await MenuAPI.ToggleSheet(sheetId);
        if (response.status === 200) {
            dispatch(getListTools());
            ToastShowSuccess(t('toastNoti.restore'));
        } else {
            ToastShowError('Error.');
        }
    };

    //Export list saved file
    const listFilesView = dataFiltered?.map((file, index) => {
        if (index >= pageOfFiles?.minIndex && index < pageOfFiles?.maxIndex) {
            return <ToolRow key={file.toolCode} currentUser={user} fileInfo={file} onRestoreSheet={() => handleRestoreSheet(file)} />;
        }
    });

    return (
        <>
            {isAdmin ? (
                <>
                    <Stack>
                        {/* <Box
                            sx={{
                                pt: 2,
                                pb: 2,
                                width: '100wh',
                            }}
                        >
                          

                            <ListFilesFunction searchKeywords={searchKeywords} handleSearchInput={handleSearchInput} />
                        </Box> */}

                        {/* Main View Tool Manager */}

                        <Stack
                            sx={{
                                m: 2,
                                boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
                                borderRadius: '5px',
                                width: '100wh',
                                height: '90vh',
                            }}
                        >
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Table size={dense ? 'small' : 'medium'}>
                                    <ToolTableHead order={order} orderBy={orderBy} headLabel={TABLE_TOOL_HEAD} rowCount={listToolsInit.length} numSelected={selected.length} onSort={onSort} />

                                    <TableBody height="60vh">{listFilesView}</TableBody>
                                </Table>
                            </Box>

                            <Box component="div" sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 2, marginBottom: 2 }}>
                                {listTools?.length > 0 ? (
                                    <Pagination defaultCurrent={1} current={pageOfFiles?.currentPage} total={pageOfFiles?.totalPages} showSizeChanger={false} onChange={handleChangePageFiles} />
                                ) : null}
                            </Box>
                        </Stack>

                        <CustomToastComponent />
                    </Stack>
                    <CustomToastComponent />
                </>
            ) : (
                <Page404 />
            )}
        </>
    );
};

export default ToolManagement;
