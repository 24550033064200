import { Card } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';

import RowSelectComponent from '../share-components/RowSelect';
import SectionTitle from '../share-components/Tilte';
import TableD70D77 from './TableD70D77';
import TableG70G71 from './TableG70G71';

const Block0202 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.tool002version100?.[`block002`];

    const listF64 = [
        { id: 1, value: 20 },
        { id: 2, value: 22 },
        { id: 3, value: 24 },
        { id: 4, value: 27 },
        { id: 5, value: 30 },
        { id: 6, value: 33 },
        { id: 7, value: 36 },
        { id: 8, value: 39 },
        { id: 9, value: 42 },
        { id: 10, value: 45 },
        { id: 11, value: 48 },
    ];
    const listF66 = [
        { id: 1, value: 2 },
        { id: 2, value: 4 },
        { id: 3, value: 6 },
        { id: 4, value: 8 },
        { id: 5, value: 10 },
        { id: 6, value: 12 },
        { id: 7, value: 14 },
        { id: 8, value: 16 },
    ];
    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                <SectionTitle title="連結ボルトの計算" />
                <RowSelectComponent
                    blockNumber="block002"
                    name="f64"
                    description="連結ボルト径"
                    initValue={blockData?.f64?.value}
                    symbol={{ normalText: 'd', mathSymbol: '=' }}
                    listValue={listF64}
                    unit={UNIT.mm}
                />

                <RowSelectComponent
                    blockNumber="block002"
                    name="f66"
                    description="連結ボルト本数"
                    initValue={blockData?.f66?.value}
                    symbol={{ normalText: 'n1', mathSymbol: '=' }}
                    listValue={listF66}
                    unit={UNIT.hon}
                />

                <Box component="div" sx={{ display: 'inline', paddingLeft: '15%', paddingRight: '15%', alignItems: 'flex-start', justifyContent: 'center', flexDirection: 'row' }}>
                    <TableD70D77 arrayValue={blockData?.d70D77} />
                    <TableG70G71 arrayValue={blockData?.g70G71} />
                </Box>
            </Stack>
        </Card>
    );
};

export default Block0202;
