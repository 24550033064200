import { Box, Card, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useSelector } from 'react-redux';
import { UNIT } from 'utils/StringDefinition';
import RowTextComponent from '../share-components/RowText';
import RowTextThreeInput from '../share-components/RowThreeInput';
import SectionTitle, { InstructRow, SectionTitleSub } from '../share-components/Tilte';
import image1 from './5-1.png';

const Block0501 = ({ content }) => {
    const sheetData = useSelector((state) => state.sheet.dataSection);
    const blockData = sheetData?.data?.version100?.[`block009`];

    return (
        <Card
            elevation={3}
            component="main"
            sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0px 16px 16px 16px',
            }}
        >
            <Stack sx={{ width: '100%' }} spacing={0.25}>
                {/* <SectionTitle title="主桁の補強材の入力" /> */}
                <article className="h-full w-full relative overflow-hidden flex items-center justify-center m-auto">
                    <img src={image1} alt="background" height="375" />

                    <Typography className="absolute w-full flex justify-center top-[1rem] left-[-11rem] text-sm text-center">{isNaN(blockData?._T187) ? 'L4' : `L4 = ${blockData?._T187}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[2.5rem] left-[-16rem] text-sm text-center">
                        {isNaN(blockData?._T186) ? 't4' : `t4 = ${blockData?._T186}`}
                    </Typography>

                    <Typography className="absolute w-full flex justify-center top-[1rem] left-[11.5rem] text-sm text-center">{isNaN(blockData?._T185) ? 'b' : `b = ${blockData?._T185}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[7.5rem] left-[18rem] text-sm -rotate-90">{isNaN(blockData?._T184) ? 'h4' : `h4 = ${blockData?._T184}`}</Typography>

                    <Typography className="absolute w-full flex justify-center top-[12.8rem] left-[0rem] text-sm -rotate-90">{isNaN(blockData?._iH2) ? 'h2' : `h2 = ${blockData?._iH2}`}</Typography>
                </article>

                <InstructRow />

                <RowTextThreeInput
                    blockNumber="block009"
                    name="p184"
                    description="補強ﾌﾟﾚｰﾄ高"
                    initValue={{ looking: blockData?._L184, input: blockData?.p184, calculate: blockData?._T184 }}
                    symbol={{ normalText: 'h4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block009"
                    name="p185"
                    description="補強ﾌﾟﾚｰﾄ幅"
                    initValue={{ looking: blockData?._L185, input: blockData?.p185, calculate: blockData?._T185 }}
                    symbol={{ normalText: 'b', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block009"
                    name="p186"
                    description="補強ﾌﾟﾚｰﾄ厚"
                    initValue={{ looking: blockData?._L186, input: blockData?.p186, calculate: blockData?._T186 }}
                    symbol={{ normalText: 't4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />
                <RowTextThreeInput
                    blockNumber="block009"
                    name="p187"
                    description="補強ﾌﾟﾚｰﾄ間隔"
                    initValue={{ looking: blockData?._L187, input: blockData?.p187, calculate: blockData?._T187 }}
                    symbol={{ normalText: 'L4', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <RowTextThreeInput
                    blockNumber="block009"
                    name="p188"
                    description="ボルト本数"
                    initValue={{ looking: blockData?._L188, input: blockData?.p188, calculate: blockData?._T188 }}
                    symbol={{ normalText: 'n', mathSymbol: '=' }}
                    unit={UNIT.hon}
                />

                <RowTextThreeInput
                    blockNumber="block009"
                    name="p189"
                    description="すみ肉溶接"
                    initValue={{ looking: blockData?._L189, input: blockData?.p189, calculate: blockData?._T189 }}
                    symbol={{ normalText: 'S', mathSymbol: '=' }}
                    unit={UNIT.mm}
                />

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitle title="主桁の補強検討" />
                </Box>
                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="1) 補強板の照査" />
                </Box>
                <RowTextComponent
                    description="箇所　①の溶接に対して"
                    physicSymbol="τ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                    valueCalculate={blockData?._AL186}
                    mathSymbol={blockData?._AR186}
                    valueStandard={blockData?._AS186}
                    status={blockData?._BC186}
                ></RowTextComponent>
                <RowTextComponent description="箇所　②の溶接に対して"></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じる引張応力度照査"
                    valueCalculate={blockData?._AL189}
                    mathSymbol={blockData?._AR189}
                    valueStandard={blockData?._AS189}
                    status={blockData?._BC189}
                    physicSymbol="σ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・溶接部に生じるせん断応力度照査"
                    valueCalculate={blockData?._AL191}
                    mathSymbol={blockData?._AR191}
                    valueStandard={blockData?._AS191}
                    status={blockData?._BC191}
                    physicSymbol="τ"
                    unit={UNIT.Nmm2}
                    isEqualTo={true}
                ></RowTextComponent>

                <RowTextComponent
                    paddingLeftFit={2}
                    description="・合成応力度の照査"
                    valueCalculate={blockData?._AL193}
                    mathSymbol={blockData?._AR193}
                    valueStandard={blockData?._AS193}
                    status={blockData?._BC193}
                    unit={UNIT.Nmm2}
                ></RowTextComponent>

                <Box component="div" sx={{ display: 'inline-block', paddingLeft: 2 }}>
                    <SectionTitleSub title="2) ボルトの照査" />
                </Box>
                <RowTextComponent
                    description="（ウェブに取り付けるボルト本数の照査）"
                    valueCalculate={blockData?._AL197}
                    mathSymbol={blockData?._AQ197}
                    valueStandardSymbol={`ρa = `}
                    valueStandard={blockData?._AS197}
                    status={blockData?._BC197}
                    physicSymbol="P"
                    unit={UNIT.N}
                    isEqualTo={true}
                ></RowTextComponent>
            </Stack>
        </Card>
    );
};

export default Block0501;
