import { Typography } from '@mui/material';

const Table0403 = ({ arrayValue1, arrayValue2, arrayCheck }) => {
    return (
        <div className="w-full overflow-x-auto sm:rounded-lg flex justify-center items-center flex-col">
            <div className="w-10/12 pb-1 flex pl-10">
                <Typography style={{ color: '#02457a' }}>
                    <strong>採用値　</strong>
                </Typography>
                <Typography>(2行目はボルト間隔)</Typography>
            </div>
            <table className="text-right pb-3">
                <thead className="text-right text-sm dark:bg-gray-700">
                    <tr className="h-[32px]">
                        {arrayValue1 &&
                            arrayValue1.map((value, index) => (
                                <td
                                    className={`border border-solid ${
                                        (index < 6 && parseFloat(arrayCheck[index]?.value) < 0) || (index > 6 && parseFloat(arrayCheck[index]?.value) > 0)
                                            ? 'bg-[#f4e6e6]  border-red-600'
                                            : 'bg-[#ccffcc]  border-gray-500'
                                    } w-[65px]  px-1 py-1`}
                                    key={index}
                                >
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value?.value}</Typography>
                                </td>
                            ))}
                    </tr>
                </thead>
                <tbody className="bg-white border-b dark:bg-gray-900 dark:border-gray-700 text-sm">
                    <tr className="h-[32px]">
                        {arrayValue2 &&
                            arrayValue2.map((value, index) => (
                                <td
                                    className={`border border-solid ${
                                        (index < 6 && parseFloat(arrayCheck[index]?.value) < 0) || (index > 6 && parseFloat(arrayCheck[index]?.value) > 0)
                                            ? 'bg-[#f4e6e6]  border-red-600'
                                            : 'bg-[#ccffcc]  border-gray-500'
                                    } w-[65px]  px-1 py-1`}
                                    key={index}
                                >
                                    <Typography sx={{ justifyContent: 'flex-end' }}>{value?.value}</Typography>
                                </td>
                            ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default Table0403;
