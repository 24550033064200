// material-ui

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

export default function OutlinedInput(theme) {
    return {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '10.5px 14px 10.5px 12px',
                },
                notchedOutline: {
                    borderColor: theme.palette.grey[300],
                },
                root: {
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.palette.secondary.main,
                    },
                    '&.Mui-focused': {
                        boxShadow: `0 0 0 1px black`,
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: `1px solid ${theme.palette.secondary.main}`,
                        },
                    },
                    '&.Mui-error': {
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.error.light,
                        },
                        '&.Mui-focused': {
                            boxShadow: `0 0 0 1px red`,
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: `1px solid red`,
                            },
                        },
                    },
                },
                inputSizeSmall: {
                    padding: '7.5px 8px 7.5px 12px',
                },
                inputMultiline: {
                    padding: 0,
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },
    };
}
