import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FieldStatus, UNIT } from 'utils/StringDefinition';
import { CompletedIcon, ErrorIcon, NAIcon } from './StatusIcon';

const RowTextComponent = (props) => {
    const {
        description,
        isEqualTo,
        descriptionWidth,
        physicSymbolWidth,
        resultInRowWidth,
        status,
        physicSymbol,
        valueCalculate,
        mathSymbol,
        valueStandard,
        valueStandardSymbol,
        unit,
        paddingLeftFit,
        resultTooltip,
        standardTooltip,
    } = props;
    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: descriptionWidth ? descriptionWidth : '40%', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 10 : 10 }}>
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: resultInRowWidth ? resultInRowWidth : '40%' }}>
                <ResultInRow
                    physicSymbol={physicSymbol}
                    isEqualTo={isEqualTo}
                    paintColor={paintColor}
                    unit={unit}
                    physicSymbolWidth={physicSymbolWidth}
                    valueResult={valueCalculate}
                    resultTooltip={resultTooltip}
                    mathSymbol={mathSymbol}
                    valueStandardSymbol={valueStandardSymbol}
                    valueStandard={valueStandard}
                    standardTooltip={standardTooltip}
                />
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 4.5 }}>
                    {/* {console.log(status)} */}
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};
export default RowTextComponent;

export const ResultInRow = (props) => {
    // console.log(props);
    return (
        <>
            <Typography sx={{ width: props?.physicSymbolWidth ? props?.physicSymbolWidth : '10%' }}>{props?.physicSymbol}</Typography>
            <Typography sx={{ width: '5%' }}>{props?.isEqualTo ? '=' : null}</Typography>
            {props?.resultTooltip !== undefined ? (
                <Tooltip title={`${props?.resultTooltip}`} placement="top" arrow>
                    <Typography sx={{ width: '20%', textAlign: 'right', color: props?.valueResult === 'エラー' ? 'red' : '' }}>{props?.valueResult !== '' ? props?.valueResult : '0'}</Typography>
                </Tooltip>
            ) : (
                <Typography sx={{ width: '20%', textAlign: 'right', color: props?.valueResult === 'エラー' ? 'red' : '' }}>{props?.valueResult !== '' ? props?.valueResult : '0'}</Typography>
            )}

            {props?.isEqualTo ? <Unit unit={props?.unit} /> : <Typography sx={{ width: '20%' }}></Typography>}
            <Typography sx={{ width: '10%', display: 'flex', justifyContent: 'center', color: props?.paintColor ? 'red' : '' }}>{props?.mathSymbol}</Typography>
            {props?.standardTooltip !== undefined ? (
                <Tooltip title={`${props?.standardTooltip}`} placement="top" arrow>
                    <Typography sx={{ width: '20%', textAlign: 'right', color: props?.valueStandard === 'エラー' ? 'red' : '' }}>
                        {props?.valueStandardSymbol && props?.valueStandardSymbol}
                        {/* {props?.valueStandard} */}
                        {props?.valueStandard !== '' ? props?.valueStandard : '0'}
                    </Typography>
                </Tooltip>
            ) : (
                <Typography sx={{ width: '20%', textAlign: 'right', color: props?.valueStandard === 'エラー' ? 'red' : '' }}>
                    {props?.valueStandardSymbol && props?.valueStandardSymbol}
                    {/* {props?.valueStandard} */}
                    {props?.valueStandard !== '' ? props?.valueStandard : '0'}
                </Typography>
            )}

            {props?.isEqualTo ? <Unit unit={props?.unit} /> : <Typography sx={{ width: '20%' }}></Typography>}
        </>
    );
};

export const RowTextSpecial = (props) => {
    const { description, isEqualTo, status, valueCalculate, mathSymbol, valueStandard, resultTooltip, standardTooltip } = props;
    const paintColor = status === FieldStatus.OK ? false : true;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'flex', width: '44%', justifyContent: 'flex-end', alignItems: 'center', paddingRight: 2 }}>
                {description && (
                    <Box component="div" sx={{ display: 'flex', width: '22%' }}>
                        <Typography>{description?.description1}</Typography>
                        <sup>2</sup>
                        <Typography>{description?.description2}</Typography>
                        <sup>2</sup>
                    </Box>
                )}
            </Box>

            <Box component="div" sx={{ display: 'inline-block', width: '37%' }}>
                <Box component="div" sx={{ display: 'flex', width: '100%' }}>
                    <Typography sx={{ width: '7%', paddingLeft: '0.5%' }}>{isEqualTo ? '=' : null}</Typography>

                    {resultTooltip !== undefined ? (
                        <Tooltip title={`${resultTooltip}`} placement="top" arrow>
                            <Typography sx={{ width: '25%', textAlign: 'right', color: valueCalculate === 'エラー' ? 'red' : '' }}>{valueCalculate}</Typography>
                        </Tooltip>
                    ) : (
                        <Typography sx={{ width: '25%', textAlign: 'right', color: valueCalculate === 'エラー' ? 'red' : '' }}>{valueCalculate}</Typography>
                    )}

                    <Typography sx={{ width: '19%' }}></Typography>
                    <Typography sx={{ width: '13%', display: 'flex', justifyContent: 'center', color: paintColor ? 'red' : '' }}>{mathSymbol}</Typography>

                    {standardTooltip !== undefined ? (
                        <Tooltip title={`${standardTooltip}`} placement="top" arrow>
                            <Typography sx={{ width: '25%', textAlign: 'right', color: valueStandard === 'エラー' ? 'red' : '' }}>{valueStandard}</Typography>
                        </Tooltip>
                    ) : (
                        <Typography sx={{ width: '25%', textAlign: 'right', color: valueStandard === 'エラー' ? 'red' : '' }}>{valueStandard}</Typography>
                    )}

                    <Typography sx={{ width: '20%' }}></Typography>
                </Box>
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: '34px' }}>
                    {/* {console.log(status)} */}
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};

export const Unit = ({ unit }) => {
    function RenderUnitText(unit) {
        switch (unit) {
            case UNIT.mm:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>{UNIT.mm}</Typography>
                    </Box>
                );

            case UNIT.Nmm2:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>N/mm</Typography>
                        <sup>2</sup>
                    </Box>
                );
            case UNIT.kN:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>kN</Typography>
                    </Box>
                );
            case UNIT.N:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography sx={{ width: '20%' }}>N</Typography>
                    </Box>
                );

            case UNIT.kNmm2:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>KN/mm</Typography>
                        <sup>2</sup>
                    </Box>
                );

            case UNIT.kata:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.kata}</Typography>
                    </Box>
                );

            case UNIT.hon:
                return (
                    <Box component="div" sx={{ display: 'flex', width: '20%' }}>
                        <Typography>{UNIT.hon}</Typography>
                    </Box>
                );

            case UNIT.description1:
                return (
                    <Box component="div">
                        <Typography>{UNIT.description1}</Typography>
                    </Box>
                );

            case UNIT.description2:
                return (
                    <Box component="div">
                        <Typography>{UNIT.description2}</Typography>
                    </Box>
                );

            case `${UNIT.degree}   ※標準は3とする`:
                return (
                    <Box component="div" sx={{ display: 'flex' }}>
                        <Typography>{UNIT.degree} ※標準は3とする</Typography>
                    </Box>
                );

            case 'none':
                return <Box component="div" sx={{ display: 'flex', width: '20%' }}></Box>;

            default:
                return <Box component="div" sx={{ display: 'flex', width: '20%' }}></Box>;
        }
    }

    const renderView = RenderUnitText(unit);

    return <>{renderView}</>;
};

export const RowNumberOfBolts = (props) => {
    const { description, status, valueAM197, valueAS197, mathSymbol, paddingLeftFit } = props;

    return (
        <Box component="div" sx={{ width: '100%', display: 'flex', alignItems: 'center', marginBottom: '4px !important', marginTop: '0px !important' }}>
            <Box component="div" sx={{ display: 'inline-block', width: '40%', alignItems: 'center', paddingLeft: paddingLeftFit ? paddingLeftFit + 10 : 10 }}>
                {description && <Typography>{description}</Typography>}
            </Box>

            <Box component="div" sx={{ display: 'flex', flexDirection: 'row', width: '40%' }}>
                <Typography sx={{ width: '9.5%' }}>{props?.physicSymbol}</Typography>
                <Typography sx={{ width: '5%' }}>{props?.isEqualTo ? '=' : null}</Typography>
                <Typography sx={{ textAlign: 'right', color: props?.valueAM197 === 'エラー' ? 'red' : '' }}>{valueAM197} </Typography>
                <Typography>&nbsp; {mathSymbol} &nbsp;</Typography>
                <Typography sx={{ textAlign: 'right', color: props?.paddingLeftFit === 'エラー' ? 'red' : '' }}> {valueAS197}</Typography>
                <Typography> &nbsp; × &nbsp;1.0</Typography>
            </Box>

            {status && (
                <Box component="div" sx={{ display: 'inline-block', width: '20%', paddingLeft: 4.5 }}>
                    {status === FieldStatus.OK ? <CompletedIcon /> : status === FieldStatus.NG ? <ErrorIcon /> : <NAIcon />}
                </Box>
            )}
        </Box>
    );
};
